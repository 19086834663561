import "react-accessible-accordion/dist/fancy-example.css";

import React, { Component } from "react";

import Accordian from "../components/Accordian";
import { Col } from "reactstrap";

export default class SchedulerSidebar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      providers,
      selectedTeamValue,
      handleSkillsChange,
      selectedSkillValue,
      createOpenShiftOpen,
      onDragStart,
      selectedScheduleBlockValue,
      selectedQHPGroup,
      onClickChangeQHP,
      allProvidersList,
      facility,
      handleFacilityChange,
      selectedfacilityValue,
      handleDepartmentChange,
      selectedDepartmentValue,
      selectedFilterLabourType,
      onSelectedFilterLabourType,
      labourTypes
    } = this.props;

    let providersList = [];

    if (this.props.providers) {
      providersList = this.props.providers.map(provider => {
        return {
          title: provider.providerName,
          id: provider.providerCode,
          classNames: "schedule-fc-event",
          labourType: provider.labourType
        };
      });
    }

    return (
      <React.Fragment>
        <Col lg={12} sm={12} md={12} className="scheduler-sidebar">
          <Accordian
            teams={this.props.teams}
            skills={this.props.skills}
            handleTeamChange={this.props.handleTeamChange}
            providers={providersList}
            selectedTeamValue={selectedTeamValue}
            handleSkillsChange={handleSkillsChange}
            selectedSkillValue={selectedSkillValue}
            onClick={createOpenShiftOpen}
            onDragStart={onDragStart}
            selectedScheduleBlockValue={selectedScheduleBlockValue}
            selectedQHPGroup={selectedQHPGroup}
            onClickChangeQHP={onClickChangeQHP}
            allProvidersList={allProvidersList}
            facility={facility}
            handleFacilityChange={handleFacilityChange}
            selectedfacilityValue={selectedfacilityValue}
            handleDepartmentChange={handleDepartmentChange}
            selectedDepartmentValue={selectedDepartmentValue}
            onSelectedFilterLabourType={onSelectedFilterLabourType}
            selectedFilterLabourType={selectedFilterLabourType}
            labourTypes={labourTypes}
            style={{height: '100%'}}
          />
        </Col>
      </React.Fragment>
    );
  }
}
