import React, { Fragment } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

const UserCard = (props) => {

  return (
    <Fragment>
      {
        props.topProviders != null ?
        <div className="top-providers col-providers">
          <span className="box-title">Top sourced providers</span>
          {
            props.topProviders && props.topProviders.length > 0 ?
              <>
                {
                  props.topProviders.map((provaider, index) => {
                    return (<span className="providers-title" key={(index+1).toString()}>{`${index+1}. ${provaider.providerName ? provaider.providerName : ''} (${provaider.acquiredShifts})`}</span>)
                  })
                }
              </>
            : null            
          }
      </div>
        : <div className="top-providers col-providers"><CircularProgress style={{ display: 'flex' }} /></div>
      }
    </Fragment>
  );
}

export default UserCard;