import React from "react";

export default ({ width = "26px", height = "26px" }) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M1.04568245,45.6528938 C1.20182924,46.924753 2.49109404,47.8536451 3.94651811,47.7428938 L9.38272981,47.7428938 C10.8596911,47.8773704 12.1846185,46.944813 12.3520891,45.6528938 L12.3520891,34.1028938 C12.1846185,32.8109746 10.8596911,31.8784171 9.38272981,32.0128938 L3.90083565,32.0128938 C2.44541159,31.9021424 1.15614679,32.8310346 1,34.1028938 L1.04568245,45.6528938 Z M15.8467967,46.01 C15.8467967,47.07 17.1601671,47.93 18.7590529,47.93 L24.2409471,47.93 C25.8512535,47.93 27.164624,47.07 27.164624,46.01 L27.164624,29.91 C27.164624,28.86 25.8512535,28 24.2409471,28 L18.7590529,28 C17.1601671,28 15.8467967,28.86 15.8467967,29.91 L15.8467967,46.01 Z M30.6935933,45.7051125 C30.773602,47.0350592 32.0747566,48.0591935 33.6058496,47.9973395 L39.0877437,47.9973395 C40.6165634,48.0537112 41.9139363,47.0325534 42,45.7051125 L42,17.2854671 C41.9139622,15.9594654 40.6146187,14.9411839 39.0877437,15.0031632 L33.6058496,15.0031632 C32.0767042,14.9356526 30.7735322,15.9569345 30.6935933,17.2854671 L30.6935933,45.7051125 Z"
          fill="#1C3A54"
        ></path>
        <path
          d="M28.0472559,9.96145537 L18.4379986,23.9980516 C17.9968905,24.6475783 17.3164856,25.0186529 16.6021443,24.9992772 C15.887803,24.9799014 15.2232164,24.5723455 14.8089706,23.8996182 L7.60202772,12.0876158 L4.19448969,18.2298571 C3.76535758,19.0246279 3.00187085,19.4944922 2.19162758,19.4624567 C1.3813843,19.4304212 0.647479706,18.9013528 0.266368562,18.0745464 C-0.114742582,17.2477401 -0.0851604252,16.2488073 0.343971719,15.4540365 L5.45527876,5.94537455 C5.85723657,5.1976679 6.55743865,4.73336449 7.31893822,4.70958353 C8.08043779,4.68580256 8.80132226,5.10572668 9.23764597,5.82725453 L16.6490412,17.993617 L24.4182279,6.8509614 L20.397333,3.26798734 L32,0 L32,13.3869361 L28.0472559,9.96145537 Z"
          fill="#0EAFE6"
        ></path>
      </g>
    </g>
  </svg>
);
