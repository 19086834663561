import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthHeader from "../containers/AuthHeader";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import SideNavigation from "../containers/SideNavigation";
import { getToken } from "../settings";
import Idle from 'react-idle';
import AlertModal from '../components/AlertModal'
import { getUserType } from "../settings/userSetting";

const AppRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let api_token = getToken();
      let userType = getUserType()
      if (!api_token && props.location.pathname != "/layout/register") {
        return (
          <div>
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          </div>
        );
      } else if (!api_token && props.location.pathname === "/layout/register") {
        return (
          <div>
            <Redirect
              to={{
                pathname: "/layout/register",
                state: { from: props.location }
              }}
            />
          </div>
        );
      } else if (!api_token && props.location.pathname === "/privacy-policy") {
        return (
          <div>
            <Redirect
              to={{
                pathname: "/privacy-policy",
                state: { from: props.location }
              }}
            />
          </div>
        );
      } else if (api_token && userType != 'generic' && userType == 'department' && props.location.pathname === "/layout/dashboard") {
        return (
          <div>
           <Redirect
              to={{
                pathname: "/layout/users",
                state: { from: props.location }
              }}
            />
          </div>
        );
      } else if (api_token && userType == 'generic' && userType != 'department' && props.location.pathname === "/layout/dashboard") {
        return (
          <div>
           <Redirect
              to={{
                pathname: "/layout/generic-dashboard",
                state: { from: props.location }
              }}
            />
          </div>
        );
      } else if (api_token && userType != 'generic' && userType != 'department' && props.location.pathname === "/layout/generic-dashboard") {
        return (
          <div>
           <Redirect
              to={{
                pathname: "/layout/dashboard",
                state: { from: props.location }
              }}
            />
          </div>
        );
      } else if (api_token) {
        return (
          <div>
            <Header {...props} />
            <SideNavigation />
            <Idle
                timeout={300000}
                onChange={({ idle }) => console.log({ idle })}
                render={({ idle }) =>
                  <span>
                    <AlertModal idle={idle}/>
                  </span>
                }
              />
            <Component {...props} />
            {/* <Footer {...props} /> */}
          </div>
        );
      }
    }}
  />
);

export default AppRoute;
