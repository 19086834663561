import React, {useEffect, useState} from "react"
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalPopUp from "../components/ModalPopUp";
import Button from "@material-ui/core/Button";
import moment from 'moment';
import 'moment-timezone';
import jstz from "jstz";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import SelectDropdown from "../components/SelectDropdown";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Alert, AlertTitle } from '@material-ui/lab';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
// import { TimePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker
} from "@material-ui/pickers";
import "date-fns";

const COMMENTS = [
    { label: "Additional patients", value: "Additional patients" },
    { label: "Additional time", value: "Additional time" },
    { label: "Covering for another provider", value: "Covering for another provider" },
    { label: "Other - (add comments)", value: "Other" }
  ];

  const materialTheme = createMuiTheme({
    overrides: {
        PrivateNotchedOutline:{
            legendNotched: {
              paddingRight:'20px'
            }
          },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#33a5db"
        }
      },
      PrivateNotchedOutline:{
        legendNotched: {
          paddingRight:'20px'
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {}
      },
      MuiPickersDay: {
        day: {},
        daySelected: {
          backgroundColor: "#33a5db"
        },
        dayDisabled: {},
        current: {}
      },
      MuiPickersModal: {
        dialogAction: {}
      },
      MuiOutlinedInput:{
        input:{
          paddingTop:11,
          paddingBottom:11
        }
      }
    }
  });
// const materialTheme = createMuiTheme({
//     overrides: {
//       PrivateNotchedOutline:{
//         legendNotched: {
//           paddingRight:'20px'
//         }
//       },
//       MuiOutlinedInput: {
//         root: {
//         },
//     }
//   }
// });

const RemoveAssignedShift = ({ openRemoveShift, closeRemoveShift, shiftId, eventId, startTime, endTime, isSource, selectedShift, selectedShiftTitle, selectedShiftDate, onEditAssignedShift, onRemoveAssignedShift, shiftStartTime, shiftEndTime, getTimeDifferenceFlag, addOnHours, askRate, addOnHourAskRate, isPTO, currentComment, currentOtherComment, comments, onCallOpenShift }) => {
    const [providerStartTime, setProviderStartTime] = useState()
    const [providerEndTime, setProviderEndTime] = useState()
    const [providerEventId, setProviderEventId] = useState(eventId)
    const [isSourceShift, setSourceShift] = useState(isSource)
    const [isOnCallOpenShift, setIsOnCallOpenShift] = useState(onCallOpenShift)
    const [shiftStart, setShiftStart] = useState(shiftStartTime)
    const [shiftEnd, setShiftEnd] = useState(shiftEndTime)
    const [diffHours, setDiffHours] = useState(addOnHours)
    const [askRates, setAskRates]= useState(askRate)
    const [addOnHourRate, setAddOnHourRate]= useState(addOnHourAskRate)
    const [isPTOShift, setIsPTOShift] = useState(isPTO)
    const [comment, setComment] = useState(currentComment)
    const [otherComment, setOtherComment] = useState(currentOtherComment)
    const [validateUpdateShiftMessage, setValidateUpdateShiftMessage] = useState("")
    
    
    
    useEffect(()=> {
        setIsPTOShift(isPTOShift)
    },[isPTOShift])

    useEffect(()=> {
        setIsOnCallOpenShift(isOnCallOpenShift)
    },[onCallOpenShift])
    
    useEffect(()=> {
        setOtherComment(currentOtherComment)
    },[currentOtherComment])
    
    useEffect(()=> {
        setOtherComment(otherComment)
    },[otherComment])

    useEffect(()=> {
        setComment(currentComment)
    },[currentComment])

    useEffect(()=> {
        setComment(comment)
    },[comment])

    useEffect(()=> {
        setAskRates(addOnHourAskRate)
    },[addOnHourAskRate])

    useEffect(()=> {
        setAskRates(askRate)
    },[askRate])

    useEffect(()=> {
        let providerStartTime = new Date(selectedShiftDate)
        providerStartTime.setHours(startTime.split(":")[0])
        providerStartTime.setMinutes(startTime.split(":")[1])
        setProviderStartTime(providerStartTime)
    },[startTime])

    useEffect(()=> {
        let providerEndTime = new Date(selectedShiftDate)
        providerEndTime.setHours(endTime.split(":")[0])
        providerEndTime.setMinutes(endTime.split(":")[1])
        setProviderEndTime(providerEndTime)
    },[endTime])

    useEffect(()=> {
        setProviderEventId(eventId)
    },[eventId])

    useEffect(()=> {
        setDiffHours(addOnHours)
    },[addOnHours])

    useEffect(()=> {
        setSourceShift(isSource)
    },[isSource])

    useEffect(()=> {
        setShiftStart(shiftStart)
    },[shiftStart])

    useEffect(()=> {
        setShiftEnd(shiftEnd)
    },[shiftEnd])

    useEffect(()=> {
        setDiffHours(diffHours)
    },[diffHours])
    
    // const getDiffHours = () => {
    //     if(shiftStart && shiftEnd && providerStartTime && providerEndTime) {
    //         let DutyDayStartTime = moment([shiftStart.split(":")[0],shiftStart.split(":")[1]], "HH:mm")
    //         let DutyDayEndTime = moment([shiftEnd.split(":")[0],shiftEnd.split(":")[1]], "HH:mm")
    //         let currentShiftTime = DutyDayStartTime.diff(DutyDayEndTime, 'hours')
    //         currentShiftTime = (24+currentShiftTime);
    //         let providerStartTimeTemp = moment([providerStartTime.split(":")[0],providerStartTime.split(":")[1]], "HH:mm")
    //         let providerEndTimeTemp = moment([providerEndTime.split(":")[0],providerEndTime.split(":")[1]], "HH:mm")
    //         let providerTime= providerStartTimeTemp.diff(providerEndTimeTemp, 'hours')
    //         providerTime = (24+providerTime);
    //         let diff = (currentShiftTime - providerTime)
    //         return diff;
    //     } else {
    //         return diffHours;
    //     }
    // }


    // const getMoonLight = () => {
    //     if(shiftStart && shiftEnd && providerStartTime && providerEndTime) {
    //         let DutyDayStartTime = moment([shiftStart.split(":")[0],shiftStart.split(":")[1]], "HH:mm")
    //         let DutyDayEndTime = moment([shiftEnd.split(":")[0],shiftEnd.split(":")[1]], "HH:mm")
    //         var shiftDuration = moment.duration(DutyDayEndTime.diff(DutyDayStartTime));
    //         var shiftHours = (shiftDuration.asHours() ? shiftDuration.asHours() : 24);
    //         let providerStartTimeTemp = moment([providerStartTime.split(":")[0],providerStartTime.split(":")[1]], "HH:mm")
    //         let providerEndTimeTemp = moment([providerEndTime.split(":")[0],providerEndTime.split(":")[1]], "HH:mm")
    //         var providerDuration = moment.duration(providerEndTimeTemp.diff(providerStartTimeTemp));
    //         var providerHours = (providerDuration.asHours() ? providerDuration.asHours() : 24);
    //         if(providerHours > shiftHours) {
    //             let diff = Math.abs((providerHours - shiftHours))
    //             setDiffHours(diff)
    //         } else {
    //             setDiffHours(0)
    //         }
    //     }
    // }

    const getMoonLight = () => {
        if(shiftStart && shiftEnd && providerStartTime && providerEndTime) {
            let DutyDayStartTime = moment([shiftStart.split(":")[0],shiftStart.split(":")[1]], "HH:mm")
            let DutyDayEndTime = moment([shiftEnd.split(":")[0],shiftEnd.split(":")[1]], "HH:mm")
            var shiftDuration = moment.duration(DutyDayEndTime.diff(DutyDayStartTime));
            var shiftHours = (shiftDuration.asHours() ? shiftDuration.asHours() : 24);
            let providerStartTimeTemp = moment([providerStartTime.getHours(),providerStartTime.getMinutes()], "HH:mm")
            let providerEndTimeTemp = moment([providerEndTime.getHours(),providerEndTime.getMinutes()], "HH:mm")
            if(!providerStartTimeTemp.isBefore(providerEndTimeTemp)) {
                var now = moment(new Date(selectedShiftDate))
                // now = now.utcOffset(0);
                now = now.set({hour:providerStartTime.getHours(),minute:providerStartTime.getMinutes(),second:0,millisecond:0})
                
                var then = moment(new Date(selectedShiftDate))
                then = then.add(1, "days")
                // then = then.utcOffset(0);
                then = then.set({hour:providerEndTime.getHours(),minute:providerEndTime.getMinutes(),second:0,millisecond:0})
               
                var ms = moment(now,"DD/MM/YYYY HH:mm").diff(moment(then,"DD/MM/YYYY HH:mm"));
                var d = moment.duration(ms);
                var s = d.asHours()
                // var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm");
               
                setDiffHours(Math.trunc(Math.abs(s) - Math.abs(shiftHours)))
            } else {
               
                // let providerStartTimeTemp = moment([providerStartTime.split(":")[0],providerStartTime.split(":")[1]], "HH:mm")
                // let providerEndTimeTemp = moment([providerEndTime.split(":")[0],providerEndTime.split(":")[1]], "HH:mm")
                var providerDuration = moment.duration(providerEndTimeTemp.diff(providerStartTimeTemp));
                var providerHours = (providerDuration.asHours() ? providerDuration.asHours() : 24);
                if(providerHours > shiftHours) {
                    let diff = Math.trunc(Math.abs(providerHours) - Math.abs(shiftHours))
                    
                    setDiffHours(diff)
                } else {
                    setDiffHours(0)
                }
            }
        }
    }

    // useEffect(()=> {
    //    getMoonLight();
    // },[shiftStart, shiftEnd, providerStartTime, providerEndTime])

    useEffect(()=> {
        if((providerStartTime !== startTime) || (providerEndTime !== endTime)) {
            getMoonLight();
        }
     },[providerStartTime])

     useEffect(()=> {
        if((providerStartTime !== startTime) || (providerEndTime !== endTime)) {
            getMoonLight();
        }
     },[providerEndTime])

    const errorStyle = {
        color: "#FF0000"
    };

    const onEditShift = () => {
        let data = {
            startTime: `${providerStartTime.getHours()}:${providerStartTime.getMinutes()}`,
            endTime: `${providerEndTime.getHours()}:${providerEndTime.getMinutes()}`,
            isSourceShift: isSourceShift,
            addOnHours: diffHours ? diffHours : 0,
            addOnHourRate: diffHours ? addOnHourRate : 0,
            id:shiftId,
            comment: diffHours ? (comment == "Other" ? otherComment : comment) : "",
            onCallOpenShift: isOnCallOpenShift ? isOnCallOpenShift : false
        }
        onEditAssignedShift(data)
    }

    const onRemoveShift = () => {
        onRemoveAssignedShift(providerEventId, shiftId)
    }

    const onChangeComment = (editorState) => {
        if(editorState && editorState.target !== undefined && editorState.target.value != 'Other') {
            setComment(editorState.target.value)
            setOtherComment("")
        } else {
            setComment("Other")
            setOtherComment(otherComment ? otherComment : "")
        }
    };

    // const providerStartTimeTemp = moment([providerStartTime.split(":")[0],providerStartTime.split(":")[1]], "HH:mm")
    // const providerEndTimeTemp = moment([providerEndTime.split(":")[0],providerEndTime.split(":")[1]], "HH:mm")
    return (
        <ModalPopUp
            onClose={() => closeRemoveShift()}
            isModalOpen={openRemoveShift}
        >
            <div className={"modal-paper modal-popper"}>
                <div className="modal-header">
                    <h2 id="modal-title">{`Edit Assigned Shift of : ${selectedShiftTitle}`}</h2>
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={()=>closeRemoveShift()}
                        className="faiconsStyle"
                    />
                </div>
                <div className="modal-body">
                
                    {validateUpdateShiftMessage ? (
                        <Alert style={{
                            marginBottom:10,
                            fontSize:10,
                            fontWeight:'bold'
                          }} icon={<InfoOutlined />} variant="outlined" severity="info">
                              {validateUpdateShiftMessage}
                        </Alert>
                    ) : null}
                    <div className="repeat-every">
                        <div className="modal-row">
                            <TextField
                                id="title"
                                label="Title"
                                margin="dense"
                                variant="outlined"
                                disabled={true}
                                value={selectedShiftTitle}
                                onChange={() => console.log("Title")}
                                defaultValue={selectedShiftTitle}
                                inputProps={{ maxLength: 15 }}
                            />
                        </div>
                    </div>
                    <div className="repeat-every">
                        <div className="modal-row">
                            <TextField
                                id="date"
                                label="Date"
                                margin="dense"
                                variant="outlined"
                                defaultValue={moment(
                                    new Date(selectedShiftDate),
                                    "ddd DD-MMM-YYYY, hh:mm A"
                                ).tz((jstz.determine().name())).format("MM-DD-YYYY")}
                                disabled
                                inputProps={{ maxLength: 15 }}
                            />
                        </div>
                    </div>
                    <div className="repeat-every">
                        <div className="modal-row">
                            <Grid container item xs={4} sm={4}>
                                <div style={{ }}>
                                    <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={isOnCallOpenShift ? true : false}
                                        value={isOnCallOpenShift}
                                        onChange={(event) => setIsOnCallOpenShift(!isOnCallOpenShift)}
                                        color="primary"
                                        />
                                    }
                                    label={"Is On Call Shift"}
                                    />
                                </div>
                            </Grid>
                        </div>
                    </div>
                    <div className="repeat-every">
                        <div className="modal-row">
                           <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    clearable={false}
                                    ampm={false}
                                    label="Start time"
                                    minutesStep={5}
                                    inputVariant="outlined"
                                    value={providerStartTime}
                                    onChange={(event) => {
                                        setProviderStartTime(event)
                                    }}
                                />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </div>
                    </div>
                    <div className="repeat-every">
                        <div className="modal-row">
                        <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    clearable={false}
                                    ampm={false}
                                    label="End time"
                                    minutesStep={5}
                                    inputVariant="outlined"
                                    value={providerEndTime}
                                    onChange={(event) => {
                                        setProviderEndTime(event)
                                    }}
                                />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </div>
                    </div>
                    {
                        (diffHours) ? 
                        <>
                            <ThemeProvider theme={materialTheme}>
                                <div className="repeat-every">
                                    <div className="modal-row">
                                    <TextField
                                            id="addon-hours-rate"
                                            label={`Addon Hours Rate`}
                                            type="number"
                                            value={addOnHourRate}
                                            margin="dense"
                                            min="1"
                                            variant="outlined"
                                            onChange={(event) => {
                                                setAddOnHourRate(event.target.value)
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{ maxLength: 15 }}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    $
                                                  </InputAdornment>
                                                ),
                                              }}
                                        />
                                    </div>
                                </div>
                            </ThemeProvider>
                            <div className="repeat-every">
                                <div className="modal-row">
                                  {
                                    COMMENTS && COMMENTS.length && <SelectDropdown
                                        label="Select comment"
                                        selectComment={COMMENTS}
                                        handleChange={onChangeComment}
                                        selected={comment}
                                        selectedValue={comment}
                                        name="comment"
                                        id="comment"
                                        className={"open-shift-skill-modal"}
                                        style={{ textAlign: "center" }}
                                    />
                                }
                                </div>
                              </div>
                            {
                              comment && comment == 'Other' ? 
                                <div className="repeat-every">
                                    <div className="modal-row">
                                        <TextareaAutosize
                                            rowsMin={5}
                                            style={{ width: "100%", height:"100%", fontSize:14 }}
                                            variant="outlined"
                                            onChange={e=>setOtherComment(e.target.value)}
                                            aria-label="maximum height"
                                            placeholder={otherComment ? otherComment : "Add your comment here"}
                                            defaultValue={otherComment ? otherComment : ""}
                                        />
                                    </div>
                                </div>
                              : null
                            }
                            </>
                        : null
                    }
                    
                     {/* <div style={{ width: 400, marginLeft: "2%" }} className="repeat-every">
                        <div className="modal-row" style={{ width: 400, marginLeft: "2%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSourceShift ? true :  false}
                            //   disabled={true}
                              onChange={(event)=>setSourceShift(!isSourceShift)}
                              value={isSourceShift}
                              color="primary"
                            />
                          }
                          style={{ width: 400}}
                          label={"Moonlight Shift"}
                        />
                      </div>
                    </div>  */}
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="primary"
                        className="modal-submit"
                        // disabled={(validateUpdateShiftMessage !== "" ? true : false)}
                        onClick={() => onEditShift()}
                    >
                        Save
                </Button>

                    <Button
                        variant="contained"
                        className="button-cancel"
                        color="secondary"
                        style={{ backgroundColor: "#ff6262" }}
                        onClick={() => onRemoveShift()}
                    >
                        Delete Shift
                </Button>
                    <Button
                        variant="contained"
                        className="button-cancel"
                        color="secondary"
                        style={{ backgroundColor: "#ff6262" }}
                        onClick={()=>closeRemoveShift()}
                    >
                        Close
                </Button>
                </div>
            </div>
        </ModalPopUp>
    )
}

export default RemoveAssignedShift;

