import axios from "axios";
import jstz from "jstz";

const API_SERVER_URL = process.env.REACT_APP_API_URL;

export const baseURL = `${API_SERVER_URL}/`;

export const getToken = () => {
  let AuthType = "Bearer ";
  let token = localStorage.getItem("api_token");
  if (token) {
    return AuthType + token;
  } else {
    return 0;
  }
};

export const isTesselMedAdmin = () => {
  let isTesselMedAdmin = localStorage.getItem("isTesselMedAdmin");
  if (isTesselMedAdmin == "true") {
    return true;
  } else {
    return 0;
  }
};

export const isSchedulerView = () => {
  let isSchedulerView = localStorage.getItem("isSchedulerView");
  if (isSchedulerView == "true") {
    return true;
  } else {
    return 0;
  }
};

export const isGenericView = () => {
  let isSchedulerView = localStorage.getItem("generic");
  if (isSchedulerView == "true") {
    return true;
  } else {
    return 0;
  }
};

export const userName = () => {
  let userName = localStorage.getItem("userName");
  if (userName) {
    return userName;
  } else {
    return "";
  }
};

export const formatStandardDate = input => {
  var date = new Date(input);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  }
};

export const formatForCreateOpenShiftDate = input => {
  var date = new Date(input);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return (
      date.getFullYear() + "-" +  (date.getMonth() + 1) + "-" + date.getDate()
    );
  }
};

export const setToken = (token = null) => {
  localStorage.removeItem("api_token");
  localStorage.removeItem("userCode");
  localStorage.removeItem("isTesselMedAdmin");
  localStorage.removeItem("is_org_user");
  localStorage.removeItem("is_fac_user");
  localStorage.removeItem("is_dep_user");
  localStorage.removeItem("isSchedulerView");
  localStorage.removeItem("generic");
  return 0;
};

export const instanceSignIn = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache"
  }
});

export const convertFormData = async data => {
  var formData = new FormData();
  for (var k in data) {
    formData.append(k, data[k]);
  }
  return formData;
};


export const convertTZ = (date) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: (jstz.determine().name())}));   
}

export const isIncludingString = (string, option) => {
  let result = false;
  if (
    !string ||
    option.label.toString().includes(string) ||
    option.value.toString().includes(string)
  ) {
    result = true;
  }
  return result;
}
