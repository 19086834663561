import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      paddingTop:0,
      paddingBottom:0,
      minWidth:150,
      width:150
    },
    color: "yellow",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0
    },
    checked: {
      color: "#33a5d !important",
    },
    outline: {
    },
    noLabel: {
    },
    MuiOutlinedInput:{
      input:{
      }
    },
    checked:{
      color: "#33a5d !important",
    },
    selected: {
    }
  }));
  
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 190
      },
    },
  };
  
  const MultiSelect = ({label, options, selectedValues=[], values=[], onChange, selectKey}) => {
    const classes = useStyles();
    const [labelWidth, setLabelWidth] = React.useState(0)
    const [selectOptions, setSelectOptions] = React.useState(options)
    const [optionFilter, setOptionFilter] = React.useState(options)
    const [optionFilterText, setOptionFilterText] = React.useState("")
    
    const theme = useTheme();
    let inputLabel = React.useRef(null);
  
    React.useEffect(() => {
      setSelectOptions(options);
      setOptionFilter(options);
    }, [options]);

    React.useEffect(() => {
      setOptionFilter(optionFilter);
    }, [optionFilter]);
    
    const filterList = async (event) => {
      // setOptionFilterText(event.target.value)
      if(event.target && event.target.value) {
        let filteredDataArray = [];
        filteredDataArray = optionFilter && optionFilter.filter(
          c => {
            return c["label"] && c["label"] != "" && c["label"]
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
          }
        );
        setOptionFilter(filteredDataArray)
      } else {
        setOptionFilter(selectOptions)
      }
    }

    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    const handleChange = (event, props) => {
      if(event.target.value.length > 0) {
        let selectedOption = [];
        if((event.target.value.filter(value => value === -1).length !== 0) && props.props.value === -1) {
          options.forEach((option) => {
            selectedOption.push(option)
          });
        } else if(props.props.value === -1 && (event.target.value.filter(value => value === -1).length === 0)) {
            selectedOption = [];
            onChange(selectedOption);
        } else if(
          (event.target.value.filter(value => value === -1).length !== 0 && props.props.value !== -1) ||
          (props.props.value !== -1 && ((options.length) - 1) === event.target.value.length)) {
            options.forEach((option) => {
            if(event.target.value.findIndex(value => value === option.value) > -1) {             
              selectedOption.push(option)
            }
            if(props.props.value !== -1) {
              if((selectedOption.findIndex(option => option.value === -1)) > -1) {
                selectedOption.splice(selectedOption.findIndex(option => option.value === -1), 1);
              }
              
            } 
            
          });
          if(event.target.value.findIndex(value => value === -1) === -1 && (((options.length) - 1) === event.target.value.length)) {
            options.forEach((option) => {
              if(option.value === -1) {
                selectedOption.push(option)
              }
            });
          } 
        } else  {
          options.forEach((option) => {
            if(event.target.value.findIndex(value => value === option.value) > -1) {
              selectedOption.push(option)
            }
          });
        }

        if(selectedOption.length > 0) {
          onChange(selectedOption);
        }
      } else {
        onChange([]);
      }  
    };
  
    return (
      <FormControl variant="outlined" className={clsx(classes.formControl)} key={selectKey}>
        <InputLabel shrink={selectedValues.length > 0 ? true : false}
          ref={inputLabel} htmlFor="outlined-adornment-amount">{label}</InputLabel>
          <Select
            key={"select"+selectKey}
            multiple
            value={selectedValues}
            onChange={(e,hh)=>handleChange(e, hh)}
            variant="outlined"
            MenuProps={{
              onEnter: () => {
                setOptionFilter("");
                setSelectOptions("")
              },
              onExit: () => {
                setOptionFilter("");
                setSelectOptions("")
              },
              disableAutoFocusItem: true,
              MenuListProps: {
                disableListWrap: true
              }
            }}
            input={<OutlinedInput classes={{notchedOutline: classes.outline}} labelWidth={selectedValues.length > 0 ? labelWidth : 0} notched={true} variant="outlined" />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{label}</em>;
              }
              let selectedArray = [];
              selected.forEach((selected) => {
                if(options.findIndex(option => option.value === selected) > -1) {
                  selectedArray.push(options[options.findIndex(option => option.value === selected)].label)
                }
              });
              return selectedArray.join(', ');
            }}
            MenuProps={MenuProps}
          >
            <TextField 
              autoFocus={true}
              onChange={filterList}
              // value={optionFilterText}
              label="Search"
              variant="filled"
              onKeyDown={e => {
                // Prevent MUI-Autoselect while typing
                e.stopPropagation();
              }}
              style={{position:"relative", paddingBottom:10}}
             />
            {optionFilter.length !== 0 && optionFilter.map((option, key) => {
              return (
              <MenuItem classes={{ selected: classes.selected }} key={"itemkey"+option.value.toString()} value={option.value}>
                <Checkbox color="primary" checked={selectedValues.findIndex(value => value === option.value) > -1} />
                <ListItemText key={"itemkeyText"+option.value.toString()} primary={option.label} />
              </MenuItem>
            )})}
          </Select>
        </FormControl>
    );
  }

  export default MultiSelect;