import React from "react";

export default ({width = "26px", height = "26px"}) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="dept" transform="translate(21.500000, 20.000000) rotate(90.000000) translate(-21.500000, -20.000000) translate(1.000000, -2.000000)" fillRule="nonzero">
            <path d="M25.9041629,38.5155132 L31.8393063,38.5155132 C32.4824542,36.6945816 34.5560316,35.5576012 36.7166368,35.8411836 C38.8772421,36.124766 40.4751652,37.7436359 40.4751652,39.6489815 C40.4751652,41.5543272 38.8772421,43.1731971 36.7166368,43.4567795 C34.5560316,43.7403619 32.4824542,42.6033815 31.8393063,40.7824499 L24.6103017,40.7824499 C24.2640002,40.7824499 23.9318823,40.662486 23.68701,40.4489488 C23.4421379,40.2354116 23.3045702,39.945793 23.3045702,39.6438059 L23.3045702,22.8332805 L17.5,22.8332805 L17.5,20.5663438 L23.3045702,20.5663438 L23.3045702,4.3561943 C23.3045557,4.05515029 23.442501,3.76658479 23.6877205,3.55468874 C23.9329399,3.34279279 24.2650963,3.22513981 24.6103017,3.22790164 L31.8155657,3.22790164 C32.4589172,1.40103353 34.5380412,0.259444511 36.7052745,0.543099044 C38.8725077,0.826753578 40.4757139,2.45029787 40.4757139,4.36136995 C40.4757139,6.27244204 38.8725077,7.89598633 36.7052745,8.17964087 C34.5380412,8.4632954 32.4589172,7.32170638 31.8155657,5.49483827 L25.8804224,5.49483827 L25.8804224,20.5663438 L31.8155657,20.5663438 C32.4591196,18.7335404 34.5437891,17.5873428 36.7176498,17.871069 C38.8915106,18.1547953 40.5,19.7830138 40.5,21.6998121 C40.5,23.6166105 38.8915106,25.244829 36.7176498,25.5285553 C34.5437891,25.8122815 32.4591196,24.6660839 31.8155657,22.8332805 L25.8804224,22.8332805 L25.9041629,38.5155132 Z" id="Path" fill="#1C3A54" transform="translate(29.000000, 22.000000) scale(-1, -1) rotate(-180.000000) translate(-29.000000, -22.000000) "></path>
            <g id="Swap" transform="translate(8.500000, 22.000000) rotate(-90.000000) translate(-8.500000, -22.000000) translate(-1.000000, 14.000000)" fill="#0EAFE6">
                <path d="M19,11.2988685 L19,15.2948303 C18.7503533,15.7212057 18.1635176,15.9993591 17.5122642,16 L1.50566038,16 C0.829938193,15.996406 0.228982175,15.6946657 0,15.2440072 L0,11.2988685 C0.00494,10.0382546 1.44800936,9.01818444 3.22641509,9.01818444 L6.68584906,9.01818444 L9.56273585,12.0294497 L12.4396226,9.01818444 L15.7735849,9.01818444 C17.5519906,9.01818444 18.99506,10.0382546 19,11.2988685 L19,11.2988685 Z M4.07142857,3.76180059 C4.06092478,5.29317005 5.266537,6.6782541 7.12510338,7.2698968 C8.98366975,7.8615395 11.1282676,7.54293736 12.5568716,6.46295158 C13.9854755,5.38296572 14.4160473,3.75480966 13.6474098,2.33921036 C12.8787723,0.923610988 11.0626718,6.00138544e-06 9.04764902,6.00138544e-06 C6.30768334,-0.00366740033 4.08122798,1.67947234 4.07142857,3.76180059 L4.07142857,3.76180059 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
);
