import { SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_FAIL } from "./actionTypes";

import { instanceSignIn, convertFormData, baseURL } from "../settings";

export const signIn = postData => async dispatch => {
  try {
    dispatch({ type: SIGN_IN });
    const response = await instanceSignIn.post(
      `${baseURL}Authentication/Login`,
      await convertFormData(postData)
    );
    if (response.data.status) {
      dispatch({
        type: SIGN_IN_SUCCESS,
        signInPayload: response
      });
    }
    return response.data;
  } catch (error) {
    dispatch({ type: SIGN_IN_FAIL, error });
  }
};
