import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCountdownClock from '../../node_modules/react-countdown-clock';
import Grid from "@material-ui/core/Grid";
var timer;

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [time, setTime] = React.useState(30);
  const int = 30;

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes() + 1;
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const handleClickOpen = () => {
    setOpen(true);
    timer = setTimeout(function(){ console.log("Logout"); onLogout()}, 60000);
  };

  const handleClose = () => {
    clearTimeout(timer);
    setOpen(false);
  };

  const onLogout = () => {
    localStorage.removeItem("api_token");
    localStorage.removeItem("userCode");
    localStorage.removeItem("isTesselMedAdmin");
    localStorage.removeItem("is_org_user");
    localStorage.removeItem("is_fac_user");
    localStorage.removeItem("is_dep_user");
    localStorage.removeItem("loginUserType");
    localStorage.removeItem("generic");
    localStorage.clear();
    window.location.href = "/";
  }

  useEffect(() => {
    if(props.idle)   {
      handleClickOpen();
    } else {
      handleClose()
    }
  }, [props.idle]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h1 >Do you want to continue your session?</h1> </DialogTitle>
        <DialogContent>
          <h4> {`For security reasons your session will time out at ${formatAMPM(new Date)} unless you continue.`} </h4>
        </DialogContent>
      </Dialog>
    </div>
  );
}