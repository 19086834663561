import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import jstz from "jstz";
import ReactTooltip from 'react-tooltip'
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import {
  addProvider,
  changeOpenShiftSkillRate,
  deleteProvider,
  getAllProviders,
  getAllProvidersShift,
  getCeilingRate,
  getFacilityDepartment,
  getProviderByDepartmentSkill,
  getSaveSkills,
  getTeamScheduleBlock,
  getTeamScheduleDates,
  getTeamSkills,
  postCreateBlock,
  postOpenShift,
  postOpenShiftSave,
  resendNotificationOpenShift
} from "../actions/scheduler";
import { faPlus, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import Alert from "sweetalert2";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker from "../components/DatePicker";
import { EventEmitter } from "../components/events";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FullCalendar from "@fullcalendar/react";
import Grid from "@material-ui/core/Grid";
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import ModalPopUp from "../components/ModalPopUp";
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import ReactDOM from 'react-dom';
import SchedulerSidebar from "../containers/SchedulerSidebar";
import Select from "react-select";
import SelectDropdown from "../components/SelectDropdown";
import StaticDatePicker from '../common/DatePicker/index';
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from '@material-ui/core/Typography';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dayGridPlugin from "@fullcalendar/daygrid";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { formatStandardDate, convertTZ, formatForCreateOpenShiftDate } from "../settings/index";
import instance from "../utils/services/services";
import moment from 'moment';
import 'moment-timezone';
import styled from 'styled-components';
import timeGridPlugin from "@fullcalendar/timegrid";
import { toast } from "react-toastify";
import {withStyles} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker
} from "@material-ui/pickers";
import "date-fns";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import RemoveAssignedShift from "../components/RemoveAssignedShift";
import { Alert as InfoAlert } from '@material-ui/lab';
import { clone } from "lodash";
import {isIncludingString} from  "../settings/index"
import InputAdornment from '@material-ui/core/InputAdornment';

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const filterLabourTypes = [
  { label: "Per Diem", value: "PerDiem" },
  { label: "Locum", value: "Locum" },
  { label: "Employee", value: "Employee" },
  { label: "Volunteer", value: "Volunteer" }
];

const labourTypes = [
  { label: "Select all", value: "all" },
  { label: "Per Diem", value: "PerDiem" },
  { label: "Locum", value: "Locum" },
  { label: "Employee", value: "Employee" },
  { label: "Volunteer", value: "Volunteer" }
];

const COMMENTS = [
  { label: "Additional patients", value: "Additional patients" },
  { label: "Additional time", value: "Additional time" },
  { label: "Covering for another provider", value: "Covering for another provider" },
  { label: "Other - (add comments)", value: "Other" }
];
const notificationDefault = [
  { label: "Per Diem", value: "PerDiem", selected: false },
  { label: "Locum", value: "Locum", selected: false },
  { label: "Employee", value: "Employee", selected: false },
  { label: "Volunteer", value: "Volunteer", selected: false }
];

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#33a5db"
      }
    },
    PrivateNotchedOutline:{
      legendNotched: {
        paddingRight:'20px'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {}
    },
    MuiPickersDay: {
      day: {},
      daySelected: {
        backgroundColor: "#33a5db"
      },
      dayDisabled: {},
      current: {}
    },
    MuiPickersModal: {
      dialogAction: {}
    },
    MuiOutlinedInput:{
      input:{
        paddingTop:11,
        paddingBottom:11
      }
    }
  }
});

const FilterRow = styled.div`
  height: 3rem;
  margin-top: 1rem;
`;

export class Scheduler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCalendarClickEvent: {},
      isDepViewLocal: false,
      isLoading: false,
      dropdownFacility: false,
      depSelectionType: 'team',
      isDepView: false,
      isDepViewDisabled: false,
      dropdownDepartment: false,
      selectedfacilityValue:
        props.facilityPayload.data !== undefined &&
          props.facilityPayload.data.length > 0
          ? props.facilityPayload.data[0].facilityCode
          : "",
      defaultFacilityValue: [],
      selectedDepartmentValue:
        props.facilityPayload.data !== undefined &&
          props.facilityPayload.data.length > 0
          ? props.facilityPayload.data[0].department[0].departmentCode
          : "",
      selectedTeamValue: "",
      selectedSkillValue: "",
      selectedScheduleBlockValue: "",
      selectedTeamName: "",
      SelectedSkillName: "",
      filterPanelExpanded: {
        filter: true,
        name: true
      },
      checkedSkills: [],
      isCreateBlockModelOpen: false,
      isOpenShiftModelSuccessOpen: false,
      selectedStartBlockDate: "",
      selectedEndBlockDate: "",
      isOpenShiftModalOpen: false,
      skillRows: [
        {
          skillSelect: "",
          rateText: ""
        }
      ],
      saveSkillsState: [],
      selectedSkillList: [],
      saveSkillArray: [],
      maxAskRate: "",
      askedRate: "",
      dragStartDate: 0,
      dragProviderCode: null,
      currentTeamData: "",
      currentTeamDataDays: [],
      scheduleDatesState: props.scheduleDates.providerDatas
        ? props.scheduleDates.providerDatas
        : [],
      calenderScheduleData: [],
      calenderKey: Math.random() + "",
      defaultDate: this.formatDate(new Date()),
      startDateTime: null,
      endDateTime: null,
      isRepeatModeOpen: false,
      selectedQHPGroup: "all",
      repeats: 1,
      isRepeatChecked: false,
      repeatType: 1,
      recurrenceNoofTime: "",
      open: false,
      isOpenShiftRateChange: false,
      selectedOpenShift: {
        skillLevel: "",
        askRate: 0,
        date: new Date(),
        startTime: null,
        endTime: null,
        addOnHourRate: 0,
        isSourceShift: false,
        diffHour:0,
        comment: "",
        otherComment:""
      },
      selectedOpenShiftSkillValue: 0,
      isCreateOpenShiftModal: false,
      selectedCreateOpenShift: {
        skillLevel: "",
        askRate: 0,
        date: new Date(),
        labourType:[],
        askRate:false,
        onCallOpenShift:false
      },
      selectedCreateOpenShiftSkillValue: "",
      isDragStart: false,
      selectedCeilingRate: null,
      isSchedulerToggle: false,
      isPast: false,
      validateCreateOpenShiftMessage: "",
      validateUpdateOpenShiftMessage: "",
      selectedCreateOpenShiftLabourTypeValue: [],
      resendNotifications: [],
      notifications: [
        { label: "Per Diem", value: "PerDiem", selected: false },
        { label: "Locum", value: "Locum", selected: false },
        { label: "Employee", value: "Employee", selected: false },
        { label: "Volunteer", value: "Volunteer", selected: false }
      ],
      myView: 'dayGridMonth',
      selectedDays: [],
      droppedDate: new Date(),
      minDroppedDate:new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      maxDroppedDate:new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      selectedFilterLabourType:"",
      teamSkills:[],
      teamScheduleBlock:[],
      openRemoveShift:false,
      currentClikedProviderTitle: "",
      currentClikedProviderStartDate: null,
      currentClikedProviderEventId:null,
      currentClikedProviderComment:"",
      currentClikedProviderOtherComment:"",
      assignSelectedProviderStartTime:null,
      assignSelectedProviderEndTime:null,
      assignSelectedProviderOnCallOpenShift: false,
      isDepatViewInfo:false,
      selectedDepartmentViewProviderProviderName: null,
      selectedDepartmentViewProviderDescription: null,
      selectedDepartmentViewProviderEndTime: null,
      selectedDepartmentViewProviderStartTime: null,
      selectedDepartmentViewProviderPreferredCallNumber: null,
      isShowCalendarTitleOpen: false,
      selectedCalenderTitleDate: new Date()
    };
    this.calendarComponentRef = React.createRef();
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${null})`;
    const userCode = localStorage.getItem("userCode");
    this.props.getFacilityDepartment(userCode);

    this.setState(
      {
        scheduleDatesState: this.props.scheduleDates.providerDatas
          ? this.props.scheduleDates.providerDatas
          : [],
        calenderKey: Math.floor(Math.random() * 100) + "",
        selectedSkillValue: "",
        selectedScheduleBlockValue: "",
        selectedTeamValue: "",
        selectedTeamName: "",
        scheduleDatesState: [],
        selectedfacilityValue: "",
        selectedDepartmentValue: ""
      },
      () => {

        this.getScheduleEvent();
      }
    );
    // this.props.getAllProviders();
    // this.props.getSaveSkills();
    if (
      localStorage.getItem("isSchedulerView") &&
      localStorage.getItem("isSchedulerView") == "true"
    ) {
      this.setState({ isDepView: true, isDepViewDisabled: true });
    }
    this.checkOrgUser(userCode);
  }

  onChangeDepartmentView = (v, nv) => {
    this.setState({ isDepView: v === 'dep', depSelectionType: v, selectedScheduleBlockValue: "" }, () => {
      this.setState({ isSchedulerToggle: this.state.isDepView }, () => {
        if (this.state.isSchedulerToggle) {
          this.onChangeSchedulerToggle(this.formatDate(new Date()));
        } else {
          this.setState(
            {
              defaultDate: this.formatDate(new Date()),
              scheduleDatesState: [],
              selectedSkillValue: this.state.selectedSkillValue ? this.state.selectedSkillValue : "",
              selectedScheduleBlockValue: this.state.selectedScheduleBlockValue ? this.state.selectedScheduleBlockValue : "",
              selectedTeamValue: this.state.selectedTeamValue ? this.state.selectedTeamValue : "",
              selectedTeamName: this.state.selectedTeamName ? this.state.selectedTeamName : "",
              selectedfacilityValue: this.state.selectedfacilityValue ? this.state.selectedfacilityValue : "",
              selectedDepartmentValue: this.state.selectedDepartmentValue ? this.state.selectedDepartmentValue : "",
            },
            () => {
              let event = [];
              event['target'] = {'value' : this.state.selectedDepartmentValue};
              this.handleDepartmentChange(event);
              this.getScheduleEvent();
            }
          );
        }
      });
    });
  }

  handlePanelCollapse = (v) => {
    const sval = this.state.filterPanelExpanded;
    sval[v] = !sval[v];
    this.setState({ filterPanelExpanded: sval })
  }

  checkOrgUser = usercode => {
    if (usercode) {
      instance
        .get(`Organization/GetOrganization?`, {
          params: { userCode: usercode }
        })
        .then(response => {
          if (!response.error) {
            if (response.data.data) {
              localStorage.setItem("is_org_user", true);
            } else {
              this.checkFacUser(usercode);
            }
          }
        })
        .catch(err => {
          // console.log(err);
        });
    }
  };

  checkFacUser = usercode => {
    instance
      .get(`Facility/GetFacilityByUserCode?`, {
        params: { code: usercode }
      })
      .then(response => {
        if (!response.error) {
          if (response.data.data) {
            localStorage.setItem("is_fac_user", true);
          } else {
            this.checkDepUser(usercode);
          }
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  checkDepUser = usercode => {
    instance
      .get(`Department/GetByUserCode?`, {
        params: { code: usercode }
      })
      .then(response => {
        if (!response.error && response.data.data) {
          localStorage.setItem("is_dep_user", true);
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };
  openCreateNewShift = event => {
    if (event.target.parentElement && event.target.parentElement.classList.contains("single-date-open-shift")) {
      let startDate = new Date(event.target.parentElement.myDate.date);
      startDate.setDate(startDate.getDate());
      this.setState(
        {
          selectedCreateOpenShift: {
            date: this.formatDate(startDate),
            schedulerCode: this.state.selectedScheduleBlockValue,
            teamCode: this.state.selectedTeamValue,
            skillLevel: "",
            askRate: null,
            labourType:[],
            onCallOpenShift: false
          },
          validateCreateOpenShiftMessage:"",
          selectedCreateOpenShiftSkillValue: "",
          selectedCreateOpenShiftLabourTypeValue:[],
          selectedDays:[]
        },
        () => {
          let selectedDropDate = new Date(startDate);
          selectedDropDate = selectedDropDate.setDate(selectedDropDate.getDate());
          this.onChangeSelectedDates(new Date(selectedDropDate))
          this.createOpenShiftModal();
        }
      );
    }
  };

  componentDidMount = () => {
    EventEmitter.on("isDepViewToggle", event =>
      this.setState(
        { isDepView: event, selectedScheduleBlockValue: "" },
        () => {
          this.setState({ isSchedulerToggle: this.state.isDepView }, () => {
            if (this.state.isSchedulerToggle) {
              this.onChangeSchedulerToggle(this.state.defaultDate);
            } else {
              this.setState(
                {
                  defaultDate: this.formatDate(new Date(this.state.defaultDate)),
                  scheduleDatesState: []
                },
                () => {
                  this.getScheduleEvent();
                }
              );
            }
          });
        }
      )
    );

    // document.addEventListener("click", this.openCreateNewShift);
    let draggableEl = document.getElementById("external-events");
    if(draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let id = eventEl.getAttribute("data");
          return {
            title: title,
            id: id
          };
        }
      });
    }
  };

  onClickPrev = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.prev();
    let currentMonthDate = this.calendarComponentRef.current.getApi().state
      .currentDate;
    let view = this.calendarComponentRef.current.getApi().view;
    currentMonthDate = new Date(
      currentMonthDate.setDate(currentMonthDate.getDate() + 1)
    );
    let date = this.formatDate(currentMonthDate);
    this.setState({ defaultDate: date });

    if (this.state.isDepView) {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();

      let selectedPrevDate = this.calendarComponentRef.current.getApi().state
        .currentDate;

      selectedPrevDate = new Date(
        selectedPrevDate.setDate(selectedPrevDate.getDate() + 1)
      );

      let selectedMonthDate = selectedPrevDate.getMonth();
      let selectedYearDate = selectedPrevDate.getFullYear();
      if (
        (currentDate.getMonth() + 1 < selectedMonthDate + 1 ||
          currentYear < selectedYearDate) &&
        this.state.isDepView
      ) {
        this.setState({ isSchedulerToggle: false });
      } else if (view.type == 'dayGridMonth' && this.state.isDepView) {
        this.setState({ isSchedulerToggle: true });
      } else if ((selectedMonthDate + 1) >= (currentDate.getMonth() + 1) &&
        (currentYear >= selectedYearDate) && this.state.isDepView) {
        this.setState({ isSchedulerToggle: false });
      } else {
        this.setState({ isSchedulerToggle: true });
      }

      let currentSelectedDate = new Date(this.calendarComponentRef.current.getApi().state.currentDate);
      if (view.type == 'dayGridDay') {
        currentSelectedDate.setDate(currentSelectedDate.getDate() - 1);
      }
      currentSelectedDate = this.formatDate(currentSelectedDate);
      this.onChangeSchedulerToggle(
        currentSelectedDate
      );
      this.setState({ myView: view.type, calenderKey: Math.random() + "" })
    }
  };

  onClickCalenderTitle = () => {
    this.setState({isShowCalendarTitleOpen: !this.state.isShowCalendarTitleOpen})
  }

  handleCalenderTitle = (date) => {
    this.setState({defaultDate:date, calenderKey: Math.random() + ""}, () => {
      if(this.state.isDepView) {
        this.onChangeSchedulerToggle(
          this.formatDate(this.state.defaultDate)
        );
      }
      this.setState({isShowCalendarTitleOpen: !this.state.isShowCalendarTitleOpen})
    })
  }

  onCLickNext = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.next();
    let currentMonthDate = this.calendarComponentRef.current.getApi().state
      .currentDate;
    let view = this.calendarComponentRef.current.getApi().view;
    currentMonthDate = new Date(
      currentMonthDate.setDate(currentMonthDate.getDate() + 1)
    );
    let date = this.formatDate(currentMonthDate);
    this.setState({ defaultDate: date });

    if (this.state.isDepView) {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let selectedNextDate = this.calendarComponentRef.current.getApi().state
        .currentDate;

      if (view.type != 'dayGridDay') {
        selectedNextDate = new Date(
          selectedNextDate.setDate(selectedNextDate.getDate() + 1)
        );
      }
      let selectedMonthDate = selectedNextDate.getMonth();
      let selectedYearDate = selectedNextDate.getFullYear();
      if (
        (currentDate.getMonth() + 1 < selectedMonthDate + 1 ||
          currentYear < selectedYearDate) &&
        this.state.isDepView
      ) {
        this.setState({ isSchedulerToggle: false });
      } else if (view.type == 'dayGridMonth' && this.state.isDepView) {
        this.setState({ isSchedulerToggle: true });
      } else if ((currentDate.getMonth() + 1) >= (selectedMonthDate + 1) ||
        (currentYear <= selectedYearDate) && this.state.isDepView) {
        this.setState({ isSchedulerToggle: false });
      }
      this.onChangeSchedulerToggle(this.formatDate(selectedNextDate));
      this.setState({ myView: view.type, calenderKey: Math.random() + "" })
    }
  };

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.scheduleDates !== this.props.scheduleDates) {
      this.setState(
        {
          scheduleDatesState: nextProps.scheduleDates
            ? nextProps.scheduleDates.providerDatas
            : []
        },
        () => {
          this.getScheduleEvent();
        }
      );
      this.forceUpdate();
    }
    if (
      nextProps.facilityPayload.data !== undefined &&
      nextProps.facilityPayload !== this.props.facilityPayload
    ) {
      this.setState(
        {
          selectedfacilityValue:
            nextProps.facilityPayload.data !== undefined &&
              nextProps.facilityPayload.data.length > 0
              ? nextProps.facilityPayload.data[0].facilityCode
              : "",
          selectedDepartmentValue:
            nextProps.facilityPayload.data &&
              nextProps.facilityPayload.data.length > 0 &&
              nextProps.facilityPayload.data[0].department
              ? nextProps.facilityPayload.data[0].department[0].departmentCode
              : ""
        },
        () => {
          if(this.state.isDepViewDisabled) {
            this.onChangeDepartmentView('dep');
          }
          if (
            (localStorage.getItem("isSchedulerView") &&
            localStorage.getItem("isSchedulerView") != "true") && 
            (localStorage.getItem("isTesselMedAdmin") &&
            localStorage.getItem("isTesselMedAdmin") != "true")
          ) {
            this.setState({depSelectionType:'dep', isDepView:true}, () => {
              this.onChangeSchedulerToggle(this.formatDate(new Date()))
            });
          } else if(!this.state.isDepViewDisabled) {
            this.getTeamScheduleDateBlock(
              nextProps.facilityPayload.data[0].department[0].departmentCode
            );
          }
         
          // this.props.getAllProviders(this.state.selectedfacilityValue);
          if (this.state.selectedDepartmentValue) {
            this.props.getAllProviders(this.state.selectedDepartmentValue);
          }
          // this.setInitialValues();
        }
      );

      if (
        nextProps.facilityPayload.data.length > 0 &&
        nextProps.facilityPayload.data[0].department
      ) {
        // this.getTeamScheduleDateBlock(
        //   nextProps.facilityPayload.data[0].department[0].departmentCode
        // );
      }
    }
  }

  createBlockOpen = () => {
    const todayDate = moment(new Date());
    this.setState({
      isCreateBlockModelOpen: true,
      selectedStartBlockDate: todayDate,
      selectedEndBlockDate: todayDate
    });
  };
  createBlockClose = () => {
    this.setState({
      isCreateBlockModelOpen: false,
      selectedStartBlockDate: "",
      selectedEndBlockDate: ""
    });
  };

  handleStartBlockDate = date => {
    this.setState({
      selectedStartBlockDate: moment(new Date(date)),
      selectedEndBlockDate: moment(new Date(date))
    });
  };

  handleEndBlockDate = date => {
    this.setState({
      selectedEndBlockDate: moment(new Date(date))
    });
  };

  getUpdatedTeamList = async (departmentId) => {
    if (departmentId) {
      await this.props.getTeamScheduleBlock(departmentId).then(response => {
        if (typeof response !== undefined) {
          if (response.data.status) {
            const { data = [] } = response.data;
            if (data.length) {
              this.setState({teamScheduleBlock:data}, ()=> {
                this.getTeam(this.state.selectedTeamValue, this.state.selectedTeamName);
              })
            } else {
              this.setState({
                selectedTeamValue: "",
                teamScheduleBlock:[],
                teamSkills:[]
              });
            }
          }
        }
      });
    }
  }

  handleCreateBlockSubmit = () => {
    if(this.state.teamScheduleBlock && this.state.teamScheduleBlock.length > 0)  {
      let scheduleList = this.state.teamScheduleBlock.filter(scheduler => scheduler.teamCode === this.state.selectedTeamValue)
      if(scheduleList && scheduleList.length > 0) {
        let checkValid = scheduleList[0].schedule.filter(block => {
          if(
            moment(
              new Date(block.startDate),
              "ddd DD-MMM-YYYY, hh:mm A"
            ).utcOffset(0).format('L') === 
            moment(
              new Date(this.state.selectedStartBlockDate),
              "ddd DD-MMM-YYYY, hh:mm A"
            ).utcOffset(0).format('L') &&
            moment(
              new Date(block.endDate),
              "ddd DD-MMM-YYYY, hh:mm A"
            ).utcOffset(0).format('L') === moment(
              new Date(this.state.selectedEndBlockDate),
              "ddd DD-MMM-YYYY, hh:mm A"
            ).utcOffset(0).format('L') && !block.isDeactivated) {
              return true
            }
        })
        if(checkValid && checkValid.length > 0) {
          toast.error("Schedule block already exists in same date range.");
          return false
        }
      }
    }
    
    this.props.postCreateBlock(
      this.state.selectedTeamValue,
      formatStandardDate(this.state.selectedStartBlockDate),
      formatStandardDate(this.state.selectedEndBlockDate)
    ).then(async (response) => {
      if (response.data.status && (response.data.data.schedulerCode && this.state.selectedScheduleBlockValue === undefined || this.state.selectedScheduleBlockValue == "")) {
        await this.getUpdatedTeamList(this.state.selectedDepartmentValue);
        let schedulerCode = response.data.data.schedulerCode ? response.data.data.schedulerCode : ""
        await this.props.getTeamScheduleDates(
          schedulerCode
        ).then((res) => {
          this.setState({ selectedScheduleBlockValue: schedulerCode, isCreateBlockModelOpen: false }, () => {
            toast.success("Schedule block created successfully.");
            this.setState({
              selectedStartBlockDate: new Date(),
              selectedEndBlockDate: new Date()
            }, ()=> {
              this.props.getAllProviders(this.state.selectedDepartmentValue);
              let event = [];
              event['target'] = {'value': this.state.selectedSkillValue, 'name': this.state.selectedSkillName};
              this.handleSkillsChange(event)
            });
          });
        });
      } else if (response.data.status) {
        this.props.getTeamScheduleDates(
          this.state.selectedScheduleBlockValue
        );
        await this.getUpdatedTeamList(this.state.selectedDepartmentValue);
        toast.success("Schedule block created successfully.");
        this.setState({
          isCreateBlockModelOpen: false
        }, ()=> this.setState({
          selectedStartBlockDate: new Date(),
          selectedEndBlockDate: new Date()
        }));
      } else {
        toast.error(response.data.error.message);
      }
    });

    this.setState({
      isCreateBlockModelOpen: false
    });
  };

  createOpenShiftOpen = () => {
    const { selectedScheduleBlockValue } = this.state;
    if (selectedScheduleBlockValue !== "") {
      this.props
        .postOpenShiftSave(selectedScheduleBlockValue)
        .then(response => {
          if (response.data.status) {
            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            toast.success("Post created successfully.");
            this.getScheduleEvent();
          } else {
            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            this.getScheduleEvent();   
            toast.error(response.data.error.message);
          }
        });
    } else {
      this.getScheduleEvent();
      toast.error("Please select your schedule.");
    }
  };

  // createOpenShiftOpen = () => {
  //   this.setState({
  //     isOpenShiftModalOpen: true
  //   });
  //   if (this.state.selectedScheduleBlockValue) {
  //     this.props
  //       .getSaveSkills(this.state.selectedScheduleBlockValue)
  //       .then(() => {
  //         this.setState({
  //           saveSkillArray: this.props.getSavSkills
  //         });
  //       });
  //   }
  // };

  createOpenShiftClose = () => {
    this.setState({
      isOpenShiftModalOpen: false
    });
  };

  repeatModalClose = () => {
    this.setState({
      isRepeatModeOpen: false,
      selectedDays: []
    },()=> this.getScheduleEvent());
  };

  depatViewInfoModalClose = () => {
    this.setState({
      isDepatViewInfo: !this.state.isDepatViewInfo
    }, ()=>{
      if(!this.state.isDepatViewInfo) {
        this.setState({
          selectedDepartmentViewProviderProviderName:null,
          selectedDepartmentViewProviderDescription:null,
          selectedDepartmentViewProviderEndTime: null,
          selectedDepartmentViewProviderStartTime: null,
          selectedDepartmentViewProviderPreferredCallNumber: null
        })
      }
    });
  };

  repeatOnchange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  /**
   * when we click on event we are displaying event details
   */

  checkIfOtherSelectComment = (comment) => {
    if(COMMENTS.filter(data => data.value == comment).length === 0) {
      return true
    } else {
      return false
    }
  }

  eventClick = (eventClick) => {
    this.setState({ currentCalendarClickEvent: eventClick });
    if (this.state.isDepView) {
      let startTimeDept = new Date()
      startTimeDept.setHours(eventClick.event.extendedProps.startProviderTime.split(":")[0])
      startTimeDept.setMinutes(eventClick.event.extendedProps.startProviderTime.split(":")[1])
      let endTimeDept = new Date()
      endTimeDept.setHours(eventClick.event.extendedProps.endProviderTime.split(":")[0])
      endTimeDept.setMinutes(eventClick.event.extendedProps.endProviderTime.split(":")[1])
      this.setState({
        selectedDepartmentViewProviderProviderName: eventClick.event.extendedProps.providerName,
        selectedDepartmentViewProviderDescription: eventClick.event.extendedProps.description,
        selectedDepartmentViewProviderStartTime: startTimeDept,
        selectedDepartmentViewProviderEndTime: endTimeDept,
        selectedDepartmentViewProviderPreferredCallNumber: eventClick.event.extendedProps.preferredCallNumber
      }, () => {
        setTimeout(()=>{
          this.depatViewInfoModalClose()
        }, 250) 
      })
    } else {
      if (eventClick.event.id !== "-1") {
        let startDate = convertTZ(eventClick.event.start)
        this.setState({
          currentClikedProviderTitle: eventClick.event.extendedProps.providerName,
          currentClikedProviderEventId: eventClick.event.id,
          currentClikedProviderStartDate: startDate, 
          currentClikedProviderStartDate: startDate, 
          currentClikedProviderStartDate: startDate, 
          currentClikedProviderStartTime: eventClick.event.extendedProps.startProviderTime,
          currentClikedProviderAskRate: eventClick.event.extendedProps.rate ? eventClick.event.extendedProps.rate : 0,
          currentClikedProviderAddOnHourRate: eventClick.event.extendedProps.addOnHourRate ? eventClick.event.extendedProps.addOnHourRate : 0,
          currentClikedProviderEndTime: eventClick.event.extendedProps.endProviderTime,
          currentClikedProviderOnCallOpenShift: eventClick.event.extendedProps.onCallOpenShift ? eventClick.event.extendedProps.onCallOpenShift : false,
          currentClikedProviderIsSourceShift: (eventClick.event.extendedProps.isSourceShift || eventClick.event.extendedProps.rate) ? true : false,
          currentClikedProviderAddOnHours: (eventClick.event.extendedProps.addOnHours != undefined && eventClick.event.extendedProps.addOnHours) ? eventClick.event.extendedProps.addOnHours : 0,
          currentClikedProviderComment: (eventClick.event.extendedProps.comment ? COMMENTS.filter(data => data.value == eventClick.event.extendedProps.comment).length === 0 ? "Other" : eventClick.event.extendedProps.comment : ""),
          currentClikedProviderOtherComment: (eventClick.event.extendedProps.comment && COMMENTS.filter(data => data.value == eventClick.event.extendedProps.comment).length === 0 ? eventClick.event.extendedProps.comment : ""),
          currentClikedProviderShiftId: eventClick.event.extendedProps.shiftId},() => {
            setTimeout(()=> {
              this.setState({openRemoveShift:true})
            },250)
        })
      } else {
        let startDate = convertTZ(eventClick.event.start)
        // startDate.setDate(startDate.getDate());
        let startProviderTime = new Date(startDate)
        startProviderTime.setHours(eventClick.event.extendedProps.startProviderTime.split(":")[0])
        startProviderTime.setMinutes(eventClick.event.extendedProps.startProviderTime.split(":")[1])
        let endProviderTime = new Date(startDate)
        endProviderTime.setHours(eventClick.event.extendedProps.endProviderTime.split(":")[0])
        endProviderTime.setMinutes(eventClick.event.extendedProps.endProviderTime.split(":")[1])
        this.setState(
          {
            selectedOpenShift: {
              skillLevel: eventClick.event.extendedProps.skill,
              askRate: eventClick.event.extendedProps.rate,
              date: this.formatDate(startDate),
              schedulerCode: this.state.selectedScheduleBlockValue,
              teamCode: this.state.selectedTeamValue,
              calendarId: eventClick.event.extendedProps.shiftId,
              selectedOpenShiftSkillValue: eventClick.event.extendedProps.skill,
              ceilingRate: eventClick.event.extendedProps.ceilingRate,
              startTime: startProviderTime,
              endTime: endProviderTime,
              rateMask: eventClick.event.extendedProps.rateMask,
              onCallOpenShift: eventClick.event.extendedProps.onCallOpenShift ? eventClick.event.extendedProps.onCallOpenShift : false,
              isSourceShift: (eventClick.event.extendedProps.isSourceShift || eventClick.event.extendedProps.rate) ? true : false,
              addOnHours: (eventClick.event.extendedProps.addOnHours != undefined && eventClick.event.extendedProps.addOnHours) ? eventClick.event.extendedProps.addOnHours : 0,
              diffHour: (eventClick.event.extendedProps.addOnHours != undefined && eventClick.event.extendedProps.addOnHours) ? eventClick.event.extendedProps.addOnHours : 0,
              addOnHourRate: eventClick.event.extendedProps.addOnHourRate ? eventClick.event.extendedProps.addOnHourRate : 0,
              comment: eventClick.event.extendedProps.comment ? this.checkIfOtherSelectComment(eventClick.event.extendedProps.comment) ? "Other" : eventClick.event.extendedProps.comment : "",
              otherComment: eventClick.event.extendedProps.comment && this.checkIfOtherSelectComment(eventClick.event.extendedProps.comment) ? eventClick.event.extendedProps.comment : "",
            },
            validateUpdateOpenShiftMessage: ""
          },
          () => {
            if (eventClick.event.extendedProps.shiftId) {
              this.openShiftRateChangeModal();
            }
          }
        );
      }
    }
  }

  onEditAssignedShift = (data) => {
    this.setState({ isLoading: true });
    
    instance.post(`Scheduler/Calendar/ChangeProviderTime?id=${data.id}&StartTime=${data.startTime}&EndTime=${data.endTime}&isSourceShift=${data.isSourceShift}&addOnHours=${data.addOnHours}&addOnHourRate=${data.addOnHourRate}&comment=${data.comment}&onCallOpenShift=${data.onCallOpenShift}`)
    .then(response => {
      if (response.data.status) {
        this.setState({openRemoveShift:false})
        this.setState({ isOpenShiftRateChange: false });
        this.setState({
          currentClikedProviderTitle:"",
          currentClikedProviderStartDate:null,
          currentClikedProviderEventId:null,
          currentClikedProviderStartTime:null,
          currentClikedProviderEndTime:null,
          currentClikedProviderShiftId:null,
          currentClikedProviderAddOnHours:null,
          currentClikedProviderAskRate:null,
          currentClikedProviderIsSourceShift: false,
          currentClikedProviderAddOnHourRate: null,
          currentClikedProviderOnCallOpenShift: false,
          currentClikedProviderComment: "",
          currentClikedProviderOtherComment:""
        },() => {
          this.setState({openRemoveShift:false})
        })
        this.props.getTeamScheduleDates(
          this.state.selectedScheduleBlockValue
        );
        this.setState({ isLoading: false });
        this.getScheduleEvent();
        toast.success("Shift time updated successfully");
      } else {
        this.setState({ isLoading: false });
        toast.error(response.error.message);
      }
    })
    .catch(err => {
      // console.log(err);
      this.setState({openRemoveShift:false})
      this.setState({ isOpenShiftRateChange: false });
      this.setState({ isLoading: false });
    });
  }

  getDiffHours = () => {
    const { selectedOpenShift } = this.state;
    let shiftStart = (this.state.currentTeamData && this.state.currentTeamData[0].startTime)
    let shiftEnd = (this.state.currentTeamData && this.state.currentTeamData[0].endTime)
   
    let startTime = (selectedOpenShift && selectedOpenShift.startTime)
    let endTime = (selectedOpenShift &&selectedOpenShift.endTime)
    if(shiftStart && shiftEnd && startTime && endTime) {
      let DutyDayStartTime = moment([shiftStart.split(":")[0],shiftStart.split(":")[1]], "HH:mm")
      let DutyDayEndTime = moment([shiftEnd.split(":")[0],shiftEnd.split(":")[1]], "HH:mm")
      var shiftDuration = moment.duration(DutyDayEndTime.diff(DutyDayStartTime));
      var shiftHours = (shiftDuration.asHours() ? shiftDuration.asHours() : 24);
      let providerStartTimeTemp = moment([startTime.getHours(),startTime.getMinutes()], "HH:mm")
      let providerEndTimeTemp = moment([endTime.getHours(),endTime.getMinutes()], "HH:mm")
      if(!providerStartTimeTemp.isBefore(providerEndTimeTemp)) {
        var now = moment(new Date(selectedOpenShift.date))
        // now = now.utcOffset(0);
        now = now.set({hour:startTime.getHours(),minute:startTime.getMinutes(),second:0,millisecond:0})
        var then = moment(new Date(selectedOpenShift.date))
        then = then.add(1, "days")
        // then = then.utcOffset(0);
        then = then.set({hour:endTime.getHours(),minute:endTime.getMinutes(),second:0,millisecond:0})
        var ms = moment(now,"DD/MM/YYYY HH:mm").diff(moment(then,"DD/MM/YYYY HH:mm"));
        var d = moment.duration(ms);
        var s = d.asHours()
        // var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm");
        return (Math.trunc(Math.abs(s) - Math.abs(shiftHours)))
      } else {
        var providerDuration = moment.duration(providerEndTimeTemp.diff(providerStartTimeTemp)  );
        var providerHours = (providerDuration.asHours() ? providerDuration.asHours() : 24);
        if(providerHours > shiftHours) {
          return Math.trunc(Math.abs(providerHours) - Math.abs(shiftHours))
        } else {
          return 0
        }
      }
    }
  }

  // getDiffHours = () => {
  //   const { selectedOpenShift } = this.state;
  //   let shiftStart = (this.state.currentTeamData && this.state.currentTeamData[0].startTime)
  //   let shiftEnd = (this.state.currentTeamData && this.state.currentTeamData[0].endTime)
   
  //   let startTime = (selectedOpenShift && selectedOpenShift.startTime)
  //   let endTime = (selectedOpenShift &&selectedOpenShift.endTime)
  //   if(shiftStart && shiftEnd && startTime && endTime) {
  //       let DutyDayStartTime = moment([shiftStart.split(":")[0],shiftStart.split(":")[1]], "HH:mm")
  //       let DutyDayEndTime = moment([shiftEnd.split(":")[0],shiftEnd.split(":")[1]], "HH:mm")
  //       let currentShiftTime = DutyDayStartTime.diff(DutyDayEndTime, 'hours')
  //       currentShiftTime = (24+currentShiftTime);
  //       let providerStartTimeTemp = moment([startTime.split(":")[0],startTime.split(":")[1]], "HH:mm")
  //       let providerEndTimeTemp = moment([endTime.split(":")[0],endTime.split(":")[1]], "HH:mm")
  //       if(providerStartTimeTemp.isBefore(providerEndTimeTemp) || providerStartTimeTemp.isSame(providerEndTimeTemp)) {
  //         let providerTime = providerStartTimeTemp.diff(providerEndTimeTemp, 'hours')
  //         providerTime = (24+providerTime);
  //         let diff = (currentShiftTime - providerTime)
  //         if(Math.sign(diff) == 1 || Math.sign(diff) == 0) {
  //           this.setState({
  //             validateUpdateOpenShiftMessage: ""
  //           });
  //           return diff;
  //         } else {
  //           this.setState({
  //             validateUpdateOpenShiftMessage: ""
  //           });
  //           return 0;
  //         }
  //       } else {
  //         // this.setState({
  //         //   validateUpdateOpenShiftMessage: "Start Time should greater than End time!"
  //         // });
  //         return 0;
  //       }
  //   } else {
  //     return 0;
  //   }
  // }

  onRemoveAssignedShift = (eventId, shiftId) => {
    this.state.currentCalendarClickEvent.event.remove(); // It will remove event from the calendar
    let startDate = this.formatDate(this.state.currentClikedProviderStartDate);
    let providerData = {
      teamCode: this.state.selectedTeamValue,
      date: startDate,
      shiftId: shiftId
    };
    if (this.state.currentCalendarClickEvent.event.flag === 1) {
      providerData["id"] = eventId;
    } else {
      providerData["providerId"] = eventId;
    }
    this.props.deleteProvider(providerData).then(response => {
      if (response !== undefined && response.data.status) {
        this.props.getTeamScheduleDates(
          this.state.selectedScheduleBlockValue
        );
        toast.success("Your Event has been deleted.");
      } else {
        toast.error("Please try again.");
      }
      this.setState({ defaultDate: startDate }, () => this.onCloseEditAssignedShift());
    });
  }

  onCloseEditAssignedShift = () => {
    this.setState({openRemoveShift: false}, () => {
      this.setState({currentClikedProviderTitle:"",
        currentClikedProviderStartDate:null,
        currentClikedProviderEventId:null,
        currentClikedProviderStartTime:null,
        currentClikedProviderEndTime:null,
        currentClikedProviderShiftId:null,
        currentClikedProviderAddOnHours:null,
        currentClikedProviderAskRate:null,
        currentClikedProviderAddOnHourRate: null,
        currentClikedProviderOnCallOpenShift: false,
        currentClikedProviderComment: "",
        currentClikedProviderOtherComment:""
      })
    })
  }


  // getTeamScheduleDateBlockFilter = (data) => {
  //   return data[0].schedule && data[0].schedule.filter((schedule) => schedule.isDeactivated === false);
  // }

  getTeamScheduleDateBlockFilterCode = (data) => {
    let teamSchedule = data[0].schedule && data[0].schedule.filter((schedule) => schedule.isDeactivated === false);
    if(teamSchedule.length !== 0) {
      return teamSchedule[0].scheduleCode
    } else {
      return "";
    }
  }

  getTeamScheduleDateBlock = departmentId => {
    if (departmentId) {
      this.props.getTeamScheduleBlock(departmentId).then(response => {
        if (typeof response.data !== undefined) {
          if (response.data.data.length) {
            const { data = [] } = response.data;
            if (data.length && data[0].schedule.length) {
              this.setState(
                {
                  selectedTeamValue: data.length > 0 ? data[0].teamCode : "",
                  teamScheduleBlock: data,
                  selectedScheduleBlockValue: (data.length > 0 && data[0].schedule.length !== 0) ? this.getTeamScheduleDateBlockFilterCode(data) : "",
                },
                async () => {
                  if (data.length) {
                    this.getTeam(data[0].teamCode, data[0].teamName);
                  }
                }
              );
            } else {
              if(data[0].teamCode, data[0].teamName) {
                this.setState({
                  selectedTeamValue: data.length > 0 ? data[0].teamCode : "",
                  // selectedSkillValue: "",
                  selectedScheduleBlockValue: "",
                  teamScheduleBlock: data,
                  selectedTeamName: data.length > 0 ? data[0].teamName : "",
                  scheduleDatesState: []
                }, async () => {
                  if (data.length) {
                    this.getTeam(data[0].teamCode, data[0].teamName);
                  }
                });  
              }
              this.setState({
                selectedTeamValue: "",
                // selectedSkillValue: "",
                selectedScheduleBlockValue: "",
                teamScheduleBlock: [],
                selectedTeamName: "",
                scheduleDatesState: []
              }, ()=> this.getScheduleEvent());
            }
          } else {
             this.setState({
                selectedTeamValue: "",
                selectedSkillValue: "",
                selectedScheduleBlockValue: "",
                selectedTeamValue: "",
                selectedTeamName: "",
                scheduleDatesState: [],
                teamScheduleBlock: [],
                teamSkills:[]
              }, ()=> this.getScheduleEvent());
          }
        } else {
          this.setState({
             selectedTeamValue: "",
             selectedSkillValue: "",
             selectedScheduleBlockValue: "",
             selectedTeamValue: "",
             selectedTeamName: "",
             scheduleDatesState: [],
             teamScheduleBlock: [],
             teamSkills:[]
           }, ()=> this.getScheduleEvent());
       }
      });
    }
  };

  handleDateClick = arg => { };

  handleDepartmentChange = event => {
    this.setState(
      {
        selectedSkillValue: this.state.selectedSkillValue ? this.state.selectedSkillValue : "",
        selectedScheduleBlockValue: this.state.selectedScheduleBlockValue ? this.state.selectedScheduleBlockValue : "",
        selectedTeamValue: this.state.selectedTeamValue ? this.state.selectedTeamValue : "",
        selectedTeamName: this.state.selectedTeamName ? this.state.selectedTeamName : "",
        selectedDepartmentValue: event.target.value,
        scheduleDatesState: []
      },
      () => {
        if (this.state.selectedDepartmentValue) {
          this.props.getAllProviders(this.state.selectedDepartmentValue);
        }
        this.getAllSkilByDept();
        if (this.state.isDepView) {
          this.onChangeSchedulerToggle(this.formatDate(new Date()));
        } else {
          // this.getTeamScheduleDateBlock(event.target.value);
          this.setInitialValues();
        }
        this.getScheduleEvent();
      }
    );
  };

  facilityChange = facilityId => {
    let value = facilityId !== undefined ? facilityId : null;
    if (value) {
      let facility = this.props.facilityPayload
        ? this.props.facilityPayload.data
        : null;

      let selectedFacility = facility.filter(facility => {
        return facility.facilityCode === parseInt(value);
      });
      this.setState(
        {
          selectedfacilityValue: value,
          selectedSkillValue: "",
          selectedScheduleBlockValue: "",
          selectedTeamValue: "",
          selectedTeamName: "",
          scheduleDatesState: [],
          selectedSkillValue:"",
          scheduleDatesState:[],
          selectedDepartmentValue:
            selectedFacility && selectedFacility[0].department
              ? selectedFacility[0].department[0].departmentCode
              : ""
        },
        () => {
          if(this.state.selectedDepartmentValue) {
            let event = [];
            event['target'] = {'value' : this.state.selectedDepartmentValue};
            this.handleDepartmentChange(event)
            this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue);
          } else {
            this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue);
            this.getScheduleEvent()
          }
        }
      );
    }
  };

  setInitialValues = () => {
      if(!this.state.selectedfacilityValue) {
        this.setState({selectedfacilityValue: this.props.facilityPayload.data !== undefined &&
          this.props.facilityPayload.data.length > 0
          ? this.props.facilityPayload.data[0].facilityCode
          : ""}, () => {
            if(this.state.selectedfacilityValue) {
              let selectedFacility = this.props.facilityPayload.data.filter(facility => {
                return facility.facilityCode === parseInt(this.state.selectedfacilityValue);
              });
              if(selectedFacility.length !== 0) {
                this.setState(
                  {
                        selectedSkillValue: "",
                        selectedScheduleBlockValue: "",
                        selectedTeamValue: "",
                        selectedTeamName: "",
                        scheduleDatesState: [],
                        selectedSkillValue:"",
                        selectedDepartmentValue:
                      !this.state.selectedDepartmentValue ? (selectedFacility && selectedFacility[0].department && selectedFacility[0].department.length !== 0
                        ? selectedFacility[0].department[0].departmentCode
                        : "") : this.state.selectedDepartmentValue
                  },
                  () => {
                    if(this.state.selectedDepartmentValue) {
                      this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue);
                    } else {
                      this.getScheduleEvent();
                    }
                  });
              }
            } else if(this.state.selectedDepartmentValue) {
              this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue)
            }
          })
      } else if(this.state.selectedfacilityValue) {
        let selectedFacility = this.props.facilityPayload.data.filter(facility => {
          return facility.facilityCode === parseInt(this.state.selectedfacilityValue);
        });
        if(selectedFacility.length !== 0) {
          this.setState(
            {
                  selectedSkillValue: "",
                  selectedScheduleBlockValue: "",
                  selectedTeamValue: "",
                  selectedTeamName: "",
                  scheduleDatesState: [],
                  selectedSkillValue:"",
                  selectedDepartmentValue:
                  !this.state.selectedDepartmentValue ? (selectedFacility && selectedFacility[0].department && selectedFacility[0].department.length !== 0
                    ? selectedFacility[0].department[0].departmentCode
                    : "") : this.state.selectedDepartmentValue
            },
            () => {
              if(this.state.selectedDepartmentValue) {
                this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue);
              } else {
                this.getScheduleEvent();
              }
            });
        }
      } else if(this.state.selectedDepartmentValue) {
        this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue)
      }
  }

  handleFacilityChange = event => {
    let value = event.target.value !== undefined ? event.target.value : null;
    if (value !== "undefined") {
      let facility = this.props.facilityPayload
        ? this.props.facilityPayload.data
        : null;

      let selectedFacility = facility.filter(facility => {
        return facility.facilityCode === parseInt(value);
      });
      this.setState(
        {
          selectedSkillValue: "",
          selectedScheduleBlockValue: "",
          selectedTeamValue: "",
          selectedTeamName: "",
          scheduleDatesState: [],
          currentTeamDataDays: "",
          selectedfacilityValue: value,
          selectedDepartmentValue:
            selectedFacility.length && selectedFacility[0].department.length
              ? selectedFacility[0].department[0].departmentCode
              : ""
        },
        () => {
          // this.getScheduleEvent();
          if (this.state.selectedDepartmentValue) {
            if(this.state.isDepView) {
              this.onChangeSchedulerToggle(this.formatDate(new Date()))
            } else {
              this.props.getAllProviders(this.state.selectedDepartmentValue)
              this.getTeamScheduleDateBlock(this.state.selectedDepartmentValue);
            }
          } else {
            this.props.getAllProviders('');
            this.setInitialValues();
          }
        }
      );
    }
  };

  getHourOfCurrentShift = (startTime, endTime) => {
    var fromDate = parseInt(new Date(startTime).getTime()/1000); 
    var toDate = parseInt(new Date(endTime).getTime()/1000);
    var timeDiff = (toDate - fromDate)/3600; 
    return timeDiff;
  }

  getTeam = async (value, name) => {
    let teamScheduleArray = this.state.teamScheduleBlock;
    if (value && name) {
        await this.props.getTeamSkills(value)
        .then(response => {
          if (response.data.status) {
            this.setState({
              selectedTeamValue: value,
              selectedTeamName: name,
              teamScheduleBlock: teamScheduleArray,
              teamSkills:response.data.data,
              selectedSkillValue:response.data.data.length !== 0 ? response.data.data[0]: ""
            },() => {
              if (!this.state.selectedSkillValue) {
                this.setState({
                  selectedQHPGroup: "all"
                });
              }
              if (this.state.selectedDepartmentValue && this.state.selectedSkillValue) {
                this.props
                  .getProviderByDepartmentSkill(
                    this.state.selectedDepartmentValue,
                    this.state.selectedSkillValue
                  )
                  .then(response => {
                    if (response.data.status) {
                    } else {
                      // toast.error("something went wrong");
                    }
                  });
              }
              const currentTeamData = this.state.teamScheduleBlock.filter(
                team => value === team.teamCode
              );
              let currentTeamDataDays = currentTeamData[0].frequencyIntervalText
                .split(",")
                .map(item => item.trim());
        
              this.setState(
                {
                  currentTeamData: currentTeamData,
                  currentTeamDataDays: currentTeamDataDays,
                  
                },
                async () => {
                  if (currentTeamData && this.state.teamSkills && this.state.teamSkills.length !== 0) {
                    this.getScheduleBlock(currentTeamData[0].scheduleCode);
                  } else {
                    this.getScheduleBlock(currentTeamData[0].scheduleCode);
                  }
                }
              );
            })
          }
        })
        .catch(error => {
        });
    }
  };

  handleTeamChange = async event => {
    if (event.target.value) {
      let team = this.state.teamScheduleBlock && this.state.teamScheduleBlock.filter(team => team.teamCode === event.target.value)
      this.setState({
        selectedSkillValue: "",
        selectedScheduleBlockValue: "",
        selectedTeamValue: event.target.value,
        selectedTeamName: team.length ? team[0].teamName : event.target.name,
        scheduleDatesState: []
      });
      // this.props.getTeamSkills(event.target.value);
      await this.props.getTeamSkills(event.target.value)
      .then(response => {
        if (response.data.status) {
          this.setState({teamSkills:response.data.data, selectedSkillValue: response.data.data ? response.data.data[0] : ""})
        }
      })
      .catch(error => {
      });

      const currentTeamData = this.state.teamScheduleBlock.filter(
        team => event.target.value == team.teamCode
      );
      let currentTeamDataDays = currentTeamData[0].frequencyIntervalText
        .split(",")
        .map(item => item.trim());
      this.setState(
        {
          currentTeamData: currentTeamData,
          currentTeamDataDays: currentTeamDataDays
        },
        () => {
          this.getTeam(event.target.value, event.target.name);
          // this.getScheduleEvent();
        }
      );
    }
  };

  getScheduleBlock = scheduleCode => {
    if (scheduleCode && this.state.currentTeamData) {
  
      const currentDateRange = this.state.currentTeamData.filter(
        sch => scheduleCode === sch.scheduleCode
      );
      this.setState({
        selectedScheduleBlockValue: currentDateRange[0].schedule.length ? this.getTeamScheduleDateBlockFilterCode(currentDateRange): ""
      }, () => {
        if (currentDateRange.length && currentDateRange[0].schedule.length && this.state.selectedScheduleBlockValue !== "") {
          let event = [];
          event['target'] = {'value': this.state.selectedScheduleBlockValue};
          this.handleScheduleBlockChange(event)
          // let startDateTime = new Date(currentDateRange[0].schedule[0].startDate);
          // startDateTime.setDate(startDateTime.getDate() - 1);
          // startDateTime = startDateTime.getTime();
          // let endDateTime = new Date(currentDateRange[0].schedule[0].endDate);
          // endDateTime.setDate(endDateTime.getDate() + 1);
          // endDateTime = endDateTime.getTime();
          // let defaultDate = new Date(currentDateRange[0].schedule[0].startDate);
          // defaultDate.setDate(defaultDate.getDate() + 1);
          //  //Get current date for past time slot selected and set current month show in calendar
          // let currentMonthDate = new Date();
          // currentMonthDate.setHours(0)
          // currentMonthDate.setMinutes(0)
          // currentMonthDate.setSeconds(0)
  
          // if(("0" + (defaultDate.getMonth() + 1)).slice(-2) < ("0" + (currentMonthDate.getMonth() + 1)).slice(-2) && 
          // ((defaultDate.getFullYear()) <= (currentMonthDate.getFullYear()) && (currentMonthDate.getFullYear()) <= defaultDate.getFullYear())) {
          //   defaultDate = currentMonthDate;
          // }
  
          // this.setState({
          //   startDateTime: startDateTime,
          //   endDateTime: endDateTime,
          //   minDroppedDate:new Date(new Date(startDateTime).getFullYear(), new Date(startDateTime).getMonth(), ("0" + (new Date(startDateTime).getDate()))),
          //   maxDroppedDate:new Date(new Date(endDateTime).getFullYear(), new Date(endDateTime).getMonth(), ("0" + (new Date(endDateTime).getDate()))),
          //   defaultDate: this.formatDate(defaultDate)
          // }, ()=> {
          //   this.props.getTeamScheduleDates(this.state.selectedScheduleBlockValue).then(response => {
          //     if (response.data.status) {
          //       this.getScheduleEvent();
          //     } else {
          //       toast.error("something went wrong");
          //     }
          //   });
          // });
          // this.props.getTeamScheduleDates(scheduleCode);
        } else {
          this.getScheduleEvent();
        }
      });
     
    }
  };

  handleScheduleBlockChange = e => {
    if (e.target.value) {
      this.setState(
        {
          selectedScheduleBlockValue: e.target.value
        },
        () => { }
      );
      console.log("e.target.value--",e.target.value)
      const currentDateRange = this.state.currentTeamData[0].schedule.filter(
        sch => e.target.value == sch.scheduleCode
      );

      let startDateTime = new Date(currentDateRange[0].startDate);
      startDateTime.setDate(startDateTime.getDate() + 1);
      startDateTime.setHours(0)
      startDateTime.setMinutes(0)
      startDateTime.setSeconds(0)
      startDateTime = startDateTime.getTime();
      let endDateTime = new Date(currentDateRange[0].endDate);
      endDateTime.setDate(endDateTime.getDate() + 1);
      endDateTime = endDateTime.getTime();
      let defaultDate = new Date(currentDateRange[0].startDate);
      defaultDate.setDate(defaultDate.getDate() + 1);
      
      //Get current date for past time slot selected and set current month show in calendar
      let currentMonthDate = new Date();
      currentMonthDate.setHours(0)
      currentMonthDate.setMinutes(0)
      currentMonthDate.setSeconds(0)

      if(("0" + (defaultDate.getMonth() + 1)).slice(-2) < ("0" + (currentMonthDate.getMonth() + 1)).slice(-2) && 
      ((defaultDate.getFullYear()) <= (currentMonthDate.getFullYear()) && (currentMonthDate.getFullYear()) <= defaultDate.getFullYear())) {
        defaultDate = currentMonthDate;
      }
     
      this.setState({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        minDroppedDate:new Date(new Date(startDateTime).getFullYear(), new Date(startDateTime).getMonth(), ("0" + (new Date(startDateTime).getDate()))),
        maxDroppedDate:new Date(new Date(endDateTime).getFullYear(), new Date(endDateTime).getMonth(), ("0" + (new Date(endDateTime).getDate()))),
        defaultDate: this.formatDate(defaultDate)
      });
      this.props.getTeamScheduleDates(e.target.value).then(response => {
        if (response.data.status) {
          this.getScheduleEvent();
        } else {
          toast.error("Schedule not found");
        }
      });
    }
  };

  handleSkillsChange = event => {
    if(event.target.value) {
      this.setState({
        selectedSkillValue: event.target.value,
        selectedSkillName: event.target.name
      });
      if (!event.target.value) {
        this.setState({
          selectedQHPGroup: "all"
        });
      }
      if (this.state.selectedDepartmentValue && event.target.value) {
        this.props
          .getProviderByDepartmentSkill(
            this.state.selectedDepartmentValue,
            event.target.value
          )
          .then(response => {
            if (response.data.status) {
              this.getScheduleEvent();
            } else {
              this.getScheduleEvent();
              // toast.error("something went wrong");
            }
          });
      }
    }
  };

  drop = info => {
    this.setState({
      dragStartDate: "",
      dragProviderCode: null,
      isDragStart: false
    });
    alert(
      info.event.title + " was dropped on " + info.event.start.toISOString()
    );
  };

  providerDrop = event => {
    let dropDate = new Date(event.date);
    dropDate.setDate(dropDate.getDate());
    dropDate = this.formatDate(dropDate);
    this.setState({
      providerId: `${event.draggedEl.id}`,
      providerName: event.draggedEl.title,
      droppedDate: `${dropDate}`,
      isDragStart: false,
      selectedDaysArray: [],
      // minDroppedDate:new Date(new Date(event.date).getFullYear(), new Date(event.date).getMonth(), 1),
    }, () => {
      this.setState({isRepeatModeOpen: !this.state.isRepeatModeOpen}, ()=> {
        let selectedDropDate = new Date(event.date);
        selectedDropDate = selectedDropDate.setDate(selectedDropDate.getDate());
        this.onChangeSelectedDates(new Date(selectedDropDate))
      })
    });
  };

  toggleRepeatChange = () => {
    this.setState({
      isRepeatChecked: !this.state.isRepeatChecked
    });
  };

  formatConflictDate = (date) => {
    let d = new Date(date)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('-');
  }

  showConflictDateResponse = (response) => {
      let dates = [];
      response.data && response.data.providerCalendarOutput.forEach((data)=> {
        dates.push(this.formatConflictDate(data.date));
      })
      return dates.join(', ');
  }

  getConflictDateResponse = (response) => {
    let dates = [];
    response.data && response.data.providerCalendarOutput.forEach((data)=> {
      dates.push(data.date.split("T")[0]);
    })
    return dates;
  }

  handleRepeatProviderPost = () => {
    this.setState({ isLoading: true });
    let providerData;
    // let droppedDate = new Date(this.state.droppedDate);
    // droppedDate.setDate(droppedDate.getDate() + 1);
    let dateArray = [];
    this.state.selectedDays.map((day, idx) => {
      dateArray.push(formatStandardDate(day));
    })

    if (this.state.isRepeatChecked) {
      providerData = {
        teamCode: `${this.state.selectedTeamValue}`,
        providerId: this.state.providerId,
        recurrenceType: this.state.repeats,
        recurrenceNoofTime: this.state.repeatType,
        recurrenceStartDate: dateArray,
        skilllevel: `${this.state.selectedSkillValue}`,
        schedulerCode: `${this.state.selectedScheduleBlockValue}`,
        StartTime:(this.state.assignSelectedProviderStartTime ? this.state.assignSelectedProviderStartTime : this.state.currentTeamData && this.state.currentTeamData[0].startTime),
        EndTime:(this.state.assignSelectedProviderEndTime? this.state.assignSelectedProviderEndTime : this.state.currentTeamData && this.state.currentTeamData[0].endTime),
        onCallShift:(this.state.assignSelectedProviderOnCallOpenShift? this.state.assignSelectedProviderOnCallOpenShift : false)
      };
    } else {
      providerData = {
        teamCode: `${this.state.selectedTeamValue}`,
        providerId: this.state.providerId,
        date: dateArray,
        skilllevel: `${this.state.selectedSkillValue}`,
        schedulerCode: `${this.state.selectedScheduleBlockValue}`,
        StartTime:(this.state.assignSelectedProviderStartTime ? this.state.assignSelectedProviderStartTime : this.state.currentTeamData && this.state.currentTeamData[0].startTime),
        EndTime:(this.state.assignSelectedProviderEndTime? this.state.assignSelectedProviderEndTime : this.state.currentTeamData && this.state.currentTeamData[0].endTime),
        onCallShift:(this.state.assignSelectedProviderOnCallOpenShift? this.state.assignSelectedProviderOnCallOpenShift : false)
      };
    }

    this.props.addProvider(providerData).then(response => {
      if (response.data.status) {
        this.props.getTeamScheduleDates(this.state.selectedScheduleBlockValue);
        this.setState({ isLoading: false });
        toast.success("Your provider has been added.");
      } else {
        if (!this.state.isRepeatChecked) {
          const { providerName } = this.state;
          Alert.fire({
            title:"There is a potential conflict with one or more shifts. Would you like to proceed in assigning the shift(s) anyway?",
            html:
              `<div class="table-responsive">
            <table class="table">
            <tbody>
            <tr>
            <td>Title</td>
            <td><strong>` +
              providerName +
              `</strong></td>
            </tr>
            <tr>
            <td>Date</td>
            <td><strong>&nbsp;
            ` +
             this.showConflictDateResponse(response.data) +
              `
            </strong></td>
            </tr>
            </tbody>
            </table>
            </div>`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          }).then(result => {
            if (result.dismiss) {
              this.props.getTeamScheduleDates(
                this.state.selectedScheduleBlockValue
              );
              // toast.error(response.data.error.message);
              this.setState({ isLoading: false });
            } else {
              providerData["isConfirmed"] = true;
              if (this.state.isRepeatChecked) { 
                providerData["recurrenceStartDate"] = this.getConflictDateResponse(response.data);
              } else {
                providerData["date"] = this.getConflictDateResponse(response.data);
              }
              this.props.addProvider(providerData).then(response => {
                if (response.data.status) {
                  this.props.getTeamScheduleDates(
                    this.state.selectedScheduleBlockValue
                  );
                  this.setState({ isLoading: false });
                  toast.success("Your provider has been added.");
                } else {
                  this.props.getTeamScheduleDates(
                    this.state.selectedScheduleBlockValue
                  );
                  toast.error(response.data.error.message);
                  this.setState({ isLoading: false });
                }
              });
            }
          });
        } else {
          this.props.getTeamScheduleDates(
            this.state.selectedScheduleBlockValue
          );
          toast.error(response.data.error.message);
          this.setState({ isLoading: false });
        }
      }
    });
    this.setState({
      dragStartDate: "",
      dragProviderCode: null,
      defaultDate: this.state.droppedDate,
      isRepeatModeOpen: false,
      isRepeatChecked: false,
      repeatType: 1
    });
  };

  eventOverlap = (stillEvent, movingEvent) => {
    return stillEvent.allDay && movingEvent.allDay;
  };

  handleSkillsRate = event => {
    let value = event.target.value;
    if (value <= parseInt(this.state.maxAskRate)) {
      return this.setState({ askedRate: value });
    } else {
      return this.setState({ askedRate: "" });
    }
  };

  handleOpenShiftSubmit = () => {
    const openShift = this.state.skillRows.map(skill => {
      return { skill: skill.skillSelect.skill, askRate: skill.rateText.rate };
    });

    const openShiftObj = {
      teamCode: this.state.selectedTeamValue,
      scheduleCode: this.state.selectedScheduleBlockValue,
      openShift: openShift,
      hello: "world"
    };

    this.props.postOpenShift(openShiftObj);
    this.setState({
      isOpenShiftModalOpen: !this.state.isOpenShiftModalOpen,
      isOpenShiftModelSuccessOpen: !this.state.isOpenShiftModelSuccessOpen
    });
  };

  handleOpenShiftModalSuccess = () => {
    this.setState({
      isOpenShiftModelSuccessOpen: !this.state.isOpenShiftModelSuccessOpen
    });
  };

  handleSkillChange = idx => e => {
    const { name, value } = e.target;
    const skillRows = [...this.state.skillRows];
    skillRows[idx].skillSelect = {
      [name]: value
    };

    let selectedSkillObj = this.props.getSavSkills.filter(
      rate => rate.skill === this.state.skillRows[idx].skillSelect.skill
    );
    skillRows[idx].selectedSkillRate = selectedSkillObj[0].ceilingPrice;

    this.setState({
      skillRows,
      selectedSkill: this.state.skillRows[idx].skillSelect.skill
    });
  };

  handleRateChange = idx => e => {
    const { name, value } = e.target;
    const skillRows = [...this.state.skillRows];

    skillRows[idx].rateText = {
      [name]: value
    };

    if (parseInt(value) > this.state.skillRows[idx].selectedSkillRate) {
      skillRows[idx].rateError = true;
      skillRows[idx].rateText.rate = "";
    } else {
      skillRows[idx].rateError = false;
    }

    this.setState({
      skillRows
    });
  };

  handleSkillAddRow = () => {
    const item = {
      skillSelect: "",
      rateText: ""
    };

    let filteredValue;

    this.state.skillRows.map(rowSkills => {
      filteredValue = this.props.getSavSkills.filter(skills => {
        return skills.skill != rowSkills.skillSelect.skill;
      });
    });

    this.setState({
      skillRows: [...this.state.skillRows, item],
      filteredSkillValue: filteredValue
      // saveSkillArray: filteredValue
    });
  };

  handleRemoveSpecificSkillRow = idx => () => {
    const skillRows = [...this.state.skillRows];
    skillRows.splice(idx, 1);
    this.setState({ skillRows });
  };

  onDragStart = (info, sideBar = false) => {
    if (sideBar) {
      this.setState({
        dragStartDate: 1,
        dragProviderCode: info.providerCode,
        isDragStart: true,
        selectedDays: []
      });
    } else {
      this.setState({
        dragStartDate: info.event.start,
        dragProviderCode: info.event.extendedProps.resource,
        isDragStart: true
      });
    }
  };

  formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  onEventAllow = (drag, event) => {
    let dragCurrentPositionDate = new Date(
      moment(drag.startStr).format("MM/DD/YYYY")
    );
    // dragCurrentPositionDate.setDate(dragCurrentPositionDate.getDate() - 2);
    let dayName = days[dragCurrentPositionDate.getDay()];
    dragCurrentPositionDate = dragCurrentPositionDate.getTime();
    const { currentTeamDataDays } = this.state;
    if (false &&
      this.state.scheduleDatesState.length &&
      this.state.selectedTeamValue != undefined
    ) {
      const isProviderExists = [...this.state.scheduleDatesState].find(
        (provider, key) => {
          // let providerMatchDate = new Date(provider.date);
          // providerMatchDate = this.formatDate(providerMatchDate);
          // providerMatchDate = new Date(providerMatchDate);
          // providerMatchDate = providerMatchDate.getTime();
          let providerMatchDate =  new Date(
            moment(provider.date).format("MM/DD/YYYY")
          );
          providerMatchDate = providerMatchDate.getTime();
          if (
            dragCurrentPositionDate >= this.state.startDateTime &&
            dragCurrentPositionDate <= this.state.endDateTime
          ) {
            if (
              currentTeamDataDays.length &&
              currentTeamDataDays.includes(dayName)
            ) {
              if (
                provider.providerCode === this.state.dragProviderCode &&
                providerMatchDate === dragCurrentPositionDate
              ) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      );
      if (isProviderExists !== undefined && isProviderExists.id) {
        return false;
      } else {
        return true;
      }
    } else {
      let isDateDrag = false;
      if (
        dragCurrentPositionDate >= this.state.startDateTime &&
        dragCurrentPositionDate <= this.state.endDateTime
      ) {
        if (
          currentTeamDataDays.length &&
          currentTeamDataDays.includes(dayName)
        ) {
          isDateDrag = true;
        } else {
          isDateDrag = false;
        }
      } else {
        isDateDrag = false;
      }
      if (isDateDrag) {
        return true;
      } else {
        return false;
      }
    }
  };

  toTime = time => {
    var timeString = time;
    var H = +timeString.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? "AM" : "PM";
    timeString = h + timeString.substr(2, 3) + ampm;
    return timeString;
  };

  getScheduleEvent = () => {
    const { scheduleDatesState } = this.state;
    let calenderScheduleDataArray = this.state.scheduleDatesState.map(schedule => {
      return {
        start: schedule.date,
        end: schedule.date,
        id: schedule.providerCode,
        shiftId: schedule.id,
        ceilingRate: schedule.ceilingRate,
        flag: schedule.flag,
        rate: schedule.askRate != 0 ? schedule.askRate : null,
        isSourceShift: (schedule.isSourceShift && schedule.isSourceShift !== undefined) ? true : false,
        addOnHourRate: (schedule.addonRate && schedule.addonRate !== undefined) ? schedule.addonRate : 0,
        addOnHours: (schedule.addOnHours && schedule.addOnHours !== undefined) ? schedule.addOnHours : 0,
        comment:(schedule.comment && schedule.comment !== undefined) ? schedule.comment : "",
        rateMask: schedule.rateMask ? true : false,
        onCallOpenShift: schedule.onCallOpenShift ? true : false,
        skill: schedule.askRate ? schedule.skillLevel : "",
        providerName: schedule.providerName,
        colorCode: this.state.isDepView ? (schedule.color_Code ? schedule.color_Code : null) : null,
        isMobileRequest: schedule.isMobileRequest ? schedule.isMobileRequest : false,
        startProviderTime: ((schedule.startTime !== undefined) ? schedule.startTime : "00:00:00"),
        endProviderTime: ((schedule.endTime !== undefined) ? schedule.endTime : "00:00:00"),
        preferredCallNumber: (schedule.preferredCallNumber ? schedule.preferredCallNumber: ""),
        description: this.state.isDepView
          ? `${
          schedule.providerName != null
            ? schedule.providerName +
            " (" +
            schedule.teamName +
            ") (" +
            schedule.skillLevel +
            ")"
            : ""
          } ${
          schedule.askRate != null &&
            schedule.askRate != 0 &&
            schedule.flag !== 5 &&
            schedule.flag !== 3 &&
            schedule.flag !== 4
            ? schedule.providerName != null
              ? ""//schedule.skillLevel + schedule.providerName
              : ""
            : ""
          }`
          : "",
        title: this.state.isDepView
          ? `${
          schedule.providerName != null &&
            schedule.flag !== 10
            ? schedule.providerName.split(" ")[0].substring(0, 1) +
            ". " +
            schedule.providerName.split(" ")[1] +
            " (" +
            schedule.teamName +
            ") (" +
            schedule.skillLevel.substring(0, 3) +
            ") "
            : ""
          } ${
          schedule.askRate != null &&
            schedule.askRate != 0 &&
            schedule.flag === 1
            ? schedule.skillLevel.substring(0, 3) +
            " (" +
            schedule.teamName +
            ")"
            : ""
          } ${
          schedule.askRate != null &&
            schedule.askRate != 0 &&
            schedule.flag === 10
            ? schedule.skillLevel.substring(0, 3) +
            " (" +
            schedule.teamName +
            ")"
            : ""
          }`
          : `${schedule.providerName != null ? schedule.providerName : ""} ${
          schedule.askRate != null && schedule.askRate != 0
            ? schedule.skillLevel + (!schedule.rateMask ? " $" + schedule.askRate: "")
            : ""
          }`,
        resource: schedule.providerCode,
        editable: schedule.flag == 1 || schedule.flag == 10 ? false : true,
        classNames: (() => {
          if (schedule.flag == 0) {
            return "empty";
          } else if (schedule.flag == 1) {
            return "open-red";
          } else if (schedule.flag == 4 && schedule.providerCode != null && schedule.isSourceShift == true && schedule.addOnHours > 0) {
            return "blocked-blue";
          } else if (schedule.flag == 2 || schedule.flag == 4) {
            return "blocked-blue";
          } else if (schedule.flag == 3) {
            return "showing-interest-yellow";
          } else if (schedule.flag == 5) {
            return "purple";
          } else if (schedule.flag === 10) {
            return "#A6D609";
          }
        })(),
        backgroundColor: (() => {
          if (schedule.flag == 1) {
            return "#ff5151";
          } else if (schedule.flag == 4 && schedule.providerCode != null && schedule.isSourceShift == true && schedule.addOnHours > 0) {
            return "#506886";
          } else if (schedule.flag == 2 || schedule.flag == 4) {
            return "#41b6e6";
          } else if (schedule.flag == 3) {
            return "#ffbf0a";
          } else if (schedule.flag == 5) {
            return "#9932CC";
          } else if (schedule.flag === 10) {
            return "#A6D609";
          } else {
            return "#fff";
          }
        })(),
        borderColor: (() => {
          if (schedule.flag == 1) {
            return "#ff5151";
          } else if (schedule.flag == 4 && schedule.providerCode != null && schedule.isSourceShift == true && schedule.addOnHours > 0) {
            return "#506886";
          } else if (schedule.flag == 2 || schedule.flag == 4) {
            return "#41b6e6";
          } else if (schedule.flag == 3) {
            return "#ffbf0a";
          } else if (schedule.flag == 5) {
            return "#9932CC";
          } else if (schedule.flag === 10) {
            return "#A6D609";
          } else {
            return "#fff";
          }
        })()
      };
    });
    this.setState(
      {
        calenderScheduleData: calenderScheduleDataArray,
        calenderKey: Math.random() + "",
        // isDepView: this.state.isDepView
      },
      () => {
        this.forceUpdate()
      }
    );
  };

  EventDetail = ({ event, el }) => {
    const content = <div style={{ alignContent: "center", alignItems: "left", justifyContent: "center", flexDirection: "row" }}>
      {
        event.extendedProps.colorCode != null && <>
          <span style={{ paddingLeft: 5 }}>
            <i style={{
              borderRadius: '50%',
              height: 8,
              alignItems: "left",
              justifyContent: "center",
              width: 8,
              color: event.extendedProps.colorCode
            }} className="fa fa-circle"></i>
          </span>
          {
            event.extendedProps.isMobileRequest && <span style={{ paddingRight: 0, paddingLeft:2 }}>
              <PhoneIphone color="white">Filled</PhoneIphone>
            </span>
          }
        </>
      }
      <span style={{ paddingLeft: event.extendedProps.isMobileRequest ? 2 : (event.extendedProps.colorCode == null ? 20 : 5) }}>{event.title}</span>
    </div>;
    ReactDOM.render(content, el);
    return el;
  };

  eventReceive = event => {
    // event.draggedEl.remove();
  };

  renderDayAvailable = () => {
    const { currentTeamData } = this.state;
    let availableDays = currentTeamData
      ? currentTeamData[0].frequencyIntervalText.split(",")
      : [];
    availableDays = availableDays.map(function (s) {
      return s.trim();
    });
    return (
      <ButtonGroup size="small" style={{ height: 20, marginRight: '4px' }} color="primary" variant="contained" className="day-available-button-group">
        {days.map((day, idx) => {
          let dayFirstChar = day.substring(0, 1);
          return (
            <Button
              key={idx}
              value={idx}
              style={{
                padding: '0px', minWidth: 'unset', width: '15px', cursor: 'not-allowed',
                pointerEvents: 'auto'
              }}
              variant={availableDays.includes(day) ? "contained" : 'outlined'}
            // disabled = {!availableDays.includes(day)}

            // className={
            //   availableDays.includes(day)
            //     ? "days-available"
            //     : "days-not-available"
            // }
            >
              {dayFirstChar}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  };

  onClickChangeQHP = val => {
    this.setState({ selectedQHPGroup: val, selectedFilterLabourType:"" });
  };

  handleShiftRateChange = event => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      askRate: event.target.value
    });
    this.setState({ selectedOpenShift: shiftData });
  };

  handleAddOnHourRateChange = event => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      addOnHourRate: event.target.value
    });
    this.setState({ selectedOpenShift: shiftData });
  };

  onChangeComment = (event) => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      comment: event.target.value
    });
    this.setState({ selectedOpenShift: shiftData });
  };

  onOtherCommentChange = (event) => {
    let { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      otherComment: event.target.value
    });
    this.setState({ selectedOpenShift: shiftData });
  }

  handleChangeRateMask = event => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      rateMask: selectedOpenShift.rateMask
      ? false
      : true
    });
    this.setState({ selectedOpenShift: shiftData });
  };
  
  handleChangeOnCallOpenShift = event => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      onCallOpenShift: selectedOpenShift.onCallOpenShift
      ? false
      : true
    });
    this.setState({ selectedOpenShift: shiftData });
  };

  handleShiftStartTimeChange = event => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      startTime: event
    });
    
    this.setState({ selectedOpenShift: shiftData },()=> {
      const data = Object.assign(selectedOpenShift, {
        diffHour: this.getDiffHours(),
        addOnHours: this.getDiffHours(),
        addOnHourRate: this.getDiffHours() ? this.state.selectedOpenShift.addOnHourRate: 0,
        comment: selectedOpenShift.comment || ""
      });
      this.setState({selectedOpenShift:data});
    });
  };
  
  handleShiftEndTimeChange = event => {
    const { selectedOpenShift } = this.state;
    const shiftData = Object.assign(selectedOpenShift, {
      endTime: event
    });
    this.setState({ selectedOpenShift: shiftData },()=> {
      const data = Object.assign(selectedOpenShift, {
        diffHour: this.getDiffHours(),
        addOnHours: this.getDiffHours(),
        addOnHourRate: this.getDiffHours() ? this.state.selectedOpenShift.addOnHourRate: 0,
        comment: this.state.selectedOpenShift.comment || ""
      });
      this.setState({selectedOpenShift:data});
    });
  };

  validateUpdateOpenShiftRate = () => {
    const { selectedOpenShift } = this.state;

    let validateUpdateOpenShiftMessage = "";
    if (
      selectedOpenShift.askRate === null ||
      !selectedOpenShift.askRate ||
      selectedOpenShift.askRate.toString().trim() === ""
    ) {
      validateUpdateOpenShiftMessage = "Please enter skill rate.";
    } else if (selectedOpenShift.askRate <= 0) {
      validateUpdateOpenShiftMessage =
        "Please enter skill rate greater than zero.";
    } else if (selectedOpenShift.ceilingRate < selectedOpenShift.askRate) {
      validateUpdateOpenShiftMessage =
        `Skill rate should be less then or equal to ` +
        selectedOpenShift.ceilingRate;
    }

    if(this.getDiffHours()) {
      if (
        selectedOpenShift.addOnHourRate === null ||
        !selectedOpenShift.addOnHourRate ||
        selectedOpenShift.addOnHourRate.toString().trim() === ""
      ) {
        validateUpdateOpenShiftMessage = "Please enter skill rate.";
      } else if (selectedOpenShift.addOnHourRate <= 0) {
        validateUpdateOpenShiftMessage =
          "Please enter skill rate greater than zero.";
      }
    }

    this.setState({
      validateUpdateOpenShiftMessage: validateUpdateOpenShiftMessage
    });
    return validateUpdateOpenShiftMessage ? false : true;
  };

  updateOpenShiftTime = () => {
    let { selectedOpenShift } = this.state;

    // const selectedOpenShiftStartTimeTemp = moment([this.state.selectedOpenShift.startTime.split(":")[0],this.state.selectedOpenShift.startTime.split(":")[1]], "HH:mm")
    // const selectedOpenShiftEndTimeTemp = moment([this.state.selectedOpenShift.endTime.split(":")[0],this.state.selectedOpenShift.endTime.split(":")[1]], "HH:mm")
  
    // if((selectedOpenShift.startTime && selectedOpenShift.endTime && (!moment(selectedOpenShiftStartTimeTemp).isSameOrBefore(selectedOpenShiftEndTimeTemp, 'hour') && !moment(selectedOpenShiftStartTimeTemp).isSameOrBefore(selectedOpenShiftEndTimeTemp, 'minute'))) ||
    //   (selectedOpenShift.endTime && selectedOpenShift.startTime && (moment(selectedOpenShiftEndTimeTemp).isSameOrBefore(selectedOpenShiftStartTimeTemp, 'hour') && moment(selectedOpenShiftEndTimeTemp).isSameOrBefore(selectedOpenShiftStartTimeTemp, 'minute')))) {
    //       return false
    //   } else {
      let data = {
        id: selectedOpenShift.calendarId,
        startTime:`${selectedOpenShift.startTime.getHours()}:${selectedOpenShift.startTime.getMinutes()}`,
        endTime:`${selectedOpenShift.endTime.getHours()}:${selectedOpenShift.endTime.getMinutes()}`,
        rateMask:selectedOpenShift.rateMask,
        addOnHourRate:selectedOpenShift.addOnHourRate,
        isSourceShift:selectedOpenShift.isSourceShift,
        addOnHours:selectedOpenShift.addOnHours,
        onCallOpenShift:selectedOpenShift.onCallOpenShift,
        comment: this.state.selectedOpenShift.diffHour && selectedOpenShift.comment ? (selectedOpenShift.comment == "Other" ? selectedOpenShift.otherComment : selectedOpenShift.comment) : ""
      }
      this.onEditAssignedShift(data)
    // }
  }

  updateOpenShiftRate = () => {
    if (this.validateUpdateOpenShiftRate()) {
      let { selectedOpenShift, selectedSkillValue } = this.state;
      selectedOpenShift.skillLevel = selectedOpenShift.skillLevel
        ? selectedOpenShift.skillLevel
        : selectedSkillValue;
        selectedOpenShift['date'] = [selectedOpenShift['date']]
      this.props.changeOpenShiftSkillRate(selectedOpenShift).then(response => {
        if (response.data.status) {
          let notificationsArray = this.state.notifications;
          let resendNotificationsArray = [];
          notificationsArray.map(item => {
            if (item.selected) {
              resendNotificationsArray.push(item.value);
            }
          });
          if (resendNotificationsArray.length > 0) {
            this.resendNotification();
          } else {
            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            this.getScheduleEvent();
            this.setState({ isOpenShiftRateChange: false });
            // this.openShiftRateChangeModal();
          }
          toast.success("Shift rate has been updated.");
        } else {
          this.props.getTeamScheduleDates(
            this.state.selectedScheduleBlockValue
          );
          this.getScheduleEvent();
          toast.error(response.data.error.message);
        }
        this.setState({ isOpenShiftRateChange: false });
        // this.openShiftRateChangeModal();
      });
    } else {
      return false;
    }
  };

  openShiftRateChangeModal = () => {
    this.setState({ isOpenShiftRateChange: !this.state.isOpenShiftRateChange });
  };

  createOpenShiftModal = () => {
    this.setState(
      {
        isCreateOpenShiftModal: !this.state.isCreateOpenShiftModal
      },
      () => {
        if (this.state.isCreateOpenShiftModal) {
          this.getAllSkilByDept();
        }
      }
    );
  };

  onChangeShiftSkills = async event => {
    const { selectedCreateOpenShift, selectedDepartmentValue } = this.state;
    const skillLevelData = Object.assign(selectedCreateOpenShift, {
      skillLevel: (event.target.value ? event.target.value :"")
    });
    await this.props
      .getCeilingRate(selectedDepartmentValue, event.target.value)
      .then(response => {
        if (response.data.status) {
          this.setState({
            selectedCeilingRate: response.data.data.ceilingRate
          },()=> this.setState({
            selectedCreateOpenShift: skillLevelData,
            selectedCreateOpenShiftSkillValue: event.target.value
          }));
        } else {
          this.setState({
            selectedCeilingRate: 0
          },() => this.setState({
            selectedCreateOpenShift: skillLevelData,
            selectedCreateOpenShiftSkillValue: event.target.value
          }));
          toast.error(response.data.error.message);
        }
      });
  };

  // onChangeLabourTypes = async (labourTypes = []) => {
  //   const { selectedCreateOpenShift } = this.state;
  //   let labourTypeArray = [];

  //   labourTypes && labourTypes.forEach(i => {
  //     labourTypeArray.push({
  //       label: i.label,
  //       value: i.value
  //     });
  //   });
  //   const skillLevelData = Object.assign(selectedCreateOpenShift, {
  //     labourType: labourTypeArray
  //   });
  //   this.setState({
  //     selectedCreateOpenShift: skillLevelData,
  //     selectedCreateOpenShiftabourTypeValue: labourTypeArray
  //   });
  // };

  onChangeLabourTypes = (opt, { option }) => {
    const { selectedCreateOpenShift } = this.state;
    let newOpts = opt;
    let string = this.state.searchField;

    if (option && option.value === "all") {
      let filteredOptions = clone(labourTypes);

      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );

      string = null;
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
    }
  
    const skillLevelData = Object.assign(selectedCreateOpenShift, {
      labourType: newOpts
    });
    this.setState({
      selectedCreateOpenShift: skillLevelData,
      selectedCreateOpenShiftLabourTypeValue: newOpts,
      searchField: string
    });
    // this.setState({
    //   searchField: string,
    //   selectedCreateOpenShiftabourTypeValue: newOpts,
    // });
  };

  onInputChange = (string, { action }) => {
    if (action === "input-change") {
      this.setState({
        searchField: string
      });
    }
  };

  filterOption = ({ label, value }, string) => {
    if (value === "all") {
      return true;
    } else if (string) {
      return label.includes(string) || value.toString().includes(string);
    } else {
      return true;
    }
  };

  onChangeShiftRate = event => {
    const { selectedCreateOpenShift } = this.state;
    const shiftData = Object.assign(selectedCreateOpenShift, {
      askRate: event.target.value
    });
    this.setState({ selectedCreateOpenShift: shiftData });
  };

  onChangeRateMask = event => {
    const { selectedCreateOpenShift } = this.state;
    const shiftData = Object.assign(selectedCreateOpenShift, {
      rateMask: selectedCreateOpenShift.rateMask
      ? false
      : true
    });
    this.setState({ selectedCreateOpenShift: shiftData });
  };

  onChangeOnCallOpenShift = event => {
    const { selectedCreateOpenShift } = this.state;
    const shiftData = Object.assign(selectedCreateOpenShift, {
      onCallOpenShift: selectedCreateOpenShift.onCallOpenShift
      ? false
      : true
    });
    this.setState({ selectedCreateOpenShift: shiftData });
  };
  
  validateCreateOPenShiftRate = () => {
    const { selectedCreateOpenShift } = this.state;
    let validateCreateOpenShiftMessage = "";
    if (
      selectedCreateOpenShift.skillLevel == null ||
      selectedCreateOpenShift.skillLevel.trim() == ""
    ) {
      validateCreateOpenShiftMessage = "Please select your skill.";
    } else if (
      selectedCreateOpenShift.askRate === null ||
      !selectedCreateOpenShift.askRate ||
      selectedCreateOpenShift.askRate.trim() == ""
    ) {
      validateCreateOpenShiftMessage = "Please enter skill rate.";
    } else if (selectedCreateOpenShift.askRate <= 0) {
      validateCreateOpenShiftMessage =
        "Please enter skill rate greater than zero.";
    } else if (
      this.state.selectedCeilingRate < selectedCreateOpenShift.askRate
    ) {
      validateCreateOpenShiftMessage =
        `Skill rate should be less then or equal to ` +
        this.state.selectedCeilingRate;
    } else if (selectedCreateOpenShift.schedulerCode == undefined || !selectedCreateOpenShift.schedulerCode || selectedCreateOpenShift.schedulerCode == "" || selectedCreateOpenShift.schedulerCode === null) {
      validateCreateOpenShiftMessage = "Please select schedule block."
    }
    this.setState({
      validateCreateOpenShiftMessage: validateCreateOpenShiftMessage
    });
    return validateCreateOpenShiftMessage ? false : true;
  };

  createOpenShiftRate = () => {
    const { selectedCreateOpenShift } = this.state;
    let selectedLabourType = [];
    let selectedArray = selectedCreateOpenShift;
    let selectedLabourTypeArray = [];
    selectedCreateOpenShift.labourType && selectedCreateOpenShift.labourType.forEach((element, i) => {
      selectedLabourTypeArray.push(element.value);
    });
    // const skillLevelData = Object.assign(selectedCreateOpenShift, {
    //   labourType: selectedLabourType
    // });
    // if (selectedCreateOpenShift.schedulerCode == undefined || !selectedCreateOpenShift.schedulerCode || selectedCreateOpenShift.schedulerCode == "" || selectedCreateOpenShift.schedulerCode === null) {
    //   toast.error("Please select schedule block");
    //   return false
    // }
    if (this.validateCreateOPenShiftRate()) {
      let dateArray = [];
      this.state.selectedDays.map((day, idx) => {
        dateArray.push(formatForCreateOpenShiftDate(day));
      })
      selectedArray['labourType'] = selectedLabourTypeArray;
      selectedArray['isSourceShift'] = true;
      selectedArray['date'] = dateArray;
      this.props
        .changeOpenShiftSkillRate(selectedArray)
        .then(response => {
          if (response.data.status) {
            this.setState({ selectedCreateOpenShiftSkillValue: "" });
            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            toast.success("Shift created successfully.");
            this.getScheduleEvent();
          } else {
            this.setState({ selectedCreateOpenShiftSkillValue: "" });
            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            this.getScheduleEvent();
            toast.error(response.data.error.message);
          }
          this.setState({selectedDays:[]})
          this.createOpenShiftModal();
        }).catch((error) => {
          toast.error(error.message);
        });
    } else {
      return false;
    }
  };

  // getAddButton = day => {
  //   if (!this.state.isDragStart && !this.state.isDepView) {
  //     let button = document.createElement("button");
  //     button.classList = "single-date-open-shift";
  //     let nowDate = new Date(day.date);
  //     nowDate.setDate(nowDate.getDate() + 1);
  //     let currentSelectedMonth = new Date(this.state.defaultDate);
  //     currentSelectedMonth.setDate(currentSelectedMonth.getDate() + 1);
  //     button.setAttribute("id", Math.floor(Math.random() * 100) + "");
  //     button.setAttribute(
  //       "style",
  //       `display: flex; bottom:0; margin-right:3px; z-index: 999; position: absolute; justify-content: flex-end; font-size: small;`
  //     );

  //     button.innerHTML = `+`;
  //     button.myDate = day;
  //     let STARTDATETIME = null;
  //     STARTDATETIME = new Date(this.state.startDateTime);
  //     STARTDATETIME.setDate(1);
  //     if (this.state.startDateTime && this.state.endDateTime) {
  //       // console.log('Date--->',!this.state.isDepView, new Date(currentSelectedMonth), STARTDATETIME, new Date(this.state.endDateTime))
  //       //If Month to Month (!this.state.isDepView && ((new Date(currentSelectedMonth) >= STARTDATETIME) && (new Date(currentSelectedMonth) <= new Date(this.state.endDateTime)))){
  //       //If Date to Date if (!this.state.isDepView && ((new Date((nowDate.setDate(nowDate.getDate()))).getTime()) > this.state.startDateTime) && ((new Date((nowDate.setDate(nowDate.getDate()))).getTime()) < this.state.endDateTime)){
  //       if (!this.state.isDepView && ((new Date(currentSelectedMonth) >= STARTDATETIME) && (new Date(currentSelectedMonth) <= new Date(this.state.endDateTime)))) {
  //         //console.log('Correct DateINDISE--->',!this.state.isDepView, new Date(currentSelectedMonth), STARTDATETIME, new Date(this.state.endDateTime))
  //         button.removeAttribute("disabled", false);
  //       } else {
  //         button.setAttribute("disabled", true);
  //       }
  //     } else {
  //       button.setAttribute("disabled", true);
  //     }
  //     day.el.appendChild(button);
  //   }
  // };

  onChangeSchedulerToggle = defaultDate => {
    const { selectedDepartmentValue } = this.state;
    let currentMonthDate = new Date(defaultDate);
    // let month = currentMonthDate.getMonth() + 1;
    let month = defaultDate.split('-')[1]
    let year = defaultDate.split('-')[0]
    // let year = currentMonthDate.getFullYear();
    if (selectedDepartmentValue !== "") {
      this.props
        .getAllProvidersShift(selectedDepartmentValue, month, year)
        .then(response => {
          if (response.data.status) {
            let date = defaultDate;
            this.setState(
              {
                defaultDate: date,
                isPast: false,
                scheduleDatesState: response.data.data.providerDatas.length
                  ? response.data.data.providerDatas
                  : []
              },
              () => {
                this.getScheduleEvent();
              }
            );
          } else {
            this.getScheduleEvent();
          }
        });
    } else {
      toast.error("Please selecte your department.");
    }
  };

  getAllSkilByDept = async () => {
    const { selectedDepartmentValue } = this.state;
    if (selectedDepartmentValue) {
      await instance
        .get(`Department/GetDepartmentSkillLevelsByCode?`, {
          params: { code: selectedDepartmentValue }
        })
        .then(response => {
          if (response.data.status) {
            this.setState({
              allSkills: response.data.data ? response.data.data : []
            },() => {
              if(this.state.allSkills && this.state.allSkills.length !== 0) {
                let event = [];
                event['target'] = {'value' : this.state.allSkills[0]};
                this.onChangeShiftSkills(event);
              }
            });
          }
        })
        .catch(error => {
        });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.openCreateNewShift);
  };

  delOpenShift = event => {
    this.state.currentCalendarClickEvent.event.remove(); // It will remove event from the calendar

    let providerData = {
      id: this.state.selectedOpenShift.calendarId,
      teamCode: this.state.selectedOpenShift.teamCode,
      shiftId: this.state.selectedOpenShift.calendarId
    };

    this.props.deleteProvider(providerData).then(response => {
      if (response !== undefined && response.data.status) {
        this.props.getTeamScheduleDates(this.state.selectedScheduleBlockValue);
        toast.success("Your Event has been deleted.");
      } else {
        toast.error("Please try again.");
      }
      this.setState({ defaultDate: this.state.selectedOpenShift.date });
    });
    this.openShiftRateChangeModal();
  };

  // handleEventPositioned = info => {
  //   if (
  //     info.event.extendedProps.flag === 2 ||
  //     info.event.extendedProps.flag === 3 ||
  //     info.event.extendedProps.flag === 4 ||
  //     info.event.extendedProps.flag === 5
  //   ) {
  //     info.el.setAttribute("title", info.event.extendedProps.description);
  //   }
  // };

  handleChangeResendNotifications = event => {
    let notificationsArray = this.state.notifications;
    let selectedData = notificationsArray.findIndex(
      item => item.value === event.target.value
    );
    notificationsArray[selectedData].selected = notificationsArray[selectedData]
      .selected
      ? false
      : true;
    this.setState({ notifications: notificationsArray });
  };

  resendNotification = () => {
    let notificationsArray = this.state.notifications;
    let resendNotificationsArray = [];
    notificationsArray.map(item => {
      if (item.selected) {
        resendNotificationsArray.push(item.value);
      }
    });

    let { selectedOpenShift, selectedSkillValue } = this.state;
    selectedOpenShift.skillLevel = selectedOpenShift.skillLevel
      ? selectedOpenShift.skillLevel
      : selectedSkillValue;
    const { skillLevel, date, teamCode } = selectedOpenShift;

    let notificationData = {
      skillLevel,
      date,
      teamCode,
      labourType: resendNotificationsArray
    };

    if (resendNotificationsArray.length > 0) {
      this.props
        .resendNotificationOpenShift(notificationData)
        .then(response => {
          // this.openShiftRateChangeModal();
          this.setState({ isOpenShiftRateChange: false });
          if (response.status) {
            this.setState({ notifications: notificationDefault });
            toast.success("Notification send successfully.");

            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            this.getScheduleEvent();
          } else {
            this.props.getTeamScheduleDates(
              this.state.selectedScheduleBlockValue
            );
            this.getScheduleEvent();
            toast.error(response.data.error.message);
          }
        });
    } else {
      toast.error("Please select notification type.");
    }
  };


  getDayValue = (dt) => {
    const baseDate = new Date('01-01-2000');    
    return dt && dt.getDate() + (dt && dt.getMonth() * 30) + ((dt && dt.getYear() - baseDate.getYear()) * 12 *30);
  }

  getAddButton =(day) => {
    let drag = Array();
    drag['startStr'] = this.formatDate(day.date)
    console.log("start",drag['startStr'])
    if (!this.state.isDragStart && !this.state.isDepView && this.onEventAllow(drag)) {
      let div = document.createElement("div");

      let button = document.createElement("a");
      button.classList = "single-date-open-shift slider slider-left";
      button.innerHTML = "<div class='slide-1 single-date-open-shift'><span>+</span></div><div class='slide-2 single-date-open-shift'>ADD</div>"

      div.appendChild(button);
      let nowDate = new Date(day.date);
      
      if(nowDate) {
        nowDate.setDate(nowDate.getDate() + 1);
      }
     
      let currentSelectedMonth = new Date(this.state.defaultDate);
      currentSelectedMonth.setDate(currentSelectedMonth.getDate() + 1);
      button.setAttribute("id", Math.floor(Math.random() * 100) + "");
      button.myDate = day;
      button.addEventListener("click", this.openCreateNewShift);

      // console.log("this.state.startDateTime-->", this.state.startDateTime, "this.state.endDateTime-->", this.state.endDateTime)
      if (this.state.startDateTime && this.state.endDateTime) {
        
        const startDay = this.getDayValue(new Date(this.state.startDateTime));

        const endDay = this.getDayValue(new Date(this.state.endDateTime));
        
        const currentDay = this.getDayValue(new Date(moment(day.date).utcOffset(0)));
        const todayDay = this.getDayValue(new Date(moment().utcOffset(0)));

        // console.log("currentDay-->", currentDay, day.date)
        // console.log("todayDay-->", todayDay)
        // console.log("currentDate.getMonth-->", day.view.context.calendar.state.currentDate.getMonth())
        // console.log("day.date.getMonth()-->", day.date.getMonth())
        // console.log("CONDITIONS--->", (currentDay >= startDay && currentDay <= endDay && currentDay >= todayDay && day.view.context.calendar.state.currentDate.getMonth() === day.date.getMonth() && day.date.getMonth()))
        if (currentDay >= startDay && currentDay <= endDay && currentDay >= todayDay && day.view.context.calendar.state.currentDate.getMonth() === day.date.getMonth() && (day.date.getMonth() !== undefined || day.date.getMonth() >= 0)) {
          if (!this.state.isDepView) {
              try {
                if(day.el.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[0].children[0].children[0].children[day.el.cellIndex]) {
                
                  day.el.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[0].children[0].children[0].children[day.el.cellIndex].appendChild(div);
                }
              } catch(e) {
                console.log('Exception captured on code: ', e);        
            }
          }
        }
        // LAST Version code
        // console.log("currentDay",currentDay, endDay+1)
        // if (currentDay >= startDay && currentDay <= endDay && currentDay >= todayDay && day.date.getMonth() === day.view.calendar.getDate().getMonth()) {
        //   if ( !this.state.isDepView) {
        //       try{
        //         if(day.el.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[0].children[0].children[0].children[day.el.cellIndex]) {
        //           day.el.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[0].children[0].children[0].children[day.el.cellIndex].appendChild(div);
        //         }
        //       }catch(e){
        //         // console.log('Exception captured on code: ', e);        
        //     }
        //   }
        // }
      }
    }
  };
  onClickMonth = () => {
    this.setState({ defaultDate: this.formatDate(new Date()), myView: 'dayGridMonth', calenderKey: Math.random() + "" }, () => {
      this.calendarComponentRef.current.getApi().changeView('dayGridMonth');
    })
  }

  onClickWeek = () => {
    this.setState({ defaultDate: this.formatDate(new Date()), myView: 'dayGridWeek' }, () => {
      this.calendarComponentRef.current.getApi().changeView('dayGridWeek');
    })
  }

  onClickDay = () => {
    this.setState({ defaultDate: this.formatDate(new Date()), myView: 'dayGridDay' }, () => {
      this.calendarComponentRef.current.getApi().changeView('dayGridDay');
    })
  }

  onChangeSelectedDates = (date) => {
    let selectedDaysArray = this.state.selectedDays;
    if (selectedDaysArray.findIndex((day) => ((new Date(day).getTime()) === (new Date(date).getTime()))) !== -1) {
      if (this.state.selectedDays.length !== 1) {
        selectedDaysArray.splice(selectedDaysArray.findIndex((day) => ((new Date(day).getTime()) === (new Date(date).getTime()))), 1);
      }
    } else {
      selectedDaysArray.push(new Date(date));
    }
    this.setState({ selectedDays: selectedDaysArray }, () => {
    });
  }

  getDisableWeekdays = (date) => {
    const { currentTeamData } = this.state;
    let availableDays = currentTeamData
      ? currentTeamData[0].frequencyIntervalText.split(",")
      : [];
      availableDays = availableDays.map(function (s) {
        return s.trim();
      });
      let disabledDays = [];
      days && days.forEach((day, index) => {
        if(availableDays.indexOf(day) === -1) {
          disabledDays.push(index);
        }
      });
      return disabledDays;
  }

  onSelectedFilterLabourType = (e) => {
    this.setState({selectedFilterLabourType:e.target.value});
  }

  handleEventPositioned=(info)=> {
    if (
        (this.state.isDepView &&
        (info.event.extendedProps.flag === 2 ||
        info.event.extendedProps.flag === 3 ||
        info.event.extendedProps.flag === 4 ||
        info.event.extendedProps.flag === 5))
      ) {
        let startTime = new Date()
        startTime.setHours(info.event.extendedProps.startProviderTime.split(":")[0])
        startTime.setMinutes(info.event.extendedProps.startProviderTime.split(":")[1])
        let endTime = new Date()
        endTime.setHours(info.event.extendedProps.endProviderTime.split(":")[0])
        endTime.setMinutes(info.event.extendedProps.endProviderTime.split(":")[1])
        let tooltipInfo =`</div>
          <h2><b>${info.event.extendedProps.description}</b></h2>
          <h3><b>Prefered Call Number: </b>${info.event.extendedProps.preferredCallNumber ? info.event.extendedProps.preferredCallNumber : "N/A"}</h3>
          <h4><b>Start Time: </b>${startTime
            ? moment(convertTZ(startTime)).format("hh:mm A") : ""}</h4>
          <h4><b>End Time: </b>${endTime
            ? moment(convertTZ(endTime)).format("hh:mm A") : ""}</h4>
        </div>`
        info.el.setAttribute("data-tip",tooltipInfo);
        info.el.setAttribute("data-html",true);
        ReactTooltip.rebuild();
      }
   }
  
  render() {
    const {
      selectedfacilityValue,
      selectedDepartmentValue,
      selectedTeamValue,
      selectedScheduleBlockValue,
      selectedSkillValue,
      isOpenShiftModalOpen,
      currentTeamData,
      selectedQHPGroup,
      selectedOpenShift,
      selectedCreateOpenShift,
      selectedCreateOpenShiftSkillValue,
      selectedCreateOpenShiftLabourTypeValue,
      selectedFilterLabourType
    } = this.state;

    const {
      facilityPayload: { data },
      teamScheduleBlock,
      providersList,
      allProvidersList
    } = this.props;

    const facility = data;
    if (providersList != null) {
      providersList.sort(function (a, b) {
        var textA = a.providerName.toUpperCase();
        var textB = b.providerName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    if (allProvidersList && allProvidersList.length) {
      allProvidersList.sort(function (a, b) {
        var textA = a.providerName.toUpperCase();
        var textB = b.providerName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    
    const buttons = {
      myPrevButton: {
        text: "<",
        click: this.state.isSchedulerToggle
          ? console.log("click")
          : this.onClickPrev
      },
      myTitle:{
        text: (moment(
          new Date(this.state.defaultDate),
          "DD-MMM-YYYY"
        ).utcOffset(0).format('MMMM YYYY')),
        click: this.onClickCalenderTitle
      },
      myNextButton: {
        text: ">",
        click: this.onCLickNext
      },
      myDayGridMonth: {
        text: "Month",
        click: this.onClickMonth
      },
      myDayGridWeek: {
        text: "Week",
        click: this.onClickWeek
      },
      myDayGridDay: {
        text: "Day",
        click: this.onClickDay
      },
      myCustomControl:{
        html:'<div className="dept-margin">\
      </div>'
      }
    };

    const errorStyle = {
      color: "#FF0000"
    };
    const spinner = {
      marginRight: "10%"
    };

    function renderEventContent(dayRenderInfo) {
      let button = document.createElement("button");
      button.innerHTML = '+';


      button.classList = 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall';
      dayRenderInfo.el.appendChild(button);
    }
    // providerStartTime.getHours()
    // const selectedOpenShiftStartTimeTemp = this.state.selectedOpenShift.startTime ? moment([this.state.selectedOpenShift.startTime.split(":")[0],this.state.selectedOpenShift.startTime.split(":")[1]], "HH:mm"): "00:00"
    // const selectedOpenShiftEndTimeTemp = this.state.selectedOpenShift.endTime ? moment([this.state.selectedOpenShift.endTime.split(":")[0],this.state.selectedOpenShift.endTime.split(":")[1]], "HH:mm"): "00:00"
   
    const providerStartTimeTemp = this.state.assignSelectedProviderStartTime ? moment([this.state.assignSelectedProviderStartTime.split(":")[0],this.state.assignSelectedProviderStartTime.split(":")[1]], "HH:mm") : this.state.currentTeamData && moment([this.state.currentTeamData[0].startTime.split(":")[0], this.state.currentTeamData[0].startTime.split(":")[1]], "HH:mm")
    const providerEndTimeTemp = this.state.assignSelectedProviderEndTime ? moment([this.state.assignSelectedProviderEndTime.split(":")[0],this.state.assignSelectedProviderEndTime.split(":")[1]], "HH:mm") : this.state.currentTeamData && moment([this.state.currentTeamData[0].endTime.split(":")[0], this.state.currentTeamData[0].endTime.split(":")[1]], "HH:mm")
    return (
      <div class="shceduler-main animated fadeIn" style={{ height: 'calc(100vh - 50px)', padding: '4px 0px', boxShadow:'none' }}>
        <Row style={{ margin: '0px', height: '100%' }}>
          <Col lg={2}
            md={3}
            sm={0}
            style={{
              borderRight: '1px solid #CDCDCD',
              padding: '0px',
              height: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              background: '#FFFFFF',
              margin: '0px'
            }}>
            <Accordion style={{ margin: '0px !important' }}
              expanded={this.state.filterPanelExpanded.filter === true}
              onChange={() => this.handlePanelCollapse('filter')}>
              <AccordionSummary
                expandIcon={<UnfoldMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ height: '2.5rem', minHeight: 'unset', backgroundImage: 'linear-gradient(315deg, #FFFFFF 15%, #1C85C7 74%)' }}
              >
                <div style={{
                  display: 'flex',
                  width: '100%'
                }}>
                  <div style={{
                    background: '#FFFF',
                    background: 'rgb(255, 255, 255)',
                    width: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#1B84C6'
                  }}>
                    <GroupOutlinedIcon />
                  </div>
                  <div class="side-triangle" />
                  <div style={{
                    color: '#FFFFFF',
                    paddingLeft: '1.5rem',
                    alignItems: 'center',
                    display: 'flex'
                  }}>
                    <Typography> Filter</Typography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{
                padding: '8px 4px 4px',
                flexDirection: 'column',
                gap: '1rem'
              }}>
                <FilterRow>
                  <SelectDropdown
                    label="Facility"
                    facility={facility}
                    handleChange={this.handleFacilityChange}
                    selected={selectedfacilityValue}
                    selectedValue={selectedfacilityValue}
                    name="facility"
                    id="select-facility"
                    disabled={this.props.isLoading}
                  />
                </FilterRow>

                <FilterRow>
                  <SelectDropdown
                    label="Department"
                    facility={facility}
                    handleChange={this.handleDepartmentChange}
                    selected={selectedfacilityValue}
                    selectedValue={selectedDepartmentValue}
                    name="department"
                    id="select-department"
                    disabled={this.props.isLoading}
                  />
                </FilterRow>

                <div style={!this.state.isDepViewDisabled ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '2px' } : { display: 'grid', gridGap: '2px' }}>
                  <div>
                    <Button size="small"
                      disabled={this.props.isLoading}
                      variant={this.state.depSelectionType === 'dep' ? 'contained' : 'outlined'}
                      color="primary"
                      style={{ width: '100%', height: '3.5rem' }}
                      onClick={() => this.onChangeDepartmentView('dep')}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MeetingRoomOutlinedIcon style={{ marginRight: '4px' }} />Department
                        </div>
                    </Button>
                  </div>
                  {
                    !this.state.isDepViewDisabled && <div>
                    <Button size="small"
                      disabled={this.props.isLoading}
                      variant={this.state.depSelectionType === 'team' ? 'contained' : 'outlined'}
                      color="primary"
                      style={{ width: '100%', height: '3.5rem' }}
                      onClick={() => this.onChangeDepartmentView('team')}>
                      <div style={{ display: 'flex', alignItems: 'center' }}><GroupOutlinedIcon style={{ marginRight: '4px' }} />Team
                        </div>

                    </Button>
                  </div>
                  }
                </div>

                {!this.state.isDepView ? (
                  <>
                    <FilterRow>
                      <SelectDropdown
                        disabled={this.state.isDepView}
                        label="Select Team"
                        teams={this.state.selectedDepartmentValue ? this.state.teamScheduleBlock : []}
                        handleChange={this.handleTeamChange}
                        selected={this.state.selectedTeamValue}
                        selectedValue={this.state.selectedTeamValue}
                        name="teams"
                        id="select-teams"
                        disabled={this.props.isLoading}
                      />
                    </FilterRow>
                    {currentTeamData && 
                      <form style={{ width: '100%' }}>
                        <fieldset className="container-filedset">
                          <legend className="container-filedset--label">Availability</legend>
                          <div className="container-filedset--data"><div>{this.renderDayAvailable()}</div>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <div className="time-range-availability">
                                {this.toTime(currentTeamData[0].startTime) +
                                  " - " +
                                  this.toTime(currentTeamData[0].endTime)}
                              </div>
                            </div>
                          </div>
                        </fieldset></form>
                    }

                    <FilterRow>
                      <SelectDropdown
                        disabled={this.state.isDepView}
                        label="Select Skills"
                        skills={this.state.teamSkills}
                        handleChange={this.handleSkillsChange}
                        selected={this.state.selectedSkillValue}
                        selectedValue={this.state.selectedSkillValue}
                        name="skills"
                        id="select-skills"
                        disabled={this.props.isLoading}
                      />
                    </FilterRow>
                     <FilterRow style={{ display: 'flex' }}>
                      <div style={{ flex: '1' }}>
                        {
                          this.state.teamScheduleBlock && <SelectDropdown
                          disabled={this.state.isDepView}
                          label="Schedule Block"
                          scheduleBlock={this.state.teamScheduleBlock}
                          handleChange={this.handleScheduleBlockChange}
                          selected={selectedTeamValue}
                          name="scheduleBlock"
                          id="schedule-block"
                          className="date-to-date-dropdown"
                          selectedValue={this.state.selectedScheduleBlockValue}
                          disabled={this.props.isLoading}
                        />
                        }
                      </div>
                      <div style={{ width: '3rem', height: '100%' }}>
                        <Button size="small"
                          variant={'outlined'}
                          color="primary"
                          disabled={this.props.isLoading}
                          style={{ height: '100%', padding: '0px', height: '33px', width: '33px', minWidth: 'unset' }}
                          onClick={this.createBlockOpen}>
                          <AddSharpIcon /></Button></div>

                    </FilterRow>
                  </>
                ) : (<></>)}

              </AccordionDetails>
            </Accordion>
            <Accordion style={{
              margin: '0px  !important',
              display: 'flex',
              overflow: 'hidden',
              flexDirection: 'column',
              marginTop: 7
            }}
              expanded={!this.state.isDepView && this.state.filterPanelExpanded.name === true && selectedScheduleBlockValue && selectedSkillValue && selectedTeamValue}
              onChange={() => this.handlePanelCollapse('name')}
              disabled={this.state.isDepView || !selectedScheduleBlockValue || !selectedSkillValue || !selectedTeamValue}>
              <AccordionSummary
                expandIcon={<UnfoldMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ height: '2.5rem', minHeight: 'unset', backgroundImage: 'linear-gradient(315deg, #FFFFFF 15%, #1C85C7 74%)' }}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{
                    background: '#FFFF',
                    background: 'rgb(255, 255, 255)',
                    width: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#1B84C6'
                  }}>
                    <GroupOutlinedIcon />
                  </div>
                  <div clas="side-triangle" />
                  <div style={{
                    color: '#FFFFFF',
                    paddingLeft: '1.5rem',
                    alignItems: 'center',
                    display: 'flex'
                  }}>
                    <Typography> QHPs</Typography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column', overflow: 'hidden', padding: '0px 2px', flex: '1 !important', height: '100%' }}>
                <SchedulerSidebar
                  teams={teamScheduleBlock}
                  skills={this.state.teamSkills}
                  providers={providersList}
                  allProvidersList={allProvidersList}
                  handleTeamChange={this.handleTeamChange}
                  selectedTeamValue={this.state.selectedTeamValue}
                  handleSkillsChange={this.handleSkillsChange}
                  selectedSkillValue={selectedSkillValue}
                  createOpenShiftOpen={this.createOpenShiftOpen}
                  onDragStart={this.onDragStart}
                  selectedScheduleBlockValue={selectedScheduleBlockValue}
                  selectedQHPGroup={selectedQHPGroup}
                  onClickChangeQHP={this.onClickChangeQHP.bind(this)}
                  facility={facility}
                  handleFacilityChange={this.handleFacilityChange}
                  selectedfacilityValue={selectedfacilityValue}
                  handleDepartmentChange={this.handleDepartmentChange}
                  selectedDepartmentValue={selectedDepartmentValue}
                  selectedFilterLabourType={selectedFilterLabourType}
                  onSelectedFilterLabourType={this.onSelectedFilterLabourType}
                  labourTypes={filterLabourTypes}
                />
              </AccordionDetails>
            </Accordion>
            {/* } */}
          </Col>
          <Col lg={10} md={9} sm={12}>
            <div className="scheduler-calendar" id="scheduler-calendar" deepview={this.state.isDepView}>
              <div>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk={true}
                    open={this.state.isShowCalendarTitleOpen}
                    variant="inline"
                    inputVariant="outlined"
                    label=""
                    format="MM/dd/yyyy"
                    InputProps={{
                      disableUnderline: true,
                      classes: this.props.classes
                    }}
                    views={['month','year']}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{visibility: 'hidden', top:0, height: 0}}
                    value={this.state.defaultDate}
                    InputAdornmentProps={{ shrink: true, position: "end" }}
                    onChange={(date)=>console.log(date)}
                    onYearChange={this.handleCalenderTitle}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            
              <FullCalendar
                key={this.state.calenderKey}
                defaultView={this.state.myView && this.state.isDepView ? this.state.myView : 'dayGridMonth'}
                customButtons={buttons}
                header={{
                  left: "",
                  center: "myPrevButton myTitle myNextButton",
                  right: this.state.isDepView ? "myDayGridMonth, myDayGridWeek, myDayGridDay" :""
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                ref={this.calendarComponentRef}
                weekends={this.state.calendarWeekends}
                events={this.state.calenderScheduleData}
                dateClick={this.handleDateClick}
                dayRender={this.getAddButton}
                droppable={true}
                selectable={false}
                stickyHeaderDates={true}
                eventAllow={this.onEventAllow}
                eventDragStart={this.onDragStart}
                drop={this.providerDrop}
                eventClick={this.eventClick}
                eventOverlap={this.eventOverlap}
                eventPositioned={this.handleEventPositioned}
                eventLimit={true}
                views= {{
                  month: {
                    eventLimit: 6
                  }
                }}
                contentHeight={window.height}
                height={window.innerHeight - 65}
                eventRender={this.EventDetail}
                showNonCurrentDates={false}
                buttonText={{
                  month: 'Month',
                  week: 'Week',
                  day: 'Day'
                }}
                defaultDate={this.state.defaultDate}
              />
            </div>
            </div>
          </Col>

        </Row>


        {/* Create Schedule Block Modal*/}

        <ModalPopUp
          onClose={this.createBlockClose}
          isModalOpen={this.state.isCreateBlockModelOpen}
        >
          <div className={"modal-paper"}>
            <div className="modal-header">
              <h2 id="modal-title">Create Schedule Block</h2>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={this.createBlockClose}
                className="faiconsStyle"
              />
            </div>

            <div className="modal-body">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                 <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date *"
                        minDate={new Date()}
                        format="MM/dd/yyyy"
                        InputProps={{
                          disableUnderline: true,
                          classes: this.props.classes
                        }}
                        value={this.state.selectedStartBlockDate}
                        InputAdornmentProps={{ shrink: true, position: "end" }}
                        // error={this.state.isMandate && !this.state.dob}
                        onChange={this.handleStartBlockDate}
                      />
                    </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date *"
                        format="MM/dd/yyyy"
                        InputProps={{
                          disableUnderline: true,
                          classes: this.props.classes
                        }}
                        minDate={this.state.selectedStartBlockDate}
                        animateYearScrolling={true}
                        // inputValue={!this.state.selectedEndBlockDate ? this.state.selectedStartBlockDate : this.state.selectedEndBlockDate}
                        value={this.state.selectedEndBlockDate}
                        InputAdornmentProps={{ shrink: true, position: "end" }}
                        onChange={this.handleEndBlockDate}
                        // error={!this.state.selectedStartBlockDate}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <Button
                variant="contained"
                color="primary"
                className="modal-submit"
                onClick={this.handleCreateBlockSubmit}
              >
                Create
              </Button>
            </div>
          </div>
        </ModalPopUp>

        {/* Create Open Shift Modal*/}

        <ModalPopUp
          onClose={this.createOpenShiftClose}
          isModalOpen={isOpenShiftModalOpen}
        >
          <div className={"modal-paper open-premium-shif"}>
            <div className="modal-header">
              <h2 id="modal-title">Open Premium Shift</h2>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={this.createOpenShiftClose}
                className="faiconsStyle"
              />
            </div>

            <div className="modal-body">
              <Row>
                <Col xs={6}>
                  <div className="modal-skills-constainer">
                    {this.state.skillRows.map((item, idx) => (
                      <div className="modal-sills-list">
                        <div className="modal-skill-dropdown">
                          <select
                            name="skill"
                            value={this.state.skillRows[idx].skill}
                            onChange={this.handleSkillChange(idx)}
                            className="form-control"
                          >
                            <option disabled selected value="">
                              Select Skill
                            </option>
                            {this.props.getSavSkills
                              ? this.state.saveSkillArray.map((skills, i) => (
                                <option key={i} value={skills.skill}>
                                  {skills.skill}
                                </option>
                              ))
                              : ""}
                          </select>
                        </div>
                        <div className="modal-skill-input">
                          <input
                            type="number"
                            name="rate"
                            placeholder={
                              this.state.skillRows[idx].selectedSkillRate
                                ? "$" +
                                this.state.skillRows[idx].selectedSkillRate
                                : "Rate"
                            }
                            value={this.state.skillRows[idx].rateText.rate}
                            onChange={this.handleRateChange(idx)}
                            className="form-control"
                          />
                        </div>
                        {Boolean(this.state.skillRows.length > 1) && (
                          <button
                            className="btn btn-sm delete-skill"
                            onClick={this.handleRemoveSpecificSkillRow(idx)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              className="faiconsStyle"
                            />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={this.handleSkillAddRow}
                    className="btn add-skill"
                  >
                    <FontAwesomeIcon icon={faPlus} className="faiconsStyle" />
                  </button>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                variant="contained"
                color="primary"
                className="modal-submit"
                onClick={this.handleOpenShiftSubmit}
              >
                Create
              </Button>
            </div>
          </div>
        </ModalPopUp>

        <ModalPopUp
          onClose={this.handleOpenShiftModalSuccess}
          isModalOpen={this.state.isOpenShiftModelSuccessOpen}
        >
          <div className={"modal-paper"}>
            <div className="modal-body">
              <h4
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "18px",
                  paddingTop: "25px"
                }}
              >
                Open Premium Shift Created Successfully
              </h4>
            </div>
            <div className="modal-footer">
              <Button
                variant="contained"
                color="primary"
                className="modal-submit"
                onClick={this.handleOpenShiftModalSuccess}
              >
                Ok
              </Button>
            </div>
          </div>
        </ModalPopUp>

        <ModalPopUp
          onClose={this.repeatModalClose}
          isModalOpen={this.state.isRepeatModeOpen}
        >
          <div className={"modal-paper modal-popper"}>
            <div className="modal-header">
              <h2 id="modal-title">Repeat</h2>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={this.repeatModalClose}
                className="faiconsStyle"
              />
            </div>

            <div className="modal-body" style={{ alignItems: 'center', justifyContent: 'center' }}>
              <div className="repeat-every" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="modal-row">
                  <label for="reccProviderName">Name</label>

                  <select className="form-control" name="reccProviderName" disabled>
                    <option value={this.state.providerName} selected>
                      {" "}
                      {this.state.providerName}{" "}
                    </option>
                  </select>
                </div>
              </div>

              <div className="repeat-every" style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid>
                  <Grid>
                      <StaticDatePicker
                        minDate={this.state.minDroppedDate}
                        maxDate={this.state.maxDroppedDate}
                        defaultValue={this.state.selectedDays[this.state.selectedDays.length-1]}
                        selectedDays={this.state.selectedDays}
                        onChange={this.onChangeSelectedDates}
                        disablePrevMonth={false}
                        disableNextMonth={false}
                        disableWeekDays={this.getDisableWeekdays()}
                      />
                  </Grid>
                  <Grid>
                  <div className="repeat-every">
                    <div className="modal-row">
                        <TextField
                          id="shift-start-time"
                          label="Start Time"
                          type="time"
                          value={this.state.assignSelectedProviderStartTime}
                          margin="dense"
                          variant="outlined"
                          onChange={(event) => this.setState({assignSelectedProviderStartTime: event.target.value})}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                              step: 1800 // 30 min
                          }}
                          defaultValue={(this.state.currentTeamData && `${this.state.currentTeamData[0].startTime.split(":")[0]}:${this.state.currentTeamData[0].startTime.split(":")[1]}`)}
                          required
                          error={(this.state.assignSelectedProviderStartTime && this.state.assignSelectedProviderEndTime && (!moment(providerStartTimeTemp).isSameOrBefore(providerEndTimeTemp, 'hour') && !moment(providerStartTimeTemp).isSameOrBefore(providerEndTimeTemp, 'minute')))}
                        />
                        {(this.state.assignSelectedProviderStartTime && this.state.assignSelectedProviderEndTime && (!moment(providerStartTimeTemp).isSameOrBefore(providerEndTimeTemp, 'hour') && !moment(providerStartTimeTemp).isSameOrBefore(providerEndTimeTemp, 'minute')) ?
                          <h6 style={errorStyle}>Start time should not be after or equal maximal time.</h6> : null
                        )}
                      </div>
                    </div>
                    
                      <div className="modal-row">
                        <TextField
                          id="shift-end-time"
                          label="End Time"
                          type="time"
                          value={this.state.assignSelectedProviderEndTime}
                          margin="dense"
                          variant="outlined"
                          onChange={(event) => this.setState({assignSelectedProviderEndTime: event.target.value})}
                          InputLabelProps={{
                              shrink: true
                          }}
                          inputProps={{
                              step: 1800 // 30 min
                          }}
                          defaultValue={(this.state.currentTeamData && `${this.state.currentTeamData[0].endTime.split(":")[0]}:${this.state.currentTeamData[0].endTime.split(":")[1]}`)}
                          required
                          error={(this.state.assignSelectedProviderEndTime && this.state.assignSelectedProviderStartTime && (moment(providerEndTimeTemp).isSameOrBefore(providerStartTimeTemp, 'hour') && moment(providerEndTimeTemp).isSameOrBefore(providerStartTimeTemp, 'minute')))}
                        />
                        {(this.state.assignSelectedProviderEndTime && this.state.assignSelectedProviderStartTime && (moment(providerEndTimeTemp).isSameOrBefore(providerStartTimeTemp,'hour') && moment(providerEndTimeTemp).isSameOrBefore(providerStartTimeTemp, 'minute')) ?
                          <h6 style={errorStyle}>End time should not be before or equal minimal time.</h6> : null
                        )}
                      </div>
              
                   
                      <div className="modal-row">
                    <Grid container item xs={4} sm={4}>
                      <div style={{ }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.assignSelectedProviderOnCallOpenShift ? true : false}
                              value={this.state.assignSelectedProviderOnCallOpenShift}
                              onChange={(event) => this.setState({assignSelectedProviderOnCallOpenShift: !this.state.assignSelectedProviderOnCallOpenShift})}
                              color="primary"
                            />
                          }
                          label={"Is On Call Shift"}
                        />
                      </div>
                    </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="repeat-every" style={{ display: 'flex' }}>
                <p><InfoOutlined color={"#33a5db"} htmlColor={"#33a5db"} /></p>
                <p style={{ paddingLeft: 4 }}>More than one date can be selected in a random manner. Clicking again on selected dates can do deselected.</p>
              </div>
            </div>
            <div className="modal-footer">
              {!this.state.isLoading ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="modal-submit"
                  onClick={this.handleRepeatProviderPost}
                >
                  Save
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className="modal-submit"
                    onClick={this.handleRepeatProviderPost}
                  >
                    <CircularProgress color="#FFFFFF" size={20} style={spinner} />{" "}
                  Save
                  </Button>
                )}
            </div>
          </div>
        </ModalPopUp>
        <div id="shift-rate-change-modal">
          <ModalPopUp
            onClose={this.openShiftRateChangeModal}
            isModalOpen={this.state.isOpenShiftRateChange}
          >
            <div className={"modal-paper modal-popper"} style={{width: "600px"}}>
              <div className="modal-header">
              <h2 id="modal-title">{`Edit Open Shift : ${selectedOpenShift.skillLevel}`}</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={this.openShiftRateChangeModal}
                  className="faiconsStyle"
                />
              </div>
              <div className="modal-body">
                {this.state.validateUpdateOpenShiftMessage && this.state.validateUpdateOpenShiftMessage != "Start Time should greater than End time!"? (
                  <h6 className={"text-center"} style={errorStyle}>
                    {this.state.validateUpdateOpenShiftMessage}
                  </h6>
                ) : null}
                {this.state.validateUpdateOpenShiftMessage && this.state.validateUpdateOpenShiftMessage == "Start Time should greater than End time!" ? (
                      <InfoAlert style={{
                          marginBottom:10,
                          fontSize:10,
                          fontWeight:'bold'
                        }} icon={<InfoOutlined />} variant="outlined" severity="info">
                            {this.state.validateUpdateOpenShiftMessage}
                      </InfoAlert>
                  ) : null}
                <div className="repeat-every">
                  <div className="modal-row">
                    <TextField
                      id="date"
                      label="Date"
                      margin="dense"
                      variant="outlined"
                      defaultValue={moment(
                        new Date(selectedOpenShift.date),
                        "ddd DD-MMM-YYYY, hh:mm A"
                      ).utcOffset(0).format("MM-DD-YYYY")}
                      disabled
                      inputProps={{ maxLength: 15 }}
                    />
                  </div>
                </div>
              
                  <div className="repeat-every">
                      <div className="modal-row">
                        <ThemeProvider theme={materialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                clearable={false}
                                ampm={false}
                                label="Start time"
                                minutesStep={5}
                                inputVariant="outlined"
                                value={selectedOpenShift.startTime}
                                onChange={(event) => {
                                    this.handleShiftStartTimeChange(event)
                                }}
                            />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                          {/* <TextField
                              id="shift-start-time"
                              label="Start Time"
                              type="time"
                              value={selectedOpenShift.startTime}
                              margin="dense"
                              variant="outlined"
                              onChange={this.handleShiftStartTimeChange}
                              InputLabelProps={{
                                  shrink: true
                              }}
                              inputProps={{
                                  step: 1800 // 30 min
                              }}
                              required
                          /> */}
                      </div>
                    </div>
                    <div className="repeat-every">
                        <div className="modal-row">
                        <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    clearable={false}
                                    ampm={false}
                                    label="End time"
                                    minutesStep={5}
                                    inputVariant="outlined"
                                    value={selectedOpenShift.endTime}
                                    onChange={(event) => {
                                        this.handleShiftEndTimeChange(event)
                                    }}
                                />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>

                            {/* <TextField
                                id="shift-end-time"
                                label="End Time"
                                type="time"
                                value={selectedOpenShift.endTime}
                                margin="dense"
                                variant="outlined"
                                onChange={this.handleShiftEndTimeChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{
                                    step: 1800 // 30 min
                                }}
                                required
                            /> */}
                        </div>
                    </div>
                <div className="repeat-every">
                  <div className="modal-row">
                  <Grid container spacing={1}>
                  <Grid container item xs={5} sm={5}>
                    <TextField
                      id="skill-rate"
                      label="Rate"
                      type="number"
                      margin="dense"
                      variant="outlined"
                      onChange={this.handleShiftRateChange}
                      defaultValue={selectedOpenShift.askRate}
                      helperText={
                        selectedOpenShift.ceilingRate
                          ? `Skill rate should be less then or equal to ` +
                          selectedOpenShift.ceilingRate
                          : null
                      }
                      inputProps={{ maxLength: 15 }}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                    </Grid>
                    <Grid container item xs={3} sm={3}>
                      <div style={{ marginLeft:"8%"}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOpenShift.rateMask ? true :  false}
                              onChange={this.handleChangeRateMask}
                              value={selectedOpenShift.rateMask}
                              color="primary"
                            />
                          }
                          label={"Rate Mask"}
                        />
                      </div>
                    </Grid>
                    <Grid container item xs={4} sm={4}>
                      <div style={{ }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOpenShift.onCallOpenShift ? true : false}
                              onChange={this.handleChangeOnCallOpenShift}
                              value={selectedOpenShift.onCallOpenShift}
                              color="primary"
                            />
                          }
                          label={"Is On Call Shift"}
                        />
                      </div>
                    </Grid>
                    </Grid>
                    {
                        this.state.selectedOpenShift.diffHour ? 
                          <>
                            <ThemeProvider theme={materialTheme}>
                                <div className="repeat-every">
                                    <div className="modal-row" style={{paddingTop:30}}>
                                    <TextField
                                        id="addon-hours-rate"
                                        label="Addon Hours Rate"
                                        type="number"
                                        value={selectedOpenShift.addOnHourRate}
                                        margin="dense"
                                        min="1"
                                        variant="outlined"
                                        onChange={this.handleAddOnHourRateChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{ maxLength: 15 }}
                                        required
                                      />
                                    </div>
                                </div>
                            </ThemeProvider>
                            <div className="repeat-every">
                              <div className="modal-row">
                                <SelectDropdown
                                  label="Select comment"
                                  selectComment={COMMENTS}
                                  handleChange={this.onChangeComment}
                                  selected={selectedOpenShift.comment}
                                  selectedValue={selectedOpenShift.comment}
                                  name="comment"
                                  id="comment"
                                  className={"open-shift-skill-modal"}
                                  style={{ textAlign: "center" }}
                                />
                              </div>
                            </div>
                            {
                              this.state.selectedOpenShift.comment && this.state.selectedOpenShift.comment == 'Other' ? 
                                <div className="repeat-every">
                                  <div className="modal-row">
                                  <TextareaAutosize
                                    rowsMin={5}
                                    style={{ width: "100%", height:"100%", fontSize:14 }}
                                    variant="outlined"
                                    aria-label="maximum height"
                                    placeholder={this.state.selectedOpenShift.otherComment ? this.state.selectedOpenShift.otherComment : "Add your comment here"}
                                    defaultValue={this.state.selectedOpenShift.otherComment ? this.state.selectedOpenShift.otherComment : ""}
                                    onChange={this.onOtherCommentChange}
                                  />
                                </div>
                              </div>
                              : null
                            }
                          </>
                        : null
                    }
                  </div>
                </div>
                <div className="repeat-every">
                  <div className="modal-row">
                    <div style={{ width: 400, marginLeft: "18%" }}>
                      {this.state.notifications.map(notification => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={notification.selected}
                                onChange={this.handleChangeResendNotifications}
                                value={notification.value}
                                color="primary"
                              />
                            }
                            label={notification.label}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                
              </div>
              
              <div className="modal-footer">
                <Button
                  variant="contained"
                  color="primary"
                  className="modal-submit"
                  // disabled={(this.state.validateUpdateOpenShiftMessage !== "" ? true : false)}
                  onClick={this.updateOpenShiftTime}
                >
                  Save Time
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="modal-submit"
                  onClick={this.resendNotification}
                >
                  Resend Notification
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="modal-submit"
                  onClick={this.updateOpenShiftRate}
                >
                  {"Save Rate & Mask"}
                </Button>
 
                <Button
                  variant="contained"
                  className="button-cancel"
                  color="secondary"
                  style={{ backgroundColor: "#ff6262" }}
                  onClick={this.delOpenShift}
                >
                  Delete
                </Button>
              </div>
            </div>
          </ModalPopUp>
        </div>
        <div id="create-open-shift-modal">
          <ModalPopUp
            onClose={this.createOpenShiftModal}
            isModalOpen={this.state.isCreateOpenShiftModal}
          >
            <div className={"modal-paper"} style={{width:"700px"}}>
              <div className="modal-header">
                <h2 id="modal-title">{`Create New Shift`}</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={this.createOpenShiftModal}
                  className="faiconsStyle"
                />
              </div>
              <div className="modal-body">
              {this.state.validateCreateOpenShiftMessage ? (
                    <h6 className={"text-center"} style={errorStyle}>
                      {this.state.validateCreateOpenShiftMessage}
                    </h6>
                  ) : null}
              <Grid container direction="row" spacing={3} style={{paddingTop:10}}>
                  
                  <Grid item xs={6} sm={6}>
                      <StaticDatePicker
                        minDate={this.state.minDroppedDate}
                        maxDate={this.state.maxDroppedDate}
                        defaultValue={this.state.selectedDays[this.state.selectedDays.length-1]}
                        selectedDays={this.state.selectedDays}
                        onChange={this.onChangeSelectedDates}
                        disablePrevMonth={false}
                        disableNextMonth={false}
                        disableWeekDays={this.getDisableWeekdays()}
                      />
                  </Grid>
                  <div style={{ 
                    borderLeft: "3px solid #40b6e6",
                    height:"300px",
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-3%",
                    top: "10%"}}></div>
                  <Grid item xs={6} sm={6}>
                    <div className="repeat-every" style={{ marginTop:20}}>
                      <div className="modal-row">
                        <SelectDropdown
                          label="Select Skills"
                          selectShiftSkills={this.state.allSkills}
                          handleChange={this.onChangeShiftSkills}
                          selected={selectedCreateOpenShiftSkillValue}
                          selectedValue={selectedCreateOpenShiftSkillValue}
                          name="shiftskills"
                          id="shiftskills"
                          className={"open-shift-skill-modal"}
                          style={{ textAlign: "center" }}
                        />
                      </div>
                    </div>
                    <div className="repeat-every" style={{ marginTop:10}}>
                      <div className="modal-row">
                      <Grid container spacing={1}>
                        <Grid container item xs={7} sm={7}>
                          
                          <TextField
                            id="skill-rate"
                            label="Rate"
                            type="number"
                            margin="dense"
                            variant="outlined"
                            onChange={this.onChangeShiftRate}
                            defaultValue={selectedCreateOpenShift.askRate}
                            inputProps={{ maxLength: 15 }}
                            helperText={
                              this.state.selectedCeilingRate
                                ? `Skill rate should be less then or equal to ` +
                                this.state.selectedCeilingRate
                                : null
                            }
                            required
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          // disabled={!selectedCreateOpenShiftSkillValue}
                          />
                          </Grid>
                          <Grid container item xs={5} sm={5}>
                            <div style={{ width: 400, marginLeft: "10%" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedCreateOpenShift.rateMask}
                                    onChange={this.onChangeRateMask}
                                    value={selectedCreateOpenShift.rateMask}
                                    color="primary"
                                  />
                                }
                                label={"Rate Mask"}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div className="repeat-every" style={{ marginTop:10}}>
                      <div className="modal-row">
                          <Select
                            className="labour-type"
                            style={{
                              width: 210,
                              marginLeft: 800,
                              textAlign: "center",
                              justifyContent: "center"
                            }}
                            filterOption={this.filterOption}
                            onInputChange={this.onInputChange}
                            onChange={this.onChangeLabourTypes}
                            options={labourTypes}
                            placeholder="Select labor type"
                            // isSearchable={true}
                            value={selectedCreateOpenShiftLabourTypeValue}
                            defaultValue={selectedCreateOpenShiftLabourTypeValue}
                            isMulti={true}
                          />
                      </div>
                    </div>
                    
                    <div style={{ width: 500, marginBottom:5, marginLeft:5, marginTop:10 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCreateOpenShift.onCallOpenShift}
                            onChange={this.onChangeOnCallOpenShift}
                            value={selectedCreateOpenShift.onCallOpenShift}
                            color="primary"
                          />
                        }
                        label={<Typography variant="body2" color="textSecondary">Is On Call Shift</Typography>}
                      />
                    </div>
                    </Grid>
                </Grid>
              </div>
              <div className="modal-footer">
                <Button
                  variant="contained"
                  className="button-cancel"
                  color="secondary"
                  style={{ backgroundColor: "#ff6262" }}
                  onClick={this.createOpenShiftModal}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="modal-submit"
                  onClick={this.createOpenShiftRate}
                >
                  Save
                </Button>
              </div>
            </div>
          </ModalPopUp>
        </div>
        <ModalPopUp
          onClose={this.depatViewInfoModalClose}
          isModalOpen={this.state.isDepatViewInfo}
        >
          <div className={"modal-paper modal-popper"}>
            <div className="modal-header">
              <h2 id="modal-title">Shift Info : {this.state.selectedDepartmentViewProviderProviderName}</h2>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={this.depatViewInfoModalClose}
                className="faiconsStyle"
              />
            </div>

            <div className="modal-body" style={{ alignItems: 'center', justifyContent: 'center' }}>
              <div className="repeat-every" style={{justifyContent: 'center' }}>
                  <div>
                    <h4 style={{justifyContent: 'center', paddingBottom:10 }}><b>{this.state.selectedDepartmentViewProviderDescription}</b></h4>
                    {<h4 style={{justifyContent: 'center', paddingBottom:10 }}><b>Prefered Call Number: </b>{this.state.selectedDepartmentViewProviderPreferredCallNumber ? this.state.selectedDepartmentViewProviderPreferredCallNumber : "N/A"}</h4>}
                    <h4 style={{justifyContent: 'center', paddingBottom:10 }}><b>Start Time: </b>{this.state.selectedDepartmentViewProviderStartTime
                      ? moment(convertTZ(this.state.selectedDepartmentViewProviderStartTime)).format("hh:mm A") : ""}</h4>
                    <h4 style={{justifyContent: 'center', paddingBottom:10 }}><b>End Time: </b>{this.state.selectedDepartmentViewProviderEndTime
                      ? moment(convertTZ(this.state.selectedDepartmentViewProviderEndTime)).format("hh:mm A") : ""}</h4>
                  </div>
              </div>
            </div>
          </div>
        </ModalPopUp>
        {
          this.state.openRemoveShift ? <RemoveAssignedShift
          selectedShiftTitle={this.state.currentClikedProviderTitle}
          selectedShiftDate={this.state.currentClikedProviderStartDate}
          openRemoveShift={this.state.openRemoveShift}
          onEditAssignedShift={this.onEditAssignedShift}
          eventId={this.state.currentClikedProviderEventId}
          endTime={this.state.currentClikedProviderEndTime}
          startTime={this.state.currentClikedProviderStartTime}
          shiftId={this.state.currentClikedProviderShiftId}
          isSource={this.state.currentClikedProviderIsSourceShift}
          askRate={this.state.currentClikedProviderAskRate}
          addOnHourAskRate={this.state.currentClikedProviderAddOnHourRate}
          addOnHours={this.state.currentClikedProviderAddOnHours}
          onCallOpenShift={this.state.currentClikedProviderOnCallOpenShift}
          onRemoveAssignedShift={this.onRemoveAssignedShift}
          shiftStartTime={this.state.currentTeamData && this.state.currentTeamData[0].startTime}
          shiftEndTime={this.state.currentTeamData && this.state.currentTeamData[0].endTime}
          getTimeDifferenceFlag={this.getHourOfCurrentShift}
          currentComment={this.state.currentClikedProviderComment}
          currentOtherComment={this.state.currentClikedProviderOtherComment}
          comments={COMMENTS}
          closeRemoveShift={this.onCloseEditAssignedShift}
          isPTO={false}
        /> : null
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getFacilityDepartment,
      getTeamScheduleBlock,
      getTeamSkills,
      getProviderByDepartmentSkill,
      getTeamScheduleDates,
      addProvider,
      postCreateBlock,
      getSaveSkills,
      postOpenShift,
      deleteProvider,
      getAllProviders,
      changeOpenShiftSkillRate,
      getCeilingRate,
      getAllProvidersShift,
      postOpenShiftSave,
      resendNotificationOpenShift
    },
    dispatch
  );
};

const mapStateToProps = state => ({
  isLoading: state.scheduler.isLoading,
  facilityPayload: state.scheduler.facilityPayload,
  error: state.scheduler.error,
  teamScheduleBlock: state.scheduler.teamScheduleBlock,
  teamSkills: state.scheduler.teamSkills,
  providersList: state.scheduler.providersList,
  scheduleDates: state.scheduler.scheduleDates,
  scheduleWeekAndTime: state.scheduler.scheduleWeekAndTime,
  providerPayload: state.scheduler.providerPayload,
  createBlockPayload: state.scheduler.createBlockPayload,
  getSavSkills: state.scheduler.getSaveSkills,
  openShiftRes: state.scheduler.createOpenShiftPayload,
  defaultFacility: state.scheduler.defaultFacility,
  isFacilityLoaded: state.scheduler.isFacilityLoaded,
  defaultDepartment: state.scheduler.defaultDepartment,
  allProvidersList: state.scheduler.allProvidersList,
  changedskillRate: state.scheduler.skillRatePayoad,
  ceilingRate: state.scheduler.ceilingRatePayload,
  allProvidersShiftList: state.scheduler.allProvidersShiftList,
  postOpenShiftSave: state.scheduler.postOpenShiftSave,
  resendNotificationOpenShift: state.scheduler.resendNotificationOpenShift
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
