import React, { Component } from "react";
import { EventEmitter } from "../components/events";
import instance from "../utils/services/services";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable, { defaultThemes } from "react-data-table-component";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import LinearIndeterminate from "../common/ListLoader";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  rows: {
    style: {
      minHeight: '38px',
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight: 'bold',
      fontSize:16
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class TeamDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamData: [],
      isLoading: false
    };
  }

  fetchTeam = async () => {
    this.setState({ isLoading: true });
    await instance.get(`Team/GetAll`).then((response) => {
      if (response.status) {
        this.setState({
          teamData: response.data.data !== null ? response.data.data : []
        });
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  componentWillMount() {
    EventEmitter.on("teamCreated", event => this.fetchTeam());
  }

  componentDidMount() {
    this.fetchTeam();
  }

  getTeamById = row => {
    instance
      .get(`Team/GetById`, { params: { id: row.teamId } })
      .then(res => {
        EventEmitter.emit("teamEdit", res);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  render() {
    const columns = [
      {
        name: "TEAM",
        selector: "teamName",
        sortable: true,
        cell: row => <div style={{ flexDirection: "row" }}>
          <FiberManualRecordIcon fontSize="large" style={{
            fontSize: 18, borderWidth: 0.5, borderStyle: 'solid',
            borderColor: "gray", color: row.color_Code ? row.color_Code : 'transparent'
          }} />
          <span style={{ paddingLeft: 10 }}>{row.teamName}</span>
        </div>
      },
      {
        name: "FACILITY",
        selector: "facilityName",
        sortable: true
      },
      {
        name: "DEPARTMENT",
        selector: "departmentName",
        sortable: true,
        // hide: "md"
      },
      {
        name: "ACTION",
        cell: row => (
          <Button
            startIcon={<EditIcon />}
            style={{
              backgroundColor: "#33a6db",
              color: "white",
              fontWeight: 500
            }}
            title={"Edit"}
            variant="contained"
            size="small"
            onClick={() => this.getTeamById(row)}
          >
            Edit
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      }
    ];

    return (
      <DataTable
        title={
          <Grid container spacing={1}>
            <Grid item xs={1.5} md={1.5} sm={1.5}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Team
                </h1>
              </div>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
              <Button
                variant="contained"
                size="small"
                style={{
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#33a6db",
                  fontSize: 8,
                  fontWeight: 600
                }}
                onClick={this.props.createNewTeam}
              >
                <AddIcon />
                  Add
                </Button>
            </Grid>
          </Grid>
        }
        subHeader={true}
        columns={columns}
        data={this.state.teamData}
        defaultSortField="teamName"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={this.state.isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign: "center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent: "center", fontWeight: 500 }}>
          <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."} />
        </div>}
        noDataComponent={
          <div style={{ paddingTop: 150, paddingBottom: 150, fontSize: 14, fontWeight: 500 }}>
            Teams not found!
          </div>
        }
      />
    );
  }
}

export default TeamDataTable;