import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import RaisedButton from 'material-ui/RaisedButton'
import AppBar from 'material-ui/AppBar'
import { MuiThemeProvider } from 'material-ui/styles';
import axios from 'axios';
import MaskedInput from 'react-text-mask';


import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

export  class AccountDataVerification extends Component {

  state={
  
    showPassword: false
  }



    continue = e=>{
        e.preventDefault();
        this.props.nextStep();
        this.sendRegistrationData();
    }
    back = e=>{
        e.preventDefault();
        this.props.prevStep();
    }
    handleClickShowPassword=()=> {
      // let bool=this.state.showPassword;
       this.setState({ showPassword: !this.state.showPassword });
     }
    sendRegistrationData=()=>{
        axios.post(`https://tesselmeduserservice.azurewebsites.net/api/Providers/Register`,{
           phone: this.props.values.mobileNumber,
           emailId: this.props.values.email,
           password: this.props.values.password,
           dob: this.props.values.dob,
           firstName: this.props.values.firstName,
           lastName: this.props.values.lastName,
           addressLine1:this.props.values.address,
           //COME BACK TO THIS
         //  specialty:this.props.values.speciality
          }).then(res => {
           var usid=res.data.data.userId.toString();
           this.props.setUserId(usid);
        
          },this).catch(error=> {
            // console.log(error);
      });
    }
    render() {
        const divStyle = {
            paddingLeft: '30%',
            paddingTop: '5%',
           // border: '5px solid pink'
          };
        const {values,handleChange}=this.props;
      
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    {/* <AppBar title="Provider Registration"></AppBar> */}
                    <div  className="container" style={divStyle}>
                    <h6>Please verify your Mobile number and email id</h6>
                    <br/>
                   
                    <TextField
                    name="Mobile Number"
                    
                    label="Mobile Number"
                    onChange={handleChange('mobileNumber')}
                   />
                    <br/>
                    <br/>
                    <TextField
                    name="Password"
                   
                    label="Email"
                    onChange={handleChange('email')}
                    />
                    <br/>
                    <br/>
                    <TextField
                    name="Password"
                    label="Password"
                    type={this.state.showPassword ? "text":"password"}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                   />
                    <br/>
                    <br/>
                    <TextField
                    name="dob"
                    label="Date of birth"
                    placeholder="MM/DD/YYYY"
                    onChange={handleChange('dob')}
                    helperText="Date Format:(MM/DD/YYYY)"
                    // InputProps={{
                        
                    //     inputComponent: TextMaskCustom,
                    //   }}
                    />
                    <br/>
                    <br/>
                    <Button variant="outlined" color="secondary" onClick={this.back}>
                      Back
                    </Button>&nbsp;&nbsp;
                   <Button variant="outlined" color="primary" onClick={this.continue}  disabled={!values.email || !values.mobileNumber || !values.password  || !values.dob}>
                      Send verification code
                    </Button>
                    {/* <RaisedButton
                    label="Back"
                    primary={true}
                    style={styles.button}
                    onClick={this.back}/>
                     */}
                    {/* <RaisedButton
                    label="Send verification code"
                    primary={true}
                    style={styles.button}
                    onClick={this.continue}
                    disabled={!values.email || !values.mobileNumber || !values.password  || !values.dob}/> */}
                    </div>
              </React.Fragment>
            </MuiThemeProvider>
             
        )
    }
}
const styles = {
    button:{
        margin:20
    }
}
export default AccountDataVerification;
