import React from "react";
import { connect } from "react-redux";
// import { css } from "@emotion/core";
// First way to import
// import { ClipLoader } from "react-spinners";
// Another way to import. This is recommended to reduce bundle size
// import BeatLoader from "react-spinners/BeatLoader";
import LinearIndeterminate from "../ListLoader";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: #137bc0;
//   margin: 18%;
//   left:32%;
//   position: absolute !important;
//   z-index:999999 !important;
// `;


// const Loader = props => (
//   <BeatLoader
//     css={override}
//     size={35}
//     color={"#137bc0"}
//     loading={props.isLoading}
//   />
// );

const overrideStyle = {
  position: 'absolute',
  left: '60%',
  top: '50%',
  zIndex: 9999999,
  width: '150px',
  height: '150px',
  margin: '-75px 0 0 -75px'
}

const Loader = props => (
  <>
    {
      props.isLoading && <div style={overrideStyle}>
        <LinearIndeterminate isLoading={props.isLoading} loaderColor={'#3f51b5'} titleColor={'#3f51b5'} loaderTitle={"Loading..."} />
      </div>
    }
  </>
);

const mapStateToProps = state => ({
  isLoading: (state.scheduler.isLoading || state.report.isLoading),
});

export default connect(mapStateToProps, null)(Loader);
