import Button from "@material-ui/core/Button";
import { Col } from "reactstrap";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { EventEmitter } from "../components/events";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import React from "react";
import SelectDropdown from "../components/SelectDropdown";
import TextField from "@material-ui/core/TextField";

class Accordian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDepView: false,
      isDepViewDisabled: false,
      showAll: true,
      searchText: '',
      qhpLists: (props.selectedQHPGroup == "all" ? props.allProvidersList : props.providers)
    };
  }

  componentWillMount() {
    if (
      localStorage.getItem("isSchedulerView") &&
      localStorage.getItem("isSchedulerView") == "true"
    ) {
      this.setState({ isDepView: true, isDepViewDisabled: true, qhpLists: (this.props.selectedQHPGroup == "all" ? this.props.allProvidersList : this.props.providers) });
    }
  }

  componentWillReceiveProps = async (nextProps, prevProps) => {
    if (nextProps.selectedQHPGroup != this.props.selectedQHPGroup || nextProps.allProvidersList && this.props.allProvidersList && (nextProps.allProvidersList.length !== this.props.allProvidersList.length || nextProps.providers.length !== this.props.providers.length)) {
      if (nextProps.selectedQHPGroup == "all") {
        this.refs.searchQHP.value = '';
        this.setState({ qhpLists: nextProps.allProvidersList, searchText: '' }, () => this.filterQHPList(nextProps.selectedFilterLabourType));
      } else if (nextProps.selectedQHPGroup != "all") {
        this.refs.searchQHP.value = '';
        this.setState({ qhpLists: nextProps.providers, searchText: '' }, () => this.filterQHPList(nextProps.selectedFilterLabourType));
      }
    } else if(!nextProps.allProvidersList) {
      this.refs.searchQHP.value = '';
      this.setState({ qhpLists: !nextProps.allProvidersList ? [] : [], searchText: !nextProps.providers ? '' :'' }, () => this.filterQHPList(nextProps.selectedFilterLabourType));
    } else if(!nextProps.providers) {
      this.refs.searchQHP.value = '';
      this.setState({ qhpLists: !nextProps.providers ? [] : [], searchText: !nextProps.providers ? '' :'' }, () => this.filterQHPList(nextProps.selectedFilterLabourType));
    }
    if(nextProps.selectedFilterLabourType !== this.props.selectedFilterLabourType) {
      this.setState({ qhpLists: (nextProps.selectedQHPGroup == "all" ? nextProps.allProvidersList : nextProps.providers) },() => this.filterQHPList(nextProps.selectedFilterLabourType));
    }
  }

  componentDidMount() {
    this.props.onClickChangeQHP('all');
  }

  onChangeDepartmentView = (e) => {
    this.setState({ isDepView: e.target.checked, isDepViewDisabled: true }, async () => {
      EventEmitter.emit("isDepViewToggle", this.state.isDepView);
      setTimeout(() => {
        this.setState({ isDepViewDisabled: false });
      }, 100);
    })
  }

  customFilter = (e) => {
    let searchText = e.target.value;
    if (searchText && searchText.length > 1) {
      this.setState({ searchText: searchText }, () => {
        this.filterQHPList(this.props.selectedFilterLabourType);
      });
    } else {
      this.setState({ qhpLists: (this.props.selectedQHPGroup == "all" ? this.props.allProvidersList : this.props.providers) }, ()=> this.filterQHPList(this.props.selectedFilterLabourType));
    }
  }

  filterQHPList = (selectedFilterLabourType="") => {
    const { qhpLists } = this.state;
    if (this.state.searchText && this.state.searchText.length > 1) {
      let qhpListArray = qhpLists && qhpLists.filter(qhp => {
        return (this.props.selectedQHPGroup == "all" ? (qhp.providerName && qhp.providerName.toLowerCase().includes(this.state.searchText.toLowerCase())) : (qhp.title && qhp.title.toLowerCase().includes(this.state.searchText.toLowerCase())))
      });
      if(selectedFilterLabourType != "") {
        let qhpListLabourFilterArray = qhpListArray && qhpListArray.filter(qhp => qhp.labourType === selectedFilterLabourType);
        this.setState({ qhpLists: qhpListLabourFilterArray });
      } else {
        this.setState({ qhpLists: qhpListArray });
      }
    } else if (selectedFilterLabourType != "") {
      let qhpList = (this.props.selectedQHPGroup == "all" ? this.props.allProvidersList : this.props.providers)
      let qhpListLabourFilterArray = qhpList && qhpList.filter(qhp => qhp.labourType === selectedFilterLabourType);
      this.setState({ qhpLists: qhpListLabourFilterArray });
    } else {
      this.setState({ qhpLists: (this.props.selectedQHPGroup == "all" ? this.props.allProvidersList : this.props.providers) });
    }
  }

  onShowAvailableChanged = (v) => {
    this.setState({ showAll: v === 'all' });
    this.props.onClickChangeQHP(v !== 'all' ? 'available' : 'all');
  }

  render() {
    const {
      selectedScheduleBlockValue,
      selectedSkillValue,
      selectedFilterLabourType,
      onSelectedFilterLabourType,
      labourTypes
    } = this.props;
    
    let qhpListData = this.state.qhpLists;
    qhpListData = qhpListData && qhpListData.length > 0 ? (qhpListData.filter((v,i,a)=>a.findIndex(t=>(t.providerCode === v.providerCode))===i)) : []
    return (
      <div>
        <div className={"accordian"}>
          <Col style={{ paddingBottom: 10, paddingRight: 0, paddingLeft: 0, flex: 0 }}></Col>
          <Grid
            item
            container
            xs={12}
            md={12}
            direction="column"
            alignItems="center"
            style={{ paddingBottom: 10, flex: 0 }}
          >
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '2px', width: 'inherit' }}>
              <div>
                <Button size="small"
                  variant={this.state.showAll ? 'contained' : 'outlined'}
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={() => this.onShowAvailableChanged('all')}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Show All
                    </div>
                </Button>
              </div>
              <div>
                <Button size="small"
                  variant={!this.state.showAll ? 'contained' : 'outlined'}
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={() => this.onShowAvailableChanged('skill')}>
                  Team Skill
                </Button>
              </div>
            </div>
          </Grid>
          <Grid style={{ paddingBottom: 10 }}>
              <SelectDropdown
                label="By Labor Type"
                laborType={labourTypes}
                handleChange={onSelectedFilterLabourType}
                selected={selectedFilterLabourType}
                selectedValue={selectedFilterLabourType}
                name="laborType"
                id="select-labor"
              />
          </Grid>
          <Grid>
            <TextField
              ref="searchQHP"
              value={this.state.searchText}
              placeholder={this.props.selectedQHPGroup == "all" ? "Search all QHP" : "Search available QHP"}
              onChange={(e) => this.setState({ searchText: e.target.value })}
              onInput={this.customFilter}
              margin="dense"
              variant="outlined"
              inputProps={{ maxLength: 15 }}
            />
          </Grid>

          {this.props.selectedQHPGroup == "all" ? (
            <ExpansionPanel defaultExpanded>
              {qhpListData ? (
                <ExpansionPanelDetails>
                  <div id="external-events" style={{ height: 100 + "%" }}>
                    {qhpListData.map((provider, keyAll) => (
                      
                      <div
                        onMouseDown={() =>
                          this.props.onDragStart(provider, true)
                        }
                        className={"fc-event fc-event-qhp"}
                        title={provider.providerName}
                        id={provider.providerCode}
                        data={provider.providerCode}
                        key={keyAll+"all" + provider.providerCode}
                        style={{
                          height: '2rem',
                          display: 'flex',
                          alignItems: 'center',
                          pointerEvents: selectedScheduleBlockValue === "" ? "none" : "auto",
                          cursor: selectedScheduleBlockValue === "" ? "none" : "grab"
                        }}
                      >
                        <div style={{ flex: 1 }}>{provider.providerName ? provider.providerName.trim().split(" ").join(", "):""}</div>
                        {selectedScheduleBlockValue !== '' ? (<div><DragHandleIcon style={{ color: 'inherit !important' }} fontSize="small" /></div>) : (<></>)}
                      </div>
                    ))}
                  </div>
                </ExpansionPanelDetails>
              ) : (
                  <ExpansionPanelDetails></ExpansionPanelDetails>
                )}
            </ExpansionPanel>
          ) : (
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelDetails>
                  <div id="external-events" style={{ width: 100 + "%" }}>
                    {selectedSkillValue &&
                      qhpListData.map((provider, keyAvailable) => (
                        <div
                          onMouseDown={() =>
                            this.props.onDragStart(provider, true)
                          }
                          className={"fc-event fc-event-qhp"}
                          style={{ borderColor: selectedScheduleBlockValue === "" ? '#CDCDCD' : 'unset' }}
                          title={provider.title}
                          id={provider.id}
                          data={provider.id}
                          key={keyAvailable+"available" + provider.id}
                          style={{
                            height: '2rem',
                            display: 'flex',
                            alignItems: 'center',
                            pointerEvents:
                              selectedScheduleBlockValue === "" ? "none" : "auto",
                            cursor: selectedScheduleBlockValue === "" ? "none" : "grab"
                          }}
                        >
                          <div style={{ flex: 1 }}>{provider.title ? provider.title.trim().split(" ").join(", "):""}</div>
                          {selectedScheduleBlockValue !== '' ? (<div><DragHandleIcon style={{ color: 'inherit !important' }} fontSize="small" /></div>) : (<></>)}
                        </div>
                      ))}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
        </div>
      </div>
    );
  }
}

export default Accordian;
