import {
  GET_FACILITY_DEPARTMENT,
  GET_FACILITY_DEPARTMENT_SUCCESS,
  GET_FACILITY_DEPARTMENT_FAIL,
  GET_TEAM_SCHEDULE_BLOCK,
  GET_TEAM_SCHEDULE_BLOCK_SUCCESS,
  GET_TEAM_SCHEDULE_BLOCK_FAIL,
  GET_TEAM_SKILLS,
  GET_TEAM_SKILLS_SUCCESS,
  GET_TEAM_SKILLS_FAIL,
  GET_PROVIDER_BY_DEPARTMENT_SKILL,
  GET_PROVIDER_BY_DEPARTMENT_SKILL_SUCCESS,
  GET_PROVIDER_BY_DEPARTMENT_SKILL_FAIL,
  GET_TEAM_SCHEDULE_DATES,
  GET_TEAM_SCHEDULE_DATES_SUCCESS,
  GET_TEAM_SCHEDULE_DATES_FAIL,
  ADD_PROVIDER,
  ADD_PROVIDER_SUCCESS,
  ADD_PROVIDER_FAIL,
  POST_CREATE_BLOCK,
  POST_CREATE_BLOCK_SUCCESS,
  POST_CREATE_BLOCK_FAIL,
  GET_SAVE_SKILL,
  GET_SAVE_SKILL_SUCCESS,
  GET_SAVE_SKILL_FAIL,
  POST_OPEN_SHIFT,
  POST_OPEN_SHIFT_SUCCESS,
  POST_OPEN_SHIFT_FAIL,
  DELETE_PROVIDER,
  DELETE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_FAIL,
  GET_ALL_PROVIDER,
  GET_ALL_PROVIDER_SUCCESS,
  GET_ALL_PROVIDER_FAIL,
  CHANGE_OPEN_SKILL_RATE,
  CHANGE_OPEN_SKILL_RATE_SUCCESS,
  CHANGE_OPEN_SKILL_RATE_FAIL,
  GET_SKILL_CEILING_RATE,
  GET_SKILL_CEILING_RATE_SUCCESS,
  GET_SKILL_CEILING_RATE_FAIL,
  GET_ALL_PROVIDER_SHIFT,
  GET_ALL_PROVIDER_SHIFT_SUCCESS,
  GET_ALL_PROVIDER_SHIFT_FAIL,
  POST_OPEN_SHIFT_SAVE,
  POST_OPEN_SHIFT_SAVE_SUCCESS,
  POST_OPEN_SHIFT_SAVE_FAIL,
  RESEND_NOTIFICATION,
  RESEND_NOTIFICATION_SUCCESS,
  RESEND_NOTIFICATION_FAIL 
} from "./actionTypes";

import instance from "../utils/services/services";

// export const getFacilityDepartment = id => async dispatch => {
//   try {
//     dispatch({ type: GET_FACILITY_DEPARTMENT });
//     const response = await instance.get(
//       `Facility/GetFacilityDeparmentByUserCode?code=${id}`
//     );
//     dispatch({
//       type: GET_FACILITY_DEPARTMENT_SUCCESS,
//       facilityPayload: response
//     });
//   } catch (error) {
//     dispatch({ type: GET_FACILITY_DEPARTMENT_FAIL, error });
//   }
// };

export const getFacilityDepartment = id => dispatch => {
  dispatch({ type: GET_FACILITY_DEPARTMENT });

  instance
    .get(`Facility/GetFacilityDeparmentByUserCode?code=${id}`)
    .then(response => response)
    .then(
      data =>
        dispatch({
          type: GET_FACILITY_DEPARTMENT_SUCCESS,
          facilityPayload: data
        }),
      error =>
        dispatch({
          type: GET_FACILITY_DEPARTMENT_FAIL,
          error: error.message || "Unexpected Error!!!"
        })
    );
};

export const getTeamScheduleBlock = id => async dispatch => {
  try {
    dispatch({ type: GET_TEAM_SCHEDULE_BLOCK });
    const response = await instance.get(`Team/GetByDepartment?id=${id}`);
    dispatch({
      type: GET_TEAM_SCHEDULE_BLOCK_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: GET_TEAM_SCHEDULE_BLOCK_FAIL, error });
  }
};

export const getTeamSkills = id => async dispatch => {
  try {
    dispatch({ type: GET_TEAM_SKILLS });
    const response = await instance.get(`team/GetSkills?code=${id}`);
    dispatch({
      type: GET_TEAM_SKILLS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: GET_TEAM_SKILLS_FAIL, error:(error.message || "Unexpected Error!!!") });
  }
};

export const getProviderByDepartmentSkill = (id, skills) => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_BY_DEPARTMENT_SKILL });
    const response = await instance.get(
      `Facility/GetProvidersByDepartment?departmentCode=${id}&skills=${skills}`
    );
    dispatch({
      type: GET_PROVIDER_BY_DEPARTMENT_SKILL_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: GET_PROVIDER_BY_DEPARTMENT_SKILL_FAIL, error: error.message || "Unexpected Error!!!"});
  }
};

export const getTeamScheduleDates = scheduleCode => async dispatch => {
  try {
    dispatch({ type: GET_TEAM_SCHEDULE_DATES });
    const response = await instance.get(
      `Team/GetDatesForScheduleCodeWithData?code=${scheduleCode}`
    );
    dispatch({
      type: GET_TEAM_SCHEDULE_DATES_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: GET_TEAM_SCHEDULE_DATES_FAIL, error: error.message || "Unexpected Error!!!"});
  }
};

export const addProvider = providerData => async dispatch => {
  try {
    dispatch({ type: ADD_PROVIDER });
    const response = await instance.post(`/Scheduler/Calendar/AddProvider`, {
      ...providerData
    });

    dispatch({
      type: ADD_PROVIDER_SUCCESS,
      providerAddPayload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: ADD_PROVIDER_FAIL, error });
  }
};

export const deleteProvider = providerData => async dispatch => {
  try {
    dispatch({ type: DELETE_PROVIDER });
    const response = await instance.post(`/Scheduler/Calendar/DeleteProvider`, {
      ...providerData
    });

    dispatch({
      type: DELETE_PROVIDER_SUCCESS,
      providerDeletePayload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: DELETE_PROVIDER_FAIL, error });
  }
};

export const postCreateBlock = (
  teamCode,
  startDate,
  endDate
) => async dispatch => {
  try {
    dispatch({ type: POST_CREATE_BLOCK });

    const response = await instance.post(`/Scheduler/CreateBlock`, {
      teamCode: teamCode,
      startDate: startDate,
      endDate: endDate
    });

    dispatch({
      type: POST_CREATE_BLOCK_SUCCESS,
      createBlockPayload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: POST_CREATE_BLOCK_FAIL, error });
  }
};

export const getSaveSkills = scheduleBlockCode => async dispatch => {
  try {
    dispatch({ type: GET_SAVE_SKILL });

    const response = await instance.post(
       `/Team/GetOpenShiftSkills?scheduleCode=${scheduleBlockCode}`
      // `/Scheduler/Save?code=${scheduleBlockCode}`
    );

    dispatch({
      type: GET_SAVE_SKILL_SUCCESS,
      getSaveSkillPayload: response
    });
  } catch (error) {
    dispatch({ type: GET_SAVE_SKILL_FAIL, error });
  }
};

export const postOpenShift = openShift => async dispatch => {
  try {
    dispatch({ type: POST_OPEN_SHIFT });

    const response = await instance.post(`/Scheduler/CreateOpenShift`, {
      ...openShift
    });

    dispatch({
      type: POST_OPEN_SHIFT_SUCCESS,
      createOpenShiftPayload: response
    });
  } catch (error) {
    dispatch({ type: POST_OPEN_SHIFT_FAIL, error });
  }
};
export const getAllProviders = id => dispatch => {
// export const getAllProviders = facilityCode => dispatch => {
  dispatch({ type: GET_ALL_PROVIDER });
  instance
    // .get(`Facility/GetProvidersByFacility?facilityCode=${facilityCode}`)
    .get(`Facility/GetProvidersByDepartment?departmentCode=${id}`)
    .then(response => response)
    .then(
      data => {
        dispatch({
          type: GET_ALL_PROVIDER_SUCCESS,
          payload: data
        });
      },
      error =>
        dispatch({
          type: GET_ALL_PROVIDER_FAIL,
          error: error.message || "Unexpected Error!!!"
        })
    );
};

export const changeOpenShiftSkillRate = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_OPEN_SKILL_RATE });
    const response = await instance.post(
      `/Scheduler/Calendar/CreateOpenShift`,
      {
        ...data
      }
    );

    dispatch({
      type: CHANGE_OPEN_SKILL_RATE_SUCCESS,
      skillRatePayoad: response
    });
    return response;
  } catch (error) {
    dispatch({ type: CHANGE_OPEN_SKILL_RATE_FAIL, error });
  }
};

export const getCeilingRate = (code, skill) => async dispatch => {
  try {
    dispatch({ type: GET_SKILL_CEILING_RATE });
    const response = await instance.get(
      `Department/GetCeilingRate?code=${code}&skill=${skill}`
    );

    dispatch({
      type: GET_SKILL_CEILING_RATE_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: GET_SKILL_CEILING_RATE_FAIL, error });
  }
};

export const getAllProvidersShift = (
  departmentCode,
  month,
  year
) => async dispatch => {
  try {
    dispatch({ type: GET_ALL_PROVIDER_SHIFT });
    const response = await instance.get(
      `Team/GetAllProvider?departmentCode=${departmentCode}&month=${month}&year=${year}`
    );
  
    dispatch({
      type: GET_ALL_PROVIDER_SHIFT_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({ type: GET_ALL_PROVIDER_SHIFT_FAIL, error });
  }
};

export const postOpenShiftSave = code => async dispatch => {
  try {
    dispatch({ type: POST_OPEN_SHIFT_SAVE });

    const response = await instance.post(`/Scheduler/Save?code=${code}`);
    dispatch({
      type: POST_OPEN_SHIFT_SAVE_SUCCESS,
      postOpenShiftSave: response
    });
    return response;
  } catch (error) {
    dispatch({ type: POST_OPEN_SHIFT_SAVE_FAIL, error });
  }
};

export const resendNotificationOpenShift = data => async dispatch => {
  try {
    dispatch({ type: RESEND_NOTIFICATION });
    const response = await instance.post(
      `/Scheduler/Calendar/NotificationForOpenShift`,
      {
        ...data
      }
    );

    dispatch({
      type: RESEND_NOTIFICATION_SUCCESS,
      resendNotificationPayoad: response
    });
    return response;
  } catch (error) {
    dispatch({ type: RESEND_NOTIFICATION_FAIL, error });
  }
};