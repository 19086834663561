import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import instance from "../utils/services/services";
import Grid from "@material-ui/core/Grid";
import { EventEmitter } from "../components/events";
import { toast } from "react-toastify";
let skills = [];

export default class AddOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      org: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      SPL: "",
      id: "",
      isUpdate: false,
      npi: "",
      isSubmitted: false,
      orginfo: [],
      nameExists: false
    };
  }

  componentDidMount() {
    this.fetchOrgInfo();
    this.getMasterSkillSet();

    EventEmitter.on("orgEdit", event => this.editOrg(event));
    EventEmitter.on("orgModalClosed", event => this.setMode(event));
  }

  getMasterSkillSet() {
    var skillsArray = [];
    skills = [];
    instance
      .get(`Organization/GetSkillLevels`)
      .then(response => {
        skillsArray = response.data.data.skillLevel;
        skillsArray.forEach(i => {
          skills.push({
            label: i,
            value: i
          });
        });
      })
      .catch(error => {
        // console.log(error);
      });
  }

  fetchOrgInfo = async () => {
    const response = await instance.get(`Organization/GetAll`);
    this.setState({ orginfo: response.data.data });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      if (name == "org") {
        this.checkNameAvailability();
      }
    });
    if (event.target.value !== "") {
      this.setState({ isReq: false });
    }
  };

  checkNameAvailability = () => {
    this.setState({ nameExists: false });
    this.state.orginfo.forEach(org => {
      if (this.state.isUpdate) {
        if (
          this.state.org.toLowerCase() === org.organizationName.toLowerCase() &&
          org.organizationId !== this.state.id
        ) {
          this.setState({ nameExists: true });
        }
      } else {
        if (
          this.state.org.toLowerCase() === org.organizationName.toLowerCase()
        ) {
          this.setState({ nameExists: true });
        }
      }
    });
  };

  closeModal = () => {
    EventEmitter.emit("closeModal", "");
  };

  handleSkillChange = e => {
    this.setState({ skill: e.target.value });
  };

  setMode = state => {
    this.setState({ isUpdate: state });
  };

  updateOrg = () => {
    const { org, address, city, state, zip, nameExists } = this.state;
    if (
      nameExists === true ||
      org.trim() === "" ||
      address.trim() === "" ||
      city.trim() === "" ||
      state.trim() === "" ||
      zip.trim() === ""
    ) {
      this.setState({ isSubmitted: true });
      return false;
    }

    instance
      .post(`Organization/Update`, {
        id: this.state.id,
        name: this.state.org,

        extension: {
          address: {
            addressline1: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip
          },
          npi: this.state.npi
        }
      })
      .then(res => {
        // console.log(res);
      }, this)
      .then(event => {
        EventEmitter.emit("orgCreated", event);
        toast.success("Organization updated successfully.");
      })
      .catch(error => {
        // console.log(error);
      });
  };

  createOrg = () => {
    const { org, address, city, state, zip, nameExists } = this.state;
    if (
      nameExists === true ||
      org.trim() === "" ||
      address.trim() === "" ||
      city.trim() === "" ||
      state.trim() === "" ||
      zip.trim() === ""
    ) {
      this.setState({ isSubmitted: true });
      return false;
    }

    instance
      .post(`Organization/Create`, {
        id: this.state.id,
        name: this.state.org,

        extension: {
          address: {
            addressline1: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip
          },
          npi: this.state.npi
        }
      })
      .then(res => {
        // console.log(res);
      }, this)
      .then(event => {
        EventEmitter.emit("orgCreated", event);
        toast.success("Organization created successfully.");
      })
      .catch(error => {
        // console.log(error);
      });
  };

  editOrg = event => {
    this.setState({
      id: event.organizationId,
      org: event.organizationName,
      address: event.extension.address.addressline1,
      city: event.extension.address.city,
      state: event.extension.address.state,
      zip: event.extension.address.zip,
      npi: event.extension.npi,
      isUpdate: true
    });
  };

  render() {
    const errorStyle = {
      color: "#FF0000",
      marginLeft:10
    };
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="organization-name"
              label="Organization"
              value={this.state.org}
              required
              error={
                (this.state.isSubmitted && this.state.org === "") ||
                (this.state.nameExists && this.state.org !== "")
              }
              onChange={this.handleChange("org")}
              margin="dense"
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>
          {this.state.org.trim() === "" && this.state.isSubmitted ? (
              <h6 style={errorStyle}>Please enter organization name</h6>
            ) : null}
            {this.state.org.trim() !== "" &&
            this.state.nameExists === true &&
            this.state.isSubmitted ? (
              <h6 style={errorStyle}>Organization name already exists</h6>
            ) : null}
          <Grid item xs={12} sm={12}>
            <TextField
              id="organization-npi"
              label="NPI"
              value={this.state.npi}
              onChange={this.handleChange("npi")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="organization-address"
              label="Address"
              value={this.state.address}
              onChange={this.handleChange("address")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 100 }}
              required
              error={this.state.isSubmitted && this.state.address === ""}
            />
          </Grid>
          {this.state.address.trim() === "" && this.state.isSubmitted ? (
              <h6 style={errorStyle}>Please enter address</h6>
            ) : null}
          <Grid item xs={12} sm={12}>
            <TextField
              id="organization-city"
              label="City"
              value={this.state.city}
              onChange={this.handleChange("city")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required
              error={this.state.isSubmitted && this.state.city === ""}
            />
          </Grid>
          {this.state.city.trim() === "" && this.state.isSubmitted ? (
              <h6 style={errorStyle}>Please enter city</h6>
            ) : null}
          <Grid item xs={12} sm={12}>
            <TextField
              id="organization-state"
              label="State"
              value={this.state.state}
              onChange={this.handleChange("state")}
              margin="dense"
              variant="outlined"
              fullWidth
              // inputProps={{ maxLength: 2 }}
              required
              error={this.state.isSubmitted && this.state.state === ""}
            />
          </Grid>
            {this.state.state.trim() === "" && this.state.isSubmitted ? (
              <h6 style={errorStyle}>Please enter state</h6>
            ) : null}
          <Grid item xs={12} sm={12}>
            <TextField
              id="organization-zip"
              label="Zip Code"
              value={this.state.zip}
              onChange={this.handleChange("zip")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 5 }}
              required
              error={this.state.isSubmitted && this.state.zip === ""}
            />
          </Grid>
          {this.state.zip.trim() === "" && this.state.isSubmitted ? (
              <h6 style={errorStyle}>Please enter zip</h6>
            ) : null}
          <div className="button-wrapper primary">
            {this.state.isUpdate == false ? (
              <Button
                variant="contained"
                className="button-primary"
                color="primary"
                onClick={this.createOrg}
              >
                CREATE
              </Button>
            ) : (
              <Button
                variant="contained"
                className="button-primary"
                color="secondary"
                onClick={this.updateOrg}
              >
                UPDATE
              </Button>
            )}
          </div>
          <div className="button-wrapper">
            <Button
              variant="contained"
              className="button-cancel"
              color="secondary"
              onClick={this.closeModal}
            >
              CANCEL
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}
