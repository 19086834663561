import {
  GET_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  reportPayload: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT:
      return {
        ...state,
        isLoading: true
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportPayload: action.reportPayload.data,
      };
    case GET_REPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
};
