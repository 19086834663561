import {
    SIGN_IN,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAIL
  } from "../actions/actionTypes";
  import {setUserType} from "../settings/userSetting";
  import { EventEmitter } from "../components/events";

  const initialState = {
    redirect: false,
    signInPayload: {}
  };
  export default (state = initialState, action) => {
    switch (action.type) {
      case SIGN_IN:
        return {
          ...state,
          redirect: false
        };
      case SIGN_IN_SUCCESS:
        if(action.signInPayload.data.data.userType == '' || action.signInPayload.data.data.userType == "provider") {
          return {
            ...state,
            redirect: false,
            error: action.error
          };
        } else {
          localStorage.setItem("api_token", action.signInPayload.data.data.token);
          localStorage.setItem("userCode", action.signInPayload.data.data.userCode);
          localStorage.setItem("is_org_user", false);
          localStorage.setItem("is_fac_user", false);
          localStorage.setItem("is_dep_user", false);
          localStorage.setItem("generic", (action.signInPayload.data.data.userType == "generic" ? true : false));
          localStorage.setItem("isTesselMedAdmin", action.signInPayload.data.data.isTesselMedAdmin);
          localStorage.setItem("isSchedulerView", (action.signInPayload.data.data.isViewScheduler || (action.signInPayload.data.data.userType == "generic")));
          localStorage.setItem("userName", action.signInPayload.data.data.firstName + " " + action.signInPayload.data.data.lastName);
        if(action.signInPayload.data.data.isTesselMedAdmin) {
          setUserType("admin");
        } else {
          setUserType(action.signInPayload.data.data.userType)
        }
          return {
            ...state,
            redirect: true,
            signInPayload: action.signInPayload.data
          };
        }
      case SIGN_IN_FAIL:
        return {
          ...state,
          error: action.error
        };
        default:
        return state;
    }
  };
  