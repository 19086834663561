import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export class VerificationSuccess extends Component {
    render() {
        const divStyle = {
            paddingLeft: '15%',
            paddingTop: '30%',
           // border: '5px solid pink'
          };
          const verificationStyle = {
           // paddingLeft: '5%',
            //paddingTop: '30%',
            //border: '5px solid pink',
            color:'	#228B22'
          };
        return (
            <div style={divStyle}>
                <h1 style={verificationStyle} >Verification Successful !</h1>
                <p>Thank you for verifying your mobile number & Email Id !
                   Please <Link to="/" variant="body2">
                {"LOGIN"}
              </Link> to continue
                </p>
            </div>
        )
    }
}
export default VerificationSuccess;