import React from "react";
import { connect } from "react-redux";
import instance from "../../utils/services/services";
import Button from "@material-ui/core/Button";
import moment from "moment";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable, { defaultThemes } from "react-data-table-component";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { GET_REPORT_SUCCESS, GET_REPORT } from "../../actions/actionTypes";
import { toast } from "react-toastify";
import { spacing } from "@material-ui/system";
import LinearIndeterminate from "../../common/ListLoader";
import MultiSelect from "../../common/MultiSelect";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { convertTZ } from "../../settings/index";
import { constrainPoint } from "@fullcalendar/core";
import jstz from "jstz";

var colorsCode = [];
while (colorsCode.length < 100) {
    do {
        var color = Math.floor((Math.random()*1000000)+1);
    } while (colorsCode.indexOf(color) >= 0);
    colorsCode.push("#" + ("000000" + color.toString(16)).slice(-6));
}

const styles = theme => ({
  root: {
    width: '100%',
  },
  disabled: {},
  focused: {},
  error: {},
  inputAdornedStart: {},
  notchedOutline: {
    height: 35
  },
  input: {
    paddingTop: 8,
    paddingBottom: 8
  },
  MuiInputAdornment: {
    positionStart: {
      marginRight: 0
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
});
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#33a5db"
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {}
    },
    MuiPickersDay: {
      day: {},
      daySelected: {
        backgroundColor: "#33a5db"
      },
      dayDisabled: {},
      current: {}
    },
    MuiPickersModal: {
      dialogAction: {}
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: 8,
        paddingBottom: 8,
        minWidth:160
      }
    },
    MuiOutlinedInput: {
      marginTop: 0,
      marginBottom: 0
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0
      }
    }
  }
});

const reportType = [
  {
    label: "Time to Fill Open Shift",
    value: "timeToFillOpenShift"
  },
  {
    label: "Shift Inventory",
    value: "shiftDetails"
  },
  {
    label: "Leave Report",
    value: "leaveReport"
  },
  {
    label: "PTO/CME Leave Report",
    value: "PTOCMELeaveReport"
  },
  {
    label: "Shift Report",
    value: "shiftReport"
  },
  {
    label: "Teamwise Report",
    value: "teamwiseReport"
  },
  {
    label: "Provider Weekly Report",
    value: "providerWeeklyReport"
  }
];

const shiftType = [
  {
    label: "All (Sourced and Regular Shift, Add-on Hours)",
    value: null
  },
  {
    label: "Regular Shift",
    value: 0
  },
  {
    label: "Sourced Shift",
    value: 1
  },
  {
    label: "Add-On Hours Shift",
    value: 2
  },
];

const customPaginationStyles = {
  header: {
    style: {
      // minHeight: "56px",
      width: "100%"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: "38px"
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight: 'bold',
      fontSize: 16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14,
      alignItems:"center"
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class ReportDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      userCode: null,
      facilities: [],
      departments: [],
      providers: [],
      allProvider:[],
      teams: [],
      selectedReportType: null,
      selectedFacility: null,
      selectedDepartment: null,
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      minDate: new Date(),
      currentColumn: [],
      selectedMultipleFacility: [],
      selectedMultipleDepartment: [],
      selectedMultipleProvider: [],
      selectedMultipleTeam: [],
      selectedProvider:null,
      expanded: 'panel1',
      selectedShiftType: {
        label: "Sourced Shift",
        value: 1
      },
      leaveTypes:[]
    };
  }

  componentWillMount = async () => {
    this.setState({ userCode: localStorage.getItem("userCode") }, async () => {
      this.getColumns();
      await this.getAllFacilities();
      this.onChangeReportType();
    });
  }

  handleChange = panel => () => {
    this.setState({
      expanded: this.state.expanded !== panel ? panel : false
    });
  };

  getAllLeaveTypes = async () => {
    const response = await instance.get(
      `Leave/GetLeaveType`
    );
    const { data } = response.data;
    let leaveTypes = [{
      label: "All",
      value: 0
    }];
    if (data) {
      await response.data.data.forEach(element => {
        if(element.leaveTypeName == 'Education/CME' || element.leaveTypeName == 'PTO') {
          leaveTypes.push({
            label: element.leaveTypeName,
            value: (element.leaveTypeName == 'Education/CME' ? 2 : 1)
          });
        }
      });
    }
    this.setState({leaveTypes:leaveTypes});
  }

  getUserType = () => {
    let userType = localStorage.getItem("loginUserType");
    return userType;
  };

  getAllFacilities = async () => {
    if (this.state.userCode) {
      await instance
        .get(`Facility/GetFacilityDeparmentByUserCode?`, {
          params: { code: this.state.userCode }
        })
        .then(response => {
          if (!response.error) {
            if (response.status && response.data) {
              let facilitiesArray = [];
              response.data.data.forEach((facility, i) => {
                facilitiesArray.push({
                  id: facility.facilityId,
                  value: facility.facilityCode,
                  label: facility.facilityName,
                  department: facility.department
                });
              });
              this.setState({ facilities: facilitiesArray }, () => { });
            }
          }
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };

  getTeams = async () => {
    this.setState({ isLoading: true });
    await instance.get(`Team/GetAll`).then((response) => {
      if (response.status) {
        let teamArray = []
        let teams = (response.data.data !== null ? response.data.data : [])
        teamArray.push({
          id: -1,
          value: -1,
          label: 'All'
        });
        teams && teams.forEach((team)=> {
          teamArray.push({
            value: team.teamCode,
            label: team.teamName
          })
        });
        this.setState({
          teams: teamArray
        });
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  getAllProviders = async () => {
    this.setState({ isLoading: true });
    await instance.get(`Users/GetAll`).then((response) => {
      if (response.status) {
        let providerArray = []
        let providers = (response.data.data !== null ? response.data.data : [])
        providers && providers.forEach((provider)=> {
          if(provider.isProvider) {
            providerArray.push({
              value: provider.userCode,
              label: `${provider.firstName} ${provider.lastName}`
            })
          }
        });
        this.setState({
          allProvider: providerArray
        });
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  getReport = async (isReport = false) => {
    const {
      selectedFacility,
      selectedReportType,
      selectedStartDate,
      selectedEndDate,
      selectedDepartment,
      selectedMultipleFacility,
      userCode,
      selectedProvider
    } = this.state;
    if (!isReport) {
      this.getColumns();
    }
    let dollarUSLocale = Intl.NumberFormat('en-US');
    let endDate = moment(new Date(selectedEndDate)).utcOffset(0).format("MM-DD-YYYY");
    let startDate = moment(new Date(selectedStartDate)).utcOffset(0).format("MM-DD-YYYY");
    let url = "";
    if (selectedReportType.value !== "shiftReport" && selectedReportType.value !== "teamwiseReport" && selectedReportType.value !== "providerWeeklyReport" && selectedReportType.value !== "PTOCMELeaveReport") {
      let facilityCode = selectedFacility.value;
      let departmentCode = selectedDepartment.value;
      if (selectedReportType.value === "timeToFillOpenShift") {
        url = `Kpi/GetOpenShiftTimeToFillReport?facilityCode=${facilityCode}&departmentCode=${departmentCode}&startDate=${startDate}&endDate=${endDate}`;
      } else if (selectedReportType.value === "shiftDetails") {
        url = `Kpi/GetShiftDetails?facilityCode=${facilityCode}&departmentCode=${departmentCode}&startDate=${startDate}&endDate=${endDate}`;
      } else if (selectedReportType.value === "leaveReport") {
        let isDepartment = this.getUserType() === "department";
        let isFacility = this.getUserType() === "facility";
        let isOrgUser = this.getUserType() === "organization";
        url = `Leave/GetLeaves?userCode=${userCode}&isDepartmentUser=${isDepartment}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}&startDate=${startDate}&endDate=${endDate}&leaveStatus=${'Approved'}`;
      }
    } else if (selectedReportType.value === "shiftReport") {
      url = `Scheduler/GetSchedulerCalendarDetails`;
    } else if (selectedReportType.value === "PTOCMELeaveReport") {
      url = `Leave/GetPTOLeaves?providerCode=${selectedProvider.value}&startDate=${startDate}&endDate=${endDate}&leaveType=${this.state.selectedLeaveType.value}`
    }

    if (!isReport) {
      this.setState({ isLoading: true });
    }
    if (selectedReportType.value === "shiftReport") {
      let data = {
        "provider_Code": await this.fetchSelectedProvider(),
        "department_Code": await this.fetchSelectedDepartment(),
        "facility_Code": await this.fetchSelectedFacility(),
        "startDate": startDate,
        "endDate": endDate,
        "shiftType": this.state.selectedShiftType.value
      }

      await instance.post(url, data).then(response => {
        const { data } = response.data;
        let reports = [];
        if (data) {
          response.data.data.schedulerCalendarDetails.forEach(element => {
            reports.push(element);
          });
        }
        if (!isReport) {
          this.setState({ isLoading: false });
        }
        if (!isReport) {
          this.setState({ reports: reports });
        } else {
          if (reports.length === 0) {
            toast.error(
              "Records not found with selected filters. Try again with change filters"
            );
          } else {
            let newReportArray = [];
            reports.forEach((report, i) => {
              let row = {
                "PROVIDER NAME": report.qhpName,
                "FACILITY NAME": report.facilityName,
                "DEPARTMENT NAME": report.departmentName,
                "TEAM NAME": report.teamName,
                "SHIFT DATE": this.getShiftDate(report),
                "START TIME": this.showHours(report.shiftStartTime),
                "END TIME": this.showHours(report.shiftEndTime),
                "HOURS": this.getHours(report),
                "NPI": report.npi,
                "SHIFT COST": `$ ${dollarUSLocale.format(report.shiftCost)}`,
                "TOTAL COST": `$ ${dollarUSLocale.format(report.totalCost)}`
              };
              newReportArray.push(row);
            });
            this.exportReport(newReportArray);
          }
        }
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    } else {
      let data = null;
      if(selectedReportType.value === "teamwiseReport" || selectedReportType.value === "providerWeeklyReport") {

        if (this.state.selectedReportType.value === "teamwiseReport") {
          url = `Scheduler/GetTeamwiseProviderSchedule`;
          data = {
              "team_Code": await this.fetchSelectedTeam(),
              "startDate": startDate,
              "endDate": endDate
          }
        } else {
          url = `Scheduler/GetProviderScheduleReport?providerCode=${this.state.selectedProvider.value}&startDate=${startDate}&endDate=${endDate}`;
        }
        await instance.post(url, data).then(response => {
          const { data } = response.data;
          let reports = [];
          if (data) {
            response.data.data.forEach((element, index) => {
              if (this.state.selectedReportType.value === "providerWeeklyReport") {
                
                reports.push(element);
                
              } else {
                reports.push(element);
              }
            });
          }
          if (!isReport) {
            this.setState({ isLoading: false });
          }
          if (!isReport) {
            if(this.state.selectedReportType.value === "providerWeeklyReport") {
              let startWeekNumber = (moment(new Date(startDate), 'DD-MM-YYYY').utcOffset(0).week());
              let endWeekNumber = (moment(new Date(endDate), 'DD-MM-YYYY').utcOffset(0).week());
              let reportsData = [];
             
              let y = startWeekNumber;
              for(let x = startWeekNumber; x <= endWeekNumber;) {
                let totalCostOfWeek = 0
                
                reports && reports.forEach((element, index) => {
                  if(x === moment(new Date(element.shiftDate), 'DD-MM-YYYY').utcOffset(0).week()) {
                    let elementData = element
                    totalCostOfWeek = (totalCostOfWeek+elementData.duration)
                    elementData['totalCostOfWeekFlag'] = false;
                    elementData['totalCostOfWeek'] = 0;
                    elementData['weekNumber'] = x
                    reportsData.push(elementData);
                  }
                })
                
                if (totalCostOfWeek) {
                  let itemData = reportsData;
                  itemData.forEach((item, index) => {
                    if(x === item.weekNumber) {
                      reportsData[index]['totalCostOfWeek'] = totalCostOfWeek;
                    } 
                  })
                }
                x++;
              }
              this.setState({ reports: reportsData});
            } else {
              this.setState({ reports: reports});
            }
          } else {
            if (reports.length === 0) {
              toast.error(
                "Records not found with selected filters. Try again with change filters"
              );
            } else {
              let newReportArray = [];
              let row = [];
              if(this.state.selectedReportType.value === "providerWeeklyReport") {
                let startWeekNumber = (moment(new Date(startDate), 'DD-MM-YYYY').utcOffset(0).week());
                let endWeekNumber = (moment(new Date(endDate), 'DD-MM-YYYY').utcOffset(0).week());
                let reportsData = [];
               
                let y = startWeekNumber;
                for(let x = startWeekNumber; x <= endWeekNumber;) {
                  let totalCostOfWeek = 0
                  
                  reports && reports.forEach((element, index) => {
                    if(x === moment(new Date(element.shiftDate), 'DD-MM-YYYY').utcOffset(0).week()) {
                      let elementData = element
                      totalCostOfWeek = (totalCostOfWeek+elementData.duration)
                      elementData['totalCostOfWeekFlag'] = false;
                      elementData['totalCostOfWeek'] = 0;
                      elementData['weekNumber'] = x
                      reportsData.push(elementData);
                    }
                  })
                  
                  if (totalCostOfWeek) {
                    let itemData = reportsData;
                    itemData.forEach((item, index) => {
                      if(x === item.weekNumber) {
                        reportsData[index]['totalCostOfWeek'] = totalCostOfWeek;
                      } 
                    })
                  }
                  x++;
                }
                this.setState({ reports: reportsData});
                reports.forEach((report, i) => {
                row = {
                  "PROVIDER NAME": report.qhpName,
                  "TEAM NAME": report.teamName,
                  "SHIFT": this.getShiftName(report),
                  "SKILL": report.skilllevel,
                  "DATE": this.getShiftDate(report),
                  "START TIME": report.shiftStartTime,
                  "END TIME":  report.shiftEndTime,
                  "DURATION":  report.duration,
                  "TOTAL HOURS FOR WEEK": report.totalCostOfWeek
                };
                 newReportArray.push(row);
              });
              
              } else {
                reports.forEach((report, i) => {
                  if (this.state.selectedReportType.value === "teamwiseReport") {
                    row = {
                     "TEAM NAME": report.teamName,
                     "SHIFT": this.getShiftName(report),
                     "SKILL": this.getReportSkillName(report),
                     "DATE": this.getShiftDate(report),
                     "START TIME": this.getReportTeamStartTime(report),
                     "END TIME": this.getReportTeamEndTime(report),
                     "DURATION": report.shiftHours,
                   };
                 
                   newReportArray.push(row);
                 }
               });
              }
               
              this.exportReport(newReportArray);
            }
          }
        }).catch(() => {
          this.setState({ isLoading: false });
        });
      } else {
        await instance.get(url, data).then(response => {
          const { data } = response.data;
          let reports = [];
          if (data) {
            let totalCME = 0;
            let totalPTO = 0;
            response.data.data.forEach(element => {
              if (this.state.selectedReportType.value === "PTOCMELeaveReport") {
                if(element.type =='PTO') {
                  totalPTO = totalPTO+element.ptO_cme_hours
                } else {
                  totalCME = totalCME+element.ptO_cme_hours
                }
              }
              reports.push(element);
            });
            if (this.state.selectedReportType.value === "PTOCMELeaveReport") {
              reports && reports.forEach((report, index) => {
                reports[index]['totalCME'] = totalCME;
                reports[index]['totalPTO'] = totalPTO;
              })
            }
          }
          if (!isReport) {
            this.setState({ isLoading: false });
          }
          if (!isReport) {
            this.setState({ reports: reports });
          } else {
            if (reports.length === 0) {
              toast.error(
                "Records not found with selected filters. Try again with change filters"
              );
            } else {
              let newReportArray = [];
              let totalCME = 0;
              let totalPTO = 0;
              reports.forEach((report, i) => {
                if (
                  this.state.selectedReportType.value === "timeToFillOpenShift"
                ) {
                  let row = {
                    "DEPARTMENT NAME": report.departmentName,
                    "TEAM NAME": report.teamName,
                    "SHIFT DATE": this.getShiftDateTime(report),
                    "SHIFT CREATED DATE": this.getShiftDateCreatedTime(report),
                    "SHIFT SECURED DATE": this.getShiftSecuredDateTime(
                      report
                    ),
                    "DIFFERENCE HOURS": report.differenceHours
                  };
                  newReportArray.push(row);
                } else if (this.state.selectedReportType.value === "shiftDetails") {
                  let row = {
                    "SCHEDULED SHIFT COUNT": report.scheduledShiftCount,
                    "OPEN SHIFT COUNT": report.openShiftCount,
                    "SECURED SHIFT COUNT": report.securedShiftCount,
                    "CURRENT OPEN SHIFT COUNT": report.currentOpenShiftCount,
                  };
                  newReportArray.push(row);
                } else if (this.state.selectedReportType.value === "leaveReport") {
                  let row = {
                    "PROVIDER NAME": report.provider_Name,
                    "FROM DATE": this.getFromDate(report),
                    "TO DATE": this.getToDate(report),
                    "STATUS": this.getStatus(report),
                  };
                  newReportArray.push(row);
                } else if (this.state.selectedReportType.value === "PTOCMELeaveReport") {
                  if(report.type =='PTO') {
                    totalPTO = totalPTO+report.ptO_cme_hours
                  } else {
                    totalCME = totalCME+report.ptO_cme_hours
                  }
                  let row = {
                    "PROVIDER NAME": report.qhpName,
                    "TYPE": report.type,
                    "FROM DATE": this.getDate(report),
                    "START TIME": this.getDate(report),
                    "DURATION": report.ptO_cme_hours,
                  };
                  newReportArray.push(row);
                }
              });
              if (this.state.selectedReportType.value === "PTOCMELeaveReport") {
                newReportArray && newReportArray.forEach((report, index) => {
                  newReportArray[index]['TOTAL CME'] = totalCME;
                  newReportArray[index]['TOTAL PTO'] = totalPTO;
                })
              }
              this.exportReport(newReportArray);
            }
          }
        }).catch(() => {
          this.setState({ isLoading: false });
        });
      }
    
    }
  };

  exportReport = csvData => {
    const { selectedReportType } = this.state;
    let fileName =
      selectedReportType.value.toUpperCase() +
      "_" +
      moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A")
        .format("MM_DD_YYYY");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    toast.success(
      `File exported successfully. Found the ${csvData.length} records`
    );
  };

  getShiftDate = row => {
    // return row.shiftDate
    // ?  moment(convertTZ(new Date(row.shiftDate.split("T")[0]))).format("MM-DD-YYYY") : "";

    return row.shiftDate
    ? moment(
      new Date(row.shiftDate.split("T")[0])
  ).utcOffset(0).format("MM-DD-YYYY") : ""

    return row.shiftDate
    ? moment(new Date(row.shiftDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
  };

  getShiftDateTime = row => {
    // return row.shiftDateTime
    // ?  moment(convertTZ(new Date(row.shiftDateTime.split("T")[0]))).format("MM-DD-YYYY") : "";
    return row.shiftDateTime
    ? moment(new Date(row.shiftDateTime.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
  };

  getShiftDateCreatedTime = row => {
    return row.shiftCreatedDateTime ? moment(
      new Date(row.shiftCreatedDateTime),
      "ddd DD-MMM-YYYY, hh:mm A"
    ).tz((jstz.determine().name())).format("MM-DD-YYYY hh:mm A") : "";
    // return row.shiftCreatedDateTime
    // ?  moment(convertTZ(new Date(row.shiftCreatedDateTime.split("T")[0]))).format("MM-DD-YYYY hh:mm A") : "";
  };

  getShiftSecuredDateTime = row => {
    // var offset = new Date(row.shiftSecuredDateTime.split("T")[0]).getTimezoneOffset();
    return row.shiftSecuredDateTime ? moment(
      new Date(row.shiftSecuredDateTime),
      "ddd DD-MMM-YYYY, hh:mm A"
    ).tz((jstz.determine().name())).format("MM-DD-YYYY hh:mm A") : "";
    // return row.shiftSecuredDateTime
    // ?  moment(convertTZ(new Date(row.shiftSecuredDateTime.split("T")[0]))).format("MM-DD-YYYY hh:mm A") : "";
  };

  getFromDate = request => {
    return request.fromDate
    ? moment(new Date(request.fromDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
    // return request.fromDate
    // ?  moment(convertTZ(new Date(request.fromDate.split("T")[0]))).format("MM-DD-YYYY") : "";
  };

  getToDate = request => {
    return request.toDate
    ? moment(new Date(request.toDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
    // return request.toDate
    // ?  moment(convertTZ(new Date(request.toDate.split("T")[0]))).format("MM-DD-YYYY") : "";
  };

  getDate = request => {
    return request.date
    ? moment(new Date(request.date.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
    
    // return request.date
    // ?  moment(convertTZ(new Date(request.date.split("T")[0]))).format("MM-DD-YYYY") : "";
   
  };

  getHours = request => {
    if(request.shiftStartTime && request.shiftEndTime) {
      let x = new Date(request.shiftDate);
      x.setHours(request.shiftStartTime.split(":")[0])
      x.setMinutes(request.shiftStartTime.split(":")[1])
      let startDate = moment(x)
      let y = new Date(request.shiftDate)
      y.setHours(request.shiftEndTime.split(":")[0])
      y.setMinutes(request.shiftEndTime.split(":")[1])
      let endDate= moment(y)
      return Math.abs(startDate.diff(endDate, 'hours'))
    } else {
      return null;
    }
  };


  resetFilters = () => {
    this.setState(
      {
        selectedReportType: null,
        selectedFacility: null,
        selectedDepartment: null,
        selectedStartDate: new Date(),
        selectedEndDate: new Date(),
        selectedMultipleFacility: [],
        selectedMultipleDepartment:[],
        selectedMultipleProvider: []
      },
      () => {
        this.setState({ reports: [] });
      }
    );
  };

  onChangeReportType = async report => {
    this.setState(
      {
        selectedReportType: report ? report : reportType[0]
      },
      async () => {
        this.setState({
          selectedFacility: null,
          selectedDepartment: null,
          selectedMultipleFacility: [],
          selectedMultipleDepartment:[],
          selectedMultipleProvider: []
        }, () => {
          if (this.state.selectedReportType.value === 'teamwiseReport') {
            this.getTeams();
          } else if (this.state.selectedReportType.value === 'providerWeeklyReport') {
            this.getAllProviders();
          } else if (this.state.selectedReportType.value === 'PTOCMELeaveReport') {
            this.getAllProviders();
            this.getAllLeaveTypes()
          } else {
            if (this.state.facilities && this.state.facilities.length !== 0) {
              if (this.state.selectedReportType.value === 'shiftReport') {
                this.onChangeMultipleFacility(this.state.facilities[0]);
              } else {
                this.onChangeFacility(this.state.facilities[0]);
              }
            }
          }
        });
      }
    );
  };

  onChangeFacility = facility => {
    this.setState({ selectedFacility: facility }, () => {
      this.setState({
        selectedDepartment: null
      });
      let departmentArray = [];
      if (facility) {
        facility.department.forEach((department, i) => {
          departmentArray.push({
            id: department.departmentId,
            value: department.departmentCode,
            label: department.departmentName
          });
        });
        this.setState({ departments: departmentArray }, () => {
          this.onChangeDepartment(this.state.departments[0])
        });
      } else {
        this.setState({ departments: departmentArray });
      }
    });
  };

  onChangeMultipleFacility = facility => {
    this.setState({ selectedMultipleFacility: facility }, () => {
      this.setState({
        selectedDepartment: null
      });
      let departmentArray = [];
      if (this.state.selectedMultipleFacility) {
        if(this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' && this.state.selectedMultipleFacility.length) {
          departmentArray.push({
            id: -1,
            value: -1,
            label: 'All'
          });
        }
        this.state.selectedMultipleFacility.length && this.state.selectedMultipleFacility.forEach((facility, i) => {
          facility.department && facility.department.forEach((dept, i) => {
            departmentArray.push({
              id: dept.departmentId,
              value: dept.departmentCode,
              label: dept.departmentName
            });
          });
        });
        this.setState({ departments: departmentArray });
      } else {
        this.setState({ departments: departmentArray });
      }
    });
  };

  getProviderByDepartments = async (departments) => {
    let departmentArray = [];

    departments && departments.forEach((department) => {
      departmentArray.push(department.value)
    });
    if (departmentArray.length) {
      if (this.state.userCode) {
        await instance
          .get(`Facility/GetProvidersByDepartment?${await this.fetchSelectedDepartmentDetail()}`)
          .then(response => {
            if (!response.error) {
              if (response.status && response.data) {
                let providerArray = [];
                if(this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' && response.data.data.length !== 0) {
                  providerArray.push({
                    id: -1,
                    value: -1,
                    label: 'All'
                  });
                }
                response.data.data.forEach((provider, i) => {
                  providerArray.push({
                    value: provider.providerCode,
                    label: provider.providerName
                  });
                });
                this.setState({ providers: providerArray });
              }
            }
          })
          .catch(error => {
            // console.log(error);
          });
      } else {
        this.setState({ providers: [] });
      }
    }
  }

  onChangeDepartment = department => {
    this.setState({ selectedDepartment: department });
  };

  onChangeMultipleDepartment = department => {
    this.setState({ selectedMultipleDepartment: department }, () => {
      this.getProviderByDepartments(this.state.selectedMultipleDepartment)
    });
  };

  onChangeMultipleProvider = provider => {
    this.setState({ selectedMultipleProvider: provider });
  };

  onChangeProvider = provider => {
    this.setState({ selectedProvider: provider });
  };

  onChangeLeaveTypes = LeaveType => {
    this.setState({ selectedLeaveType: LeaveType });
  };
  
  onChangeMultipleTeam  = team => {
    this.setState({ selectedMultipleTeam: team });
  };

  onChangeStartDate = startDate => {
    this.setState({ selectedStartDate: startDate, minDate: startDate });
  };

  onChangeEndDate = endDate => {
    this.setState({ selectedEndDate: endDate });
  };

  onChangeShiftType = shiftType => {
    this.setState({ selectedShiftType: shiftType });
  };

  getStatus = request => {
    switch (request.leaveStatus) {
      case "Open":
        return "Open";
      case "Approved":
        return "Approved";
      case "Denied":
        return "Denied";
      case "Cancelled":
        return "Cancelled";
      default:
        return "Open";
    }
  };

  getShiftName = request => {
   
    if(request.isSourceShift) {
      return "Source Shift"
    } else {
      return "Regular Shift"
    }
  };

  getSkillName = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.skilllevel}</span>
    } else if (request.qhpCode == -1 && request.isSourceShift) {
      return <span style={{color:"red"}}>{request.skilllevel}</span>
    } else {
      return <span>{request.skilllevel}</span>
    }
  };

  getTeamName = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.teamName}</span>
    } else if (request.qhpCode == -1 && request.isSourceShift) {
      return <span style={{color:"red"}}>{request.teamName}</span>
    } else {
      return <span>{request.teamName}</span>
    }
  };

  getQHPName = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.qhpName}</span>
    } else if (request.qhpCode == -1 && request.isSourceShift) {
      return <span style={{color:"red"}}>{request.qhpName}</span>
    } else {
      return <span>{request.qhpName}</span>
    }
  };

  getWeeklyQHPName = request => { 
    if (request.qhpCode == -1 && request.isSourceShift) {
      return <span style={{color:"red"}}>{request.qhpName}</span>
    } else {
      return <span>{request.qhpName}</span>
    }
  };

  getTeamStartTime = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.shiftStartTime}</span>
    } else {
      return <span>{request.shiftStartTime}</span>
    }
  };

  getTeamEndTime = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.shiftEndTime}</span>
    } else {
      return <span>{request.shiftEndTime}</span>
    }
  };

  getDuration = request => { 
    let duration = this.getHours(request)
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{duration}</span>
    } else {
      return <span>{duration}</span>
    }
  };

  getReportSkillName = request => { 
    return request.skilllevel
  };

  getReportTeamStartTime = request => { 
    if(request.isPTO) {
      return request.shiftStartTime
    } else {
      return request.shiftStartTime
    }
  };

  getReportTeamEndTime = request => { 
    if(request.isPTO) {
      return request.shiftEndTime
    } else {
      return request.shiftEndTime
    }
  };

  getTeamStartTime = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.shiftStartTime}</span>
    } else {
      return <span>{request.shiftStartTime}</span>
    }
  };

  getTeamEndTime = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.shiftEndTime}</span>
    } else {
      return <span>{request.shiftEndTime}</span>
    }
  };

  getDuration = request => { 
    let duration = this.getHours(request)
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{duration}</span>
    } else {
      return <span>{duration}</span>
    }
  };

  getQHPName = request => { 
    if(request.isPTO) {
      return <span style={{color:"blue"}}>{request.qhpName}</span>
    } else if (request.qhpCode == -1 && request.isSourceShift) {
      return <span style={{color:"red"}}>{request.qhpName}</span>
    } else {
      return <span>{request.qhpName}</span>
    }
  };

  showHours = (time) => {
    return (time == "00:00:00" ? "24:00:00" : time)
  }

  getColumns = () => {
    let dollarUSLocale = Intl.NumberFormat('en-US');
    const openShiftTimeColumns = [
      {
        name: "DEPARTMENT NAME",
        sortable: true,
        selector: "departmentName",
        width: "200px"
      },
      {
        name: "TEAM NAME",
        sortable: true,
        selector: "teamName"
      },
      {
        name: "SHIFT DATE",
        selector: "shiftDateTime",
        cell: row => this.getShiftDateTime(row),
        sortable: true
      },
      {
        name: "SHIFT CREATED DATE",
        selector: "shiftCreatedDateTime",
        cell: row => this.getShiftDateCreatedTime(row),
        sortable: true
      },
      {
        name: "SHIFT SECURED DATE",
        selector: "shiftSecuredDateTime",
        sortable: true,
        cell: row => this.getShiftSecuredDateTime(row)
      },
      {
        name: "DIFFERENCE HOURS",
        selector: "differenceHours",
        sortable: true
      }
    ];

    const shiftDetailColumn = [
      {
        name: "SCHEDULED SHIFT COUNT",
        sortable: true,
        selector: "scheduledShiftCount",
        width: "310px"
      },
      {
        name: "OPEN SHIFT COUNT",
        sortable: true,
        selector: "openShiftCount",
        width: "310px"
      },
      {
        name: "SECURED SHIFT COUNT",
        selector: "securedShiftCount",
        sortable: true,
        width: "310px"
      },
      {
        name: "CURRENT OPEN SHIFT COUNT",
        selector: "currentOpenShiftCount",
        sortable: true,
        width: "310px"
      }
    ];

    const leaveReportColumn = [
      {
        name: "PROVIDER NAME",
        sortable: true,
        selector: "provider_Name",
        // hide: "md"
      },
      {
        name: "FROM DATE",
        sortable: true,
        selector: "fromDate",
        cell: row => this.getFromDate(row)
      },
      {
        name: "TO DATE",
        sortable: true,
        selector: "toDate",
        cell: row => this.getToDate(row)
      },
      {
        name: "STATUS",
        selector: "leaveStatus",
        cell: row => this.getStatus(row),
        sortable: false
      }
    ];

    const PTOCMELeaveReport = [
      {
        name: "PROVIDER NAME",
        sortable: true,
        selector: "qhpName",
        // hide: "md"
      },
      {
        name: "LEAVE TYPE",
        sortable: true,
        selector: "type"
      },
      {
        name: "DATE",
        sortable: true,
        selector: "date",
        cell: row => this.getDate(row)
      },
      {
        name: "START TIME",
        sortable: true,
        selector: "startTime"
      },
      {
        name: "DURATION",
        sortable: true,
        selector: "ptO_cme_hours"
      },
      {
        name: "TOTAL PTO",
        sortable: true,
        selector: "totalPTO"
      },
      {
        name: "TOTAL CME",
        sortable: true,
        selector: "totalCME"
      }
    ];
    
    let shiftReportColumnData = [
      {
        name: "PROVIDER NAME",
        selector: (this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' && this.state.selectedMultipleProvider.length) ? "qhpName" : "",
        sortable: true,
        // hide: "md"
      },
      {
        name: "FACILITY NAME",
        sortable: true,
        selector: "facilityName",
      },
      {
        name: "DEPARTMENT NAME",
        sortable: true,
        selector: "departmentName",
      },
      {
        name: "TEAM NAME",
        selector: "teamName",
        sortable: true
      },
      {
        name: "SHIFT DATE",
        cell: row => this.getShiftDate(row),
        selector: "shiftDate",
        sortable: true
      },
      {
        name: "START-TIME",
        selector: "shiftStartTime",
        cell: row => this.showHours(row.shiftStartTime),
        sortable: true
      },
      {
        name: "END-TIME",
        selector: "shiftEndTime",
        cell: row => this.showHours(row.shiftEndTime),
        sortable: true
      },
      {
        name: "HOURS",
        cell: row => row.regularShiftHours,//this.getHours(row),
        sortable: true,
        // width: "55px"
      },
      {
        name: "ADD-ON-HOURS",
        cell: row => row.addOnHours,//this.getHours(row),
        sortable: true,
        // width: "55px"
      },
      {
        name: "NPI",
        selector: "npi",
        sortable: true
      },
      {
        name: "HOURLY COST",
        // selector: "shiftCost",
        cell: row => `$ ${dollarUSLocale.format(row.shiftCost)}`,
        sortable: true
      },
      {
        name: "ADD-ON COST",
        // selector: "shiftCost",
        cell: row => `$ ${dollarUSLocale.format(row.addOnHourRate)}`,
        sortable: true
      },
      {
        name: "TOTAL COST",
        // selector: "totalCost",
        cell: row => `$ ${dollarUSLocale.format(row.totalCost)}`,
        sortable: true
      },
      {
        name: "COMMENT",
        // selector: "comment",
        cell: row => (row.comment ? row.comment : ""),
        // sortable: true
      }
    ];

    let shiftReportColumnForMoonLight = [
      {
        name: "PROVIDER NAME",
        selector: (this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' && this.state.selectedMultipleProvider.length) ? "qhpName" : "",
        sortable: true,
        // hide: "md"
      },
      {
        name: "FACILITY NAME",
        sortable: true,
        selector: "facilityName",
      },
      {
        name: "DEPARTMENT NAME",
        sortable: true,
        selector: "departmentName",
      },
      {
        name: "TEAM NAME",
        selector: "teamName",
        sortable: true
      },
      {
        name: "ADD-ON HOURS SHIFT",
        selector: "teamName",
        sortable: true,
        cell: row => (
          <Typography
            variant="overline"
            style={{
              height: 20,
              display: "inline-flex",
              padding: "4px 8px",
              flexGrow: 0,
              fontSize: 10,
              fontWeight: 600,
              minWidth: 20,
              alignItems: "center",
              flexShrink: 0,
              lineHeight: 10,
              whiteSpace: "nowrap",
              borderRadius: 5,
              backgroundColor: (row.addOnHours > 0 || row.isSourceShift) ? "#659658" : "#939393",
              color: "white",
              justifyContent: "center"
            }}
          >
            {row.addOnHours > 0 ? "ADD-ON" : row.isSourceShift ? "SOURCE" : "REGULAR"}
          </Typography>
        ),
      },
      {
        name: "SHIFT DATE",
        cell: row => this.getShiftDate(row),
        selector: "shiftDate",
        sortable: true
      },
      {
        name: "START-TIME",
        // selector: "shiftStartTime",
        cell: row => this.showHours(row.shiftStartTime),
        sortable: true
      },
      {
        name: "END-TIME",
        // selector: "shiftEndTime",
        cell: row => this.showHours(row.shiftEndTime),
        sortable: true
      },
      {
        name: "SHIFT HOURS",
        cell: row => row.regularShiftHours,//this.getHours(row),
        sortable: true
      },
      {
        name: "ADD-ON HOURS",
        cell: row => row.addOnHours,//this.getHours(row),
        sortable: true
      },
      {
        name: "NPI",
        selector: "npi",
        sortable: true
      },
      {
        name: "HOURLY COST",
        // selector: "shiftCost",
        cell: row => `$ ${dollarUSLocale.format(row.shiftCost)}`,
        sortable: true
      },
      {
        name: "ADD-ON COST",
        // selector: "shiftCost",
        cell: row => `$ ${dollarUSLocale.format(row.addOnHourRate)}`,
        sortable: true
      },
      {
        name: "TOTAL COST",
        // selector: "totalCost",
        cell: row => `$ ${dollarUSLocale.format(row.totalCost)}`,
        sortable: true
      },
      {
        name: "COMMENT",
        // selector: "comment",
        cell: row => (row.comment ? row.comment : ""),
        // sortable: true
      }
    ];
    
    const shiftReportColumn  = (this.state.selectedShiftType.value === null ? shiftReportColumnForMoonLight : shiftReportColumnData)
    
    const teamwiseReportColumn = [
      {
        name: "TEAM NAME",
        sortable: true,
        selector: "teamName"
      },
      {
        name: "SHIFT",
        sortable: true,
        cell: row => this.getShiftName(row)
      },
      {
        name: "SKILL",
        sortable: true,
        cell: row => this.getSkillName(row),
        selector: "skilllevel"
      },
      {
        name: "PROVIDER NAME",
        selector: "qhpName",
        cell: row => this.getQHPName(row),
        sortable: true
      },
      {
        name: "DATE",
        selector: "shiftDate",
        cell: row => this.getShiftDate(row),
        sortable: true
      },
      {
        name: "START-TIME",
        selector: "shiftStartTime",
        cell: row => this.getTeamStartTime(row),
        sortable: true
      },
      {
        name: "END-TIME",
        selector: "shiftEndTime",
        cell: row => this.getTeamEndTime(row),
        sortable: true,
      },
      {
        name: "DURATION",
        cell: row => row.shiftHours
      }
    ];

    const providerWeeklyReport = [
      {
        name: "PROVIDER NAME",
        selector: "qhpName",
        cell: row => this.getWeeklyQHPName(row),
        sortable: false
      },
      {
        name: "TEAM NAME",
        sortable: true,
        selector: "teamName"
      },
      {
        name: "SHIFT",
        sortable: true,
        cell: row => this.getShiftName(row)
      },
      {
        name: "SKILL",
        sortable: true,
        cell: row => row.skilllevel,
        // selector: "skilllevel"
      },
      
      {
        name: "DATE",
        selector: "shiftDate",
        cell: row => this.getShiftDate(row),
        sortable: true
      },
      {
        name: "START-TIME",
        selector: "shiftStartTime",
        sortable: true
      },
      {
        name: "END-TIME",
        selector: "shiftEndTime",
        sortable: true,
      },
      {
        name: "DURATION",
        selector: "duration",
        sortable: true
        // cell: row => this.getDuration(row)
      },
      {
        name: "TOTAL HOURS FOR WEEK",
        // selector: "totalCostOfWeek",
        cell: row => <b style={{fontSize:18, color:colorsCode[row.weekNumber]}}>{row.totalCostOfWeek}</b>,
        sortable: true
      },
    ];

    if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "timeToFillOpenShift"
    ) {
      this.setState({ currentColumn: openShiftTimeColumns });
    } else if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "shiftDetails"
    ) {
      this.setState({ currentColumn: shiftDetailColumn });
    } else if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "leaveReport"
    ) {
      this.setState({ currentColumn: leaveReportColumn });
    } else if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "PTOCMELeaveReport"
    ) {
      this.setState({ currentColumn: PTOCMELeaveReport });
    }  else if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "shiftReport"
    ) {
      this.setState({ currentColumn: shiftReportColumn });
    } else if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "teamwiseReport"
    ) {
      this.setState({ currentColumn: teamwiseReportColumn });
    } else if (
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "providerWeeklyReport"
    ) {
      this.setState({ currentColumn: providerWeeklyReport });
    }
  };

  fetchSelectedFacility = async () => {
    let selectedArray = [];
    this.state.selectedMultipleFacility.length && this.state.selectedMultipleFacility.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  fetchSelectedDepartment = async () => {
    let selectedArray = [];
    this.state.selectedMultipleDepartment.length && this.state.selectedMultipleDepartment.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  fetchSelectedProvider = async () => {
    let selectedArray = [];
    this.state.selectedMultipleProvider.length && this.state.selectedMultipleProvider.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  fetchSelectedTeam = async () => {
    let selectedArray = [];
    this.state.selectedMultipleTeam.length && this.state.selectedMultipleTeam.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  fetchSelectedDepartmentDetail = async () => {
    let selectedArray = '';
    this.state.selectedMultipleDepartment.length && this.state.selectedMultipleDepartment.forEach((value) => {
      selectedArray+=`departmentCodes=${value.value}&`
    });
    return selectedArray;
  }

  getSelectedFacility = () => {
    let selectedArray = [];
    this.state.selectedMultipleFacility.length && this.state.selectedMultipleFacility.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  getSelectedDepartment = () => {
    let selectedArray = [];
    this.state.selectedMultipleDepartment.length && this.state.selectedMultipleDepartment.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  getSelectedProvider = () => {
    let selectedArray = [];
    this.state.selectedMultipleProvider.length && this.state.selectedMultipleProvider.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }
  
  getSelectedTeam = () => {
    let selectedArray = [];
    this.state.selectedMultipleTeam.length && this.state.selectedMultipleTeam.forEach((value) => {
      selectedArray.push(value.value)
    });
    return selectedArray;
  }

  getEnable = () => {
    if ((
      this.state.selectedReportType &&
      this.state.selectedReportType.value === "shiftReport"
    )) {
      return (!this.state.selectedReportType ||
        !this.state.selectedMultipleFacility.length ||
        !this.state.selectedMultipleDepartment.length)
    } else if (this.state.selectedReportType && this.state.selectedReportType.value === 'teamwiseReport') {
      return (!this.state.selectedReportType || !this.state.selectedMultipleTeam.length)
    } else if (this.state.selectedReportType && this.state.selectedReportType.value === 'providerWeeklyReport'){
      return (!this.state.selectedReportType || !this.state.selectedProvider)
    } else if (this.state.selectedReportType && this.state.selectedReportType.value === 'PTOCMELeaveReport'){
      return (!this.state.selectedReportType || !this.state.selectedProvider || !this.state.selectedLeaveType)
    } else {
      return (!this.state.selectedReportType ||
        !this.state.selectedFacility ||
        !this.state.selectedReportType ||
        !this.state.selectedDepartment)
    }
  }

  render() {
    const customStyles = {
      control: base => ({
        ...base,
        minHeight: 30,
        minWidth:150
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 4
      }),
      clearIndicator: base => ({
        ...base,
        padding: 4
      }),
      multiValue: base => ({
        ...base
      }),
      valueContainer: base => ({
        ...base,
        padding: "0px 6px"
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0
      })
    };

    const {
      reports,
      selectedFacility,
      selectedReportType,
      selectedDepartment
    } = this.state;

    const { classes } = this.props;
    const { expanded } = this.state;

    const conditionalRowStyles = [
      {
        when: row => row.totalCostOfWeek > 0,
        style: {
          backgroundColor: 'rgba(202, 203, 204, 0.9)',
          color: 'white',
          '&:hover': {
            cursor: 'not-allowed',
          },
        },
      },
    ];

    return (
      <React.Fragment>
        <div className={classes.root}>
          <ExpansionPanel expanded={expanded === 'panel1'} >
            <ExpansionPanelSummary expandIcon={<IconButton onClick={this.handleChange('panel1')}><ExpandMoreIcon /></IconButton>}>
              <Typography variant="h3">{"Report"}</Typography>
              <Button size="medium" variant="contained" style={{
                backgroundColor: "#33a6db",
                color: "white",
                justifyContent: "center",
                textAlign: "center",
                opacity: this.getEnable()
                  ? 0.5
                  : 1,
              }} onClick={() => this.getReport(true)}
                className={classes.margin}>
                Export
                  </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={1}>
                <Grid item>
                  <Select
                    styles={customStyles}
                    defaultValue={this.state.selectedReportType}
                    value={this.state.selectedReportType}
                    onChange={this.onChangeReportType}
                    options={reportType}
                    placeholder={"Select report"}
                    isClearable
                    maxMenuHeight={100}
                  />
                </Grid>
                <Grid item>
                  {
                    this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' ? <MultiSelect
                      onChange={this.onChangeMultipleFacility}
                      value={this.state.selectedMultipleFacility}
                      options={this.state.facilities}
                      label={"Select facility"}
                      selectKey={'facility'}
                      selectedValues={this.getSelectedFacility()}
                    /> : <> 
                    {
                      this.state.selectedReportType && this.state.selectedReportType.value !== 'teamwiseReport' && this.state.selectedReportType.value !== 'providerWeeklyReport' && this.state.selectedReportType.value !== 'PTOCMELeaveReport' &&
                       <Select
                        styles={customStyles}
                        defaultValue={this.state.selectedFacility}
                        value={this.state.selectedFacility}
                        onChange={this.onChangeFacility}
                        options={this.state.facilities}
                        placeholder={"Select facility"}
                        isClearable
                        maxMenuHeight={100}
                      />
                    }
                    </>
                  }
                </Grid>
                <Grid item>
                  {
                    this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' ? <MultiSelect
                      onChange={this.onChangeMultipleDepartment}
                      value={this.state.selectedMultipleDepartment}
                      options={this.state.departments}
                      label={"Select departments"}
                      selectedValues={this.getSelectedDepartment()}
                      selectKey={'departments'}
                    /> :
                      <>
                        {
                          this.state.selectedReportType && this.state.selectedReportType.value !== 'teamwiseReport' && this.state.selectedReportType.value !== 'providerWeeklyReport' && this.state.selectedReportType.value !== 'PTOCMELeaveReport' &&
                          <Select
                          styles={customStyles}
                          defaultValue={this.state.selectedDepartment}
                          value={this.state.selectedDepartment}
                          onChange={this.onChangeDepartment}
                          options={this.state.departments}
                          placeholder={"Select department"}
                          isClearable
                          maxMenuHeight={100}
                        />
                      }
                    </>
                  }
                </Grid>
                {
                  this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' &&
                  <Grid item>
                    <MultiSelect
                      onChange={this.onChangeMultipleProvider}
                      value={this.state.selectedMultipleProvider}
                      options={this.state.providers}
                      label={"Select providers"}
                      selectedValues={this.getSelectedProvider()}
                      selectKey={'providers'}
                    />
                  </Grid>
                }
                {
                  this.state.selectedReportType && this.state.selectedReportType.value === 'shiftReport' && this.state.selectedReportType.value !== 'providerWeeklyReport' && this.state.selectedReportType.value !== 'PTOCMELeaveReport' && <Grid item><Select
                    styles={customStyles}
                    defaultValue={this.state.selectedShiftType}
                    value={this.state.selectedShiftType}
                    onChange={this.onChangeShiftType}
                    options={shiftType}
                    placeholder={"Select shift type"}
                    isClearable
                    maxMenuHeight={100}
                  />
                  </Grid>
                }
               
                {
                  this.state.selectedReportType && (this.state.selectedReportType.value === 'providerWeeklyReport' || this.state.selectedReportType.value === 'PTOCMELeaveReport') &&
                  <Grid item>
                    <Select
                      styles={customStyles}
                      defaultValue={this.state.selectedProvider}
                      value={this.state.selectedProvider}
                      onChange={this.onChangeProvider}
                      options={this.state.allProvider}
                      placeholder={"Select providers"}
                      isClearable
                      maxMenuHeight={100}
                    />
                  </Grid>
                }
                {
                  this.state.selectedReportType && this.state.selectedReportType.value === 'teamwiseReport' &&
                  <Grid item>
                    <MultiSelect
                      onChange={this.onChangeMultipleTeam}
                      value={this.state.selectedMultipleTeam}
                      options={this.state.teams}
                      label={"Select teams"}
                      selectedValues={this.getSelectedTeam()}
                      selectKey={'teams'}
                    />
                  </Grid>
                }
                {
                  this.state.selectedReportType && this.state.selectedReportType.value === 'PTOCMELeaveReport' &&
                  <Grid item>
                    <Select
                      styles={customStyles}
                      defaultValue={this.state.selectedLeaveType}
                      value={this.state.selectedLeaveType}
                      onChange={this.onChangeLeaveTypes}
                      options={this.state.leaveTypes}
                      placeholder={"Select Leave Type"}
                      isClearable
                      maxMenuHeight={100}
                    />
                  </Grid>
                }
                <Grid item style={{ paddingBottom: 20 }}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        format="MM-dd-yyyy"
                        disableFuture={false}
                        InputProps={{
                          disableUnderline: true,
                          classes: this.props.classes
                        }}
                        style={{ width: 160 }}
                        value={this.state.selectedStartDate}
                        InputAdornmentProps={{ shrink: true, position: "start" }}
                        allowKeyboardControl={false}
                        inputProps={{ readOnly: true }}
                        onChange={date => this.onChangeStartDate(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
                <Grid item style={{ paddingBottom: 20 }}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        format="MM-dd-yyyy"
                        disableFuture={false}
                        InputProps={{
                          classes: this.props.classes
                        }}
                        value={this.state.selectedEndDate}
                        InputAdornmentProps={{ shrink: true, position: "start" }}
                        inputProps={{ readOnly: true }}
                        minDate={this.state.minDate}
                        animateYearScrolling={true}
                        allowKeyboardControl={false}
                        style={{ width: 150 }}
                        onChange={date => this.onChangeEndDate(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
                <Grid item justify="center">
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => this.getReport(false)}
                    style={{
                      backgroundColor: "#33a5db",
                      color: "white",
                      marginRight: 5,
                      justifyContent: "center",
                      textAlign: "center",
                      opacity: this.getEnable()
                        ? 0.5
                        : 1,
                      fontSize: 8,
                      fontWeight: 600,
                      marginTop: 2,
                      paddingLeft: 1,
                      paddingRight: 1,
                      marginLeft: 10,
                      alignItems: "center"
                    }}
                    disabled={this.getEnable()}
                  >
                    Run
                </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={this.resetFilters}
                    style={{
                      backgroundColor: "#ff6262",
                      color: "white",
                      textAlign: "center",
                      marginLeft: 10,
                      fontSize: 8,
                      fontWeight: 600,
                      paddingLeft: 1,
                      paddingRight: 1,
                      marginTop: 2
                    }}
                  >
                    Reset
                </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <DataTable
            title={null}
            noHeader={true}
            subHeader={false}
            // subHeaderAlign={"left"}
            subHeaderComponent={null}
            columns={this.state.currentColumn}
            data={reports ? reports : []}
            defaultSortField="qhpName"
            sortIcon={<SortIcon />}
            pagination
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            paginationPerPage={10}
            persistTableHead={true}
            center={true}
            customStyles={customPaginationStyles}
            progressPending={this.state.isLoading}
            highlightOnHover
            // conditionalRowStyles={this.state.selectedReportType && this.state.selectedReportType.value === 'providerWeeklyReport' ? conditionalRowStyles: null}
            progressComponent={<div style={{ paddingTop: 150, textAlign: "center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent: "center", fontWeight: 500 }}>
              <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."} />
            </div>}
            noDataComponent={
              <div
                style={{
                  paddingTop: 150,
                  paddingBottom: 150,
                  fontSize: 14,
                  fontWeight: 500
                }}
              >
                No data found...
          </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setReportData: data =>
      dispatch({
        type: GET_REPORT_SUCCESS,
        reportPayload: data
      }),
    getReportData: () =>
      dispatch({
        type: GET_REPORT,
        reportPayload: []
      })
  };
};
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ReportDataTable));
