import React from "react";
import instance from "../../utils/services/services";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import Alert from "sweetalert2";
import moment from "moment";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Typography from "@material-ui/core/Typography";
import DataTable, { defaultThemes } from "react-data-table-component";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import { getUserType } from "../../settings/userSetting";
import LinearIndeterminate from "../../common/ListLoader";
import { convertTZ } from "../../settings/index";

const requestStatus = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Waiting for approval",
    value: "Open"
  },
  {
    label: "Approved",
    value: "UserApproved"
  },
  {
    label: "Denied",
    value: "UserDenied"
  }
];

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: '38px',
    }, 
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize:16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class DepartmentApprovalDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentRequests: [],
      userCode: null,
      departmentTypes:[],
      isLoading:false,  
      selectedRows: [],
      toggledClearRows: false,
      intervalId: null
    };
  }

  getAllDepartmentRequest = async () => {
    if(this.state.userCode) {
      // this.setState({isLoading:true});
      let isDepartment = getUserType() === "department";
      let isFacility = getUserType() === "facility";
      let isOrgUser = getUserType() === "organization";
      const response = await instance.get(
        `ShiftApproval/GetProviderShiftApprovalDetails`, {
          params: { code: this.state.userCode, isDepartmentUser: isDepartment, isFacilityUser:isFacility, isOrgUser:isOrgUser}
        }
      );
      const { data, status } = response.data;
      let requests = [];
      if (data && status) {
        response.data.data.forEach(element => {
          requests.push(element);
        });
        this.setState({ departmentRequests: requests },() => this.setState({isLoading:false}));
      } else {
        this.setState({ departmentRequests: requests },() => this.setState({isLoading:false}));
      }
    }
  };

  componentDidMount = () => {
    this.setState({ userCode: localStorage.getItem("userCode") }, () => {
      this.getAllDepartmentRequest();
    });
    var intervalId = setInterval(this.getAllDepartmentRequest.bind(), 9000);
    this.setState({intervalId: intervalId});
  };

  componentWillUnmount= () => {
    clearInterval(this.state.intervalId);
  }

  componentWillReceiveProps = async (nextProps, prevProps) => {
    if(nextProps.selectedDepartmentApprovalStatus !== this.props.selectedDepartmentApprovalStatus) {
      this.getAllDepartmentRequest()
    }
  }

  onClickUpdateStatus = (row, status) => {
    if (row.shiftApprovalId && status) {
      Alert.fire({
        title:
          status === "UserDenied"
            ? "Are you sure you want reject?"
            : "Are you sure you want accept?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: status === "UserDenied" ? "Reject" : "Accept",
        cancelButtonText: "Close"
      }).then(result => {
        if (result.value !== undefined && result.value) {
          let data = [{
            shiftApprovalId: row.shiftApprovalId,
            shiftId: row.shiftId,
            approvalStatus: status,
            code: this.state.userCode      
          }]

          instance
            .post(`ShiftApproval/UpdateShiftApproval`, data)
            .then(response => {
              if (!response.error && response.status && response.data.data.shiftApprovalOutput.shiftApprovalId !== undefined) {
                toast.success("Shift request updated successfully.");
                this.getAllDepartmentRequest();
              } else {
                toast.error(response.error);
              }
            })
            .catch(err => {
              // console.log(err);
            });
        }
      });
    } else {
      toast.error("something went wrong");
    }
  };

  onClickUpdateStatusAll = (status) => {
    if (this.state.selectedRows && this.state.selectedRows.length > 0 && status) {
      Alert.fire({
        title:
          status === "UserDenied"
            ? "Are you sure you want reject all?"
            : "Are you sure you want accept all?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: status === "UserDenied" ? "Reject" : "Accept",
        cancelButtonText: "Close"
      }).then(result => {
        if (result.value !== undefined && result.value) {
          
          let data = []
          this.state.selectedRows.forEach((shift, idx) => {
            data.push({
              shiftApprovalId: shift.shiftApprovalId,
              shiftId: shift.shiftId,
              approvalStatus: status,
              code: this.state.userCode      
            })
          })
          instance
            .post(`ShiftApproval/UpdateShiftApproval`, data)
            .then(response => {
              if (!response.error && response.status && response.data.data.shiftApprovalOutput.shiftApprovalId !== undefined) {
                toast.success("Shift request updated successfully.");
                this.getAllDepartmentRequest();
              } else {
                toast.error(response.error);
              }
            })
            .catch(err => {
              // console.log(err);
            });
        }
      });
    } else {
      toast.error("something went wrong");
    }
  };

  actionTemplate = request => {
    const opacity = request.approvalStatus !== "Open" ? 0.5 : 1;
    
    return (
      <div  style={{ }}>
        <div style={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={() =>
              this.onClickUpdateStatus(request, "UserApproved")
            }
            style={{
              backgroundColor: "#33a5db",
              color: "white",
              marginRight: 5,
              textAlign: "center",
              opacity: opacity
            }}
            disabled={request.approvalStatus !== "Open"}
          >
            Approve
          </Button>

          <Button
            variant="contained"
            className="button-cancel"
            style={{
              backgroundColor: "#ff6262",
              color: "white",
              marginRight: 5,
              textAlign: "center",
              opacity: opacity
            }}
            onClick={() =>
              this.onClickUpdateStatus(request, "UserDenied")
            }
            disabled={request.approvalStatus !== "Open"}
          >
            Reject
          </Button>
        </div>
      </div>
    );
  };

  getSkill = request => {
    return request.skill
      ? request.skill
      : "";
  };

  getStartTime = request => {
    return request.startTime
      ? request.startTime
      : "";
  };

  getEndTime = request => {
    return request.endTime
      ? request.endTime
      : "";
  };

  getToDate = request => {
    return request.departmentName
   ? request.departmentName
   : "";
 };

 getShiftDate = request => {
    return request.shiftDate
    ? moment(new Date(request.shiftDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
  };

  getSubmittedOnDate = request => {
    return request.submittedOn
    ? moment(new Date(request.submittedOn.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
  };

  getStatus = request => {
    switch (request.approvalStatus) {
      case "Open":
        return "Waiting for approval";
      case "UserApproved":
        return "Approved";
      case "UserDenied":
        return "Denied";
      default:
        return "Waiting for approval";
    }
  };
  
  handleChange = ({ selectedRows }) => {
    this.setState({selectedRows: selectedRows})
  }

  handleClearRows = () => {
    this.setState({toggledClearRows: !this.state.toggledClearRows})
  }

  render() {
    const rowDisabledCriteria = row => row.approvalStatus !== "Open";
    
    const columns = [
      {
        name: "PROVIDER NAME",
        cell: row => row.providerName,
        sortable: true,
        // hide: "md",
        selector: "providerName"
      },
      {
        name: "DEPARTMENT",
        sortable: true,
        cell: row => this.getToDate(row),
        selector: "departmentName"
      },
      {
        name: "TEAM NAME",
        sortable: true,
        selector: "teamName",
        cell: row => row.teamName
      },
      {
        name: "SKILL",
        sortable: true,
        cell: row => this.getSkill(row),
        selector: "skill",
        width: '100px'
      },
      {
        name: "SHIFT DATE",
        sortable: true,
        cell: row => this.getShiftDate(row),
        selector: "shiftDate"
      },
      {
        name: "START TIME",
        sortable: true,
        cell: row => this.getStartTime(row),
        width: '105px',
        selector:"startTime"
      },
      {
        name: "END TIME",
        sortable: true,
        cell: row => this.getEndTime(row),
        width: '105px',
        selector: "endTime"
      },
      {
        name: "SUBMITTED ON",
        sortable: true,
        cell: row => this.getSubmittedOnDate(row),
        selector: "submittedOn",
        width: '105px'
      },
      {
        name: "DURATION",
        sortable: true,
        cell: row => row.duration,
        width: '100px',
        selector: "duration"
      },
      {
        name: "STATUS",
        cell: row => <Typography
        variant="overline"
        style={{
          height: 20,
          display: "inline-flex",
          // padding: "4px 8px",
          paddingRight: '40px',
          flexGrow: 0,
          fontSize: 10,
          fontWeight: 600,
          minWidth: '105px',
          width: '105px',
          alignItems: "center",
          flexShrink: 0,
          lineHeight: 10,
          whiteSpace: "nowrap",
          borderRadius: 5,
          color: row.approvalStatus == 'Open' ? "#41b6e6" : row.approvalStatus == 'UserApproved' ? "#659658" : '#ff5151',
          justifyContent: "center"
        }}
      >
        {this.getStatus(row)}
      </Typography>,
        sortable: true,
        selector: "approvalStatus"
      },
      {
        name: "ACTION",
        cell: row => this.actionTemplate(row),
        ignoreRowClick: true,
        allowOverflow: true,
        width: '200px',
      }
    ];

    const { departmentRequests } = this.state;
    const { currentDepartmentApprovalStatus, currentDepartmentApprovalType } = this.props;
    let departmentRequestData = departmentRequests;
    let requests = currentDepartmentApprovalStatus
      ? departmentRequestData.filter(
          departmentRequest => departmentRequest.approvalStatus === currentDepartmentApprovalStatus
        )
      : departmentRequests;
      const contextActions = <>
      <div style={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={() =>
            this.onClickUpdateStatusAll("UserApproved")
          }
          style={{
            backgroundColor: "#33a5db",
            color: "white",
            marginRight: 5,
            textAlign: "center",
            zIndex:999
            // opacity: 1
          }}
        >
          Approve
        </Button>

        <Button
          variant="contained"
          className="button-cancel"
          style={{
            backgroundColor: "#ff6262",
            color: "white",
            marginRight: 5,
            textAlign: "center",
            zIndex:999
            // opacity: 1
          }}
          onClick={() =>
            this.onClickUpdateStatusAll("UserDenied")
          }
        >
          Reject
        </Button>
      </div>
    </>
    return (
      <>
        <DataTable
          title={
            <Grid container spacing={1}>
              <Grid item xs={1.5} md={1.5} sm={1.5}>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <h1
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    Shift Requests
                  </h1>
                </div>
              </Grid>
              <Grid item xs={9} md={9} sm={9}>
            
              </Grid>
            </Grid>
          }
          subHeader={true}
          subHeaderAlign={"left"}
          subHeaderComponent={
            <Grid container spacing={1}>
              <Grid item xs={6} md={6} sm={6} style={{ paddingTop: 15 }}></Grid>
              <Grid item xs={3} md={3} sm={3}>
            
              </Grid>
              <Grid item xs={3} md={3} sm={3}>
              <Select
                  styles={this.props.selectStyles}
                  defaultValue={this.props.selectedDepartmentApprovalStatus}
                  value={this.props.selectedDepartmentApprovalStatus}
                  onChange={this.props.handleDepartmentApprovalStatusChange}
                  options={requestStatus}
                />
              </Grid>
            </Grid>
          }
          columns={columns}
          contextActions={contextActions}
          selectableRows
          onSelectedRowsChange={this.handleChange}
          clearSelectedRows={this.toggledClearRows}
          selectableRowDisabled={rowDisabledCriteria}
          data={requests ? requests : []}
          defaultSortField="provider_Name"
          sortIcon={<SortIcon />}
          pagination
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          paginationPerPage={10}
          customStyles={customStyles}
          persistTableHead={true}
          progressPending={this.state.isLoading}
          highlightOnHover
          progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
            <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."}/>
          </div>}
          noDataComponent={
            <div style={{paddingTop:150, paddingBottom:150, fontSize:14, fontWeight:500}}>
              Shift request not found!
            </div>
          }
        />
      </>
    );
  }
}

export default DepartmentApprovalDataTable;
