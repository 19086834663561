import React from "react";
import { Switch } from "react-router-dom";
import { QhpRegistration } from "./../views/QhpRegistration";
import SignIn from "./../views/SignIn";
import PrivacyPolicy from './../views/PrivacyPolicy';
import Home from "./../views/Home";
import Users from "./../views/Users";
import Team from "./../views/Team";
import Dashboard from "./../views/Dashboard";
import GenericDashboard from "./../views/GenericDashboard";
import Facility from "./../views/Facility";
import Organization from "./../views/Organization";
import Department from "./../views/Department";
import Scheduler from "./../views/Scheduler";
import AppRoutes from "./AppRoutes";
import AuthRoute from "./AuthRoute";
import { connect } from "react-redux";
import SwapRequest from "./../views/SwapRequest";
import Report from "../views/Report";

class ProviderRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Switch>
        <AppRoutes exact path={`/`} component={SignIn} />
        <AuthRoute path="/login" component={SignIn}/>
        <AuthRoute path="/privacy-policy" component={PrivacyPolicy}/>
        <AuthRoute
          path={`/layout/register`}
          component={QhpRegistration}
        /> 
        <AppRoutes
          exact
          path={`/layout/home`}
          component={Home}
        />
        <AppRoutes
          exact
          path={`/layout/dashboard`}
          component={Dashboard}
        />
        <AppRoutes
          exact
          path={`/layout/generic-dashboard`}
          component={GenericDashboard}
        />
        <AppRoutes
          exact
          path={`/layout/users`}
          component={Users}
        />
        <AppRoutes
          exact
          path={`/layout/organization`}
          component={Organization}
        />
        <AppRoutes
          exact
          path={`/layout/department`}
          component={Department}
        />
        <AppRoutes
          exact
          path={`/layout/team`}
          component={Team}
        />
        <AppRoutes
          exact
          path={`/layout/scheduler`}
          component={Scheduler}
        />
        <AppRoutes
          exact
          path={`/layout/facility`}
          component={Facility}
        />
         <AppRoutes
          exact
          path={`/layout/swap-requests`}
          component={SwapRequest}
        />
        <AppRoutes
          exact
          path={`/layout/report`}
          component={Report}
        />
      </Switch>
    );
  }
}
const mapStateToProps = state => ({
  redirect: state.signin.redirect,
  isScheduler: state.scheduler.insideScheduler
});

ProviderRoutes = connect(
  mapStateToProps,
  null
)(ProviderRoutes);

export default ProviderRoutes;
