import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthHeader from "../containers/AuthHeader";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import SideNavigation from "../containers/SideNavigation";
import { getToken } from "../settings";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
        return (
          <div>
            <AuthHeader {...props} />
            <Component {...props} />
            <Footer {...props} />
          </div>
        );
    }}
  />
);

export default AuthRoute;
