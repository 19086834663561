import React from "react";

export default ({width = "26px", height = "26px"}) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
            <path d="M20.21,23.59 C18.8371654,23.4348547 17.4990196,24.090273 16.78,25.27 L16.59,25.21 C16.64,23.11 17.59,20.99 19.65,20.99 C20.5762569,20.9270417 21.3969135,21.5826986 21.54,22.5 L24.77,22.5 C24.4619022,19.9529742 22.2089918,18.0960833 19.65,18.28 C14.87,18.28 13.16,22.58 13.16,26.61 C13.16,28.52 13.59,34.74 19.06,34.74 C20.654951,34.8393314 22.216361,34.2525271 23.3511125,33.1273274 C24.485864,32.0021276 25.0858458,30.4457337 25,28.85 C25.0706099,27.515182 24.6038104,26.2076663 23.7038179,25.2193655 C22.8038253,24.2310647 21.545582,23.6442745 20.21,23.59 L20.21,23.59 Z M19.11,31.72 C18.4272608,31.7268236 17.7723966,31.449589 17.3020871,30.9546261 C16.8317775,30.4596632 16.5883334,29.7915007 16.63,29.11 C16.5443015,28.1413775 17.0126323,27.2073356 17.8400969,26.6965752 C18.6675615,26.1858149 19.7124385,26.1858149 20.5399031,26.6965752 C21.3673677,27.2073356 21.8356985,28.1413775 21.75,29.11 C21.7669803,29.812539 21.4932805,30.4909438 20.993534,30.9850114 C20.4937874,31.479079 19.8122991,31.7450079 19.11,31.72 L19.11,31.72 Z" fill="#1C3A54"></path>
            <path d="M39.92,26 L39.92,6.9 C39.8090054,5.2913617 38.5286383,4.01099463 36.92,3.9 L32.79,3.9 L32.79,5.2 C32.79,7.13299662 31.2229966,8.7 29.29,8.7 C27.3570034,8.7 25.79,7.13299662 25.79,5.2 L25.79,3.88 L13.62,3.88 L13.62,5.2 C13.62,7.13299662 12.0529966,8.7 10.12,8.7 C8.18700338,8.7 6.62,7.13299662 6.62,5.2 L6.62,3.88 L3,3.88 C1.3913617,3.99099463 0.110994629,5.2713617 0,6.88 L0,39.75 C0.106746618,41.3605684 1.38943161,42.6432534 3,42.75 L27.46,42.75 C29.4364484,46.8769691 33.6041724,49.5051234 38.18,49.51 L38.18,49.51 C44.2500925,49.3329356 49.2039414,44.5957877 49.6521606,38.5396771 C50.1003798,32.4835666 45.8978886,27.0687482 39.92,26 Z M26.45,39.55 L3.22,39.55 L3.22,14 L36.69,14 L36.69,26 C33.499226,26.3599164 30.5962341,28.0142987 28.6602642,30.5760558 C26.7242943,33.1378128 25.9252171,36.3821645 26.45,39.55 Z M38.21,46.62009 L38.21,46.62009 C34.5855572,46.6361966 31.3091708,44.464815 29.9118535,41.1205135 C28.5145362,37.776212 29.2721897,33.9193264 31.8307724,31.3521289 C34.3893551,28.7849314 38.2436722,28.014318 41.5926517,29.400386 C44.9416312,30.7864541 47.1240135,34.0555232 47.1200055,37.68 C47.0872826,42.60381 43.10381,46.5872826 38.18,46.62009 L38.21,46.62009 Z" fill="#1C3A54"></path>
            <path d="M10.12,7.2 C9.0154305,7.2 8.12,6.3045695 8.12,5.2 L8.12,2 C8.12,0.8954305 9.0154305,0 10.12,0 C11.2245695,0 12.12,0.8954305 12.12,2 L12.12,5.2 C12.12,6.3045695 11.2245695,7.2 10.12,7.2 Z" fill="#0EAFE6"></path>
            <path d="M29.29,7.2 C28.1854305,7.2 27.29,6.3045695 27.29,5.2 L27.29,2 C27.29,0.8954305 28.1854305,0 29.29,0 C30.3945695,0 31.29,0.8954305 31.29,2 L31.29,5.2 C31.29,6.3045695 30.3945695,7.2 29.29,7.2 Z" fill="#0EAFE6"></path>
            <path d="M38.89,43.36 C38.4575518,43.3535376 38.0581191,43.1274437 37.83,42.76 C37.53,42.27 35.7,39.18 35.44,38.76 C35.1610898,38.2771124 35.2388331,37.6674415 35.63,37.27 L40.41,32.53 C40.8965169,32.1192989 41.6170576,32.1517865 42.0646403,32.6046041 C42.512223,33.0574217 42.5363303,33.7782914 42.12,34.26 L38,38.31 L39.91,41.52 C40.2358401,42.0966965 40.0508979,42.8276588 39.49,43.18 C39.3119205,43.2974151 39.103304,43.36 38.89,43.36 Z" fill="#0EAFE6"></path>
        </g>
    </g>
  </svg>
);
