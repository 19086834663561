import {
  GET_FACILITY_DEPARTMENT,
  GET_FACILITY_DEPARTMENT_SUCCESS,
  GET_FACILITY_DEPARTMENT_FAIL,
  GET_TEAM_SCHEDULE_BLOCK,
  GET_TEAM_SCHEDULE_BLOCK_SUCCESS,
  GET_TEAM_SCHEDULE_BLOCK_FAIL,
  GET_TEAM_SKILLS,
  GET_TEAM_SKILLS_SUCCESS,
  GET_TEAM_SKILLS_FAIL,
  GET_PROVIDER_BY_DEPARTMENT_SKILL,
  GET_PROVIDER_BY_DEPARTMENT_SKILL_SUCCESS,
  GET_PROVIDER_BY_DEPARTMENT_SKILL_FAIL,
  GET_TEAM_SCHEDULE_DATES,
  GET_TEAM_SCHEDULE_DATES_SUCCESS,
  GET_TEAM_SCHEDULE_DATES_FAIL,
  ADD_PROVIDER,
  ADD_PROVIDER_SUCCESS,
  ADD_PROVIDER_FAIL,
  DELETE_PROVIDER,
  DELETE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_FAIL,
  POST_CREATE_BLOCK,
  POST_CREATE_BLOCK_SUCCESS,
  POST_CREATE_BLOCK_FAIL,
  GET_SAVE_SKILL,
  GET_SAVE_SKILL_SUCCESS,
  GET_SAVE_SKILL_FAIL,
  POST_OPEN_SHIFT,
  POST_OPEN_SHIFT_SUCCESS,
  POST_OPEN_SHIFT_FAIL,
  GET_ALL_PROVIDER,
  GET_ALL_PROVIDER_SUCCESS,
  GET_ALL_PROVIDER_FAIL,
  GET_SKILL_CEILING_RATE,
  GET_SKILL_CEILING_RATE_SUCCESS,
  GET_SKILL_CEILING_RATE_FAIL,
  GET_ALL_PROVIDER_SHIFT,
  GET_ALL_PROVIDER_SHIFT_SUCCESS,
  GET_ALL_PROVIDER_SHIFT_FAIL,
  POST_OPEN_SHIFT_SAVE,
  POST_OPEN_SHIFT_SAVE_SUCCESS,
  POST_OPEN_SHIFT_SAVE_FAIL,
  RESEND_NOTIFICATION,
  RESEND_NOTIFICATION_SUCCESS,
  RESEND_NOTIFICATION_FAIL 
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  facilityPayload: [],
  isFacilityLoaded: false,
  teamScheduleBlock: [],
  teamSkills: [],
  providersList: [],
  scheduleDates: [],
  scheduleWeekAndTime: [],
  providerPayload: [],
  getSaveSkills: [],
  createOpenShiftPayload: "",
  defaultFacility: [],
  defaultDepartment: [],
  allProvidersList:[],
  ceilingRate:null,
  allProvidersShiftList:[],
  postOpenShiftSave: "",
  resendNotificationPayoad:""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FACILITY_DEPARTMENT:
      return {
        ...state,
        isLoading: true,
        isFacilityLoaded: false
      };
    case GET_FACILITY_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        facilityPayload: action.facilityPayload.data,
        defaultFacility: action.facilityPayload.data.data
          ? action.facilityPayload.data.data
          : [],
        defaultDepartment: action.facilityPayload.data.length > 0 ? action.facilityPayload.data.data
          ? action.facilityPayload.data.data[0].department[1]
          : [] : [],
        isFacilityLoaded: true
      };
    case GET_FACILITY_DEPARTMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_TEAM_SCHEDULE_BLOCK:
      return {
        ...state,
        isLoading: true
      };
    case GET_TEAM_SCHEDULE_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teamScheduleBlock: action.payload.data.data
      };
    case GET_TEAM_SCHEDULE_BLOCK_FAIL:
      return {
        ...state,
        isLoading: false,
        teamScheduleBlock:[],
        error: action.error
      };
    case GET_TEAM_SKILLS:
      return {
        ...state,
        isLoading: true
      };
    case GET_TEAM_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teamSkills: action.payload.data.data
      };
    case GET_TEAM_SKILLS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_PROVIDER_BY_DEPARTMENT_SKILL:
      return {
        ...state,
        isLoading: true
      };
    case GET_PROVIDER_BY_DEPARTMENT_SKILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        providersList: action.payload.data.data ? action.payload.data.data :[]
      };
    case GET_PROVIDER_BY_DEPARTMENT_SKILL_FAIL:
      return {
        ...state,
        isLoading: false,
        providersList:[],
        error: action.error
      };
    case GET_TEAM_SCHEDULE_DATES:
      return {
        ...state,
        isLoading: true
      };
    case GET_TEAM_SCHEDULE_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleDates: action.payload.data.data,
        scheduleWeekAndTime: action.payload.data.data
      };
    case GET_TEAM_SCHEDULE_DATES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case ADD_PROVIDER:
      return {
        ...state,
        isLoading: true
      };
    case ADD_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        providerPayload: action.providerAddPayload
      };
    case ADD_PROVIDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case DELETE_PROVIDER:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        providerDeletePayload: action.providerDeletePayload
      };
    case DELETE_PROVIDER_FAIL:
      return {
        ...state,
        error: action.error
      };

    case POST_CREATE_BLOCK:
      return {
        ...state,
        isLoading: true
      };
    case POST_CREATE_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createBlockPayload: action.createBlockPayload.data
      };
    case POST_CREATE_BLOCK_FAIL:
      return {
        ...state,
        error: action.error
      };

    case GET_SAVE_SKILL:
      return {
        ...state,
        isLoading: true
      };

    case GET_SAVE_SKILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getSaveSkills: action.getSaveSkillPayload.data.data
      };
    case GET_SAVE_SKILL_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case POST_OPEN_SHIFT:
      return {
        ...state,
        isLoading: true
      };

    case POST_OPEN_SHIFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createOpenShiftPayload: action.createOpenShiftPayload
      };
    case POST_OPEN_SHIFT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_ALL_PROVIDER:
      return {
        ...state,
        isLoading: true
      };
    case GET_ALL_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allProvidersList: action.payload.data.data
      };
    case GET_ALL_PROVIDER_FAIL:
      return {
        ...state,
        isLoading: false,
        allProvidersList:[],
        error: action.error
      };
    case GET_SKILL_CEILING_RATE:
      return {
        ...state,
        isLoading: true
      };
    case GET_SKILL_CEILING_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ceilingRate: action.payload.data.data
      };
    case GET_SKILL_CEILING_RATE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
      case GET_ALL_PROVIDER_SHIFT:
        return {
          ...state,
          isLoading: true
        };
      case GET_ALL_PROVIDER_SHIFT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          allProvidersShiftList: action.payload.data.data
        };
      case GET_ALL_PROVIDER_SHIFT_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
      case POST_OPEN_SHIFT_SAVE:
        return {
          ...state,
          isLoading: true
        };
      case POST_OPEN_SHIFT_SAVE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          postOpenShiftSave: action.postOpenShiftSave
        };
      case POST_OPEN_SHIFT_SAVE_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
      case RESEND_NOTIFICATION:
        return {
          ...state,
          isLoading: true
        };
      case RESEND_NOTIFICATION_SUCCESS:
        return {
          ...state,
          isLoading: false,
          resendNotificationPayoad: action.resendNotificationPayoad
        };
      case RESEND_NOTIFICATION_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
    default:
      return state;
  }
};
