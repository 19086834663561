import React, { Fragment } from "react";
import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";

import { EventEmitter } from "../components/events";
import IconDepartment from "../assets/icon/IconDepartment";
import IconFacility from "../assets/icon/IconFacility";
import IconOrganization from "./../assets/icon/IconOrganization";
import IconReport from "../assets/icon/IconReport";
import IconScheduler from "../assets/icon/IconScheduler";
import IconSwap from "../assets/icon/IconSwap";
import IconTeam from "../assets/icon/IconTeam";
import IconUsers from "../assets/icon/IconUsers";
import IconDashboard from "../assets/icon/IconDashboard";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserType } from "../settings/userSetting";
import { routeToScheduler } from "../actions/layout";
import ReactTooltip from 'react-tooltip';

const faiconsStyle = {
  color: "#1b84c6"
};

class SideNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      isDepView: false,
      isUserSet: false,
      loginUserType: ""
    };
  }

  componentWillMount = async () => {
    this.setState({ loginUserType: getUserType() });
    if (
      (localStorage.getItem("isSchedulerView") &&
      localStorage.getItem("isSchedulerView") == "true") ||
      (localStorage.getItem("isSchedulerView") &&
      localStorage.getItem("isSchedulerView") == "generic")
    ) {
      this.setState({ isDepView: true });
    }
    EventEmitter.on("isDepViewToggle", event =>
      this.setState({ isDepView: event })
    );
  };

  toggleMenu = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  gotoScheduler = () => {
    this.props.routeToScheduler();
  };

  render() {
    const { loginUserType } = this.state;
    return (
      <Fragment>
        {loginUserType &&
        loginUserType !== "department" &&
        !this.state.isDepView ? (
          <div>
            <div className={"main-navigation"}>
              <SideNav className="nav-wrapper">
                <SideNav.Toggle
                  className="toggle-button"
                  onClick={this.toggleMenu}
                />
                <SideNav.Nav className="menubar">
                  {
                     loginUserType !== "department" && 
                      <Link to="/layout/dashboard" className="route-link">
                      <NavItem eventKey="dashboard">
                        <NavIcon data-tip="Dashboard">
                          <IconDashboard
                            className="faiconsStyle"
                            width="30px"
                            height="30px"
                          />
                        </NavIcon>
                        <NavText style={faiconsStyle}>
                          Dashboard
                        </NavText>
                      </NavItem>
                    </Link>
                  }
                  
                  <Link to="/layout/users" className="route-link">
                    <NavItem eventKey="users">
                      <NavIcon data-tip="Users and Permission">
                        <IconUsers
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>
                        Users and Permission
                      </NavText>
                    </NavItem>
                  </Link>
                  <Link to="/layout/organization" className="route-link">
                    <NavItem eventKey="Organization">
                      <NavIcon data-tip="Organization">
                        <IconOrganization
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Organization</NavText>
                    </NavItem>
                  </Link>

                  <Link to="/layout/facility" className="route-link">
                    <NavItem eventKey="Facility">
                      <NavIcon data-tip="Facility">
                        <IconFacility
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Facility</NavText>
                    </NavItem>
                  </Link>

                  <Link to="/layout/department" className="route-link">
                    <NavItem eventKey="Department">
                      <NavIcon data-tip="Department">
                        <IconDepartment
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Department</NavText>
                    </NavItem>
                  </Link>

                  <Link to="/layout/team" className="route-link">
                    <NavItem eventKey="Team">
                      <NavIcon data-tip="Team">
                        <IconTeam
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Team</NavText>
                    </NavItem>
                  </Link>

                  <Link to="/layout/scheduler" className="route-link">
                    <NavItem onClick={this.gotoScheduler} eventKey="Scheduler">
                      <NavIcon data-tip="Scheduler">
                        <IconScheduler
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Scheduler</NavText>
                    </NavItem>
                  </Link>
                  <Link to="/layout/swap-requests" className="route-link">
                    <NavItem
                      onClick={this.gotoScheduler}
                      eventKey="swap-requests"
                    >
                      <NavIcon data-tip="Approval Requests">
                        <IconSwap
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Approval Requests</NavText>
                    </NavItem>
                  </Link>
                  <Link to="/layout/report" className="route-link">
                    <NavItem eventKey="Report">
                      <NavIcon data-tip="Report">
                        <IconReport
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Report</NavText>
                    </NavItem>
                  </Link>
                </SideNav.Nav>
              </SideNav>
            </div>
          </div>
        ) : this.state.isDepView ? (
          <div className={"main-navigation"}>
            <SideNav className="nav-wrapper">
              <SideNav.Toggle
                className="toggle-button"
                onClick={this.toggleMenu}
              />
              <SideNav.Nav className="menubar">
                {
                  loginUserType == 'generic' && <Link to="/layout/generic-dashboard" className="route-link">
                  <NavItem eventKey="dashboard">
                    <NavIcon data-tip="Dashboard">
                      <IconDashboard
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>
                      Dashboard
                    </NavText>
                  </NavItem>
                </Link>
                }
               
                <Link to="/layout/scheduler" className="route-link">
                  <NavItem onClick={this.gotoScheduler} eventKey="Scheduler">
                    <NavIcon data-tip="Scheduler">
                      <IconScheduler
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>Scheduler</NavText>
                  </NavItem>
                </Link>
              </SideNav.Nav>
            </SideNav>
          </div>
        ) : (
          <div className={"main-navigation"}>
            <SideNav className="nav-wrapper">
              <SideNav.Toggle
                className="toggle-button"
                onClick={this.toggleMenu}
              />
              <SideNav.Nav className="menubar">
               {
                 loginUserType !== "department" && <Link to="/layout/dashboard" className="route-link">
                  <NavItem eventKey="dashboard">
                    <NavIcon data-tip="Dashboard">
                      <IconDashboard
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>
                      Dashboard
                    </NavText>
                  </NavItem>
                </Link>
               }
                <Link to="/layout/users" className="route-link">
                  <NavItem eventKey="users">
                    <NavIcon data-tip="Users and Permission">
                      <IconUsers
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>Users and Permission</NavText>
                  </NavItem>
                </Link>
                <Link to="/layout/team" className="route-link">
                  <NavItem eventKey="Team">
                    <NavIcon data-tip="Team">
                      <IconTeam
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>Team</NavText>
                  </NavItem>
                </Link>

                <Link to="/layout/scheduler" className="route-link">
                  <NavItem onClick={this.gotoScheduler} eventKey="Scheduler">
                    <NavIcon data-tip="Scheduler">
                      <IconScheduler
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>Scheduler</NavText>
                  </NavItem>
                </Link>
                <Link to="/layout/swap-requests" className="route-link">
                  <NavItem
                    onClick={this.gotoScheduler}
                    eventKey="swap-requests"
                  >
                    <NavIcon data-tip="Approval Requests">
                      <IconSwap
                        className="faiconsStyle"
                        width="30px"
                        height="30px"
                      />
                    </NavIcon>
                    <NavText style={faiconsStyle}>Approval Requests</NavText>
                  </NavItem>
                  <Link to="/layout/report" className="route-link">
                    <NavItem eventKey="Report">
                      <NavIcon data-tip="Report">
                        <IconReport
                          className="faiconsStyle"
                          width="30px"
                          height="30px"
                        />
                      </NavIcon>
                      <NavText style={faiconsStyle}>Report</NavText>
                    </NavItem>
                  </Link>
                </Link>
              </SideNav.Nav>
            </SideNav>
          </div>
        )}
        <ReactTooltip backgroundColor={'#1b84c6'} place={'left'}/>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ routeToScheduler }, dispatch);
};

export default connect(null, mapDispatchToProps)(SideNavigation);
