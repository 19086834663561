import React from "react";
import moment from 'moment';
import 'moment-timezone';

const DashboardListCard = (props) => {

  return (
    <React.Fragment>
      {
        props.listType && props.listType == 'upcommingLeave' && <div className="table-call-3" style={{width:"33%", minHeight:'100px'}}>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col" colSpan="2">{'Upcoming PTO/ leave'}</th>
              </tr>
            </thead>
            <tbody style={{minHeight: '140px', overflowY:'scroll'}}>
              {
                props.upcommingLeave && props.upcommingLeave.map((leave, leaveIndex) => {
                  return (
                    <tr key={"upcommingLeave"+leaveIndex.toString()}>
                      <td>{leave.providerName}</td>
                      <td>
                        <span className="calendar"><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5Z" fill="#007AD9" />
                        </svg>
                        </span>{`${moment(new Date(leave.fromDate)).utcOffset(0).format('DD-MMM-YYYY')} | ${moment(new Date(leave.toDate)).utcOffset(0).format('DD-MMM-YYYY')}`}</td>
                    </tr>
                  )
                })
              }
              {
                props.upcommingLeave == null || props.upcommingLeave.length == 0 && <tr>
                  <td style={{textAlign:"center"}}>
                    No data found 
                  </td>
               </tr>
              }
              {/* <tr>
                <td className="text-center" colSpan="2">
                  <a href="#" className="view-link">View All</a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      }
      {
        props.listType && props.listType == 'upcomingOpenShifts' &&<div className="table-call-3" style={{width:"33%", minHeight:'100px'}}>
        <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col" colSpan="2">{'New open shifts'}</th>
              </tr>
            </thead>
            <tbody style={{minHeight: '140px', overflowY:'scroll'}}>
              {
                props.upcomingOpenShifts && props.upcomingOpenShifts.map((openShifts, openShiftsIndex) => {
                  let startDate = moment(new Date(openShifts.openShiftDate)).utcOffset(0);
                  startDate.set({ hour: openShifts.startTime.split(":")[0], minute: openShifts.startTime.split(":")[1] });
                  startDate = startDate.format('DD-MMM-YYYY HH:mm')
                  let endDate = moment(new Date(openShifts.openShiftDate)).utcOffset(0)
                  endDate.set({ hour: openShifts.endTime.split(":")[0], minute: openShifts.endTime.split(":")[1] });
                  endDate = endDate.format('DD-MM-YYYY HH:mm')

                  return (
                    <tr key={"openShifts"+openShiftsIndex.toString()}>

                      <td>
                        <span className="calendar"><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5Z" fill="#007AD9" />
                        </svg>
                        </span>{`${startDate} | ${endDate}`}</td>
                    </tr>
                  )
                })
              }
              
              {
                props.upcomingOpenShifts == null || props.upcomingOpenShifts.length == 0 && <tr>
                  <td style={{textAlign:"center"}}>
                    No data found 
                  </td>
               </tr>
              }
              {/* <tr>
                <td className="text-center" colSpan="2">
                  <a href="#" className="view-link">View All</a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      }
      {
        props.listType && props.listType == 'leastPickedupShifts' && <div className="table-call-3" style={{width:"33%"}}>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col" colSpan="2">{'Priority open shifts'}
                  {/* <span className="heade-dots"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9.75C10.75 9.75 9.75 10.7812 9.75 12C9.75 13.25 10.75 14.25 12 14.25C13.2188 14.25 14.25 13.25 14.25 12C14.25 10.7812 13.2188 9.75 12 9.75ZM12 12.75C11.5625 12.75 11.25 12.4375 11.25 12C11.25 11.5938 11.5625 11.25 12 11.25C12.4062 11.25 12.75 11.5938 12.75 12C12.75 12.4375 12.4062 12.75 12 12.75ZM12 15.25C10.75 15.25 9.75 16.2812 9.75 17.5C9.75 18.75 10.75 19.75 12 19.75C13.2188 19.75 14.25 18.75 14.25 17.5C14.25 16.2812 13.2188 15.25 12 15.25ZM12 18.25C11.5625 18.25 11.25 17.9375 11.25 17.5C11.25 17.0938 11.5625 16.75 12 16.75C12.4062 16.75 12.75 17.0938 12.75 17.5C12.75 17.9375 12.4062 18.25 12 18.25ZM12 8.75C13.2188 8.75 14.25 7.75 14.25 6.5C14.25 5.28125 13.2188 4.25 12 4.25C10.75 4.25 9.75 5.28125 9.75 6.5C9.75 7.75 10.75 8.75 12 8.75ZM12 5.75C12.4062 5.75 12.75 6.09375 12.75 6.5C12.75 6.9375 12.4062 7.25 12 7.25C11.5625 7.25 11.25 6.9375 11.25 6.5C11.25 6.09375 11.5625 5.75 12 5.75Z" fill="white" />
                  </svg>
                  </span> */}
                </th>
              </tr>
            </thead>
            <tbody style={{maxHeight: '140px', overflowY: 'scroll'}}>
              {
                props.leastPickedupShifts && props.leastPickedupShifts.map((shifts, shiftsIndex) => {
                  let startDate = moment(new Date(shifts.date)).utcOffset(0);
                  startDate.set({ hour: shifts.startTime.split(":")[0], minute: shifts.startTime.split(":")[1] });
                  startDate = startDate.format('DD-MMM-YYYY HH:mm')
                  let endDate = moment(new Date(shifts.date)).utcOffset(0)
                  endDate.set({ hour: shifts.endTime.split(":")[0], minute: shifts.endTime.split(":")[1] });
                  endDate = endDate.format('DD-MM-YYYY HH:mm')

                  return (
                    <tr key={"leastPickedupShifts"+shiftsIndex.toString()}>
                      <td>
                        <span className="calendar">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5Z" fill="#007AD9" />
                        </svg>
                        </span>{`${startDate} | ${endDate}`}</td>
                    </tr>
                  )
                })
              }
              {
                props.leastPickedupShifts == null || props.leastPickedupShifts.length == 0 && <tr>
                  <td style={{textAlign:"center"}}>
                    No data found 
                  </td>
               </tr>
              }
              {/* <tr>
                <td className="text-center" colSpan="2">
                  <a href="#" className="view-link">View All</a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      }
    </React.Fragment>
  );
}

export default DashboardListCard;