import React, { Component } from "react";
import { EventEmitter } from "../components/events";
import SortIcon from "@material-ui/icons/ArrowDownward";
import instance from "../utils/services/services";
import DataTable, { defaultThemes } from "react-data-table-component";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import {getAccessLevel} from "../settings/userSetting";
import LinearIndeterminate from "../common/ListLoader";

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: "38px"
    }, 
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize:16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class OrgDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgData: [],
      selectedColumn: [],
      isAccess: false,
      isOrgUser: false,
      isDepUser: false,
      isFacUser: false,
      isLoading: false
    };
  }

  componentWillMount = async () => {
    EventEmitter.on("orgCreated", event => this.fetchOrganizations());
    await this.getColumns();
  }

  getOrganizationById = row => {
    instance
      .get(`Organization/GetById`, { params: { id: row.organizationId } })
      .then(res => {
        EventEmitter.emit("orgEdit", res.data.data);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  fetchOrganizations = async () => {
    this.setState({isLoading:true});
    await instance.get(`Organization/GetAll`).then(response => {
      if (response.status) {
        this.setState({
          orgData: response.data.data !== null ? response.data.data : []
        });
        this.setState({isLoading:false});
      } else {
        this.setState({isLoading:false});
      }
    });
  };

  componentDidMount() {
    this.fetchOrganizations();
  }

  getColumns = async () => {
    let columns = [
      {
        name: "ORGANIZATION",
        selector: "organizationName",
        sortable: true
      },
      {
        name: "ADDRESS",
        selector: "extension.address.addressline1",
        cell: row => row.extension.address && row.extension.address.addressline1 ? row.extension.address.addressline1 : "",
        sortable: true
      },
      {
        name: "CITY",
        selector: "extension.address.city",
        cell: row => row.extension.address && row.extension.address.city ? row.extension.address.city : "",
        sortable: true,
        // hide: "md"
      },
      {
        name: "STATE",
        selector: "extension.address.state",
        cell: row => row.extension.address && row.extension.address.state ? row.extension.address.state : "",
        sortable: true
      },
      {
        name: "ZIP CODE",
        selector: "extension.address.zip",
        cell: row => row.extension.address && row.extension.address.zip ? row.extension.address.zip : "",
        sortable: true,
        // hide: "md"
      }
      
    ];

    let editColumn = [{
      name: "ACTION",
      cell: row => (
        <Button
          startIcon={<EditIcon />}
          style={{
            backgroundColor: "#33a6db",
            color: "white",
            fontWeight: 500
          }}
          title={"Edit"}
          variant="contained"
          size="small"
          onClick={() => this.getOrganizationById(row)}
        >
          Edit
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }];
    
    if(getAccessLevel(1)) {
      let columnArray = [...columns, ...editColumn];
      this.setState({selectedColumn:columnArray});
    } else {
      this.setState({selectedColumn:columns});
    }
  }

  render() {
    return (
      <DataTable
        title={
          <Grid container spacing={1}>
            <Grid item xs={2} md={2} sm={2}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Organization
                </h1>
              </div>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
            <div style={{ justifyContent: "center", alignItems: "center", marginLeft: 30 }}>
              {getAccessLevel(1) ? (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      justifyContent: "center",
                      color: "white",
                      backgroundColor: "#33a6db",
                      fontSize: 8,
                      fontWeight: 600
                    }}
                    onClick={this.props.createNewOrganization}
                  >
                    <AddIcon />
                    Add
                  </Button>
                ):null}
                </div>
            </Grid>
          </Grid>
        }
        subHeader={true}
        columns={this.state.selectedColumn}
        data={this.state.orgData}
        defaultSortField="organizationName"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={this.state.isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
          <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."}/>
        </div>}
        noDataComponent={
          <div
            style={{
              paddingTop: 150,
              paddingBottom: 150,
              fontSize: 14,
              fontWeight: 500
            }}
          >
            Organizations not found!
          </div>
        }
      />
    );
  }
}

export default OrgDataTable;
