import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { EventEmitter } from "../components/events";
import InputAdornment from "@material-ui/core/InputAdornment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signIn } from "../actions/signin";
import instance from "../utils/services/services";
import { toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { clone } from "lodash";
import {isIncludingString} from  "../settings/index"

let skilllevel = [];

const labourTypes = [
  { label: "Select all", value: "all" },
  { label: "Per Diem", value: "PerDiem" },
  { label: "Locum", value: "Locum" },
  { label: "Employee", value: "Employee" },
  { label: "Volunteer", value: "Volunteer" }
];

export class AddDept extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: "",
      isUpdate: false,
      dept: "",
      rate: [],
      minrate: [],
      maxrate: [],
      skill: [],
      ranking: "",
      id: "",
      fac: "",
      SPL: "",
      userCode: 0,
      rows: [{ skill: {}, rate: "", minrate: "", maxrate: "" }],
      selectTest: "",
      isSubmitted: false,
      facs: [],
      spls: [],
      approvalRequest:false,
      selectedLabourTypes:[],
      searchField: null
    };
  }

  componentWillMount() {
    this.setState({ userCode: localStorage.getItem("userCode") });
  }

  componentDidMount() {
    this.getMasterSkillSet();
    this.getFacList();
    this.getSplList();

    EventEmitter.on("deptEdit", event => {
      this.setState({ isUpdate: true });
      this.editDept(event);
    });
    EventEmitter.on("deptModalClosed", event => this.setMode(event));
  }

  getMasterSkillSet() {
    var skillsArray = [];
    skilllevel = [];
    instance
      .get(`Organization/GetSkillLevels`)
      .then(response => {
        skillsArray = response.data.data.skillLevel;
        skillsArray.forEach(i => {
          skilllevel.push({
            label: i,
            value: i
          });
        });
      })
      .catch(error => {
        // console.log(error);
      });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  selectTest = event => {
    this.setState({ selectTest: event.value });
  };

  handleRowChange = (idx, name) => e => {
    let rows = this.state.rows;
    if (rows.length) {
      if (name === "skill") {
        rows[idx]["skill"] = e;
      } else if (name === "rate") {
        rows[idx]["rate"] = e.target.value;
      } else if (name === "maxrate") {
        rows[idx]["maxrate"] = e.target.value;
      } else if (name === "minrate") {
        rows[idx]["minrate"] = e.target.value;
      }
    }
    this.setState({
      rows
    });
  };

  handleAddRow = () => {
    const item = {
      skill: "",
      rate: ""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveRow = () => {
    this.setState(
      {
        rows: this.state.rows.slice(0, -1)
      },
      () => {
        if (this.state.rows.length() == 0) {
          this.handleAddRow();
        }
      }
    );
  };

  handleRemoveSpecificRow = idx => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    if (this.state.isUpdate) {
      this.unMapSkills(this.state.rows[idx].id);
    }
    this.setState({ rows }, () => {
      if (this.state.rows.length == 0) {
        this.handleAddRow();
      }
    });
  };

  getFacList = () => {
    var facs = [];
    instance
      .get(`Facility/GetFacilityDeparmentByUserCode?`, {
        params: { code: this.state.userCode }
      })
      .then(response => {
        response.data.data.forEach(i => {
          facs.push({
            label: i.facilityName,
            value: i.facilityId
          });
        });
        this.setState({ facs });
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getSplList = () => {
    var spls = [];
    instance
      .get(`Specialties/Get`)
      .then(response => {
        response.data.data.forEach(i => {
          spls.push({
            label: i,
            value: i
          });
        });
        this.setState({ spls });
      })
      .catch(error => {
        // console.log(error);
      });
  };

  setMode = state => {
    this.setState({ isUpdate: false });
  };

  unMapSkills = id => {
    instance
      .post(`Department/UnmapSkill?id=${id}`)
      .then(res => {
        // console.log(res);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  skillValidation = async () => {
    let status = true
    if(this.state.rows.length !== 0)  {
      this.state.rows.forEach((row) => {
        if(Object.keys(row.skill).length === 0 || row.rate == "" || row.minrate == "" || row.maxrate == "") {
          status = false
        }
      })
     return status;
    } else {
      return false
    }
  }

  updateDept = async () => {
    this.setState({ isSubmitted: false });
    const { dept, SPL, fac } = this.state;
    let status = await this.skillValidation()
    let dublicate = await this.validateSkill()
    if (dept.trim() === "" || SPL === "" || fac === "" || !status || !dublicate) {
      this.setState({ isSubmitted: true });
      return false;
    }
    let labourTypes = [];
    this.state.selectedLabourTypes.forEach(i => {
      labourTypes.push(i.value);
    });

    instance
      .post(`Department/Update`, {
        id: this.state.id,
        name: this.state.dept,
        facilityId: this.state.fac.value,
        specialty: this.state.SPL.label,
        labourType: this.state.approvalRequest ? labourTypes : [],
        extension: {
          facilityName: this.state.fac.label
        }
      })
      .then(res => {
        this.state.rows.forEach(row => {
          instance
            .post(`Department/MapSkill`, {
              skillLevel: row.skill.value,
              departmentId: this.state.id,
              ceilingPrice: row.rate,
              minPrice: row.minrate,
              maxPrice: row.maxrate
            })
            .then(res => {
            })
            .catch(error => {
              // console.log(error);
            });
        });
      }, this)
      .then(event => {
        EventEmitter.emit("deptCreated", event);
        toast.success("Department updated successfully.");
      })
      .catch(error => {
        // console.log(error);
      });
  };

  createDept = async () => {
    const { dept, SPL, fac } = this.state;
    let status = await this.skillValidation()
    let dublicate = await this.validateSkill()
    if (dept.trim() === "" || SPL === "" || fac === "" || !status || !dublicate) {
      this.setState({ isSubmitted: true });
      return false;
    }
    let labourTypes = [];
    this.state.selectedLabourTypes.forEach(i => {
      labourTypes.push(i.value);
    });
    instance
      .post(`Department/Create`, {
        name: this.state.dept,
        facilityId: this.state.fac.value,
        specialty: this.state.SPL.label,
        labourType: this.state.approvalRequest ? labourTypes : [],
        extension: {
          facilityName: this.state.fac.label
        }
      })
      .then(res => {
        this.setState(
          { id: res.data.data.departmentOutput.departmentId },
          () => {
            this.state.rows.forEach(row => {
              instance
                .post(`Department/MapSkill`, {
                  skillLevel: row.skill.value,
                  departmentId: this.state.id,
                  ceilingPrice: row.rate,
                  minPrice: row.minrate,
                  maxPrice: row.maxrate
                })
                .then(res => {
                  // console.log(res);
                })
                .catch(error => {
                  // console.log(error);
                });
            });
          }
        );
      }, this)
      .then(event => {
        EventEmitter.emit("deptCreated", event);
        toast.success("Department created successfully.");
      })
      .catch(error => {
        // console.log(error);
      });
  };

  closeModal = () => {
    EventEmitter.emit("closeModal", "");
  };

  validateSkill = async () => {
    if(this.state.rows.length > 0 && this.state.rows[0].skill) {
      let isValid = true;
      let selectedSkill = [];
      if(this.state.isUpdate) {
        this.state.rows.forEach((deptSkill, i) => {
          selectedSkill = this.state.rows.filter((row => row.skill.value === deptSkill.skill.value));
          if(selectedSkill.length > 1) {
            if(isValid) {
              toast.error("You have selected duplicate skill");
            }
            isValid = false;
          }
        });
      } else {
        this.state.rows.forEach((deptSkill, i) => {
          let selectedSkill = this.state.rows.filter((select => select.skill.value === deptSkill.skill.value));
          if(selectedSkill.length > 1) {
            if(isValid) {
              toast.error("You have selected duplicate skill");
            }
            isValid = false;
          }
        })
      }
      return isValid;
    } else {
      return true;
    }
  }

  getDeptSkillLevels(deptId) {
    var rows = [];
    this.setState({ rows: [] });
    instance
      .get(`Department/GetDepartmentSkillLevels`, { params: { id: deptId } })
      .then(response => {
        if (response.data.data.length) {
          response.data.data.forEach(i => {
            rows.push({
              skill: { label: i.skillLevel, value: i.skillLevel },
              rate: i.ceilingPrice,
              minrate: i.minPrice,
              maxrate: i.maxPrice,
              id: i.id
            });
          });
          this.setState({ rows });
        } else {
          this.handleAddRow();
        }
      })
      .catch(error => {
        // console.log(error);
      });
  }

  editDept = event => {
    let selectedLabour = [];
    event.labourTypes && event.labourTypes.forEach(i => {
      let selected = labourTypes.filter(function(obj) {
        return obj.value == i;
    })
      if(selected.length > 0) {
        selectedLabour.push(selected[0]);
      }
      
    });
  
    this.setState(
      {
        id: event.departmentId,
        dept: event.departmentName,
        selectedLabourTypes:selectedLabour,
        approvalRequest:(event.labourTypes && event.labourTypes.length > 0 ? true : false),
        SPL: { label: event.specialty, value: event.specialty },
        fac: { value: event.facilityId, label: event.extension.facilityName },
        isUpdate: true
      },
      () => this.getDeptSkillLevels(this.state.id)
    );
  };

  handleFacChange = fac => {
    this.setState({ fac });
  };

  handleSkillChange = skill => {
    this.setState({ skill });
  };

  handleSPLChange = SPL => {
    this.setState({ SPL });
  };

  onChangeApprovalRequest = (e) => {
    this.setState({approvalRequest: e.target.checked});
  }

  // onChangeLabourTypes = (labourType) => {
  //   this.setState({selectedLabourTypes: labourType})
  // }
  onChangeLabourTypes = (opt, { option }) => {
    let newOpts = opt;
    let string = this.state.searchField;

    if (option && option.value === "all") {
      let filteredOptions = clone(labourTypes);

      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );

      string = null;
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
    }
    this.setState({
      searchField: string,
      selectedLabourTypes: newOpts
    });
  };

  onInputChange = (string, { action }) => {
    if (action === "input-change") {
      this.setState({
        searchField: string
      });
    }
  };

  filterOption = ({ label, value }, string) => {
    if (value === "all") {
      return true;
    } else if (string) {
      return label.includes(string) || value.toString().includes(string);
    } else {
      return true;
    }
  };

  render() {
    const errorStyle = {
      color: "#FF0000",
      marginLeft:10
    };

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="department-name"
              label="Name"
              value={this.state.dept}
              onChange={this.handleChange("dept")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required
              error={this.state.isSubmitted && this.state.dept === ""}
            />
          </Grid>
          {this.state.isSubmitted && this.state.dept.trim() === "" ? (
              <h6 style={errorStyle}>Please enter department name</h6>
            ) : (
              <h6></h6>
            )}
          <Grid item xs={12} sm={12}>
            <Select
              styles={selectStyles}
              value={this.state.fac}
              onChange={this.handleFacChange}
              options={this.state.facs}
              placeholder="Select Facility"
              isSearchable={true}
              fullWidth
              isError={this.state.isSubmitted && this.state.fac === ""}
            />
          </Grid>
          {this.state.isSubmitted && this.state.fac === "" ? (
              <h6 style={errorStyle}>Please select facility</h6>
            ) : (
              <h6></h6>
            )}
          <Grid item xs={12} sm={12}>
            <Select
              className="dropdown_select"
              styles={selectStyles}
              value={this.state.SPL}
              onChange={this.handleSPLChange}
              options={this.state.spls}
              placeholder="Select Speciality"
              isSearchable={true}
              fullWidth
              isError={this.state.isSubmitted && this.state.SPL === ""}
            />
          </Grid>
          {this.state.isSubmitted && this.state.SPL === "" ? (
              <h6 style={errorStyle}>Please select speciality</h6>
            ) : (
              <h6></h6>
            )}
          <Grid item xs={12} sm={12}>
            <Switch
              checked={this.state.approvalRequest}
              onChange={this.onChangeApprovalRequest}
              value={this.state.approvalRequest}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
              onDoubleClick={()=> false}
            />
            <label
              htmlFor="approval-request"
              className="p-checkbox-label"
            >
              Approval Required
            </label>
          </Grid>
          {
            this.state.approvalRequest && <Grid  item xs={12} sm={12}>
              <Select
                isMulti={true}
                className="labour-type"
                style={{
                  width: 210,
                  marginLeft: 800,
                  textAlign: "center",
                  justifyContent: "center"
                }}
                filterOption={this.filterOption}
                onInputChange={this.onInputChange}
                onChange={this.onChangeLabourTypes}
                placeholder="Select labor type"
                options={labourTypes}
                fullWidth
                defaultValue={this.state.selectedLabourTypes}
                value={this.state.selectedLabourTypes}
                isError={this.state.isSubmitted && this.state.SPL === ""}
              />
            </Grid>
          }
          <Grid item xs={12} sm={12}>
            <div>
              <div className="container">
                <div className="row clearfix">
                  <div className="skills_container">
                    <table
                      className="table table-bordered table-hover"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="text-center"> # </th>
                          <th className="text-center"> Skill </th>
                          <th className="text-center"> Rate </th>
                          <th className="text-center"> Minimum Rate </th>
                          <th className="text-center"> Maximum Rate </th>
                          <th />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rows.map((item, idx) => (
                          <tr id={idx} key={idx}>
                            <td>{idx}</td>
                            <td>
                              <Select
                                name="skill"
                                value={this.state.rows[idx].skill}
                                onChange={this.handleRowChange(idx, "skill")}
                                options={skilllevel}
                                placeholder="Select Skill"

                                isSearchable={false}
                                isError={this.state.isSubmitted && Object.keys(this.state.rows[idx].skill).length === 0}
                              />
                              {this.state.isSubmitted && Object.keys(this.state.rows[idx].skill).length === 0 ? (
                                <h6 style={errorStyle}>Please select skill</h6>
                              ) : (
                                null
                              )}
                            </td>
                            <td>
                              <TextField
                                id={`department-rate${idx}`}
                                name="rate"
                                label="Rate"
                                type="number"
                                value={this.state.rows[idx].rate}
                                onChange={this.handleRowChange(idx, "rate")}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  )
                                }}
                                error={(this.state.isSubmitted && !this.state.rows[idx].rate)}
                              />
                               {(this.state.isSubmitted && !this.state.rows[idx].rate) ?
                                <h6 style={errorStyle}>Please select skill rate</h6> : null
                              }
                            </td>

                            <td>
                              <TextField
                                id={`department-minrate${idx}`}
                                name="minrate"
                                label="Min."
                                type="number"
                                value={this.state.rows[idx].minrate}
                                onChange={this.handleRowChange(idx, "minrate")}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  )
                                }}
                                error={(this.state.isSubmitted && !this.state.rows[idx].minrate)}
                              />
                              {(this.state.isSubmitted && !this.state.rows[idx].minrate) ?
                                <h6 style={errorStyle}>Please select minimum rate</h6> : null
                              }
                            </td>
                            <td>
                              <TextField
                                id={`department-maxrate${idx}`}
                                name="maxrate"
                                label="Max."
                                type="number"
                                value={this.state.rows[idx].maxrate}
                                onChange={this.handleRowChange(idx, "maxrate")}
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  )
                                }}
                                error={(this.state.isSubmitted && !this.state.rows[idx].maxrate)}
                              />
                              {(this.state.isSubmitted && !this.state.rows[idx].maxrate) ?
                                <h6 style={errorStyle}>Please select maximum rate</h6> : null
                              }
                            </td>

                            <td>
                              <button
                                onClick={this.handleAddRow}
                                className="btn btn-primary"
                              >
                                +{" "}
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={this.handleRemoveSpecificRow(idx)}
                              >
                                -
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <div className="button-wrapper primary">
            {this.state.isUpdate == false ? (
              <Button
                variant="contained"
                className="button-primary"
                color="primary"
                onClick={this.createDept}
              >
                CREATE
              </Button>
            ) : (
              <Button
                variant="contained"
                className="button-primary"
                color="secondary"
                onClick={this.updateDept}
              >
                UPDATE
              </Button>
            )}
          </div>

          <div className="button-wrapper">
            <Button
              variant="contained"
              className="button-cancel"
              color="secondary"
              onClick={this.closeModal}
            >
              {" "}
              CANCEL{" "}
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

const mapStateToProps = state => ({
  signInPayload: state.signin.signInPayload
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDept);
