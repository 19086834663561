import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import React from "react";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import jstz from "jstz";

const formatDate = date => {
  return moment(
    new Date(date),
    "ddd DD-MMM-YYYY, hh:mm A"
  ).utcOffset(0).format('MM/DD/YYYY')
  // let d = new Date(date);
  // d.setDate(d.getDate() + 1);

  // let month = "" + (d.getMonth() + 1);
  // let day = "" + d.getDate();
  // let year = d.getFullYear();

  // if (month.length < 2) month = "0" + month;
  // if (day.length < 2) day = "0" + day;
  // return [month, day, year].join("/");
};

const SelectDropdown = props => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  return (
    <form autoComplete="off" className={props.className}>
      <FormControl variant="outlined" disabled={props.disabled}>
        <InputLabel ref={inputLabel} htmlFor={props.id}>
          {props.label}
        </InputLabel>

        <Select
          value={props.selectedValue}
          onChange={props.handleChange}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              name={props.name}
              id={props.id}
            />
          }
          inputProps={{
            disabled:props.disabled
          }}
        >
          {props.defaultOption ? (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          ) : null}

          {props.facility
            ? props.name == "facility"
              ? props.facility.map(facility => {
                  return (
                    <MenuItem
                      key={facility.facilityCode}
                      value={
                        facility.facilityCode !== undefined
                          ? facility.facilityCode
                          : null
                      }
                    >
                      {facility.facilityName}
                    </MenuItem>
                  );
                })
              : ""
            : ""}

          {props.facility
            ? props.name == "department"
              ? props.facility
                  .filter(facility => {
                    return facility.facilityCode === parseInt(props.selected);
                  })
                  .map(facility => {
                    return facility.department.map(department => {
                      return (
                        <MenuItem
                          key={department.departmentCode}
                          value={
                            department.departmentCode !== undefined
                              ? department.departmentCode
                              : null
                          }
                          data-name={department.departmentName}
                        >
                          {department.departmentName}
                        </MenuItem>
                      );
                    });
                  })
              : ""
            : ""}

          {props.teams
            ? props.name === "teams"
              ? props.teams.map(team => {
                  return (
                    <MenuItem
                      key={team.teamId}
                      id={team.teamId}
                      value={team.teamCode !== undefined ? team.teamCode : null}
                      selected={team.teamId === parseInt(props.selected)}
                    >
                      {team.teamName}
                    </MenuItem>
                  );
                })
              : ""
            : ""}

          {props.skills
            ? props.name === "skills"
              ? props.skills.map((skill, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      id={idx}
                      value={skill}
                      selected={skill === parseInt(props.selected)}
                    >
                      {skill}
                    </MenuItem>
                  );
                })
              : ""
            : ""}
          {/* create new open shift modal */}
          {props.selectShiftSkills
            ? props.name === "shiftskills"
              ? props.selectShiftSkills.map((skill, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      id={idx}
                      value={skill}
                      selected={skill === parseInt(props.selected)}
                    >
                      {skill}
                    </MenuItem>
                  );
                })
              : ""
            : ""}
            {props.selectComment
              ? props.name === "comment"
                ? props.selectComment.map((comment, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        id={idx}
                        value={comment.value}
                        selected={comment.value === parseInt(props.selected)}
                      >
                        {comment.label}
                      </MenuItem>
                    );
                  })
                : ""
              : ""}
          {/* create new open shift modal over */}
          {props.scheduleBlock !== undefined && props.scheduleBlock.length > 0
            ? props.name == "scheduleBlock"
              ? props.scheduleBlock
                  .filter(scheduleBlock => {
                    return scheduleBlock.teamCode === parseInt(props.selected);
                  })
                  .map(scheduleBlock => {
                    return scheduleBlock.schedule.map(schedule => {
                      if(!schedule.isDeactivated) {
                        return (
                          <MenuItem
                            key={schedule.scheduleCode}
                            id={schedule.scheduleCode}
                            value={schedule.scheduleCode}
                            disabled={schedule.isDeactivated}
                            selected={schedule.scheduleCode !== undefined ? schedule.scheduleCode === parseInt(props.selectedValue): null}
                          >
                            {`${formatDate(schedule.startDate)} - ${formatDate(schedule.endDate)}`}
                          </MenuItem>
                        );
                      } else {
                        return null
                      }
                    });
                  })
              : ""
            : ""}
            
          {props.laborType
            ? props.name == "laborType"
              ? props.laborType.map((labour, keyIndex) => {
                  return (
                    <MenuItem key={keyIndex} value={labour.value}>
                      {labour.label}
                    </MenuItem>
                  );
                })
              : ""
          : ""}

          {props.getSavSkillsValues
            ? props.temp == "getSaveSkills"
              ? props.getSavSkillsValues.map(skills => {
                  return (
                    <MenuItem key={skills.skill} value={skills.skill}>
                      {skills.skill}
                    </MenuItem>
                  );
                })
              : ""
            : ""}
        </Select>
      </FormControl>
    </form>
  );
};

SelectDropdown.defaultProps = {
  defaultOption: true
};

export default SelectDropdown;
