//Users
export const SET_USER_PERMISSION = "SET_USER_PERMISSION";


// Layout
export const ROUTE_TO_SCHEDULER = "ROUTE_TO_SCHEDULER";


// Scheduler

export const GET_FACILITY_DEPARTMENT = "GET_FACILITY_DEPARTMENT";
export const GET_FACILITY_DEPARTMENT_SUCCESS = "GET_FACILITY_DEPARTMENT_SUCCESS";
export const GET_FACILITY_DEPARTMENT_FAIL = "GET_FACILITY_DEPARTMENT_FAIL";

export const GET_TEAM_SCHEDULE_BLOCK = "GET_TEAM_SCHEDULE_BLOCK_BLOCK";
export const GET_TEAM_SCHEDULE_BLOCK_SUCCESS = "GET_TEAM_SCHEDULE_BLOCK_SUCCESS";
export const GET_TEAM_SCHEDULE_BLOCK_FAIL = "GET_TEAM_SCHEDULE_BLOCK_FAIL";

export const GET_TEAM_SKILLS = 'GET_TEAM_SKILLS';
export const GET_TEAM_SKILLS_SUCCESS = 'GET_TEAM_SKILLS_SUCCESS';
export const GET_TEAM_SKILLS_FAIL = 'GET_TEAM_SKILLS_FAIL';


export const GET_PROVIDER_BY_DEPARTMENT_SKILL = 'GET_PROVIDER_BY_DEPARTMENT_SKILL'
export const GET_PROVIDER_BY_DEPARTMENT_SKILL_SUCCESS = 'GET_PROVIDER_BY_DEPARTMENT_SKILL_SUCCESS'
export const GET_PROVIDER_BY_DEPARTMENT_SKILL_FAIL = 'GET_PROVIDER_BY_DEPARTMENT_SKILL_FAIL'

export const GET_TEAM_SCHEDULE_DATES = "GET_TEAM_SCHEDULE_DATES";
export const GET_TEAM_SCHEDULE_DATES_SUCCESS = "GET_TEAM_SCHEDULE_DATES_SUCCESS";
export const GET_TEAM_SCHEDULE_DATES_FAIL = "GET_TEAM_SCHEDULE_DATES_FAIL";

// Post Provider to API

export const ADD_PROVIDER = "ADD_PROVIDER";
export const ADD_PROVIDER_SUCCESS = "ADD_PROVIDER_SUCCESS";
export const ADD_PROVIDER_FAIL = "ADD_PROVIDER_FAIL";

export const DELETE_PROVIDER = "DELETE_PROVIDER"
export const DELETE_PROVIDER_SUCCESS = "DELETE_PROVIDER_SUCCESS"
export const DELETE_PROVIDER_FAIL = "DELETE_PROVIDER_FAIL"

// Post CreateBlock to API

export const POST_CREATE_BLOCK = "POST_CREATE_BLOCK"
export const POST_CREATE_BLOCK_SUCCESS = "POST_CREATE_BLOCK_SUCCESS"
export const POST_CREATE_BLOCK_FAIL = "POST_CREATE_BLOCK_FAIL"


export const GET_SAVE_SKILL = "GET_SAVE_SKILL"
export const GET_SAVE_SKILL_SUCCESS = "GET_SAVE_SKILL_SUCCESS"
export const GET_SAVE_SKILL_FAIL = "GET_SAVE_SKILL_FAIL"

//SIGN IN
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";


export const POST_OPEN_SHIFT = "POST_OPEN_SHIFT"
export const POST_OPEN_SHIFT_SUCCESS = "POST_OPEN_SHIFT_SUCCESS"
export const POST_OPEN_SHIFT_FAIL = "POST_OPEN_SHIFT_FAIL"

export const GET_ALL_PROVIDER = "GET_ALL_PROVIDER"
export const GET_ALL_PROVIDER_SUCCESS = "GET_ALL_PROVIDER_SUCCESS"
export const GET_ALL_PROVIDER_FAIL = "GET_ALL_PROVIDER_FAIL"

//change skill rate
export const CHANGE_OPEN_SKILL_RATE= "CHANGE_OPEN_SKILL_RATE"
export const CHANGE_OPEN_SKILL_RATE_SUCCESS = "CHANGE_OPEN_SKILL_RATE_SUCCESS"
export const CHANGE_OPEN_SKILL_RATE_FAIL = "CHANGE_OPEN_SKILL_RATE_FAIL"

export const GET_SKILL_CEILING_RATE = "GET_SKILL_CEILING_RATE";
export const GET_SKILL_CEILING_RATE_SUCCESS = "GET_SKILL_CEILING_RATE_SUCCESS";
export const GET_SKILL_CEILING_RATE_FAIL = "GET_SKILL_CEILING_RATE_FAIL";

export const GET_ALL_PROVIDER_SHIFT = "GET_ALL_PROVIDER_SHIFT";
export const GET_ALL_PROVIDER_SHIFT_SUCCESS = "GET_ALL_PROVIDER_SHIFT_SUCCESS";
export const GET_ALL_PROVIDER_SHIFT_FAIL = "GET_ALL_PROVIDER_SHIFT_FAIL";

export const POST_OPEN_SHIFT_SAVE = "POST_OPEN_SHIFT_SAVE"
export const POST_OPEN_SHIFT_SAVE_SUCCESS = "POST_OPEN_SHIFT_SAVE_SUCCESS"
export const POST_OPEN_SHIFT_SAVE_FAIL = "POST_OPEN_SHIFT_SAVE_FAIL"

export const RESEND_NOTIFICATION = "RESEND_NOTIFICATION"
export const RESEND_NOTIFICATION_SUCCESS = "RESEND_NOTIFICATION_SUCCESS"
export const RESEND_NOTIFICATION_FAIL = "RESEND_NOTIFICATION_FAIL"

//report

export const GET_REPORT = "GET_REPORT"
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS"
export const GET_REPORT_FAIL = "GET_REPORT_FAIL"

//loader
export const SET_LOADING_TRUE = "SET_LOADING_TRUE"
export const SET_LOADING_FALSE = "SET_LOADING_FALSE"