import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import { EventEmitter } from "../components/events";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Select from "react-select";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signIn } from "../actions/signin";
import instance from "../utils/services/services";
import { toast } from "react-toastify";
import { GithubPicker } from 'react-color';
import reactCSS from 'reactcss'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core';

const colors = [
  '#4D4D4D', '#999999', '#A4DD00', '#AEA1FF', '#264348', '#4D8FAC', '#48929B', '#89C4F4', '#6C7A89',
  '#333333', '#808080', '#B0BC00', '#68BC00', '#16A5A5', '#7B64FF', '#FCC9B9', '#F47983', '#411f1f',
  '#666666', '#808900', '#194D33', '#0C797D', '#ffffdd', '#62760c', '#FFA07A', '#006a71', '#197163']
let facs = [];

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#daeff8',
  },
  barColorPrimary: {
    backgroundColor: '#33a5db',
  },
})(LinearProgress);

export class AddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: "",
      ST: "",
      ET: "",
      SD: "",
      ED: "",
      dep: "",
      fac: "",
      id: "",
      isUpdate: false,
      selectedItem: [],
      days: [],
      teaminfo: [],
      nameExists: false,
      skill: [],
      freqInterval: "0",
      skillList: [],
      userCode: 0,
      deptDisabled: true,
      skills: [],
      suggestionsDept: [],
      isSubmitted: false,
      displayColorPicker: false,
      color_Code: null,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
      rows:[],
      isSave:false
    };
  }

  componentWillMount() {
    this.setState({ userCode: localStorage.getItem("userCode") });
  }

  componentDidMount() {
    this.fetchTeam();
    this.getFacList();

    EventEmitter.on("teamEdit", event => this.editTeam(event));
    EventEmitter.on("teamModalClosed", event => this.setMode(event));

    this.setState({ deptDisabled: true });
  }

  fetchTeam = async () => {
    const response = await instance.get(`Team/GetAll`);
    this.setState({ teaminfo: response.data.data });
  };

  getMasterSkillSet(dep) {
    var skillsArray = [];
    var skills = [];
    if (dep.value) {
      instance
        .get(`Department/GetDepartmentSkillLevelsByCode?`, {
          params: { code: dep.value }
        })
        .then(response => {
          skillsArray = response.data.data;
          skillsArray.forEach(i => {
            skills.push({
              label: i,
              value: i
            });
          });
          this.setState({ skills });
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }

  getDeptList = () => {
    var suggestionsDept = [];
    this.setState({ deptDisabled: false });
    instance
      .get(`Facility/GetFacilityDeparmentByUserCode?`, {
        params: { code: this.state.userCode }
      })
      .then(response => {
        var selectedFacArray = response.data.data.filter(
          c => c.facilityName == this.state.fac.label
        )[0];
        selectedFacArray.department.forEach(dept => {
          suggestionsDept.push({
            label: dept.departmentName,
            value: dept.departmentCode
          });
        });
        this.setState({ suggestionsDept });
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getFacList = () => {
    facs = [];
    instance
      .get(`Facility/GetFacilityDeparmentByUserCode?`, {
        params: { code: this.state.userCode }
      })
      .then(response => {
        response.data.data.forEach(i => {
          facs.push({
            label: i.facilityName,
            value: i.facilityCode
          });
        });
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      if (name == "team") {
        this.checkNameAvailability();
      }
    });
  };

  handleDays = (event, newDays) => {
    var sum = 0;
    newDays.forEach(i => {
      if (i !== "") {
        sum = sum + parseInt(i);
      }
    });
    this.setState({ days: newDays });
    this.setState({ freqInterval: sum.toString() });
  };

  checkNameAvailability = () => {
    this.setState({ nameExists: false });
    this.state.teaminfo.forEach(team => {
      if (this.state.isUpdate) {
        if (
          this.state.team.toLowerCase() === team.teamName.toLowerCase() &&
          team.teamId !== this.state.id
        ) {
          this.setState({ nameExists: true });
        }
      } else {
        if (this.state.team.toLowerCase() === team.teamName.toLowerCase()) {
          this.setState({ nameExists: true });
        }
      }
    });
  };

  closeModal = () => {
    EventEmitter.emit("closeModal", "");
  };

  handleSkillChange = async skill => {
    let selectedSkill = skill !== null ? skill : [];
    this.setState({ skill: selectedSkill }, () => {
      if (this.state.skill.length === 0) {
        this.getMasterSkillSet(this.state.dep);
      }
    });
  };

  handleDeptChange = dep => {
    this.setState({ dep, skill: [] }, () => this.getMasterSkillSet(this.state.dep));
  };

  handleFacChange = fac => {
    this.setState({ fac, dep: [], skill: [] }, () => {
      this.getDeptList();
    });
  };

  setMode = state => {
    this.setState({ isUpdate: state });
  };

  updateTeam = () => {
    // const teamStartTimeTemp = moment([this.state.ST.split(":")[0],this.state.ST.split(":")[1]], "HH:mm")
    // const teamEndTimeTemp = moment([this.state.ET.split(":")[0],this.state.ET.split(":")[1]], "HH:mm")
    if (
      this.state.nameExists === true ||
      this.state.team.trim() === "" ||
      this.state.fac.value === "" ||
      this.state.dep.value === "" ||
      this.state.skill.length === 0 ||
      this.state.ST.trim() === "" ||
      this.state.ET.trim() === "" ||
      this.state.days.length === 0 
      // (this.state.ST && this.state.ET && (!moment(teamStartTimeTemp).isSameOrBefore(teamEndTimeTemp, 'hour') && !moment(teamStartTimeTemp).isSameOrBefore(teamEndTimeTemp, 'minute'))) ||
      // (this.state.ET && this.state.ST && (moment(teamEndTimeTemp).isSameOrBefore(teamStartTimeTemp, 'hour') && moment(teamEndTimeTemp).isSameOrBefore(teamStartTimeTemp, 'minute')))
    ) {
      this.setState({ isSubmitted: true });
      return false;
    }
    var skillArray = this.state.skill;
    var skillList = [];
    skillArray.forEach(i => {
      skillList.push(i.value);
    });

    this.setState({ skillList: skillList }, () => {
      instance
        .post(`Team/UpdateTeam`, {
          id: this.state.id,
          name: this.state.team,
          skillLevel: this.state.skillList,
          startTime: this.state.ST,
          endTime: this.state.ET,
          freqInterval: this.state.freqInterval,
          freqIntervalText: this.state.days,
          departmentName: this.state.dep.label,
          departmentCode: this.state.dep.value,
          facilityName: this.state.fac.label,
          facilityCode: this.state.fac.value,
          color_Code: this.state.color_Code
        })
        .then(res => {
          // console.log(res);
        }, this)
        .then(event => {
          EventEmitter.emit("teamCreated", event);
          toast.success("Team updated successfully.");
          this.closeModal();
        })
        .catch(error => {
          // console.log(error);
        });
    });
  };

  createTeam = () => {
    // const teamStartTimeTemp = moment([this.state.ST.split(":")[0],this.state.ST.split(":")[1]], "HH:mm")
    // const teamEndTimeTemp = moment([this.state.ET.split(":")[0],this.state.ET.split(":")[1]], "HH:mm")
 
    if (
      this.state.nameExists === true ||
      this.state.team.trim() === "" ||
      this.state.fac.value === "" ||
      this.state.dep.value === "" ||
      this.state.skill.length === 0 ||
      this.state.ST.trim() === "" ||
      this.state.ET.trim() === "" ||
      this.state.days.length === 0 
      // (this.state.ST && this.state.ET && (moment(teamEndTimeTemp).isBefore(teamStartTimeTemp, 'hour') && moment(teamEndTimeTemp).isBefore(teamStartTimeTemp, 'minute'))) ||
      // (this.state.ET && this.state.ST && (!moment(teamStartTimeTemp).isSameOrBefore(teamEndTimeTemp, 'hour') && !moment(teamStartTimeTemp).isSameOrBefore(teamEndTimeTemp, 'minute')))
    ) {
      this.setState({ isSubmitted: true });
      return false;
    }
    
    var skillArray = this.state.skill;
    var skillList = [];
    skillArray.forEach(i => {
      skillList.push(i.value);
    });

    this.setState({ skillList: skillList }, () => {
      instance
        .post(`Team/CreateTeam`, {
          name: this.state.team,
          skillLevel: this.state.skillList,
          startTime: this.state.ST,
          endTime: this.state.ET,
          freqInterval: this.state.freqInterval,
          freqIntervalText: this.state.days,
          departmentName: this.state.dep.label,
          departmentCode: this.state.dep.value,
          facilityName: this.state.fac.label,
          facilityCode: this.state.fac.value,
          color_Code: this.state.color_Code
        })
        .then(res => {
          // console.log(res);
        }, this)
        .then(event => {
          EventEmitter.emit("teamCreated", event);
          toast.success("Team created successfully.");
          this.closeModal();
        })
        .catch(error => {
          // console.log(error);
        });
    });
  };

  editTeam = event => {
    var skillList = [];
    this.setState({ deptDisabled: false });

    this.setState({ isUpdate: true }, () => {
      this.setState({
        id: event.data.data.teamId,
        team: event.data.data.teamName,

        ST: event.data.data.scheduler.startTime,
        ET: event.data.data.scheduler.endTime,
        days: event.data.data.scheduler.freqIntervalText,
        color_Code: event.data.data.color_Code,
        fac: {
          label: event.data.data.facilityName,
          value: event.data.data.facilityCode
        },
        dep: {
          label: event.data.data.departmentName,
          value: event.data.data.departmentCode
        },
        rows: event.data.data.schedulerBlock
      });
      event.data.data.skillLevel.forEach(i => {
        skillList.push({
          label: i,
          value: i
        });
      });
      this.setState({ skill: skillList });
    });
  };

  handleColorOpen = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  onChangeColorFromInput = () => {
    this.setState({ color_Code: null }, () => {
      this.handleColorOpen()
    })
  }

  handleColorChange = (color_Code) => {
    this.setState({ color_Code: color_Code ? color_Code.hex : ''}, () => {
      this.handleColorOpen()
    })
  }

  handleRowChange = (index, value) => {
    let scheduleBlockArray = this.state.rows;
    scheduleBlockArray[index].isDeactivated = value;
    this.setState({isSave:true});
    instance
      .post(`Scheduler/ActivateDeactivateSchedulerBlock?schedulerId=${scheduleBlockArray[index].schedulerId}&isDeactivated=${value}`)
      .then(response => {
        this.setState({isSave:false});
        if (!response.data.error && response.data.status && response.data.data.schedulerId !== undefined) {
          this.setState({rows:scheduleBlockArray});
          toast.success("Scheduler block updated successfully.");
        } else {
          toast.error(response.data.error.message);
        }
      })
      .catch(err => {
        this.setState({isSave:false});
        // console.log(err);
      });
  }

  render() {
    const basicStyle = {
      marginTop: "5%"
    };

    const errorStyle = {
      color: "#FF0000",
      paddingLeft:10
    };

    const selectStyles = {
      menu: styles =>
        !this.state.fac.value && this.state.isSubmitted
          ? { ...styles, zIndex: 999, borderColor: "red" }
          : { ...styles, zIndex: 999 }
    };

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          justifyContent: "center",
          alignItem: "center"
        },
      },
    });
    const teamStartTimeTemp = moment([this.state.ST.split(":")[0],this.state.ST.split(":")[1]], "HH:mm")
    const teamEndTimeTemp = moment([this.state.ET.split(":")[0],this.state.ET.split(":")[1]], "HH:mm")
    return (
      <React.Fragment>
        <div style={{paddingTop:0, paddingBottom:15}}>
          {
              this.state.isSave && <ColorLinearProgress />
          }
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-dense-team"
              label="Team"
              value={this.state.team}
              error={
                (this.state.nameExists === true || !this.state.team) &&
                this.state.isSubmitted
              }
              onChange={this.handleChange("team")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 15 }}
              required
            />
          </Grid>
          {this.state.nameExists === true &&
              this.state.team.trim() &&
              this.state.isSubmitted && (
                <h6 style={errorStyle}>Team name already exists</h6>
              )}
            {this.state.team.trim() === "" && this.state.isSubmitted && (
              <h6 style={errorStyle}>Enter team name</h6>
            )}
          <Grid item xs={12} sm={12}>
            <Select
              styles={selectStyles}
              value={this.state.fac}
              onChange={this.handleFacChange}
              options={facs}
              placeholder="Select Facility"
              isSearchable={true}
              required
              error={!this.state.fac.value && this.state.isSubmitted}
            />
          </Grid>
            {!this.state.fac.value && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please select facility</h6>
            )}
          <Grid item xs={12} sm={12}>
            <Select
              styles={selectStyles}
              value={this.state.dep}
              onChange={this.handleDeptChange}
              options={this.state.suggestionsDept}
              placeholder="Select a department"
              isSearchable={true}
              isDisabled={this.state.deptDisabled}
              required
              error={!this.state.dep.value && this.state.isSubmitted}
            />
          </Grid>
          {!this.state.dep.value && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please select department</h6>
            )}
          <Grid container item xs={12} sm={12}>
            <Select
              styles={selectStyles}
              value={this.state.skill}
              onChange={this.handleSkillChange}
              options={this.state.skills}
              placeholder="Select associated skill sets"
              isSearchable={true}
              required
              // style={{display:"inherit"}}
              error={this.state.skill.length === 0 && this.state.isSubmitted}
              isMulti={true}
            />
          </Grid>
          {this.state.skill.length === 0 && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please select associated skill sets</h6>
            )}
         

          <Grid container spacing={1} item xs={12} sm={12}>
          <Grid container item xs={6} sm={6}>
            <TextField
              id="ST"
              label="Start Time"
              type="time"
              value={this.state.ST}
              // fullWidth
              onChange={(e)=>{
                this.setState({ST:e.target.value})
              }}
              InputLabelProps={{
                shrink: true
              }}
              margin="dense"
              variant="outlined"
              inputProps={{
                step: 1800 // 30 min
              }}
              required
              error={(this.state.ST === true && this.state.isSubmitted)}
            />
            {/* {(this.state.ST && this.state.ET && (!moment(teamStartTimeTemp).isSameOrBefore(teamEndTimeTemp, 'hour') && !moment(teamStartTimeTemp).isSameOrBefore(teamEndTimeTemp, 'minute')) ?
              <h6 style={errorStyle}>Start time should not be after maximal time.</h6> : null
            )} */}
            {!this.state.ST && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please enter start time</h6>
            )}
           </Grid>
           <Grid container item xs={6} sm={6}>
        
            <TextField
              id="ET"
              label="End Time"
              type="time"
              value={this.state.ET}
              // fullWidth
              margin="dense"
              variant="outlined"
              onChange={(e)=> {
                this.setState({ET:e.target.value})
              }}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 1800 // 30 min
              }}
              required
              error={(this.state.ET === true && this.state.isSubmitted)}
            />
          
            {!this.state.ET && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please enter End Time</h6>
            )}

            {/* {(this.state.ET && this.state.ST && (moment(teamEndTimeTemp).isBefore(teamStartTimeTemp,'hour') && moment(teamEndTimeTemp).isBefore(teamStartTimeTemp, 'minute')) ?
              <h6 style={errorStyle}>End time should not be before minimal time.</h6> : null
            )} */}
            </Grid>
            </Grid>
            <Grid container spacing={1} item xs={12} sm={12}>
          <Grid container item xs={6} sm={6} style={{display: 'block'}}>
            <h6>Shift Days</h6>
            <ToggleButtonGroup
              value={this.state.days}
              onChange={this.handleDays}
            >
              <ToggleButton value="0">S</ToggleButton>
              <ToggleButton value="1">M</ToggleButton>
              <ToggleButton value="2">T</ToggleButton>
              <ToggleButton value="3">W</ToggleButton>
              <ToggleButton value="4">T</ToggleButton>
              <ToggleButton value="5">F</ToggleButton>
              <ToggleButton value="6">S</ToggleButton>
            </ToggleButtonGroup>
            {this.state.days.length === 0 && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please select shift Days</h6>
            )}
          </Grid>
          <Grid container item xs={6} sm={6}>
            <TextField
              id="colorCode"
              label="Select Color"
              type="text"
              value={this.state.color_Code}
              // fullWidth
              margin="dense"
              variant="outlined"
              onFocus={this.handleColorOpen}
              onChange={this.onChangeColorFromInput}
              InputLabelProps={{
                shrink: true
              }}
              autoComplete='off'
              required
              error={this.state.color_Code === true && this.state.isSubmitted}
            />
            {!this.state.color_Code && this.state.isSubmitted && (
              <h6 style={errorStyle}>Please select color code</h6>
            )}
            {this.state.displayColorPicker ?
              <div style={styles.popover}>
                <GithubPicker 
                width={250}
                value={this.state.color_Code ? this.state.color_Code : ''}
                colors={colors} color={this.state.color_Code ? this.state.color_Code : ''}
                onChange={this.handleColorChange} />
              </div>
              : null}
          </Grid>
          </Grid>
          { this.state.isUpdate && 
            <Grid item xs={12} sm={12}>
                <div className="container team">
                  <div className="row clearfix">
                    <label>Scheduler Blocks</label>
                    <div className="">
                      <table
                        className="table table-bordered table-hover team-schedule-blocks"
                        id=""
                      >
                        <thead>
                          <tr>
                            <th> # </th>
                            <th> Date</th>
                            <th> Activated </th>
                            <th> De-Activated </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rows.length > 0 ? this.state.rows.map((item, idx) => (
                            <tr id={idx} key={idx}>
                              <td>{idx+1}</td>
                              <td>
                                <div>
                                <Typography variant="h6">{this.state.rows[idx].startDate ? moment(new Date(this.state.rows[idx].startDate.split("T")[0]),"ddd DD-MMM-YYYY, hh:mm A").add(1, "days").format("MM-DD-YYYY") : null} {"-"} {this.state.rows[idx].endDate ? moment(new Date(this.state.rows[idx].endDate.split("T")[0]),"ddd DD-MMM-YYYY, hh:mm A").add(1, "days").format("MM-DD-YYYY"):null}</Typography>
                                </div>
                              </td>
                              <td>
                                <div>
                                <FormControlLabel control={<Radio checked={false === this.state.rows[idx].isDeactivated} color="primary" onChange={()=> this.handleRowChange(idx, false)} />} label="Activated" />
                                </div>
                              </td> 
                              <td>
                                <div>
                                <FormControlLabel control={<Radio checked={true === this.state.rows[idx].isDeactivated} color="primary" onChange={()=> this.handleRowChange(idx, true)} />} label="De-activated" />
                                </div>
                              </td> 
                              </tr>
                           )): <tr colspan={2} style={{textAlign:"center"}}><td><b>{"Scheduler Blocks Not Found!"}</b></td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
            </Grid>
          }
          <div className="button-wrapper primary">
            {this.state.isUpdate == false ? (
              <Button
                variant="contained"
                className="button-primary"
                color="primary"
                onClick={this.createTeam}
              >
                CREATE
              </Button>
            ) : (
                <Button
                  variant="contained"
                  className="button-primary"
                  color="secondary"
                  onClick={this.updateTeam}
                >
                  UPDATE
                </Button>
              )}
          </div>

          <div className="button-wrapper">
            <Button
              variant="contained"
              className="button-cancel"
              color="secondary"
              onClick={this.closeModal}
            >
              CANCEL
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

const mapStateToProps = state => ({
  signInPayload: state.signin.signInPayload
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTeam);
