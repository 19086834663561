import React, { Component } from 'react'
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton'
import AppBar from 'material-ui/AppBar'
import { MuiThemeProvider } from 'material-ui/styles';
import axios from 'axios';
import Button from '@material-ui/core/Button';

export class SmsCodeVerification extends Component {


    state = {
        
        invalidOtp:false,
        
      };

    continue = ()=>{
        //e.preventDefault();
        this.props.nextStep();
    }

    verifyOTP=()=>{
       // this.continue();
        axios.post(`https://tesselmeduserservice.azurewebsites.net/api/Providers/Confirmation`,{
             userId:this.props.values.userId,
             type: 'SMS',
             data: this.props.values.verificationCode
           }).then(res => {
            // if(!res.data.Errors){
             //COME BACK TO THIS
             if(res.data.error.code == 100){
                this.setState({invalidOtp:true});
               
             }else{
                this.setState({invalidOtp:false});
                this.continue();
             }
            // }else{
            //     this.setState({invalidOtp:true});
            //  }

       }).catch(error=> {
        //  console.log(error);
       });
    }
    back = e=>{
        e.preventDefault();
        this.props.prevStep();
    }
    sendRegistrationData=()=>{
        axios.post(`https://tesselmeduserservice.azurewebsites.net/api/Providers/Register`,{
           phone: this.props.values.mobileNumber,
           emailId: this.props.values.email,
           password: this.props.values.password,
           dob: this.props.values.dob,
           firstName: this.props.values.firstName,
           lastName: this.props.values.lastName,
           addressLine1:this.props.values.address,
           specialty:this.props.values.speciality
          }).then(res => {
           var usid=res.data.userId.toString();
           this.props.setUserId(usid);
        
          },this).catch(error=> {
            // console.log(error);
      });
    }
    render() {
        const divStyle = {
            paddingLeft: '30%',
            paddingTop: '5%',
           // border: '5px solid pink'
          };
          const invalidOtpStyle = {
            // paddingLeft: '5%',
             //paddingTop: '30%',
             //border: '5px solid pink',
             color:'#FF0000'
           };
           const resendOtpStyle = {
          
             color:'#006400'
           };
        const {values,handleChange}=this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                   {/* <AppBar title="Provider Registration"></AppBar> */}
                   
                    <div  className="container" style={divStyle}>
                    {!this.state.invalidOtp?
                    <h6>A verification code has been sent to your Mobile number</h6>:
                    <h6  style={invalidOtpStyle}>! Invalid One Time Password.Please try again</h6>
                    }
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <TextField
                   
                    floatingLabelText="Verify Code"
                    onChange={handleChange('verificationCode')}
                    defaultValue={values.verificationCode}/>
                    <br/>
                    {this.state.invalidOtp?
                    <button>
                    <a style={resendOtpStyle} onClick={this.sendRegistrationData}>Resend OTP</a></button>:<br/>
                    }
                    <br/>
                    <br/>
                    <br/>
                    {/* <TextField
                    hintText="Enter your email id"
                    floatingLabelText="Email"
                    onChange={handleChange('email')}
                    defaultValue={values.email}/>
                    <br/>
                    <TextField
                    hintText="Set your password"
                    floatingLabelText="Password"
                    onChange={handleChange('password')}
                    defaultValue={values.password}/>
                    <br/> */}
                    <Button variant="outlined" color="secondary" onClick={this.back} >
                    Back
                    </Button>&nbsp;&nbsp;
                    <Button variant="outlined" color="primary" onClick={this.verifyOTP}  disabled={!values.verificationCode}>
                    Verify
                    </Button>
                    {/* <RaisedButton
                    label="Back"
                    primary={true}
                    style={styles.button}
                    onClick={this.back}/> */}
                    
                    {/* <RaisedButton
                    label="Verify"
                    primary={true}
                    style={styles.button}
                    // onClick={this.continue}/>
                    onClick={this.verifyOTP}
                    disabled={!values.verificationCode}/> */}
                    </div>
              </React.Fragment>
            </MuiThemeProvider>
             
        )
    }
}
const styles = {
    button:{
        margin:20
    }
}
export default SmsCodeVerification;
