import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RaisedButton from "material-ui/RaisedButton";
import line from "../line.jpg";
import { Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signIn } from "../actions/signin";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { getToken, isTesselMedAdmin, isSchedulerView } from "../settings";
import { EventEmitter } from "../components/events";
import { toast } from "react-toastify";
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const materialTheme = createMuiTheme({
  overrides: {
    PrivateNotchedOutline:{
      legendNotched: {
        paddingRight:'10px'
      }
    },
    MuiOutlinedInput: {
      root: {
        // PrivateNotchedOutline:{
        //   "&$focused $legendLabelled": {
        //     borderWidth: 0,
        //     paddingRight:'10px'
        //   }},
        //   position: 'relative',
        //   '&$legendLabelled': {
        //       borderColor: 'rgba(0, 0, 0, 0.23)',
            
        //   },
        //   '&:hover:not($disabled):not($focused):not($error) $legendLabelled': {
        //       borderColor: '#4A90E2',
        //       // Reset on touch devices, it doesn't add specificity
        //       '@media (hover: none)': {
        //           borderColor: 'rgba(0, 0, 0, 0.23)',
        //       },
        //   },
        //   '&$focused $legendLabelled': {
        //       borderColor: '#4A90E2',
        //       borderWidth: 1,
        //   },
        //   '&$focused $legendLabelled': {
        //     borderColor: '#4A90E2',
        //     borderWidth: 1,
        // }
      },
  }
}
});

const items = [
  {
    id: 1,
    slideText:
      "TesselMed is a mobile platform for providers that allows healthcare facilities to access their existing medical staff and find new medical staff quickly and efficiently. Our targeted matching tools provide a solution to staffing challenges with no costly middleman."
  },
  {
    id: 2,
    slideText:
      "TesselMed is a mobile platform for providers that allows healthcare facilities to access their existing medical staff and find new medical staff quickly and efficiently. Our targeted matching tools provide a solution to staffing challenges with no costly middleman."
  },
  {
    id: 3,
    slideText:
      "TesselMed is a mobile platform for providers that allows healthcare facilities to access their existing medical staff and find new medical staff quickly and efficiently. Our targeted matching tools provide a solution to staffing challenges with no costly middleman."
  }
];

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`©${moment().year()} TesselMed. All rights reserved.`}
    </Typography>
  );
}

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      username: "",
      password: "",
      redirect: false,
      showPassword: false
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  componentDidMount() {
    EventEmitter.on("onUserSet", event => {
    });
  }

  authenticateUser = () => {
    toast.dismiss();
    const credentials = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.signIn(credentials).then((response)=> {
      if(!response || (!response.status && !response.data)) {
        toast.error(!window.navigator.onLine ? "Please check your internet connection." :"Invalid login.");
      } else {
        if(response.data.userType == '' || response.data.userType == "provider") {
          toast.error("Invalid login.");
        } else {
          toast.success("Login successfully.");
        }
      }
    });
  };

  renderSlider = () => {
    return items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.id}
          className={"slider-item"}
        >
          <p className={"slider-content"}>{item.slideText}</p>
        </CarouselItem>
      );
    });
  };

  render() {
    console.log(localStorage.getItem("department"), isSchedulerView())
    if (!isSchedulerView() && !isTesselMedAdmin() && localStorage.getItem("generic") == "false" && localStorage.getItem("department") == "true" && getToken()) {
      return <Redirect to="/layout/users"/>;
    } else if(!isSchedulerView() && isTesselMedAdmin() && getToken()){
      return <Redirect to="/layout/dashboard"/>;
    } else if(!isSchedulerView() && !isTesselMedAdmin() && localStorage.getItem("generic") == "true" && getToken()) {
      return <Redirect to="/layout/generic-dashboard"/>;
    } else if (!isSchedulerView() && !isTesselMedAdmin() && localStorage.getItem("generic") == "false" && getToken()) {
      return <Redirect to="/layout/dashboard"/>;
    }
    if (isSchedulerView() || localStorage.getItem("generic") == "true" && getToken()) {
      return localStorage.getItem("generic") == "true" ? <Redirect to="/layout/generic-dashboard"/> : <Redirect to="/layout/scheduler"/>
    }

   
    const { activeIndex } = this.state;
    const margin = {
      alignItems: "center",
      marginTop: "140px",
      justifyContent: "center"
    };

    const paper = {
      padding: 1,
      margin: "auto",
      maxWidth: 900,
      zIndex: 67,
      marginTop: "50px",
      marginBottom: "50px"
    };

    const cardLeft = {
      padding: 30,
      backgroundColor: "#137bc0",
      boxShadow: "0px 3px 5.94px 0.06px rgba(233, 233, 233, 0.51)",
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderLeft: "1px solid",
      borderColor: "#137bc0"
    };

    const cardRight = {
      padding: 20,
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 3px 5.94px 0.06px rgba(233, 233, 233, 0.51)",
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderRight: "1px solid",
      borderColor: "#FFFFFF"
    };
    const checkBoxStyle = {
      paddingRight: "50%"
    };

    const linkStyle = {
      fontSize: 12,
      color: "#208ccc",
      alignItems:'center',
      textAlign: "center",
    };

    const gridRight = {
      paddingTop: 50
    };

    const header = {
      textAlign: "center",
      fontSize: "36px",
      color: "#333",
      width: "100%",
      backgroundImage: `url(${line})`,
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat"
    };

    const titleRow = {
      textAlign: "left",
      fontSize: 17,
      left: "0%",
      lineHeight: 25
    };

    const carouselTitle = {
      marginTop: 100,
      color: "#FFFFFF",
      textAlign: "left",
      fontSize: 26,
      fontWeight: "600",
      lineHeight: 1.2
    };

    const carouselRow = {
      right: "0%",
      paddingBottom: 0,
      color: "#fff",
      fontSize: 17
    };

    return (
      <div style={margin}>
        <CssBaseline />
        <Paper style={paper} tag="div">
       
          <Grid container flexGrow={1} spacing={2}>
            <Grid item xs={6} sm style={cardLeft}>
              <div style={titleRow}>
                <Typography style={carouselTitle} fontSize={14} tag="h3">
                  {`Welcome to TESSELMED`}
                </Typography>
              </div>
              <div style={carouselRow}>
                <Carousel
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                >
                  {this.renderSlider()}
                  <CarouselIndicators
                    items={items}
                    className={"slider-indicators"}
                    activeIndex={activeIndex}
                    onClickHandler={this.goToIndex}
                  />
                </Carousel>
              </div>
            </Grid>
            <Grid item xs={6} sm container style={cardRight} p={20}>
              <Grid
                item
                xs
                container
                style={gridRight}
                direction="column"
                spacing={2}
              >
                <Typography style={header} tag="h1">
                  Sign In
                </Typography>
                <Grid item xs>
                <ThemeProvider theme={materialTheme}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    InputProps={{
                      classes: this.props.classes
                    }}
                    autoComplete="email"
                    autoFocus
                    onChange={this.handleChange("username")}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? "text" : "password"}
                    id="password"
                    //autoComplete="current-password"
                    onChange={this.handleChange("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  </ThemeProvider>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                    style={checkBoxStyle}
                  />
                  <RaisedButton
                    label="Sign In"
                    fullWidth
                    color="inherit"
                    labelColor={"white"}
                    backgroundColor={"#1479bf"}
                    onClick={this.authenticateUser}
                  />
                  <br />
                  <br />
                  {/* <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item>
                      <Link to="/privacy-policy" tag="span" style={linkStyle}>
                        {"Privacy Policy"}
                      </Link>
                    </Grid>
                  </Grid> */}
                </Grid>
                <Box mt={5}>
                  <MadeWithLove />
                </Box>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

const mapStateToProps = state => ({
  redirect: state.signin.redirect,
  signInPayload: state.signin.signInPayload,
  error: state.signin.error
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
