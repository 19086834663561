import React, { Component, Fragment } from "react";
import OrgDataTable from "../containers/OrgDataTable";
import AddOrg from "../containers/AddOrg";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import { EventEmitter } from "../components/events";

export class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isUpdate: false
    };
  }

  componentWillMount() {
    const bgImage= require('../backg.png');
    document.body.style.backgroundImage = `url(${bgImage})`;
  }

  componentDidMount = () => {
    
    EventEmitter.on("orgEdit", () => {
      this.setState({ isPaneOpen: true, isUpdate: true });
    });
    EventEmitter.on("closeModal", () => {
      this.setState({ isPaneOpen: false });
    });

    EventEmitter.on("orgCreated", () => {
      this.setState({ isPaneOpen: false });
    });
  }

  createNewOrganization = () => {
    this.setState({ isPaneOpen: true, isUpdate: false });
  };

  render() {
    return (
        <Fragment>
        <OrgDataTable createNewOrganization={this.createNewOrganization}></OrgDataTable>
        <Modal
          aria-labelledby="modal-title"
          open={this.state.isPaneOpen}
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "center",
            right: 0,
            left: "none",
            height: "100%",
            width: "720px",
            maxWidth: "100%",
            position: "fixed",
            zIndex: 1010
          }}
          onClose={() => {
            this.setState({ isPaneOpen: false });
          }}
        >
          <Slide
            direction="left"
            timeout={350}
            in={this.state.isPaneOpen}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                overflowY: "initial !important"
              }}
            >
              <h2
                style={{
                  background:
                    "linear-gradient(90deg, #208bca 38%, #40b6e6 100%)",
                  paddingBottom: 13,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 13,
                  color: "white"
                }}
                id="user-modal-title"
              >
                {this.state.isUpdate ? "Update Organization" : "Add Organization"}
              </h2>
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                  padding: 20,
                  overflowY: "auto",
                  paddingBottom: 90
                }}
              >
                <AddOrg />
              </div>
            </div>
          </Slide>
        </Modal>
      </Fragment>
    );
  }
}
export default Organization;
