import React, { Component } from "react";
import { EventEmitter } from "../components/events";
import instance from "../utils/services/services";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable, { defaultThemes } from "react-data-table-component";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import {getAccessLevel} from "../settings/userSetting";
import LinearIndeterminate from "../common/ListLoader";

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: '38px',
    }, 
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize:16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class FacDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facData: [],
      selectedColumn:[],
      isLoading: false
    };
  }

  fetchFacility = async () => {
    this.setState({isLoading:true});
    await instance.get(`Facility/GetAll`).then((response)=> {
      if (response.status) {
        this.setState({
          facData: response.data.data !== null ? response.data.data : []
        });
        this.setState({isLoading:false});
      } else {
        this.setState({isLoading:false});
      }
    });
  };

  componentWillMount = async () => {
    EventEmitter.on("facCreated", event => this.fetchFacility());
    await this.getColumns();
  }

  componentDidMount() {
    this.fetchFacility();
  }

  getFacilityById = row => {
    instance
      .get(`Facility/GetById`, { params: { id: row.facilityId } })
      .then(res => {
        EventEmitter.emit("facEdit", res.data.data);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getColumns = async () => {
    let columns = [
      {
        name: "FACILITY",
        selector: "facilityName",
        sortable: true
      },
      {
        name: "ORGANIZATION",
        selector: "organizationName",
        sortable: true
      },
      {
        name: "CITY",
        selector: "extension.address.city",
        sortable: true,
        // hide: "md",
        width: "190px",
        cell: row => (row.extension && row.extension.address && row.extension.address.city) ? row.extension.address.city : ''
      },
      {
        name: "STATE",
        selector: "extension.address.state",
        sortable: true,
        width: "190px",
        cell: row => (row.extension && row.extension.address && row.extension.address.state) ? row.extension.address.state : ''
       
      },
      {
        name: "ZIP CODE",
        selector: "zip",
        sortable: true,
        width: "150px",
        cell: row => row.zip ? row.zip : ''
        // hide: "md"
      },
      {
        name: "NPI",
        selector: "extension.npi",
        sortable: true,
        cell: row => row.extension ? row.extension.npi : ''
        // hide: "md"
      }     
    ];

    let editColumn = [{
      name: "ACTION",
      cell: row => (
        <Button
          startIcon={<EditIcon />}
          style={{
            backgroundColor: "#33a6db",
            color: "white",
            fontWeight: 500
          }}
          title={"Edit"}
          variant="contained"
          size="small"
          onClick={() => this.getFacilityById(row)}
        >
          Edit
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }];

    if(getAccessLevel(2)) {
      let columnArray = [...columns, ...editColumn];
      this.setState({selectedColumn:columnArray});
    } else {
      this.setState({selectedColumn:columns});
    }
  }

  render() {
  
    return (
      <DataTable
        title={
          <Grid container spacing={1}>
            <Grid item xs={1} md={1} sm={1}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Facility
                </h1>
              </div>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
            <div style={{ justifyContent: "center", alignItems: "center", marginLeft:30 }}>
              {getAccessLevel(2) && (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "#33a6db",
                    fontSize: 8,
                    fontWeight: 600
                  }}
                  onClick={this.props.createNewFacility}
                >
                  <AddIcon />
                  Add
                </Button>
              )}
              </div>
            </Grid>
          </Grid>
        }
        subHeader={true}
        columns={this.state.selectedColumn}
        data={this.state.facData}
        defaultSortField="facilityName"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={this.state.isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
          <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."}/>
        </div>}
        noDataComponent={
          <div style={{paddingTop:150, paddingBottom:150, fontSize:14, fontWeight:500}}>
            Facilities not found!
          </div>
        }
      />
    );
  }
}

export default FacDataTable;
