import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import styled from 'styled-components';

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
})`
  /* .toast-container */
  // width: 100%;
  font-size:16px
`;

export const Toast = props => (
  <StyledToastContainer
    position="top-right"
    autoClose={5000}
    toastClassName={css({
      fontSize: "12"
    })}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    maxOpened={1}
    limit={1}
    preventDuplicates={1}
    preventOpenDuplicates={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
  />
);
