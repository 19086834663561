import React, { Component } from "react";
import { Checkbox } from "primereact/checkbox";
import Select from "react-select";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import { EventEmitter } from "../components/events";
import { RadioButton } from "primereact/radiobutton";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signIn } from "../actions/signin";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import instance from "../utils/services/services";
import { toast } from "react-toastify";
import { getAccessLevel } from "../settings/userSetting";
import { getUserType } from "../settings/userSetting";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import { SET_LOADING_TRUE, SET_LOADING_FALSE } from "../actions/actionTypes";
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#daeff8',
  },
  barColorPrimary: {
    backgroundColor: '#33a5db',
  }
})(LinearProgress);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #137bc0;
  left: 40%;
  top: 40%;
  position: absolute !important;
  z-index: 999999 !important;
`;

let depts = [];
let allOrgList = [];

const labourTypes = [
  { label: "Per Diem", value: "PerDiem" },
  { label: "Locum", value: "Locum" },
  { label: "Employee", value: "Employee" },
  { label: "Volunteer", value: "Volunteer" }
];

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#33a5db"
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {}
    },
    MuiPickersDay: {
      day: {},
      daySelected: {
        backgroundColor: "#33a5db"
      },
      dayDisabled: {},
      current: {}
    },
    MuiPickersModal: {
      dialogAction: {}
    },
    MuiOutlinedInput:{
      input:{
        paddingTop:11,
        paddingBottom:11
      }
    }
  }
});

export class AddUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      npi: "",
      zip: "",
      fac: "",
      facs: [],
      deps: [],
      orgs: [],
      dept: [],
      ficilities: [],
      facility: [],
      org: [],
      cell: "",
      id: "",
      allOrgList:[],
      allFacList: [],
      allDeptList: [],
      isUpdate: false,
      isApproved: true,
      isInternal: false,
      isProvider: false,
      isUser: false,
      userId: 0,
      userCode: 0,
      showPassword: false,
      password: "",
      deptDisabled: true,
      selectedUserFacility: "",
      selectedUserDepartment: "",
      selectedUserOrganization: "",
      editUserCode: 0,
      selectedProviderFacilities: [],
      submitted: false,
      isTesselMedAdmin: false,
      isProviderDisabled: false,
      isUserDisabled: false,
      facDisabled: true,
      isDepUser: false,
      isFacUser: false,
      isOrgUser: false,
      orgUserOrg: "",
      facUserFac: "",
      deptUserDept: "",
      dob: new Date().setFullYear(Number((new Date().getFullYear())) - 5),
      isMandate: false,
      isFacMandate: true,
      isDepView: false,
      FacData: "",
      isFacAvailable: false,
      SPL: [],
      skill: [],
      labourType: [],
      LabourData: [],
      spls:[],
      selectedLabourType: "",
      rows: [{ fac: {}, labourtype: {}, employeeid: ""}],
      isLoading: false,
      isLaborTypeMandate: true,
      isProviderMandate: false,
      selectedProviderFacility: [],
      selectedTabs:0,
      isSubmitted:false,
      isGenericView: false
    };
  }

  componentWillMount() {
    this.setState({ userCode: localStorage.getItem("userCode") }, () => {
      if (this.props.newUser) {
        this.onUserChange()
        if (!this.state.isProvider && getUserType() === "admin") {
          this.setState({isLoading:true});
          this.getAllOrganizationByCode();
        } else if (!this.state.isProvider && getUserType() === "organization") {
          this.setState({isLoading:true});
          this.getAllOrganizationByCode();
        } else if (!this.state.isProvider && getUserType() === "facility") {
          this.setState({isLoading:true});
          this.getAllFacilityByCode();
        } else if (this.state.isProvider) {
          this.setState({ facDisabled: false }, () => {
            this.getInitialDropdownValues();
          });
        }
      }
    });
    this.checkUserType();
  }

  componentDidMount() {
    EventEmitter.on("userEdit", event => this.editUser(event));
    this.props.setLoader(SET_LOADING_TRUE, false)
    EventEmitter.on("userModalClosed", event => this.setMode(event));
    this.setState({ deptDisable: true });
  }

  handleCheckGenericChange = event => {
    this.setState({ isGenericView: event.target.checked });
  };

  getSplList = () => {
    var spls = [];
    instance
      .get(`Specialties/Get`)
      .then(response => {
        response.data.data.forEach(i => {
          spls.push({
            label: i,
            value: i
          });
        });
        this.setState({ spls },()=> {
          this.props.setLoader(SET_LOADING_TRUE, false)
          this.setDropdownValue(1)
        });
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  getMasterSkillSet = () => {
    var skillsArray = [];
    var skilllevel = [];
    instance
      .get(`Organization/GetSkillLevels`)
      .then(response => {
        skillsArray = response.data.data.skillLevel;
        skillsArray.forEach(i => {
          skilllevel.push({
            label: i,
            value: i
          });
        });
        this.setState({ skilllevel },()=> this.props.setLoader(SET_LOADING_TRUE, false));
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  handleDateChange = dob => {
    this.setState({ dob });
  };

  checkUserType = () => {
    if (getUserType() === "admin") {
      this.setState({ isTesselMedAdmin: true });
    } else if (getUserType() === "organization") {
      this.setState({ isOrgUser: true });
    } else if (getUserType() === "facility") {
      this.setState({ isFacUser: true });
    } else if (getUserType() === "generic") {
      this.setState({ isFacUser: true });
    } else if (getUserType() === "department") {
      this.setState({ isDepUser: true });
    }
  };

  onAccessTabClick = index => {
    this.setState({selectedTabs:index});
    if (
      index === 1 &&
      this.state.isUpdate &&
      !this.state.isProvider &&
      (this.state.editUserType === "organization" ||
        this.state.editUserType === "facility" ||
        this.state.editUserType === "generic" ||
        this.state.editUserType === "department") &&
      getUserType() === "admin"
    ) {
      this.setState({isLoading:true});
      this.getSelectedOrganizationByUserCode();
    } else if (
      index === 1 &&
      this.state.isUpdate &&
      !this.state.isProvider &&
      (this.state.editUserType === "facility" ||
        this.state.editUserType === "generic" ||
        this.state.editUserType === "department") &&
      getUserType() === "organization"
    ) {
      this.setState({isLoading:true});
      this.getSelectedFacilityByUserCode();
    } else if (
      index === 1 &&
      this.state.isUpdate &&
      !this.state.isProvider &&
      this.state.editUserType === "department" &&
      (getUserType() === "organization" || getUserType() === "facility")
    ) {
      this.setState({isLoading:true});
      this.getSelectedDepartmentByUserCode();
    }

    if (this.state.isProvider && this.state.isUpdate) {
      this.fetchFacOnUpdateProvider();
    }
  };

  getOrganizationList = () => {
    var orgs = [];
    instance
      .get(`Organization/GetAll`, {
        params: { code: this.state.userCode }
      })
      .then(response => {
        allOrgList = response.data.data;
        allOrgList.forEach(i => {
          orgs.push({
            label: i.organizationName,
            value: i.organizationId
          });
        });
        orgs = orgs.sort(this.predicateBy("label"));
        this.setState({ orgs });
      })
      .catch(error => {
        // console.log(error);
      });
  };

  // Created on 07-March-2020
  //Get all by master(All available in DB)
  getAllOrganizationByCode = () => {
    var orgs = [];
    instance
      .get(`Organization/GetAll`, {
        params: { code: this.state.userCode }
      })
      .then(response => {
        // this.setState({isLoading:false});
        allOrgList = response.data.data;
        allOrgList.forEach(i => {
          orgs.push({
            label: i.organizationName,
            value: i.organizationId
          });
        });
        orgs = orgs.sort(this.predicateBy("label"));
        this.setState({ orgs, allOrgList: allOrgList }, () =>
          this.getAllFacilityByCode()
        );
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  getAllFacilityByCode = () => {
    instance
      .get(`Facility/GetAll?`, { params: { code: this.state.userCode } })
      .then(response => {
        // this.setState({isLoading:false});
        if (!response.error) {
          this.setState({ allFacList: response.data.data }, () => {
            // this.setState({isLoading:false});
            this.getAllDepartmentByCode();
          });
        } else {
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  getAllDepartmentByCode = () => {
    instance
      .get(`Department/GetAll?`, { params: { code: this.state.userCode } })
      .then(response => {
      
        if (!response.error) {
          this.setState({ allDeptList: response.data.data }, () => {
            if(getUserType() === "admin") {
              //Organization
              let selectedOrganizationArray = [];
              this.state.allOrgList.forEach(organization => {
                  selectedOrganizationArray.push({
                    label: organization.organizationName,
                    value: organization.organizationId
                  });
              });
              selectedOrganizationArray = selectedOrganizationArray.sort(this.predicateBy("label"));
              this.setState({ orgs:selectedOrganizationArray });
              //Facility
              let selectedFacilityArray = [];
              this.state.allFacList.forEach(facility => {
                  selectedFacilityArray.push({
                    label: facility.facilityName,
                    value: facility.facilityId
                  });
              });
              this.setState({facs:selectedFacilityArray});
              //Department
              let selectedDepartmentArray = [];
   
              this.state.allDeptList.forEach(department => {
                    selectedDepartmentArray.push({
                      label: department.departmentName,
                      value: department.departmentId
                    });
              });
              // this.props.setLoader(SET_LOADING_FALSE, false);
              this.setState({ deps: selectedDepartmentArray });
              this.setState({isLoading:false});
              if(this.state.editUserCode) {
                this.setDropdownValue(1)
              }
            } else {
              this.getAllAssignedOrganizationOfLoginUser();
            }
            // this.props.setLoader(SET_LOADING_FALSE, false);
          });
        } else {
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  //Get All by login user
  getAllAssignedOrganizationOfLoginUser = () => {
    instance
      .get(`Organization/GetOrganization?`, {
        params: { userCode: this.state.userCode }
      })
      .then(response => {
        if (getUserType() !== "admin") {
          this.setState({ orgUserOrg: response.data.data }, () => {
            this.getAllAssignedFacilityOfLoginUser();
          });
        } else {
          this.getAllAssignedFacilityOfLoginUser();
        }
      });
  };

  getAllAssignedFacilityOfLoginUser = () => {
    instance
      .get(`Facility/GetFacility?`, {
        params: { userCode: this.state.userCode }
      })
      .then(response => {
        this.setState({ facUserFac: response.data.data }, () => {
          this.getAllAssignedDepartmentOfLoginUser();
        });
      });
  };

  getAllAssignedDepartmentOfLoginUser = () => {
    instance
      .get(`Department/GetDepartment?`, {
        params: { userCode: this.state.userCode }
      })
      .then(response => {
        if(response.data) {
          this.setState({ deptUserDept: response.data.data }, () => {
            let selectedOrg = this.state.orgUserOrg;
            let orgs = [];
            //organization
            if (allOrgList.length !== 0 && selectedOrg) {
              this.state.allOrgList.forEach(organization => {
                if (selectedOrg === organization.organizationId) {
                  orgs.push({
                    label: organization.organizationName,
                    value: organization.organizationId
                  });
                }
              });
              orgs = orgs.sort(this.predicateBy("label"));
              this.setState({ orgs });
            }
            //organization
            let selectedFacilityArray = [];
            this.state.facUserFac.forEach(fac => {
              this.state.allFacList.forEach(facility => {
                if (fac === facility.facilityId) {
                  selectedFacilityArray.push({
                    label: facility.facilityName,
                    value: facility.facilityId
                  });
                }
              });
            });
  
            this.setState({ facs: selectedFacilityArray });
            //Department
            let selectedDepartmentArray = [];
            this.state.deptUserDept.forEach(dep => {
              this.state.allDeptList.forEach(department => {
                if (dep === department.departmentId) {
                  selectedDepartmentArray.push({
                    label: department.departmentName,
                    value: department.departmentId
                  });
                }
              });
            });
            this.setState({ deps: selectedDepartmentArray });
            this.setState({isLoading:false});
            this.props.setLoader(SET_LOADING_FALSE, false);
            if(this.state.editUserCode) {
              this.setDropdownValue(1)
            }
          });
        } else {
          this.props.setLoader(SET_LOADING_FALSE, false);
        }
    }); 
  };


  setDropdownValue = (index) => {
      if (
        index === 1 &&
        this.state.isUpdate &&
        !this.state.isProvider &&
        (this.state.editUserType === "organization" ||
          this.state.editUserType === "facility" ||
          this.state.editUserType === "department") &&
        getUserType() === "admin"
      ) {
        this.setState({isLoading:true});
        this.getSelectedOrganizationByUserCode();
      } else if (
        index === 1 &&
        this.state.isUpdate &&
        !this.state.isProvider &&
        (this.state.editUserType === "facility" ||
          this.state.editUserType === "department") &&
        getUserType() === "organization"
      ) {
        this.setState({isLoading:true});
        this.getSelectedFacilityByUserCode();
      } else if (
        index === 1 &&
        this.state.isUpdate &&
        !this.state.isProvider &&
        this.state.editUserType === "department" &&
        (getUserType() === "organization" || getUserType() === "facility")
      ) {
        this.setState({isLoading:true});
        this.getSelectedDepartmentByUserCode();
      }
      if (this.state.isProvider && this.state.isUpdate) {
        this.fetchFacOnUpdateProvider();
      }
  }

  // Get All of editing user selected
  getSelectedOrganizationByUserCode = () => {
    instance
      .get(`Organization/GetOrganization?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(response => {
        if (response.status) {
          let allOrganization = this.state.orgs;
          let selectedOrganization = allOrganization.filter(organization => {
            return organization.value === response.data.data;
          });
          this.setState({ org: selectedOrganization && selectedOrganization.length !== 0 ? selectedOrganization[0] : response.data.data }, () =>
          this.getSelectedFacilityByUserCode() //this.handleOrgChange(this.state.org)
          );
        } else {
          this.setState({isLoading:false});
          this.props.setLoader(SET_LOADING_FALSE, false);
        }
      });
  };

  getSelectedFacilityByUserCode = () => {
    let isDepartment = getUserType() === "department";
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    instance
      .get(`Facility/GetFacilityDetails?`, {
        params: { userCode: this.state.editUserCode, isDepartmentUser: isDepartment, isFacilityUser: isFacility, isOrgUser:isOrgUser }
      })
      .then(async response => {
        // this.setState({isLoading:false});
        if (response.status) {
          if(response.data.data !== undefined) {
            let selectedFacArray = [];
            this.state.facs.forEach((facility, idx) => {
              let facilityIds = response.data.data.facilities;
              let selected = facilityIds.filter(fac => {
                return fac === facility.value;
              });
              if (selected.length) {
                selectedFacArray.push(facility);
              }
            });
            console.log()
            if(this.state.editUserType === "generic") {
              this.setState(
                { facility: selectedFacArray[0], deptDisabled: false, isDepView: response.data.data.isScheduleViewOnly, isGenericView:true },
                () => {
                  // this.props.setLoader(SET_LOADING_FALSE, false);
                  this.getSelectedDepartmentByUserCode();
                }
              );
            } else {
              this.setState(
                { facility: selectedFacArray, deptDisabled: false, isDepView: response.data.data.isScheduleViewOnly },
                () => {
                  // this.props.setLoader(SET_LOADING_FALSE, false);
                  this.getSelectedDepartmentByUserCode();
                }
              );
            }
            
          } else {
            this.setState({isLoading:false});
            this.props.setLoader(SET_LOADING_FALSE, false);
          }
        } else {
          this.setState({isLoading:false});
          this.props.setLoader(SET_LOADING_FALSE, false);
        }
      });
  };

  getSelectedDepartmentByUserCode = () => {
    instance
      .get(`Department/GetDepartment?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(async response => {
        // this.setState({isLoading:false});
        if (response.data !== "") {
          let selectedDeptArray = [];
          this.state.deps.forEach((department, idx) => {
            let departmentIds = response.data.data;
            let selected = departmentIds.filter(dept => {
              return dept === department.value;
            });
            if (selected.length) {
              selectedDeptArray.push(department);
            }
          });
          this.setState({ dept: selectedDeptArray }, ()=>  {
            this.setState({isLoading:false});
            this.props.setLoader(SET_LOADING_FALSE, false)
            this.setState({isLoading:false});
          });
        } else {
          this.setState({isLoading:false});
          this.props.setLoader(SET_LOADING_FALSE, false)
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.props.setLoader(SET_LOADING_FALSE, false);
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  //End on 07-March-2020

  getAllDepartmentsForFacUser = () => {
    instance
      .get(`Department/GetAll?`, { params: { code: this.state.userCode } })
      .then(response => {
        if (!response.error) {
          this.setState({ allDeptList: response.data.data }, () => {
            if (!this.state.isUpdate) {
              this.getLinkedFacForFacUser();
            }
          });
        } else {
          this.props.setLoader(SET_LOADING_FALSE, false);
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.props.setLoader(SET_LOADING_FALSE, false);
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  getAllDepartments = () => {
    instance
      .get(`Department/GetAll?`, { params: { code: this.state.userCode } })
      .then(response => {
        if (!response.error) {
          this.setState({ allDeptList: response.data.data });
        } else {
          this.props.setLoader(SET_LOADING_FALSE, false);
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.props.setLoader(SET_LOADING_FALSE, false);
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  getAllFacilities = () => {
    instance
      .get(`Facility/GetAll?`, { params: { code: this.state.userCode } })
      .then(response => {
        if (!response.error) {
          this.setState({ allFacList: response.data.data }, () => {
            this.setState({isLoading:false});
            if (!this.state.isUpdate && this.state.isOrgUser) {
              this.getLinkedOrgForOrgUser();
            }
          });
        }
        this.props.setLoader(SET_LOADING_FALSE, false);
        this.setState({isLoading:false});
      })
      .catch(error => {
        this.setState({isLoading:false});
        this.props.setLoader(SET_LOADING_FALSE, false);
        // console.log(error);
      });
  };

  handleOrgChange = org => {
    if (
      !this.state.isProvider &&
      (this.state.isTesselMedAdmin || this.state.isOrgUser)
    ) {
      this.setState({ org, facDisabled: false, facility:[] }, () => {
        this.getFacListForOrgTessel();
      });
    }
  };

  handleCheckChange = event => {
    this.setState({ isDepView: event.target.checked });
  };

  handleMultipleFacilityChange = facility => {
    if (getUserType() === "admin" || getUserType() === "organization") {
      this.setState({ facility, deptDisabled: false }, () => {
        this.setState({ dept: [] });
        let deps = [];
        this.state.facility.forEach((fac, i) => {
          if (fac.value !== undefined && this.state.allDeptList) {
            this.state.allDeptList.forEach(i => {
              if (i.facilityId === fac.value) {
                deps.push({
                  label: i.departmentName,
                  value: i.departmentId
                });
              }
            });
          }
        });

        this.setState({ deps }, () => {
          if (this.state.isUpdate) {
            instance
              .get(`Department/GetDepartment?`, {
                params: { userCode: this.state.editUserCode }
              })
              .then(async response => {
                if (response.data !== "") {
                  if (
                    this.state.editUserCode &&
                    !this.state.isProvider &&
                    this.state.deps
                  ) {
                    let selectedDepartment = response.data.data;
                    let selectedDepartmentArray = [];
                    selectedDepartment.forEach((department, idx) => {
                      this.state.deps.forEach((dept, i) => {
                        if (dept.value === department) {
                          selectedDepartmentArray.push(dept);
                        }
                      });
                    });
                    this.setState({ dept: selectedDepartmentArray }, ()=> {
                      this.setState({isLoading:false});
                      this.props.setLoader(SET_LOADING_FALSE, false);
                    });
                  } else {
                    this.setState({isLoading:false});
                    this.props.setLoader(SET_LOADING_FALSE, false);
                  }
                } else {
                  this.props.setLoader(SET_LOADING_FALSE, false);
                  this.setState({isLoading:false});
                }
              })
              .catch(error => {
                this.setState({isLoading:false});
                this.props.setLoader(SET_LOADING_FALSE, false);
                // console.log(error);
              });
          }
        });
      });
    }
  };

  handleFacChange = fac => {
    if (
      !this.state.isProvider &&
      (this.state.isTesselMedAdmin || this.state.isOrgUser)
    ) {
      this.setState({ fac, deptDisabled: false }, () => {
        this.getDepBasedOnFacList();
      });
    }

    if (!this.state.isProvider && this.state.isFacUser) {
      this.setState({ fac, deptDisabled: false }, () => {
        this.getDepListForFacTessel();
      });
    }
    if (this.state.isProvider) {
      this.setState({ fac });
      this.setState({isLoading:false});
    }
  };

  getLinkedFacForFacUser = () => {
    instance
      .get(`Facility/GetFacility?`, {
        params: { userCode: this.state.userCode }
      })
      .then(response => {
        this.setState({ facUserFac: response.data.data }, () => {
          this.getDepListForFacTessel();
        });
      });
  };

  getLinkedOrgForOrgUser = () => {
    instance
      .get(`Organization/GetOrganization?`, {
        params: { userCode: this.state.userCode }
      })
      .then(response => {
        this.setState({isLoading:false});
        this.setState({ orgUserOrg: response.data.data }, () => {
          this.setState({isLoading:false});
          this.getFacListForOrgTessel();
        });
      });
  };

  getDepListForFacTessel = () => {
    var deps = [];
    var fac = {};
    if (this.state.isFacUser && !this.state.isUpdate) {
      this.state.allDeptList.forEach(i => {
        // if (i.facilityId == this.state.facUserFac) {
        deps.push({
          label: i.departmentName,
          value: i.departmentId
        });
        // }
      });
    } else if (this.state.isFacUser && this.state.isUpdate) {
      fac = this.state.fac;
      if (this.state.fac) {
        this.state.allDeptList.forEach(i => {
          if (i.facilityId === fac.value) {
            deps.push({
              label: i.departmentName,
              value: i.departmentId
            });
          }
        });
      }
    }

    this.setState({ deps }, () => {
      if (this.state.isUpdate) {
        this.bindDeptOnEditUser();
      }
    });
  };

  getFacListForOrgTessel = async () => {
    let facs = [];
    var org = {};
    // this.setState({isLoading:false});
    if (getUserType() === "organization" && !this.state.isUpdate) {
      this.state.allFacList.forEach(i => {
        if (i.organizationId == this.state.orgUserOrg) {
          facs.push({
            label: i.facilityName,
            value: i.facilityId
          });
        }
      });
    } else if (this.state.isTesselMedAdmin && this.state.isUpdate) {
      org = this.state.org;
      if (this.state.org) {
        this.state.allFacList.forEach(i => {
          if (i.organizationId === org.value) {
            facs.push({
              label: i.facilityName,
              value: i.facilityId
            });
          }
        });
      }
    } else if (getUserType() === "organization" && this.state.isUpdate) {
      this.state.allFacList.forEach(i => {
        facs.push({
          label: i.facilityName,
          value: i.facilityId
        });
      });
    } else if (getUserType() === "admin" && !this.state.isUpdate) {
      this.state.allFacList.forEach(facility => {
        if (this.state.org.value === facility.organizationId) {
          facs.push({
            label: facility.facilityName,
            value: facility.facilityId
          });
        }
      });
    }

    this.setState({ facs }, () => {
      if (this.state.isUpdate) {
        this.bindFacOnEditUser();
      } else {
        this.setState({isLoading:false});
      }
    });
  };

  getDepBasedOnFacList = () => {
    var deps = [];
    var fac = "";
    fac = this.state.fac;

    if (this.state.fac && this.state.allDeptList) {
      this.state.allDeptList.forEach(i => {
        if (i.facilityId === fac.value) {
          deps.push({
            label: i.departmentName,
            value: i.departmentId
          });
        }
      });
    }

    this.setState({ deps }, () => {
      if (this.state.isUpdate) {
        this.bindDeptOnEditUser();
      }
    });
  };

  handleDeptChange = dept => {
    this.setState({ dept });
  };

  getDepartmentByMutipleFacility = async fac => {
    let { deps } = this.state;
    if (fac.value !== undefined && this.state.allDeptList) {
      this.state.allDeptList.forEach(i => {
        if (i.facilityId === fac.value) {
          deps.push({
            label: i.departmentName,
            value: i.departmentId
          });
        }
      });
    }

    if (!fac.length) {
      this.setState({ dept: [] }, ()=> {
        if(this.state.isGenericView) {
          this.setState({dept:deps})
        }
      });
    }

    this.setState({ deps }, () => {
      if (this.state.isUpdate) {
        this.bindDeptOnEditUser();
      }
    });
  };

  handleMutipleFacilityChange = async facility => {
    let selectedFacility = facility ? facility : [];
    this.setState(
      { facility: selectedFacility, deptDisabled: false },
      async () => {
        let facilityArray = this.state.facility;
        if (facilityArray !== null) {
          await this.setState({ deps: [] });
          facilityArray.length !== 0 &&
            facilityArray.forEach((facility, i) => {
              this.getDepartmentByMutipleFacility(facility);
            });
        } else {
          await this.getDepartmentByMutipleFacility([]);
        }
        this.clearDepartmet();
      }
    );
  };

  handleFacilityChangeForGeneric = async facility => {
    let selectedFacility = facility ? facility : [];
    console.log("[selectedFacility]--->",selectedFacility)
    this.setState(
      { facility: [selectedFacility], deptDisabled: false },
      async () => {
        let facilityArray = this.state.facility
        
        console.log("facilityArray-->",facilityArray)
        if (facilityArray !== null) {
          await this.setState({ deps: [] });
          facilityArray.length !== 0 &&
            facilityArray.forEach((facility, i) => {
              this.getDepartmentByMutipleFacility(facility);
            });
        } else {
          await this.getDepartmentByMutipleFacility([]);
        }
        this.clearDepartmet();
      }
    );
  };

  clearDepartmet = () => {
    if (this.state.deps.length === 0 || this.state.facility === null) {
      this.setState({ dept: [] });
    }

    let selectedDeptArray = [];
    if (this.state.dept !== null) {
      this.state.dept.forEach((department, i) => {
        this.state.deps.forEach((dapt, index) => {
          if (dapt.value === department.value) {
            selectedDeptArray.push(dapt);
          }
        });
      });
      this.setState({ dept: selectedDeptArray });
    }
  };

  bindFacOnEditUser = () => {
    let selectedFacArray = [];
    instance
      .get(`Facility/GetFacilityDetails?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(async response => {
        // this.setState({isLoading:false});
        var res = await response.data.data.facilities;
        this.setState({ isDepView: response.data.data.isScheduleViewOnly });
        if (this.state.editUserCode && this.state.facs) {
          if (this.state.isTesselMedAdmin) {
            let selectedFacArray = [];
            this.state.facs.forEach((f, i) => {
              let selected = res.filter(fac => fac === f.value);
              if (selected.length) {
                selectedFacArray.push(f);
              }
            });
            this.handleMultipleFacilityChange(selectedFacArray);
          } else if (getUserType() === "organization") {
            let selectedFacArray = [];
            this.state.facs.forEach((f, i) => {
              let selected = res.filter(fac => fac === f.value);
              if (selected.length) {
                selectedFacArray.push(f);
              }
            });
            this.handleMultipleFacilityChange(selectedFacArray);
          } else {
            selectedFacArray = this.state.facs.filter(fac => fac.value == res);
            if (selectedFacArray.length) {
              this.handleFacChange(selectedFacArray[0]);
            }
          }
        } else {
          this.setState({isLoading:false});
        }
      });
  };

  bindDeptOnEditUser = () => {
    instance
      .get(`Department/GetDepartment?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(async response => {
        if (response.data !== "") {
          if (
            this.state.editUserCode &&
            !this.state.isProvider &&
            this.state.deps
          ) {
            let selectedDepartment = response.data.data;
            this.setState({ dept: [] });
            let selectedDepartmentArray = [];
            selectedDepartment.forEach((department, idx) => {
              this.state.deps.forEach((dept, i) => {
                if (dept.value === department) {
                  selectedDepartmentArray.push(dept);
                }
              });
            });
            this.setState({ dept: selectedDepartmentArray }, () => {
              this.setState({isLoading:false})
              this.props.setLoader(SET_LOADING_FALSE, false);
            });
          } else {
            this.setState({isLoading:false});
            this.props.setLoader(SET_LOADING_FALSE, false);
          }
        } else {
          this.setState({isLoading:false});
          this.props.setLoader(SET_LOADING_FALSE, false);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getInitialDropdownValues = () => {
    if (this.state.isProvider) {
      this.getAllFacForProvider();
      this.getMasterSkillSet();
      this.getSplList();
    } else {
      this.setState({isLoading:false});
    }
  };

  predicateBy = prop => {
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  getDeptList = () => {
    depts = [];
    this.setState({ deptDisable: false });
    instance
      .get(`Facility/GetFacilityDeparmentByUserCode?`, {
        params: { code: this.state.userCode }
      })
      .then(response => {
        var selectedFacArray = response.data.data.filter(
          c => c.value === response.data.data
        )[0];
        selectedFacArray.department.forEach(dept => {
          depts.push({
            label: dept.departmentName,
            value: dept.departmentId
          });
        });
        if (this.state.isUpdate) {
          this.setState({ dept: depts });
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getAllFacForProvider = () => {
    instance
      .get(`Facility/GetAll?`, { params: { code: this.state.userCode } })
      .then(response => {
        if (!response.error) {
          this.setState({ allFacList: response.data.data }, () =>{
            this.setState({isLoading:false});
            this.getFullFacList()
          });
        }
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  fetchFacForNonTessel = () => {
    var facs = [];
    if (this.state.allFacList) {
      this.state.allFacList.forEach(i => {
        facs.push({
          label: i.facilityName,
          value: i.facilityId
        });
      });
    }

    this.setState({ facs: facs }, () => {
      if (this.state.isUpdate) {
        this.bindfetchFacForNonTessel();
      }
    });
  };

  getFullFacList = () => {
    var facs = [];
    this.state.allFacList &&
      this.state.allFacList.forEach(i => {
        facs.push({
          label: i.facilityName,
          value: i.facilityId
        });
      });
    this.setState({ facs: facs }, ()=>   this.props.setLoader(SET_LOADING_TRUE, false));
  };

  validateProviderFacility = () => {
    if(this.state.rows.length > 0 && this.state.rows[0].fac) {
      let isValid = true;
      let selectedFacility = [];
      if(this.state.isUpdate) {
        this.state.selectedProviderFacility.forEach((providerFacility, i) => {
          selectedFacility = this.state.rows.filter((row => row.fac.value === providerFacility.facilityId));
          if(selectedFacility.length > 0 && selectedFacility.length > 1) {
            if(isValid) {
              toast.error("You have selected duplicate facilityx");
            }
            isValid = false;
          }
        });
      } else {
        this.state.rows.forEach((providerFacility, i) => {
          let selectedFacility = this.state.rows.filter((select => select.fac.value === providerFacility.fac.value));
          if(selectedFacility.length > 0 && selectedFacility.length > 1) {
            if(isValid) {
              toast.error("You have selected duplicate facilityx");
            }
            isValid = false;
          }
        })
      }
      return isValid;
    } else {
      return true;
    }
  }

  saveProviderFacility = async () => {
    if(this.state.rows.length > 0 && this.state.rows[0].fac) {
      if(this.state.isUpdate) {
        await this.state.selectedProviderFacility && this.state.selectedProviderFacility.forEach(async selected => {
          let isFound = this.state.rows && this.state.rows.filter(row => row.fac.facilityProviderId === selected.facilityProviderId);
          if(isFound.length === 0) {
            let data = [];
            data['fac'] = {'facilityProviderId':selected.facilityProviderId}
            await this.unMapFacility(data, false);
          }
        });
        this.state.rows.forEach((row, i)=> {
          let saveSelectedFacArray = [];
          saveSelectedFacArray.push({
            providerCode: this.state.editUserCode,
            providerName: this.state.fname + " " + this.state.lname,
            isInteral: true,
            isInternalPerDiem: false,
            isCredentialed: false,
            isExternalProvider: false,
            facilityId: row.fac.value,
            employeeid:row.employeeid ? row.employeeid : null,
            labourType: row.labourtype && row.labourtype.value ? row.labourtype.value : "",
            facilityProviderId: row.fac.facilityProviderId ? row.fac.facilityProviderId : 0
          });
           instance
            .post(`Facility/MapProvider`, saveSelectedFacArray)
            .then(res => {}, this)
            .then(event => {})
            .catch(error => {
              // console.log(error);
            });
        });
      } else {
        this.state.rows.forEach((row, i)=> {
          let saveSelectedFacArray = [];
          saveSelectedFacArray.push({
            providerCode: this.state.editUserCode,
            providerName: this.state.fname + " " + this.state.lname,
            isInteral: true,
            isInternalPerDiem: false,
            isCredentialed: false,
            isExternalProvider: false,
            facilityId: row.fac.value,
            labourType: row.labourtype.value ? row.labourtype.value : "",
            employeeid:row.employeeid ? row.employeeid : null,
            facilityProviderId: row.fac.facilityProviderId ? row.fac.facilityProviderId : 0
          });
           instance
            .post(`Facility/MapProvider`, saveSelectedFacArray)
            .then(res => {}, this)
            .then(event => {})
            .catch(error => {
              // console.log(error);
            });
        });
      }
    }
  };

  fetchFacOnUpdate = () => {
    let selectedFacArray = [];
    instance
      .get(`Facility/GetFacilityDetails?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(response => {
        if (!response.error) {
          this.setState({ isDepView: response.data.data.isScheduleViewOnly });
          if (this.state.editUserCode && this.state.facs) {
            selectedFacArray = this.state.allFacList.filter(
              fac => fac.facilityId == response.data.data.facilities
            );

            if (selectedFacArray.length) {
              this.setState({ fac: selectedFacArray[0] }, () =>
                this.getDepBasedOnFacList()
              );
            }
          }
        }
      })
      .then(() => this.fetchDeptOnUpdate())
      .catch(error => {
        // console.log(error);
      });
  };

  bindfetchFacForNonTessel = () => {
    let selectedFacArray = [];
    instance
      .get(`Facility/GetFacility?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(response => {
        if (this.state.editUserCode && this.state.facs) {
          selectedFacArray = this.state.facs.filter(
            fac => fac.value == response.data.data
          );
        }
        if (selectedFacArray.length) {
          this.handleFacChange(selectedFacArray[0]);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  fetchFacOnUpdateProvider = () => {
    let selectedFacArray = [];
    let selectedLabourTypeArray = [];
    let isDepartment = getUserType() === "department";
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    this.setState({isLoading:true});
    instance
      .get(`Facility/GetFacilityDetails?`, {
        params: { providerCode: this.state.editUserCode, isDepartmentUser: isDepartment, isFacilityUser:isFacility, isOrgUser:isOrgUser  }
      })
      .then(response => {
        // this.setState({isLoading:false});
        if (!response.data.error) {
          if (this.state.editUserCode && this.state.allFacList.length) {
            let selectedFac = null;
            let selectedLabourtype = null;
            let rows = [];
            this.state.allFacList.forEach((facility, idx) => {
                let selectedFacArray = response.data.data.filter(
                  fac => fac.facilityId === facility.facilityId
                );
                if(selectedFacArray.length > 0) {
                  selectedFac = {label:facility.facilityName, value:selectedFacArray[0].facilityId, facilityProviderId:selectedFacArray[0].facilityProviderId};
                  let selectedLabourtypeArray = labourTypes.filter(labour => labour.value == selectedFacArray[0].labourType);
                  if(selectedFacArray) {
                     rows.push({'fac':selectedFac, 'labourtype':selectedLabourtypeArray[0], 'employeeid':selectedFacArray[0].employeeId})
                  }
                }
            });
            this.setState({rows:rows, selectedProviderFacility: response.data.data, isDepView: response.data.data.isScheduleViewOnly}, () => this.setState({isLoading:false}));
          } else {
            this.setState({isLoading:false});
          }
        } else {
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  fetchDeptOnUpdate = () => {
    let selectedDepArray = [];
    instance
      .get(`Department/GetDepartment?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(response => {
        if (
          this.state.editUserCode &&
          !this.state.isProvider &&
          this.state.deps
        ) {
          selectedDepArray = this.state.deps.filter(
            dep => dep.value == response.data.data
          );
          if (selectedDepArray.length) {
            this.setState({ dept: selectedDepArray[0] });
          }
        } else {
          this.props.setLoader(SET_LOADING_FALSE, false);
          this.setState({isLoading:false});
        }
      })
      .catch(error => {
        this.props.setLoader(SET_LOADING_FALSE, false);
        this.setState({isLoading:false});
        // console.log(error);
      });
  };

  fetchOrgOnUpdate = () => {
    let selectedOrgArray = [];
    instance
      .get(`Organization/GetOrganization?`, {
        params: { userCode: this.state.editUserCode }
      })
      .then(response => {
        if (
          this.state.editUserCode &&
          !this.state.isProvider &&
          this.state.orgs
        ) {
          selectedOrgArray = this.state.orgs.filter(
            c => c.value == response.data.data
          );
        }
        this.handleOrgChange(selectedOrgArray[0]);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  setMode = state => {
    this.setState({ isUpdate: state });
  };

  skillValidation = async () => {
    let status = true
    if(this.state.rows.length !== 0)  {
      this.state.rows.forEach((row) => {
        if(row.fac == undefined || row.fac == null || row.labourtype == null || row.labourtype == undefined || Object.keys(row.fac).length === 0 || Object.keys(row.labourtype).length === 0) {
          //|| row.employeeid == ""
          status = false
        }
      })
     return status;
    } else {
      return false
    }
  }

  updateUser = async () => {
    let dob1 = this.formatDate();
    const { skill, SPL } = this.state;
    let selectedSkill = [];
    skill && skill.forEach((element, i) => {
      selectedSkill.push(skill[i].label);
    });

    let selectedSpecialty = [];
    SPL && SPL.forEach((element, i) => {
      selectedSpecialty.push(SPL[i].label);
    });
    if (this.state.isProvider) {
      let status = await this.skillValidation()
      if(!status) {
        this.setState({isSubmitted:true})
        return false
      }
    }

    const { email, password, fname, lname, cell, dob, rows, npi} = this.state;
    if (
      email !== "" &&
      password !== "" &&
      fname !== "" &&
      lname !== "" &&
      cell !== "" &&
      dob !== ""
    ) {
      this.setState({ isMandate: false });
      if (this.state.isProvider) {
        let status = await this.skillValidation()
          if(status && (rows.length ? this.validateProviderFacility() : true) && (skill && skill.length > 0) && (SPL && SPL.length > 0)) {
            instance
            .post(`Providers/Update`, {
              userId: this.state.userId,
              password: this.state.password,
              emailId: this.state.email,
              firstName: this.state.fname,
              lastName: this.state.lname,
              npi: this.state.npi,
              phone: this.state.cell,
              zip: this.state.zip,
              isWeb: true,
              dob: dob1,
              specialty: selectedSpecialty,
              skill: selectedSkill
            })
            .then(res => {
              if(res.data.error !== undefined) {
                toast.error(res.data.error.message);
              }
            }, this)
            .then(async event => {
              if(this.state.rows.length > 0) {
                await this.saveProviderFacility();
              }
              EventEmitter.emit("userCreated", event);
              toast.success("User updated successfully.");
            })
            .catch(error => {
              // console.log(error);
            });
          } else {
            this.setState({ isMandate: true, isSubmitted:true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              if(this.state.selectedTabs == 0) {
                this.onAccessTabClick(1)
              }
            } else {
              if(this.state.selectedTabs == 1) {
                this.onAccessTabClick(0)
              }
            }
          }
        // } else {
          //  this.setState({ isMandate: false, isLaborTypeMandate: false });
        // }
      } else {
        if (this.state.isOrgUser) {
          if(!this.state.facility && (this.state.facility && this.state.facility.length === 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          } else if(!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) {
            this.setState({ isMandate: true });
            if(email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          }
        } else if (this.state.isFacUser) {
          if(!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          }
        } else if (this.state.isTesselMedAdmin) {
         if(!(this.state.isOrgUser
          ? this.state.orgUserOrg
          : this.state.org && this.state.org.value)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && (!this.state.isProvider && npi === "")) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          } else if(!this.state.facility && (this.state.facility || this.state.facility.length === 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          } else if(!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          }
        }
        
        this.setState({ submitted: true }, () => {
          setTimeout(() => this.setState({ submitted: false }), 5000);
        });
        instance
          .post(`Users/Update`, {
            userId: this.state.userId,
            emailId: this.state.email,
            password: this.state.password,
            firstName: this.state.fname,
            lastName: this.state.lname,
            npi: this.state.npi,
            phone: this.state.cell,
            zip: this.state.zip,
            dob: dob1
          })
          .then(res => {
            if(res.data.error !== undefined) {
              toast.error(res.data.error.message);
            }
          }, this)
          .then(async event => {
            if (this.state.isOrgUser) {
              // this.saveFacility();
              await this.saveMultipleFacility();
              await this.saveDepartment();
            } else if (this.state.isFacUser) {
              await this.saveDepartment();
            } else if (this.state.isTesselMedAdmin) {
              this.saveOrganization();
              await this.saveMultipleFacility();
              await this.saveDepartment();
            }
            toast.success("User updated successfully.");
            EventEmitter.emit("userCreated", event);
          })
          .catch(error => {
            // console.log(error);
          });
      }
    } else {
      this.setState({ isMandate: true });
      if( email !== "" &&
      fname !== "" &&
      lname !== "" &&
      cell !== "" &&
      dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
        this.onAccessTabClick(1)
      } else {
        this.onAccessTabClick(0)
      }
     
    }
  };

  createUser = async () => {
    let dob1 = this.formatDate();
    const {
      email,
      password,
      fname,
      lname,
      cell,
      dob,
      npi,
      fac,
      isFacUser,
      rows,
      facility
    } = this.state;
    if (this.state.isProvider) {
      let status = await this.skillValidation()
      if(!status) {
        this.setState({isSubmitted:true})
        return false
      }
    }
    if (
      email !== "" &&
      password !== "" &&
      fname !== "" &&
      lname !== "" &&
      cell !== "" &&
      dob &&
      (isFacUser || (rows.length && rows[0].fac && rows[0].fac.value !== undefined) || this.state.facility.length > 0)
      &&
      (isFacUser || (rows.length && rows[0].labourtype && rows[0].labourtype.value !== undefined) || this.state.facility.length > 0)
    ) {
      this.setState({ isMandate: false, isFacMandate: (isFacUser || (rows.length && rows[0].fac.value !== undefined) || this.state.facility.length > 0), isLaborTypeMandate:((rows.length && rows[0].labourtype.value !== undefined) || this.state.facility.length > 0) });
      if (this.state.isProvider) {
        let status = await this.skillValidation()
        if(status && this.validateProviderFacility() && (this.state.skill && this.state.skill.length > 0) && (this.state.SPL && this.state.SPL.length > 0)) {
          const { skill, SPL } = this.state;
          let selectedSkill = [];
          skill.forEach((element, i) => {
            selectedSkill.push(skill[i].label);
          });
  
          let selectedSpecialty = [];
          SPL.forEach((element, i) => {
            selectedSpecialty.push(SPL[i].label);
          });
  
          instance
            .post(`Providers/Register`, {
              emailId: this.state.email,
              password: this.state.password,
              firstName: this.state.fname,
              lastName: this.state.lname,
              npi: this.state.npi,
              phone: this.state.cell,
              zip: this.state.zip,
              isWeb: true,
              dob: dob1,
              specialty: selectedSpecialty,
              skill: selectedSkill
            })
            .then(res => {
              if(res.data.error !== undefined) {
                toast.error(res.data.error.message);
              } else {
                this.setState({ editUserCode: res.data.data.userCode }, async ()=> {
                  await this.saveProviderFacility();
                  EventEmitter.emit("userCreated", true);
                  toast.success("User created successfully.");
                });
              }
            }).then(async (event) => {
                // this.saveProviderFacility();
                // EventEmitter.emit("userCreated", event);
                // toast.success("User created successfully.");
             })
            .catch(error => {
              // console.log(error);
            });
        } else {
          this.setState({ isSubmitted:true, isMandate: true, isFacMandate: (isFacUser || (rows.length && rows[0].fac.value !== undefined) || this.state.facility.length > 0), isLaborTypeMandate: (isFacUser || (rows.length && rows[0].labourtype.value !== undefined)) });
            if( email !== "" &&
            password !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              if(this.state.selectedTabs == 0) {
                this.onAccessTabClick(1)
              }
            } else {
              if(this.state.selectedTabs == 1) {
                this.onAccessTabClick(0)
              }
          }
        }
      } else {
        if (this.state.isOrgUser) {
          if(!this.state.facility && (this.state.facility && this.state.facility.length === 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          } else if(!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) {
            this.setState({ isMandate: true });
            if(email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          }
        } else if (this.state.isFacUser) {
          if(!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          }
        } else if (this.state.isTesselMedAdmin) {
         if(!(this.state.isOrgUser
          ? this.state.orgUserOrg
          : this.state.org && this.state.org.value)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          } else if(!this.state.facility && (this.state.facility || this.state.facility.length === 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          } else if(!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) {
            this.setState({ isMandate: true });
            if( email !== "" &&
            fname !== "" &&
            lname !== "" &&
            cell !== "" &&
            dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
              this.onAccessTabClick(1)
            } else {
              this.onAccessTabClick(0)
            }
            return false
          }
        }
        if(this.state.isOrgUser) {
          const { facility } = this.state;
          if(!facility || facility.length == 0) {
            return false 
          }
        }
        this.setState({ submitted: true }, () => {
          setTimeout(() => this.setState({ submitted: false }), 5000);
        });
        instance
          .post(`Users/Register`, {
            emailId: this.state.email,
            password: this.state.password,
            firstName: this.state.fname,
            lastName: this.state.lname,
            npi: this.state.npi,
            phone: this.state.cell,
            isInteral: this.state.isInternal,
            isInternalPerDiem: false,
            isCredentialed: this.state.isApproved,
            zip: this.state.zip,
            dob: dob1
          })
          .then(res => {
            if(res.data.error !== undefined) {
              toast.error(res.data.error.message);
            } else {
              this.setState({ editUserCode: res.data.data.userCode }, async () => {
                if (this.state.isOrgUser) {
                  // this.saveFacility();
                  await this.saveMultipleFacility();
                  await this.saveDepartment();
                }
                if (this.state.isFacUser) {
                  await this.saveDepartment();
                }
                if (this.state.isTesselMedAdmin) {
                  this.saveOrganization();
                  await this.saveMultipleFacility();
                  await this.saveDepartment();
                }
                EventEmitter.emit("userCreated", true);
                toast.success("User created successfully.");
              });
            }
          }, this)
          .then(async event => {
          })
          .catch(error => {
            // console.log(error);
          });
      }
    } else {
      this.setState({ isMandate: true, isFacMandate: (isFacUser || (rows.length && rows[0].fac.value !== undefined) || this.state.facility.length > 0), isLaborTypeMandate: (isFacUser || (rows.length && rows[0].labourtype.value !== undefined)) });
      if( email !== "" &&
      password !== "" &&
      fname !== "" &&
      lname !== "" &&
      cell !== "" &&
      dob && ((this.state.isProvider && npi !== "") || (!this.state.isProvider && npi === ""))) {
        if(this.state.selectedTabs == 0) {
          this.onAccessTabClick(1)
        }
      } else {
        this.onAccessTabClick(0)
      }
    }
  };

  editUser = event => {
    let skill = [];
    event.data.skill.forEach(i => {
      skill.push({
        label: i,
        value: i
      });
    });
    let selectedSpeciality = [];
    event.data.specialty.forEach(i => {
      selectedSpeciality.push({
        label: i,
        value: i
      });
    });

    this.setState(
      {
        userId: event.data.userId,
        fname: event.data.firstName,
        lname: event.data.lastName,
        npi: event.data.npi,
        email: event.data.emailId,
        cell: event.data.phone,
        zip: event.data.zip,
        isProvider: event.data.isProvider,
        isUserDisabled: event.data.isProvider,
        isProviderDisabled: !event.data.isProvider,
        isUser: !event.data.isProvider,
        password: event.data.password,
        isUpdate: true,
        editUserCode: event.data.userCode,
        dob: new Date(event.data.dob),
        skill: skill ? skill : [],
        SPL: selectedSpeciality ? selectedSpeciality : [],
        editUserType: event.data.userType,
        isSubmitted: false
      },
      () => {
        if (this.state.editUserCode) {
          if (
            !this.state.isProvider &&
            getUserType() === "admin" &&
            (this.state.editUserType === "organization" ||
              this.state.editUserType === "facility" ||
              this.state.editUserType === "department")
          ) {
            this.setState({isLoading:true});
            if (this.state.editUserType === "organization") {
              this.getAllOrganizationByCode();
            } else if (this.state.editUserType === "facility" || this.state.editUserType === "generic") {
              this.getAllFacilityByCode();
            } else if (this.state.editUserType === "department") {
              this.getAllDepartmentByCode();
            }
          } else if (
            !this.state.isProvider &&
            getUserType() === "organization" &&
            this.state.editUserType === "facility"
          ) {
            this.setState({isLoading:true});
            this.getAllOrganizationByCode();
          } else if (
            !this.state.isProvider &&
            getUserType() === "organization" &&
            this.state.editUserType === "generic"
          ) {
            this.setState({isLoading:true});
            this.getAllOrganizationByCode();
          }  else if (
            !this.state.isProvider &&
            getUserType() === "facility" &&
            this.state.editUserType === "department"
          ) {
            this.setState({isLoading:true});
            this.getAllFacilityByCode();
          } else if (this.state.isProvider) {
            this.setState({ facDisabled: false }, () => {
              this.getInitialDropdownValues();
            });
          }
        }
      }
    );
  };

  saveMultipleFacility = async () => {
    const { facility } = this.state;
    let selectedFacilityArray = [];
    if(this.state.isGenericView) {
      selectedFacilityArray.push(facility[0].value)
    } else {
      facility && facility.length !== 0 && facility.forEach(faci => {
        selectedFacilityArray.push(faci.value)
      });
    }
  
    instance
    .post(`Facility/MapUser`, { userCode: this.state.editUserCode,
      userName: this.state.fname + " " + this.state.lname,
      facilityId: selectedFacilityArray,
      isViewScheduler: this.state.isDepView,
      isGenericUser: this.state.isGenericView
    })
    .then(res => {
    }, this)
    .then(event => {})
    .catch(error => {
      // console.log(error);
    });
  };

  saveFacility = () => {
    instance
      .post(`Facility/MapUser`, {
        userCode: this.state.editUserCode,
        userName: this.state.fname + " " + this.state.lname,
        facilityId: [this.state.isFacUser
          ? this.state.facUserFac
          : this.state.fac.value],
        isViewScheduler: this.state.isDepView
      })
      .then(res => {
      }, this)
      .then(event => {})
      .catch(error => {
        // console.log(error);
      });
  };

  saveOrganization = () => {
    instance
      .post(`Organization/MapUser`, {
        userCode: this.state.editUserCode,
        userName: this.state.fname + " " + this.state.lname,
        organizationId: this.state.isOrgUser
          ? this.state.orgUserOrg
          : this.state.org.value
      })
      .then(res => {
      }, this)
      .then(event => {})
      .catch(error => {
        // console.log(error);
      });
  };

  saveDepartment = async () => {
    const {dept} = this.state
    let selectedDpartmentArray = [];
    let isFacility = getUserType() === "facility";
    dept.forEach(dep => {
      selectedDpartmentArray.push(dep.value)
    });

    instance
        .post(`Department/MapUser`, { 
          userCode: this.state.editUserCode,
          userName: this.state.fname + " " + this.state.lname,
          departmentId: selectedDpartmentArray,
          isAdmin: isFacility
          })
        .then(res => {
        }, this)
        .then(event => {})
        .catch(error => {
          // console.log(error);
        });
  };

  closeModal = () => {
    EventEmitter.emit("closeModal", "");
  };

  onProviderChange = () => {
    this.setState({ isProvider: !this.state.isProvider, isUser: false }, () => {
      this.getInitialDropdownValues();
    });
  };

  onUserChange = () => {
    this.setState({ isUser: !this.state.isUser, isProvider: false }, () => {
      this.getInitialDropdownValues();
    });
  };

  formatDate = () => {
    let year = (new Date(this.state.dob).getFullYear().toString());
    let month = (new Date(this.state.dob).getMonth() + 1).toString();
    let date = (new Date(this.state.dob).getDate().toString());

    if (month.length === 1) {
      month = 0 + month;
    }
    if (date.length === 1) {
      date = 0 + date;
    }

    let dob = month + "-" + date + "-" + year;
    return dob;
  };

  handleSPLChange = selectedSPL => {
    this.setState({ SPL: selectedSPL });
  };

  handleRowChange = (idx, name) => e => {
    let rows = this.state.rows;
    if (rows.length) {
      if (name === "fac") {
        rows[idx]["fac"] = e;
      } else if (name === "labourtype") {
        rows[idx]["labourtype"] = e;
      } else if(name === "employeeid") {
        rows[idx]["employeeid"] = e.target.value;
      }
    }
    this.setState({
      rows
    });
  };

  handleAddRow = (row) => {
    this.setState({
      rows: [...this.state.rows, {}]
    });
  };

  handleRemoveRow = () => {
    this.setState(
      {
        rows: this.state.rows.slice(0, -1)
      },
      () => {
        if (this.state.rows.length === 0) {
          this.handleAddRow();
        }
      }
    );
  };

  unMapFacility = async (row, showMessage = true) => {
    if(this.state.userId && row.fac.facilityProviderId) {
      instance
      .post(`Facility/UnmapProvider/?id=${row.fac.facilityProviderId}`)
      .then(res => {
          if(res.data.error !== undefined) {
            toast.error(res.data.error.message);
          } else {
            if(showMessage) {
              toast.success("Facility removed successfully.");
            }
          }
        }, this)
      .then(() => this.fetchFacOnUpdateProvider())
      .catch(error => {
        // console.log(error);
      });
    }
  }
  
  handleRemoveSpecificRow = async (rowIndex) => {
    let rows = [...this.state.rows];
    if (this.state.isUpdate) {
      if(this.state.rows[Number(rowIndex)].fac && typeof this.state.rows[Number(rowIndex)].fac.value !== undefined && this.state.rows[Number(rowIndex)].fac.facilityProviderId !== undefined) {
        rows.splice(Number(rowIndex), 1);
        await this.unMapFacility(this.state.rows[Number(rowIndex)]);
      } else {
        rows.splice(Number(rowIndex), 1);
      }
    } else {
      rows.splice(Number(rowIndex), 1);
    }
    this.setState({ rows: rows}, () => {
      if (this.state.rows.length === 0) {
        this.handleAddRow();
      }
    });
  };

  handleSkillChange = selectedSkill => {
    this.setState({ skill: selectedSkill });
  };

  setSelectedProviderFac = fac => {
    this.setState({ selectedProviderFacilities: fac });
  };

  handleProviderFacChange = fac => {
    this.setState({ fac });
  };

  handleLabourTypeChange = labourType => {
    this.setState({ labourType });
  };

  render() {
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const errorStyle = {
      color: "#FF0000",
      marginLeft:10
    };
    const gridPaddingStyles = {
      marginTop: "1.25%",
      marginLeft: "1%"
    };
    
    return (
      <div
        style={{
        
        }}
      >
        {/* {this.props.isFetchLoading && (
          <BeatLoader
            css={override}
            size={35}
            color={"#137bc0"}
            loading={this.props.isFetchLoading}
          />
        )} */}
        <Grid item xs={12} sm={12} md={12}>
          <Tabs
            selectedIndex={this.state.selectedTabs}
            defaultIndex={0}
            onSelect={index => {
              if(!this.state.isLoading){
                this.onAccessTabClick(index)
              }
            }}
          >
            <TabList>
              <Tab>Info</Tab>
              <Tab>Access</Tab>
            </TabList>
            {
              this.state.isLoading && <ColorLinearProgress style={{marginBottom:10}}/>
            }
            <ValidatorForm
              ref="user-form"
              onSubmit={this.state.isUpdate ? this.updateUser : this.createUser}
              onError={errors => console.log(errors)}
            >
              
              <TabPanel>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}>
                    <RadioButton
                      name="provider"
                      onChange={this.onProviderChange}
                      checked={this.state.isProvider}
                      disabled={this.state.isProviderDisabled}
                    />
                    <label htmlFor="cb1" className="p-radiobutton-label">
                      Provider
                    </label>
                  </Grid>
                  {getAccessLevel(0) ? (
                    <Grid item xs={12} sm={3}>
                      <RadioButton
                        name="user"
                        onChange={this.onUserChange}
                        checked={this.state.isUser}
                        // checked={!this.state.isUpdate && !this.state.isProvider ? true : this.state.isUser}
                        // checked={
                        //   !this.state.isUpdate &&
                        //   !this.state.isProvider &&
                        //   !this.state.isUser
                        //     ? true
                        //     : this.state.isUser
                        // }
                        disabled={this.state.isUserDisabled}
                      />
                      <label htmlFor="cb2" className="p-radiobutton-label">
                        Admin
                      </label>
                    </Grid>
                  ) : (
                    <Grid></Grid>
                  )}
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      id="fname"
                      label="First Name *"
                      value={this.state.fname}
                      onChange={this.handleChange("fname")}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="fname"
                      inputProps={{ maxLength: 15 }}
                      validators={["required"]}
                      errorMessages={["First Name is required"]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      id="lname"
                      label="Last Name *"
                      value={this.state.lname}
                      onChange={this.handleChange("lname")}
                      margin="dense"
                      variant="outlined"
                      name="lname"
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      validators={["required"]}
                      errorMessages={["Last Name is required"]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Date of birth *"
                          format="MM/dd/yyyy"
                          maxDate={new Date().setFullYear(Number((new Date().getFullYear())) - 5)}
                          disableFuture={true}
                          InputProps={{
                            disableUnderline: true,
                            classes: this.props.classes
                          }}
                          value={this.state.dob}
                          InputAdornmentProps={{ shrink: true, position: "end" }}
                          onChange={this.handleDateChange}
                          error={this.state.isMandate && !this.state.dob}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                    {this.state.isMandate && !this.state.dob ? (
                      <h6 style={errorStyle}>*Mandatory field</h6>
                    ) : (
                      <h6></h6>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      id="cell"
                      label="Mobile No *"
                      value={this.state.cell}
                      autoComplete={"off"}
                      onChange={this.handleChange("cell")}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 10, minLength:10}}
                      validators={["required", "minStringLength:10", "maxStringLength:10", "isPositive"]}
                      errorMessages={["Mobile No is required", "Mobile No should be 10 digit", "Mobile No should be 10 digit", "Mobile No is not valid"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      id="email"
                      label="E-Mail *"
                      value={this.state.email}
                      autoComplete={"off"}
                      onChange={this.handleChange("email")}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "E-Mail is required",
                        "E-Mail is not valid"
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {
                      this.state.userId ? 
                      <TextValidator
                      name="password"
                      label="Password *"
                      autoComplete={"off"}
                      value={this.state.password}
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleChange("password")}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                      :   <TextValidator
                      name="password"
                      label="Password *"
                      value={this.state.password}
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleChange("password")}
                      margin="dense"
                      variant="outlined"
                      validators={["required", "minStringLength:6"]}
                      errorMessages={["Password is required", "Password should be length should be six character"]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      id="zip"
                      label="Zip Code *"
                      value={this.state.zip}
                      onChange={this.handleChange("zip")}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      // inputProps={{ maxLength: 5 }}
                      validators={["required"]}
                      errorMessages={["Zip Code is required"]}
                    />
                  </Grid>
                  {!this.state.isUser && (
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        id="npi"
                        label="NPI *"
                        value={this.state.npi}
                        onChange={this.handleChange("npi")}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        // inputProps={{ maxLength: 10 }}
                        validators={["required"]}
                        errorMessages={["NPI is required"]}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid container spacing={1} style={{display:"flow-root"}}>
                  {!this.state.isProvider && ( //This is for login with TesselmedAdmin, Organization, Facility, Department usertype login with creating new users
                    <>
                    {getUserType() === "organization" && (<Grid item xs={3} style={{marginTop:15, marginBottom:10, display:"flow-root", left:0}} sm={3}>
                        <Checkbox
                          inputId="cb2"
                          value={this.state.isGenericView}
                          onChange={this.handleCheckGenericChange}
                          checked={this.state.isGenericView}
                          style={{left:0}}
                        ></Checkbox>
                        <label htmlFor="cb2" style={{left:0}} className="p-checkbox-label">
                          Generic View Admin
                        </label>
                      </Grid>)}
                      {((this.state.isUpdate &&
                        this.state.editUserType === "organization" &&
                        getUserType() === "admin") ||
                        (!this.state.isUpdate &&
                          getUserType() === "admin")) ? (
                            <>
                        <Grid item xs={12} sm={12}>
                          <Select
                            styles={selectStyles}
                            value={this.state.org}
                            onChange={this.handleOrgChange}
                            options={this.state.orgs}
                            placeholder="Select Organization"
                            isSearchable={true}
                          />
                        </Grid>
                        {this.state.isMandate && this.state.isTesselMedAdmin &&
                          (!this.state.org || ((this.state.org !== null) && this.state.org.length == 0)) && (
                              <h6 style={errorStyle}>
                                Please select organization
                              </h6>
                            )}
                        </>
                      ): null}

                      {((this.state.isUpdate &&
                        (getUserType() === "admin" ||
                          getUserType() === "organization") &&
                        (this.state.editUserType === "facility" ||
                          this.state.editUserType === "generic" ||
                          this.state.editUserType === "organization")) ||
                        (!this.state.isUpdate &&
                          (getUserType() === "admin" ||
                            getUserType() === "organization"))) && (
                        <>
                          <Grid item xs={12} sm={12}>
                            {
                              this.state.isGenericView ? <Select
                                  styles={selectStyles}
                                  value={this.state.facility}
                                  onChange={this.handleFacilityChangeForGeneric}
                                  options={this.state.facs}
                                  placeholder="Select Facility *"
                                  isSearchable={true}
                                  validators={["required"]}
                                  errorMessages={["Please select facility"]}
                                  isError={this.state.facility}
                                /> : <Select
                                  styles={selectStyles}
                                  value={this.state.facility}
                                  onChange={this.handleMutipleFacilityChange}
                                  options={this.state.facs}
                                  placeholder="Select Facility *"
                                  isSearchable={true}
                                  validators={["required"]}
                                  errorMessages={["Please select facility"]}
                                  isError={this.state.facility.length === 0}
                                  isMulti={!this.state.isProvider}
                                />
                            }
                          </Grid>
                          {!this.state.isFacMandate &&
                              this.state.fac === "" &&
                              this.state.facility &&
                              !this.state.facility.length && (
                                <h6 style={errorStyle}>
                                  Please select facility
                                </h6>
                              )}
                        </>
                      )}

                      {((this.state.isUpdate &&
                        (getUserType() === "admin" ||
                          getUserType() === "organization" ||
                          getUserType() === "generic" ||
                          getUserType() === "facility") &&
                        (this.state.editUserType === "facility" ||
                        this.state.editUserType === "generic" ||
                          this.state.editUserType === "organization" ||
                          this.state.editUserType === "department")) ||
                        (!this.state.isUpdate &&
                          (getUserType() === "admin" ||
                            getUserType() === "organization" ||
                            getUserType() === "generic" ||
                            getUserType() === "facility"))) ? (
                              <>
                        <Grid item xs={12} sm={12}>
                          <Select
                            styles={selectStyles}
                            value={this.state.dept}
                            onChange={this.handleDeptChange}
                            options={this.state.deps}
                            placeholder="Select Department"
                            isSearchable={true}
                            isDisabled={
                              this.state.deptDisabled && !this.state.isFacUser
                                ? this.state.editUserType === "department"
                                  ? this.state.isGenericView ? true : false
                                  : true
                                : this.state.isGenericView ? true : false
                            }
                            isMulti={true}
                          />
                        </Grid>
                        {this.state.isMandate &&
                          (!this.state.dept || ((this.state.dept !== null) && this.state.dept.length == 0)) && (
                              <h6 style={errorStyle}>
                                Please select department
                              </h6>
                            )}
                        </>     
                      ) : null}

                      {getUserType() === "organization" && ( //This option for only login with Organization user while creating users
                        <Grid item xs={3} style={{marginTop:15, display:"flow-root", left:0}} sm={3}>
                          <Checkbox
                            inputId="cb1"
                            value={this.state.isDepView}
                            onChange={this.handleCheckChange}
                            checked={this.state.isDepView}
                            style={{left:0}}
                          ></Checkbox>
                          <label htmlFor="cb1" style={{left:0}} className="p-checkbox-label">
                            Department View Admin
                          </label>
                        </Grid>
                      )}
                    </>
                  )}

                  {this.state.isProvider && ( //This is for when creating user type of Provider(QHP)
                    <>
                      <Grid item xs={12} sm={12}>
                        <Select
                          className="dropdown_select"
                          styles={selectStyles}
                          onChange={this.handleSPLChange}
                          options={this.state.spls}
                          placeholder="Select Speciality"
                          isSearchable={true}
                          fullWidth
                          defaultValue={this.state.SPL ? this.state.SPL : []}
                          isMulti={true}
                        />
                         
                      </Grid>
                      {((!this.state.SPL && this.state.isMandate) || (this.state.SPL && this.state.SPL.length === 0 && this.state.isMandate)) ? 
                        <h6 style={errorStyle}>Please select speciality</h6>
                      : null}
                      <Grid item xs={12} sm={12}>
                        <Select
                          name="skill"
                          onChange={this.handleSkillChange}
                          options={this.state.skilllevel}
                          defaultValue={
                            this.state.skill ? this.state.skill : []
                            
                          }
                          placeholder="Select Skill"
                          isSearchable={true}
                          isMulti={true}
                        />
                      </Grid>
                      {((!this.state.skill && this.state.isMandate) || (this.state.skill && this.state.skill.length === 0 && this.state.isMandate)) ? 
                          <h6 style={errorStyle}>Please select skill</h6>
                      : null}
                      <Grid item xs={12} sm={12}>
                        <div className="skills_container">
                            <table
                              className="table table-bordered table-hover"
                              id="tab_logic"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center"> # </th>
                                  <th className="text-center"> Select Facility </th>
                                  <th className="text-center"> Select labor type  </th>
                                  <th className="text-center"> Employee # </th>
                                  <th className="text-center"> Delete </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.rows.map((item, idx) => (
                                  <tr id={idx} key={idx}>
                                    <td>{idx+1}</td>
                                    <td>
                                      <Select
                                        styles={selectStyles}
                                        value={this.state.rows[idx].fac}
                                        onChange={this.handleRowChange(idx, "fac")}
                                        options={this.state.facs}
                                        validators={["required"]}
                                        placeholder="Select Facility *"
                                        isSearchable={true}
                                        errorMessages={["Please select facility type"]}
                                        isError={(this.state.isSubmitted && !this.state.rows[idx].fac || (this.state.isSubmitted && this.state.rows[idx].fac && this.state.rows[idx].fac != null && this.state.rows[idx].fac != undefined && Object.keys(this.state.rows[idx].fac).length === 0))}
                                      />
                                      {(this.state.isSubmitted && !this.state.rows[idx].fac || (this.state.isSubmitted && this.state.rows[idx].fac && this.state.rows[idx].fac != null && this.state.rows[idx].fac != undefined && Object.keys(this.state.rows[idx].fac).length === 0)) ? (
                                        <h6 style={errorStyle}>Please select facility</h6>
                                      ) : (
                                        null
                                      )}
                                   
                                    </td>
                                    <td>
                                      <Select
                                        name="labourtype"
                                        styles={selectStyles}
                                        value={this.state.rows[idx].labourtype}
                                        onChange={this.handleRowChange(idx, "labourtype")}
                                        options={labourTypes}
                                        placeholder="Select labor type *"
                                        isSearchable={true}
                                        errorMessages={["Please select labor type"]}
                                        isError={(this.state.isSubmitted && !this.state.rows[idx].labourtype && this.state.rows[idx].labourtype == undefined || (this.state.isSubmitted && this.state.rows[idx].labourtype && this.state.rows[idx].labourtype != null && this.state.rows[idx].labourtype != undefined && Object.keys(this.state.rows[idx].labourtype).length === 0))}
                                      />
                                       {(this.state.isSubmitted && !this.state.rows[idx].labourtype || (this.state.isSubmitted && this.state.rows[idx].labourtype && this.state.rows[idx].labourtype != null && this.state.rows[idx].labourtype != undefined && Object.keys(this.state.rows[idx].labourtype).length === 0)) && (
                                        <h6 style={errorStyle}>Please select labor type</h6>
                                      )}
                                    </td>
                                    <td>
                                    <TextValidator
                                        id="employeeid"
                                        label="Employee #"
                                        value={this.state.rows[idx].employeeid}
                                        onChange={this.handleRowChange(idx, "employeeid")}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        onClick={() => this.handleAddRow(this.state.rows[idx])}
                                        className="btn btn-primary"
                                        disabled={this.state.rows.length !== idx+1 || (this.state.rows[idx].fac && this.state.rows[idx].fac.value === undefined) || (this.state.rows[idx].labourtype && this.state.rows[idx].labourtype.value === undefined)}
                                      >
                                        {" + "}
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={()=>this.handleRemoveSpecificRow(idx)}
                                        // disabled={this.state.isUpdate && (this.state.selectedProviderFacility.length === 1 && this.state.rows[idx].fac && this.state.rows[idx].fac.value === this.state.selectedProviderFacility[0].facilityId)}
                                      >
                                         {" - "}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>             
                      </Grid>
                    </>
                  )}
                </Grid>
              </TabPanel>

              <div className="button-wrapper primary">
                <Button
                  variant="contained"
                  className="button-primary"
                  color="primary"
                  type="submit"
                  disabled={this.state.isLoading}
                >
                  {this.state.isUpdate === false ? "CREATE" : "UPDATE"}
                </Button>
              </div>
              <div className="button-wrapper">
                <Button
                  variant="contained"
                  className="button-cancel"
                  color="secondary"
                  onClick={this.closeModal}
                >
                  {"CANCEL"}
                </Button>
              </div>
            </ValidatorForm>
          </Tabs>
        </Grid>
      </div>
    );
  }
}

const setLoader = (type, value) => ({ type: type, value });

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn, setLoader }, dispatch);
};

const mapStateToProps = state => ({
  signInPayload: state.signin.signInPayload,
  isFetchLoading: state.loader.isFetchLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);