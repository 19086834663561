import {
    SET_LOADING_TRUE,
    SET_LOADING_FALSE
  } from "../actions/actionTypes";
  
  const initialState = {
    isFetchLoading: false
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_LOADING_TRUE:
        return {
          ...state,
          isFetchLoading: true
        };
      case SET_LOADING_FALSE:
        return {
          ...state,
          isFetchLoading: false
        };
      default:
        return state;
    }
  };
  