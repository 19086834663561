import React, { Component, Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  CssBaseline,
  MenuItem,
  MenuList,
  withStyles,
  makeStyles
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../logo.png";
import {userName} from '../settings/index'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  },

  userName: {
    position: 'absolute',
    right: '135px',
    fontSize: '18px',
}
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const Header = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function onLogout() {
    localStorage.removeItem("api_token");
    localStorage.removeItem("userCode");
    localStorage.removeItem("isTesselMedAdmin");
  
    localStorage.removeItem("is_org_user");
    localStorage.removeItem("is_fac_user");
    localStorage.removeItem("is_dep_user");
    localStorage.removeItem("isSchedulerView");
    localStorage.removeItem("userName");
    localStorage.removeItem("loginUserType");
    localStorage.removeItem("generic");
    localStorage.clear();
    // props.history.push("/");
    window.location.href = "/";
    // setAnchorEl(null);
  }

  const popperStyle = {
    marginLeft: "90%"
  };
  const logoStyle = {
    marginLeft: "4%",
    position: "fixed"
  };

  return (
    <Fragment>
      <CssBaseline />
      <div className="header-main">
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h4" style={logoStyle}>
              <div className="logo">
                <img src={logo} />
              </div>
            </Typography>
            <Typography variant="h5" className={classes.userName}>
            {userName()}
            </Typography>
            <FontAwesomeIcon
              className={"buttonStyle"}
              icon={faUserCircle}
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={handleClick}
            />
           

            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuList>
                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </MenuList>
            </StyledMenu>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  );
};

export default Header;
