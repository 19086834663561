import React, { Component, Fragment } from "react";
import ReportDataTable from "../containers/Report/ReportDataTable";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    const bgImage= require('../backg.png');
    document.body.style.backgroundImage = `url(${bgImage})`;
  }

  render() {
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 9999 }) };
    return (
      <Fragment>
        <ReportDataTable selectStyles={selectStyles}></ReportDataTable>
      </Fragment>
    );
  }
}
export default Report;
