import React from "react";

export default ({width = "26px", height = "26px"}) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path
            d="M35.33,47 L40.33,47 C40.8718133,45.2408682 42.6186771,44.1424753 44.4388566,44.4164333 C46.2590361,44.6903913 47.6051899,46.2543191 47.6051899,48.095 C47.6051899,49.9356809 46.2590361,51.4996087 44.4388566,51.7735667 C42.6186771,52.0475247 40.8718133,50.9491318 40.33,49.19 L34.24,49.19 C33.9482619,49.19 33.6684726,49.0741075 33.4621825,48.8678175 C33.2558925,48.6615274 33.14,48.3817381 33.14,48.09 L33.14,31.85 L28.25,31.85 L28.25,29.66 L33.14,29.66 L33.14,14 C33.1399878,13.709173 33.2561984,13.430401 33.462781,13.2256964 C33.6693636,13.0209919 33.9491853,12.9073319 34.24,12.91 L40.31,12.91 C40.8519848,11.1451332 42.6035213,10.0422881 44.4292845,10.3163158 C46.2550477,10.5903435 47.6056521,12.158787 47.6056521,14.005 C47.6056521,15.851213 46.2550477,17.4196565 44.4292845,17.6936842 C42.6035213,17.9677119 40.8519848,16.8648668 40.31,15.1 L35.31,15.1 L35.31,29.66 L40.31,29.66 C40.8521553,27.8893993 42.6083635,26.782102 44.43971,27.056199 C46.2710565,27.330296 47.6261117,28.9032551 47.6261117,30.755 C47.6261117,32.6067449 46.2710565,34.179704 44.43971,34.453801 C42.6083635,34.727898 40.8521553,33.6206007 40.31,31.85 L35.31,31.85 L35.33,47 Z"
            fill="#0EAFE6"
          ></path>
          <path
            d="M26.68,48.14 L28.16,48.14 L28.16,52 L0,52 L0,48.14 L26.68,48.14 Z M1.48,46.44 L1.48,11.66 L26.68,11.66 L26.68,46.44 L1.48,46.44 Z M16.26,22 L22.1,22 L22.1,15.93 L16.26,15.93 L16.26,22 Z M16.26,32.18 L22.1,32.18 L22.1,26.1 L16.26,26.1 L16.26,32.18 Z M16.26,42.99 L22.1,42.99 L22.1,36.99 L16.26,36.99 L16.26,42.99 Z M6.36,22 L12.2,22 L12.2,15.93 L6.36,15.93 L6.36,22 Z M6.36,32.18 L12.2,32.18 L12.2,26.1 L6.36,26.1 L6.36,32.18 Z M6.36,36.94 L6.36,42.94 L12.2,42.94 L12.2,36.94 L6.36,36.94 Z M22,9.99999999 L22,8.08 C22.0116739,7.5095301 21.5696813,7.03217808 21,6.99999999 L19,6.99999999 L19,5.17 C18.9945749,4.58684009 18.5231599,4.11542509 17.94,4.11 L15.62,4.11 L15.62,0.53 C15.6145487,0.235678704 15.3743718,-8.10582024e-09 15.08,-8.10582024e-09 L13,-8.10582024e-09 C12.7072891,-8.10582024e-09 12.47,0.237289083 12.47,0.53 L12.47,4.11 L10.2,4.11 C9.61684009,4.11542509 9.14542509,4.58684009 9.14,5.17 L9.14,6.99999999 L7.14,6.99999999 C6.84099081,6.97411824 6.54519854,7.07742725 6.32732036,7.28383816 C6.10944218,7.49024906 5.99030688,7.78002933 6,8.08 L6,9.99999999 L22,9.99999999 Z"
            fill="#1C3A54"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
