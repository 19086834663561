import React from "react";

export default ({width = "26px", height = "26px"}) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
  
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Swap" fillRule="nonzero">
            <g id="Group" transform="translate(0.000000, 0.000000)">
                <path d="M34,27.1672948 L34,37.2250942 C33.5532637,38.2982774 32.5031367,38.9983869 31.3377358,39 L2.69433962,39 C1.48515256,38.9909539 0.409757575,38.2314764 0,37.0971731 L0,27.1672948 C0.00884,23.9943411 2.59117464,21.4268338 5.77358491,21.4268338 L11.9641509,21.4268338 L17.1122642,29.0061612 L22.2603774,21.4268338 L28.2264151,21.4268338 C31.4088254,21.4268338 33.99116,23.9943411 34,27.1672948 L34,27.1672948 Z" id="Shape-path" fill="#1C3A54"></path>
                <path d="M13.4805033,17.459642 C16.8828767,18.8805521 20.808872,18.1153859 23.4241374,15.5216537 C26.0394026,12.9279214 26.8276262,9.01768406 25.4205245,5.61793056 C24.013423,2.21817691 20.6887894,0 17,0 C11.9840982,-0.0088077587 7.90825101,4.03348034 7.89043806,9.03447371 C7.87108308,12.7122649 10.0781299,16.0387319 13.4805033,17.459642 Z" id="Shape-path" fill="#0EAFE6"></path>
            </g>
        </g>
    </g>
</svg>
);
