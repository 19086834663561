import React, { Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  makeStyles
} from "@material-ui/core";
import logo from "../logo.png";
import MailIcon from "@material-ui/icons/Mail";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex"
  },
  title: {
    flexGrow: 1
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  logoRow: {
    marginLeft: "4%",
    position: "fixed"
  },
  email: {
    alignItems: "right",
    textAlign: "right",
    padding: 5
  },
  iconHeader: {
    fontSize: 20,
    color: "#abd8ef",
    paddingBottom: 2
  },
  emailText: {
    textAlign: "right",
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 16,
    color: "#abd8ef"
  },
  linkLogin: {
    fontSize: 16,
    color: "#abd8ef",
    padding: 8
  },
  linkStyle:{
    fontSize: 15,
    color: "#208ccc"
  }
}));

const Header = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <div className={"header-main"}>
        <AppBar position="static">
          <Toolbar>
            <Typography align={"left"} variant="h4" style={classes.logoStyle}>
              <div className="logo">
                <img alt={"TesselMed"} src={logo} />
              </div>
            </Typography>
            <Typography variant="h6" className={classes.title}></Typography>
            <MailIcon className={classes.iconHeader} />
            <Typography
              tag="span"
              type="title"
              className={classes.emailText}
              variant="h6"
            >
              <a className={classes.linkLogin} href="mailto:contact@tesselmed.com">contact@tesselmed.com</a>
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  );
};
export default Header;
