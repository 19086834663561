import React, { Fragment, Component } from "react";
import DepartmentDataTable from "../containers/DepartmentDataTable";
import AddDept from "../containers/AddDept";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import { EventEmitter } from "../components/events";

export class Department extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isUpdate: false,
      isOrgUser: false,
      isFacUser: false,
      isDepUser: false
    };
  }

  componentWillMount() {
    const bgImage= require('../backg.png');
    document.body.style.backgroundImage = `url(${bgImage})`;
  }

  componentDidMount() {
    if (
      localStorage.getItem("is_org_user") &&
      localStorage.getItem("is_org_user") == "true"
    ) {
      this.setState({ isOrgUser: true });
    }
    if (
      localStorage.getItem("is_fac_user") &&
      localStorage.getItem("is_fac_user") == "true"
    ) {
      this.setState({ isFacUser: true });
    }
    if (
      localStorage.getItem("is_dep_user") &&
      localStorage.getItem("is_dep_user") == "true"
    ) {
      this.setState({ isDepUser: true });
    }

    EventEmitter.on("deptEdit", () => {
      this.setState({ isPaneOpen: true, isUpdate: true });
    });

    EventEmitter.on("closeModal", () => {
      this.setState({ isPaneOpen: false });
    });
    EventEmitter.on("deptCreated", () => {
      this.setState({ isPaneOpen: false });
    });
  }

  createNewDepartment = () => {
    this.setState({ isPaneOpen: true, isUpdate: false });
  };

  render() {
    return (
      <Fragment>
        <DepartmentDataTable
          createNewDepartment={this.createNewDepartment}
        ></DepartmentDataTable>

        <Modal
          aria-labelledby="modal-title"
          open={this.state.isPaneOpen}
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "center",
            right: 0,
            left: "none",
            height: "100%",
            width: "720px",
            maxWidth: "100%",
            position: "fixed",
            zIndex: 1010
          }}
          onClose={() => {
            this.setState({ isPaneOpen: false });
          }}
        >
          <Slide
            direction="left"
            timeout={350}
            in={this.state.isPaneOpen}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                overflowY: "initial !important"
              }}
            >
              <h2
                style={{
                  background:
                    "linear-gradient(90deg, #208bca 38%, #40b6e6 100%)",
                  paddingBottom: 13,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 13,
                  color: "white"
                }}
                id="user-modal-title"
              >
                {this.state.isUpdate ? "Update Department" : "Add Department"}
              </h2>
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                  padding: 20,
                  overflowY: "auto",
                  paddingBottom: 90
                }}
              >
                <AddDept />
              </div>
            </div>
          </Slide>
        </Modal>
      </Fragment>
    );
  }
}
export default Department;
