import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { EventEmitter } from "../components/events";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signIn } from "../actions/signin";
import { toast } from "react-toastify";
import instance from "../utils/services/services";

let orgs = [];
export class AddFac extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faci: "",
      org: "",
      orgJson: {},
      id: "",
      isUpdate: false,
      address: "",
      city: "",
      state: "",
      zip: "",
      npi: "",
      userCode: 0,
      isMandate: false
    };
  }

  componentWillMount() {
    this.setState({ userCode: localStorage.getItem("userCode") });
  }
  
  componentDidMount() {
    EventEmitter.on("facEdit", event => this.editFac(event));
    EventEmitter.on("facModalClosed", event => this.setMode(event));

    this.getOrganizationList();
  }
  
  getOrganizationList = () => {
    orgs = [];
    instance
      .get(`Organization/GetAll`, { params: { code: this.state.userCode } })
      .then(response => {
        response.data.data.forEach(i => {
          orgs.push({
            label: i.organizationName,
            value: i.organizationId
          });
        });
        orgs = orgs.sort(this.predicateBy("label"));
      })
      .catch(error => {
        // console.log(error);
      });
  };

  predicateBy = prop => {
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  setMode = state => {
    this.setState({ isUpdate: state });
  };

  updateFac = () => {
    this.setState({ isMandate: false });
    const { org, npi, faci, state, zip, address } = this.state;
    if (
      org !== "" &&
      npi !== "" &&
      faci !== "" &&
      state !== "" &&
      zip !== "" &&
      address !== ""
    ) {
      instance
        .post(`Facility/Update`, {
          id: this.state.id,
          name: this.state.faci,
          organizationName: this.state.org.label,
          organizationId: this.state.org.value,
          zip: this.state.zip,
          extension: {
            address: {
              addressline1: this.state.address,
              city: this.state.city,
              state: this.state.state
            },
            npi: this.state.npi
          }
        })
        .then(res => {
          // console.log(res);
        }, this)
        .then(event => {
          EventEmitter.emit("facCreated", event);
          toast.success("Facility update successfully.");
        })
        .catch(error => {
          // console.log(error);
        });
    } else {
      this.setState({ isMandate: true });
    }
  };

  createFac = () => {
    const { org, npi, faci, state, zip, address } = this.state;
    if (
      org !== "" &&
      npi !== "" &&
      faci !== "" &&
      state !== "" &&
      zip !== "" &&
      address !== ""
    ) {
      instance
        .post(`Facility/Create`, {
          name: this.state.faci,
          organizationName: this.state.org.label,
          organizationId: this.state.org.value,
          zip: this.state.zip,
          extension: {
            address: {
              addressline1: this.state.address,
              city: this.state.city,
              state: this.state.state
            },
            npi: this.state.npi
          }
        })
        .then(res => {
          // console.log(res);
        }, this)
        .then(event => {
          EventEmitter.emit("facCreated", event);
          toast.success("Facility created successfully.");
        })
        .catch(error => {
          // console.log(error);
        });
    } else {
      this.setState({ isMandate: true });
    }
  };

  closeModal = () => {
    EventEmitter.emit("closeModal", "");
  };

  editFac = event => {
    this.setState({
      id: event.facilityId,
      faci: event.facilityName,
      org: { label: event.organizationName, value: event.organizationId },
      address: event.extension.address.addressline1,
      city: event.extension.address.city,
      state: event.extension.address.state,
      zip: event.zip,
      npi: event.extension.npi,
      isUpdate: true
    });
  };

  handleOrgChange = org => {
    this.setState({ org });
  };

  render() {
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const errorStyle = {
      color: "#FF0000",
      marginLeft:10
    };
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-dense-team"
              label="Facility"
              value={this.state.faci}
              onChange={this.handleChange("faci")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required
              error={this.state.isMandate && this.state.faci === ""}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Select
              styles={selectStyles}
              value={this.state.org}
              onChange={this.handleOrgChange}
              options={orgs}
              placeholder="Select an Organization *"
              isSearchable={true}
              required={true}
              error={this.state.isMandate && this.state.org === ""}
            />
          </Grid>
          {this.state.isMandate && this.state.org === "" ? (
              <h6 style={errorStyle}>Select an Organization</h6>
            ) : (
              <h6></h6>
            )}
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-dense-team"
              label="NPI"
              value={this.state.npi}
              onChange={this.handleChange("npi")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 10 }}
              required
              error={this.state.isMandate && this.state.npi === ""}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-dense-team"
              label="Address"
              value={this.state.address}
              onChange={this.handleChange("address")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 100 }}
              required
              error={this.state.isMandate && this.state.address === ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-dense-team"
              label="State"
              value={this.state.state}
              onChange={this.handleChange("state")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 2 }}
              required
              error={this.state.isMandate && this.state.state === ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-dense-team"
              label="City"
              value={this.state.city}
              onChange={this.handleChange("city")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 50 }}
              required
              error={this.state.isMandate && this.state.city === ""}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-dense-team"
              label="Zip Code"
              value={this.state.zip}
              onChange={this.handleChange("zip")}
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 5 }}
              required
              error={this.state.isMandate && this.state.zip === ""}
            />
          </Grid>

          <div className="button-wrapper primary">
            {this.state.isUpdate == false ? (
              <Button
                variant="contained"
                className="button-primary"
                color="primary"
                onClick={this.createFac}
              >
                CREATE
              </Button>
            ) : (
              <Button
                variant="contained"
                className="button-primary"
                color="secondary"
                onClick={this.updateFac}
              >
                UPDATE
              </Button>
            )}
          </div>

          <div className="button-wrapper">
            <Button
              variant="contained"
              className="button-cancel"
              color="secondary"
              onClick={this.closeModal}
            >
              CANCEL
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

const mapStateToProps = state => ({
  signInPayload: state.signin.signInPayload
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFac);
