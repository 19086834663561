import React from "react";
import instance from "../../utils/services/services";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import Alert from "sweetalert2";
import moment from "moment";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable, { defaultThemes } from "react-data-table-component";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import LinearIndeterminate from "../../common/ListLoader";
import { convertTZ } from "../../settings/index";

const requestStatus = [
  {
    label: "Status",
    value: "Status"
  },
  {
    label: "Waiting for approval",
    value: "Open"
  },
  {
    label: "Mediator Approved",
    value: "MediatorApproved"
  },
  {
    label: "Mediator Denied",
    value: "MediatorDenied"
  },
  {
    label: "QHP Approved",
    value: "ToQHPApproved"
  },
  { 
    label: "QHP Denied",
    value: "ToQHPDenied"
  }
];

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: '38px',
    }, 
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize:16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class SwapRequestDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swapRequests: [],
      isLoading:false
    };
  }

  getAllSwapRequest = async () => {
    this.setState({isLoading:true});
    const response = await instance.get(
      `ProviderSwap/GetProviderSwapDetailsMediator`
    );
    const { data } = response.data;
    let requests = [];
    if (data) {
      response.data.data.forEach(element => {
        requests.push(element);
      });
    }
    this.setState({ swapRequests: requests }, ()=> this.setState({isLoading:false}));
  };

  componentDidMount = () => {
    this.getAllSwapRequest();
  };

  onCLickUpdateStatus = (swapId, status) => {
    if (swapId && status) {
      Alert.fire({
        title:
          status === "MediatorDenied"
            ? "Are you sure you want reject?"
            : "Are you sure you want accept?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: status === "MediatorDenied" ? "Reject" : "Accept",
        cancelButtonText: "Close"
      }).then(result => {
        if (result.value !== undefined && result.value) {
          instance
            .post(`ProviderSwap/UpdateProviderSwap`, {
              swapId: swapId,
              swapStatus: status
            })
            .then(response => {
              if (!response.error && response.data.data) {
                toast.success("Swap request updated successfully.");
                this.getAllSwapRequest();
              } else {
                toast.error(response.error);
              }
            })
            .catch(err => {
              // console.log(err);
            });
        }
      });
    } else {
      toast.error("something went wrong");
    }
  };

  actionTemplate = request => {
    const opacity = request.swapStatus !== "Open" ? 0.5 : 1;
    return (
      <>
        <div style={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={() =>
              this.onCLickUpdateStatus(request.swapId, "MediatorApproved")
            }
            style={{
              backgroundColor: "#33a5db",
              color: "white",
              marginRight: 5,
              textAlign: "center",
              opacity: opacity
            }}
            disabled={request.swapStatus !== "Open"}
          >
            Approve
          </Button>

          <Button
            variant="contained"
            className="button-cancel"
            style={{
              backgroundColor: "#ff6262",
              color: "white",
              marginRight: 5,
              textAlign: "center",
              opacity: opacity
            }}
            onClick={() =>
              this.onCLickUpdateStatus(request.swapId, "MediatorDenied")
            }
            disabled={request.swapStatus !== "Open"}
          >
            Reject
          </Button>
        </div>
      </>
    );
  };

  getFromQHPWithSkill = request => {
    return request.fromQHPName
  };

  getToQHPWithSkill = request => {
    return request.toQHPName
  };

  getShiftDate = request => {
    return request.shiftDate
  ? moment(new Date(request.shiftDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";
    // return request.shiftDate
    // ?  moment(convertTZ(new Date(request.shiftDate.split("T")[0]))).format("MM-DD-YYYY") : "";
    // return request.shiftDate
    //   ? moment(
    //       new Date(request.shiftDate.split("T")[0]),
    //       "ddd DD-MMM-YYYY, hh:mm A"
    //     )
    //       .add(1, "days")
    //       .format("MM-DD-YYYY")
    //   : "";
  };

  getStatus = request => {
    switch (request.swapStatus) {
      case "Open":
        return "Waiting for approval";
      case "MediatorApproved":
        return "Mediator Approved";
      case "MediatorDenied":
        return "Mediator Denied";
      case "ToQHPApproved":
        return "QHP Approved";
      case "ToQHPDenied":
        return "QHP Denied";
      default:
        return "Waiting for approval";
    }
  };

  render() {
    const columns = [
      {
        name: "FROM",
        sortable: true,
        selector: "fromQHPName",
        cell: row => this.getFromQHPWithSkill(row)
      },
      {
        name: "TO",
        sortable: true,
        selector: "toQHPName",
        cell: row => this.getToQHPWithSkill(row)
      },
      {
        name: "SHIFT DATE",
        selector: "shiftDate",
        cell: row => this.getShiftDate(row),
        sortable: true,
        // hide: "md"
      },
      {
        name: "STATUS",
        selector: "swapStatus",
        cell: row => this.getStatus(row),
        sortable: true
      },
      {
        name: "ACTION",
        cell: row => this.actionTemplate(row),
        ignoreRowClick: true,
        allowOverflow: true
      }
    ];

    const { swapRequests } = this.state;
    const { currentRequestStatus } = this.props;
    let swapRequestData = swapRequests;
    let requests = currentRequestStatus
      ? swapRequestData.filter(
          swapRequest => swapRequest.swapStatus === currentRequestStatus
        )
      : swapRequests;
    return (
      <DataTable
        title={
          <Grid container spacing={1}>
            <Grid item xs={1.5} md={1.5} sm={1.5}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Swap Requests
                </h1>
              </div>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
            
            </Grid>
          </Grid>
        }
        subHeader={true}
        subHeaderAlign={"left"}
        subHeaderComponent={
          <Grid container spacing={1}>
            <Grid item xs={9} md={9} sm={9} style={{ paddingTop: 15 }}>
            </Grid>
            <Grid item xs={3} md={3} sm={3}>
            <Select
                styles={this.props.selectStyles}
                defaultValue={this.props.selectedRequestStatus}
                value={this.props.selectedRequestStatus}
                onChange={this.props.handleRequestStatusChange}
                options={requestStatus}
              />
            </Grid>
          </Grid>
        }
        columns={columns}
        data={requests ? requests : []}
        defaultSortField="facilityName"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={this.state.isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
          <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."}/>
        </div>}
        noDataComponent={
          <div style={{paddingTop:150, paddingBottom:150, fontSize:14, fontWeight:500}}>
            Swap requests not found!
          </div>
        }
      />
    );
  }
}

export default SwapRequestDataTable;
