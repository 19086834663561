import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    }
}));

const LinearIndeterminate = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Fade
                in={props.isLoading}
                style={{
                    transitionDelay: props.isLoading ? '10ms' : '0ms'
                }}
                unmountOnExit
            >
                <CircularProgress thickness={4} style={{ 'color': props.loaderColor ? props.loaderColor : '#33a5db' }} />
            </Fade>
            {
                props.isLoading && <Typography variant="h5" style={{ color: props.titleColor ? props.titleColor : "#33a5db" }}>{props.loaderTitle} </Typography>
            }
        </div>
    );
};

export default LinearIndeterminate;