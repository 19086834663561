import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Provider } from "react-redux";
import store from "./utils/storage/store";
import ProviderRoutes from "./routes/routes";
import { MuiThemeProvider } from "material-ui/styles";
import { Toast } from "./common/Toast";
import Loader from "./common/Loader";
import {setUserType} from "./settings/userSetting";

let schedulerClass =
  store.getState().scheduler.insideScheduler &&
  !localStorage.getItem("api_token")
    ? "main-container scheduler"
    : "main-container";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    };
  }

  componentWillMount() {
    this.setState({ userCode: localStorage.getItem("userCode") }, () => {
      setUserType(this.state.userCode);
    });
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className={schedulerClass}>
            <MuiThemeProvider>
              <Loader />
              <ProviderRoutes />
              <Toast />
            </MuiThemeProvider>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
