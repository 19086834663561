import React, { useEffect } from "react";
import instance from "../../utils/services/services";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import Alert from "sweetalert2";
import moment from "moment";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable, { defaultThemes } from "react-data-table-component";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import { getUserType } from "../../settings/userSetting";
import ModalPopUp from "../../components/ModalPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Col, Row, Container } from "reactstrap";
import { Alert as InfoAlert } from '@material-ui/lab';
import TextField from "@material-ui/core/TextField";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import LinearIndeterminate from "../../common/ListLoader";
import {withStyles} from '@material-ui/core';
import { convertTZ, isIncludingString } from "../../settings/index";
import { clone } from "lodash";

import {
  getCeilingRate
} from "../../actions/scheduler";

const labourTypes = [
  { label: "Select all", value: "all" },
  { label: "Per Diem", value: "PerDiem" },
  { label: "Locum", value: "Locum" },
  { label: "Employee", value: "Employee" },
  { label: "Volunteer", value: "Volunteer" }
];

const requestStatus = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Waiting for approval",
    value: "Open"
  },
  {
    label: "Approved",
    value: "Approved"
  },
  {
    label: "Denied",
    value: "Denied"
  },
  {
    label: "Cancelled",
    value: "Cancelled"
  }
];

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: '38px',
    }, 
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize:16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  title: {
    textAlign: "center"
  },
  stepper: {
    background: "white",
    backgroundColor: "none"
  }
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#daeff8',
  },
  barColorPrimary: {
    backgroundColor: '#33a5db',
  },
})(LinearProgress);

const LeaveRequestDataTable = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [leaveRequests, setLeaveRequests] = React.useState([]);
  const [userCode, setUserCode] = React.useState(null);
  const [leaveTypes, setLeaveTypes] = React.useState([]);
  const [isLeaveAccepted, setIsLeaveAccepted] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedLabourTypes, setSelectedLabourTypes] = React.useState([]);
  const [selectedCeilingRate, setSelectedCeilingRate] = React.useState(0);
  const [selectedSkillMaxCeilingRate, setSelectedSkillMaxCeilingRate] = React.useState(0);
  const [scheduleDetails, setScheduleDetails] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isValidate, setIsValidate] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [searchField, setSearchField] = React.useState(null);
  
  const maxSteps = scheduleDetails.length;

  const handleNext = () => {
    setSelectedLabourTypes([]);
    setSelectedCeilingRate(0);
    setIsValidate(false);
    if(activeStep !== maxSteps-1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setIsSubmitted(false);
      createShift();
    }
  };

  const handleBack = () => {
    setSelectedLabourTypes([]);
    setSelectedCeilingRate(0);
    setIsValidate(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getAllLeaveTypes = async () => {
    const response = await instance.get(
      `Leave/GetLeaveType`
    );
    const { data } = response.data;
    let leaveTypes = [{
      label: "All",
      value: "All"
    }];
    if (data) {
      await response.data.data.forEach(element => {
        leaveTypes.push({
          label: element.leaveTypeName,
          value: element.leaveTypeId
        });
      });
    }
    setLeaveTypes(leaveTypes);
  }

  const getAllLeaveRequest = async () => {
    if (userCode) {
      let isDepartment = getUserType() === "department";
      let isFacility = getUserType() === "facility";
      let isOrgUser = getUserType() === "organization";
      setIsLoading(true);
      const response = await instance.get(
        `Leave/GetLeaves`, {
        params: { userCode: userCode, isDepartmentUser: isDepartment, isFacilityUser: isFacility, isOrgUser: isOrgUser }
      }
      );
      const { data } = response.data;
      let requests = [];
      if (data) {
        response.data.data.forEach(element => {
          requests.push(element);
        });
      }
      setIsLoading(false);
      setLeaveRequests(requests);
    }
  };

  useEffect(() => {
    if(scheduleDetails.length !== 0) {
      createShift()
    }
  }, [scheduleDetails])

  useEffect(() => {
    if(scheduleDetails.length !== 0) {
      setSelectedSkillMaxCeilingRate(scheduleDetails[activeStep].ceilingRate)
    }
  }, [activeStep])

  useEffect(() => {
    getAllLeaveTypes();
    getAllLeaveRequest();
  }, [userCode])

  useEffect(() => {
    if (localStorage.getItem("userCode")) {
      setUserCode(localStorage.getItem("userCode"))
    }
  }, [])

  const onClickUpdateStatus = (row, status) => {
    if (row.id && status) {
      Alert.fire({
        title:
          status === "Denied"
            ? "Are you sure you want reject?"
            : "Are you sure you want accept?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: status === "Denied" ? "Reject" : "Accept",
        cancelButtonText: "Close"
      }).then(result => {
        if (result.value !== undefined && result.value) {

          let isDepartment = getUserType() === "department";
          let data = isDepartment ? {
            leaveId: row.id,
            providerCode: row.providerCode,
            leaveStatus: status,
            departmentUserCode: userCode,
            leavetype:row.leaveType
          } : {
              leaveId: row.id,
              providerCode: row.providerCode,
              leaveStatus: status,
              leavetype:row.leaveType
            }

          instance
            .post(`Leave/UpdateLeave`, data)
            .then(response => {
              if (!response.data.error &&  response.data.status &&  response.data.data.leaveOutput.leaveId !== undefined) {
                toast.success("Leave request updated successfully.");
                if (status === "Approved" && response.data.data.scheduleDetails) {
                  setScheduleDetails(response.data.data.scheduleDetails);
                }
                getAllLeaveRequest();
              } else {
                toast.error(response.data.error.message);
              }
            })
            .catch(err => {
              // console.log(err);
            });
        }
      });
    } else {
      toast.error("something went wrong");
    }
  };

  const onChangeShiftRate = (selectedCeilingRate) => {
    setSelectedCeilingRate(selectedCeilingRate.target.value);
  }
  
  // const onChangeLabourTypes = (labourType) => {
  //   setSelectedLabourTypes(labourType);
  // }

  const onChangeLabourTypes = (opt, { option }) => {
    let newOpts = opt;
    let string = searchField;

    if (option && option.value === "all") {
      let filteredOptions = clone(labourTypes);

      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );

      string = null;
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
    }
    setSearchField(string);
    setSelectedLabourTypes(newOpts)
  };

  const onInputChange = (string, { action }) => {
    if (action === "input-change") {
      this.setState({
        searchField: string
      });
    }
  };

  const filterOption = ({ label, value }, string) => {
    if (value === "all") {
      return true;
    } else if (string) {
      return label.includes(string) || value.toString().includes(string);
    } else {
      return true;
    }
  };

  const createShift = () => {
    setIsLeaveAccepted(!isLeaveAccepted)
  }

  const actionTemplate = request => {
    const opacity = request.leaveStatus !== "Open" ? 0.5 : 1;
    return (
      <>
        <div style={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={() =>
              onClickUpdateStatus(request, "Approved")
            }
            style={{
              backgroundColor: "#33a5db",
              color: "white",
              marginRight: 5,
              textAlign: "center",
              opacity: opacity
            }}
            disabled={request.leaveStatus !== "Open"}
          >
            Approve
          </Button>

          <Button
            variant="contained"
            className="button-cancel"
            style={{
              backgroundColor: "#ff6262",
              color: "white",
              marginRight: 5,
              textAlign: "center",
              opacity: opacity
            }}
            onClick={() =>
              onClickUpdateStatus(request, "Denied")
            }
            disabled={request.leaveStatus !== "Open"}
          >
            Reject
          </Button>
        </div>
      </>
    );
  };

  const getFromDate = request => {
    return request.fromDate
  ? moment(new Date(request.fromDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";

    return request.fromDate
    ?  moment(request.fromDate.split("T")[0]).format("MM-DD-YYYY") : "";
  };

  const getToDate = request => {
    return request.toDate
  ? moment(new Date(request.toDate.split("T")[0])).utcOffset(0).format('MM-DD-YYYY'): "";

    // return request.toDate
    // ?  moment(request.toDate.split("T")[0]).format("MM-DD-YYYY") : "";
  };

  const getStatus = request => {
    switch (request.leaveStatus) {
      case "Open":
        return "Waiting for approval";
      case "Approved":
        return "Approved";
      case "Denied":
        return "Denied";
      case "Cancelled":
        return "Cancelled";
      default:
        return "Waiting for approval";
    }
  };

  const getLeaveType = (row) => {
    return leaveTypes[row.leaveType] && leaveTypes[row.leaveType].label
  }

  const onSaveShift = (shift) => {
    setIsValidate(true);
    if(selectedCeilingRate && Number(selectedCeilingRate) > 0 && (selectedCeilingRate && Number(selectedCeilingRate) <= scheduleDetails[activeStep].ceilingRate)) {
      let selectedLabourTypeArray = [];
      selectedLabourTypes && selectedLabourTypes.forEach((element, i) => {
        selectedLabourTypeArray.push(element.value);
      });
      let data = {
        date:[shift.scheduleDate.split("T")[0]],
        // schedulerCode:'1000000000096',
        teamCode:shift.teamCode,
        skillLevel: shift.skill,
        askRate: selectedCeilingRate,
        labourType: selectedLabourTypeArray,
        isSourceShift: true
      };
      setIsSubmitted(true);
      instance
      .post(`Scheduler/Calendar/CreateOpenShift`, data)
      .then(response => {
        if (!response.data.error && response.data.status) {
          setIsSubmitted(false);
          setSelectedLabourTypes([]);
          setSelectedCeilingRate(0);
          setIsValidate(false);
          toast.success("Shift added successfully.");
          if(!(activeStep === maxSteps-1)) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            createShift();
          }
        } else {
          setIsValidate(false);
          setIsSubmitted(false);
          toast.error(response.data.error.message);
        }
      })
      .catch(err => {
        setIsSubmitted(false);
        setIsValidate(false);
        // console.log(err);
      });
    } 
  }

  const renderConfirmOpenShift = () => {
    return (
      <ModalPopUp
        onClose={createShift}
        isModalOpen={isLeaveAccepted}
        
        modalStyle={{ width: 'auto', maxWidth: "100%" }}
      >
        <div className={"modal-paper"}>
          <div className="modal-header">
            <Typography variant="h4" style={{ color: "white" }}>{"CHOOSE AVAILABLE SHIFT"} </Typography>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={createShift}
              className="faiconsStyle"
            />
          </div>
          <div className="modal-body" style={{marginBottom:70}}>
            {
              isSubmitted && <ColorLinearProgress />
            }
            <Container>
              <Row style={{paddingTop:5}}>
                <Col xs="3"><Typography variant="h5" style={{ lineHeight: 2.33 }}>{"Shift Date: "}</Typography></Col>
                <Col xs="9">
                <Typography variant="h5" style={{ lineHeight: 2.33 }}>{
                      moment(
                        new Date(scheduleDetails[activeStep].scheduleDate.split("T")[0]),
                        "ddd DD-MMM-YYYY, hh:mm A"
                      )
                        .add(1, "days")
                        .format("MM-DD-YYYY")} </Typography>
                </Col>
              </Row>
              <Row style={{paddingTop:5}}>
                <Col xs="3"><Typography variant="h5" style={{ lineHeight: 2.33 }}>{"Skill: "} </Typography></Col>
                <Col xs="9"><Typography variant="h5" style={{ lineHeight: 2.33 }}>{scheduleDetails[activeStep].skill} </Typography> </Col>
              </Row>
              <Row style={{paddingTop:10}} key={"rate"+activeStep}>
                <Col xs="3"><Typography variant="h5" style={{ lineHeight: 2.33 }}>{"Skill Rate: "} </Typography></Col>
                <Col xs="9">
                  <TextField
                      id="skill-rate"
                      label="Rate"
                      margin="dense"
                      variant="outlined"
                      type="number"
                      error={(isValidate && Number(selectedCeilingRate) <= 0) || (selectedCeilingRate && Number(selectedCeilingRate) > scheduleDetails[activeStep].ceilingRate)}
                      onChange={onChangeShiftRate}
                      defaultValue={selectedCeilingRate}
                      inputProps={{ maxLength: 15 }}
                      helperText={
                        (selectedCeilingRate && Number(selectedCeilingRate) > scheduleDetails[activeStep].ceilingRate)
                          ? `Skill rate should be less then or equal to ` + scheduleDetails[activeStep].ceilingRate
                          : (isValidate && Number(selectedCeilingRate) <= 0) ? `Please enter skill rate.` : null
                      }
                      required
                    />
                </Col>
              </Row>
              <Row style={{paddingTop:10}} key={"labour-type"+activeStep}>
                <Col xs="3"><Typography variant="h5" style={{ lineHeight: 2.33 }}>{"Labor Type: "} </Typography></Col>
                <Col xs="9">
                  <Select
                    className="labour-type"
                    style={{
                      width: 210,
                      marginLeft: 800,
                      textAlign: "center",
                      justifyContent: "center"
                    }}
                    filterOption={filterOption}
                    onInputChange={onInputChange}
                    onChange={onChangeLabourTypes}
                    options={labourTypes}
                    placeholder="Select labor type"
                    // isSearchable={true}
                    value={selectedLabourTypes}
                    defaultValue={selectedLabourTypes}
                    isMulti={true}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="modal-footer" style={{marginTop:20, display: 'flow-root' }}>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={activeStep}
              className={classes.stepper}
              nextButton={
                <div>
                 <Button size="small" style={{ color: "white", backgroundColor: "#33a5db", opacity: activeStep === maxSteps  ? 0.5 : 1 }} onClick={()=>onSaveShift(scheduleDetails[activeStep])} disabled={activeStep === maxSteps}>
                 {(activeStep === maxSteps-1) ? "Save & Close" : "Save & Next"} {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
                <Button size="small" style={{ marginLeft:8, color: "white", backgroundColor: "#33a5db", opacity: activeStep === maxSteps ? 0.5 : 1 }} onClick={handleNext} disabled={activeStep === maxSteps}>
                  {(activeStep === maxSteps-1) ? "Skip & Close" : "Skip & Next"} {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
                </div>
              }
              backButton={
                <>
                <Button size="small" style={{ color: "white", backgroundColor: "#33a5db", opacity: activeStep === 0 ? 0.5 : 1 }} onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}{"Previous"}
                </Button>
                </>
              }
            />
             <InfoAlert style={{
                    paddingTop: 0,
                    paddingBottom:0,
                    justifyContent:'center',
                    background: 'azure',
                    borderWidth:0,
                    border: 'none',
                    fontSize:10,
                    fontWeight:'bold'
                  }} icon={<InfoOutlined />} variant="outlined" severity="info">{`IF YOU WANT TO ASSIGN THIS SHIFT CLICK ON "Save & Next" BUTTON.`}</InfoAlert>
          </div>
        </div>
      </ModalPopUp>
    )
  }

  const columns = [
    {
      name: "PROVIDER NAME",
      cell: row => row.provider_Name,
      sortable: true,
      // hide: "md",
      selector: "provider_Name"
    },
    {
      name: "FROM DATE",
      sortable: true,
      cell: row => getFromDate(row),
      selector: "fromDate"
    },
    {
      name: "TO DATE",
      sortable: true,
      cell: row => getToDate(row),
      selector: "toDate"
    },
    {
      name: "LEAVE TYPE",
      cell: row => getLeaveType(row),
      sortable: true,
      selector: "leaveType"
    },
    {
      name: "STATUS",
      cell: row => getStatus(row),
      sortable: true,
      selector: "leaveStatus"
    },
    {
      name: "ACTION",
      cell: row => actionTemplate(row),
      ignoreRowClick: true,
      allowOverflow: true
    }
  ];

  const { currentLeaveStatus, currentLeaveType } = props;
  let leaveRequestData = leaveRequests;
  let requests = currentLeaveStatus
    ? leaveRequestData.filter(
      leaveRequest => leaveRequest.leaveStatus === currentLeaveStatus
    )
    : leaveRequests;
  requests = currentLeaveType
    ? requests.filter(
      leaveRequest => leaveRequest.leaveType === currentLeaveType
    ) : requests;

  return (
    <React.Fragment>
      <DataTable
        title={
          <Grid container spacing={1}>
            <Grid item xs={1.5} md={1.5} sm={1.5}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Leave Requests
                </h1>
              </div>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>

            </Grid>
          </Grid>
        }
        subHeader={true}
        subHeaderAlign={"left"}
        subHeaderComponent={
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} sm={6} style={{ paddingTop: 15 }}></Grid>
            <Grid item xs={3} md={3} sm={3}>
              <Select
                styles={props.selectStyles}
                defaultValue={props.selectedLeaveType}
                value={props.selectedLeaveType}
                onChange={props.handleLeaveTypesChange}
                options={leaveTypes}
              />
            </Grid>
            <Grid item xs={3} md={3} sm={3}>
              <Select
                styles={props.selectStyles}
                defaultValue={props.selectedRequestStatus}
                value={props.selectedRequestStatus}
                onChange={props.handleRequestStatusChange}
                options={requestStatus}
              />
            </Grid>
          </Grid>
        }
        columns={columns}
        data={requests ? requests : []}
        defaultSortField="provider_Name"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
        <LinearIndeterminate isLoading={isLoading} loaderTitle={"Loading..."}/>
      </div>}
        noDataComponent={
          <div style={{ paddingTop: 150, paddingBottom: 150, fontSize: 14, fontWeight: 500 }}>
            {'Leave requests not found!'}
          </div>
        }
      />
      {scheduleDetails.length !== 0 && renderConfirmOpenShift()}
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCeilingRate
    },
    dispatch
  );
};

const mapStateToProps = state => ({
  ceilingRate: state.scheduler.ceilingRatePayload
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequestDataTable);