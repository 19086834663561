import React, { Fragment } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressCard = (props) => {

  return (
    <Fragment>
      {
         props.dashboardItemShow && props.dashboardItemShow.includes('providerCount') ?
         <>
          {
             props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
             <div className="no-of-providers col-providers">
               <span className="no-of-title">No of providers</span>
               <span className={`providers-price ${props.databoardStatistics.providerGrowth >= 0 ? "" : "text-red"}`}>{props.databoardStatistics && props.databoardStatistics.providerCount}</span>
               {
                  props.databoardStatistics && props.databoardStatistics.providerGrowth >= 0 ? 
                  <span className="providers-title text-green">{`${props.databoardStatistics.providerGrowth == 0 ? '' : '+'}${props.databoardStatistics.providerGrowth == 0 ? '0.0' : props.databoardStatistics.providerGrowth}%`}  <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.70615 8.79157C0.979499 9.06492 1.48064 9.06492 1.75399 8.79157L4.12301 6.42255L7.94989 10.295C8.22323 10.5683 8.72437 10.5683 8.99772 10.295L14.0091 5.2836L15.3759 6.60478C16.0592 7.33371 17.2437 6.83257 17.2437 5.8303V1.22893C17.2437 0.864465 16.8793 0.5 16.5148 0.5H11.9134C10.9112 0.5 10.41 1.68451 11.139 2.36788L12.4601 3.73462L8.49658 7.74374L4.62415 3.8713C4.3508 3.59795 3.84966 3.59795 3.57631 3.8713L0.205011 7.2426C-0.0683371 7.51595 -0.0683371 8.01708 0.205011 8.29043L0.70615 8.79157Z" fill="#68BC00" />
                  </svg>
                </span>
                </span>
                  : 
                  <span className="providers-title text-red">{`${props.databoardStatistics.providerGrowth == 0 ? '' : ''}${props.databoardStatistics.providerGrowth}%`} <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.70615 2.20843C0.979499 1.93508 1.48064 1.93508 1.75399 2.20843L4.12301 4.57745L7.94989 0.705011C8.22323 0.431663 8.72437 0.431663 8.99772 0.705011L14.0091 5.7164L15.3759 4.39522C16.0592 3.66629 17.2437 4.16743 17.2437 5.1697V9.77107C17.2437 10.1355 16.8793 10.5 16.5148 10.5H11.9134C10.9112 10.5 10.41 9.31549 11.139 8.63212L12.4601 7.26538L8.49658 3.25626L4.62415 7.1287C4.3508 7.40205 3.84966 7.40205 3.57631 7.1287L0.205011 3.7574C-0.0683371 3.48405 -0.0683371 2.98292 0.205011 2.70957L0.70615 2.20843Z" fill="#D22828" />
                  </svg>
                </span>
                </span>
                }
             </div>
          }
         </>
         : null
      }

      {
         props.dashboardItemShow && props.dashboardItemShow.includes('userCount') ? 
          <>
            {
              props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
              <div className="no-of-providers col-providers">
                <span className="no-of-title">No of admin users</span>
                <span className={`providers-price ${props.databoardStatistics.providerGrowth >= 0 ? "" : "text-red"}`}>{props.databoardStatistics && props.databoardStatistics.userCount}</span>
                {
                  props.databoardStatistics && props.databoardStatistics.userGrowth >= 0 ? 
                  <span className="providers-title text-green">{`${props.databoardStatistics.userGrowth == 0 ? '' : '+'}${props.databoardStatistics.userGrowth == 0 ? '0.0' : props.databoardStatistics.userGrowth}%`}  <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.70615 8.79157C0.979499 9.06492 1.48064 9.06492 1.75399 8.79157L4.12301 6.42255L7.94989 10.295C8.22323 10.5683 8.72437 10.5683 8.99772 10.295L14.0091 5.2836L15.3759 6.60478C16.0592 7.33371 17.2437 6.83257 17.2437 5.8303V1.22893C17.2437 0.864465 16.8793 0.5 16.5148 0.5H11.9134C10.9112 0.5 10.41 1.68451 11.139 2.36788L12.4601 3.73462L8.49658 7.74374L4.62415 3.8713C4.3508 3.59795 3.84966 3.59795 3.57631 3.8713L0.205011 7.2426C-0.0683371 7.51595 -0.0683371 8.01708 0.205011 8.29043L0.70615 8.79157Z" fill="#68BC00" />
                  </svg>
                </span>
                </span>
                  : 
                  <span className="providers-title text-red">{`${props.databoardStatistics.userGrowth == 0 ? '' : ''}${props.databoardStatistics.userGrowth}%`} <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.70615 2.20843C0.979499 1.93508 1.48064 1.93508 1.75399 2.20843L4.12301 4.57745L7.94989 0.705011C8.22323 0.431663 8.72437 0.431663 8.99772 0.705011L14.0091 5.7164L15.3759 4.39522C16.0592 3.66629 17.2437 4.16743 17.2437 5.1697V9.77107C17.2437 10.1355 16.8793 10.5 16.5148 10.5H11.9134C10.9112 10.5 10.41 9.31549 11.139 8.63212L12.4601 7.26538L8.49658 3.25626L4.62415 7.1287C4.3508 7.40205 3.84966 7.40205 3.57631 7.1287L0.205011 3.7574C-0.0683371 3.48405 -0.0683371 2.98292 0.205011 2.70957L0.70615 2.20843Z" fill="#D22828" />
                  </svg>
                </span>
                </span>
                }
              </div>
            }
          </>
         : null
      }

      {
        props.dashboardItemShow && props.dashboardItemShow.includes('departmentCount') ?
        <>
          {
           props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
           <div className="no-of-providers col-providers">
             <span className="no-of-title">Departments</span>
             <span className="providers-price">{props.databoardStatistics && props.databoardStatistics.departmentCount}</span>
             {
                  props.databoardStatistics && props.databoardStatistics.departmentGrowth >= 0 ? 
                  <span className="providers-title text-green">{`${props.databoardStatistics.departmentGrowth == 0 ? '' : '+'}${props.databoardStatistics.departmentGrowth == 0 ? '0.0' : props.databoardStatistics.departmentGrowth}%`}  <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.70615 8.79157C0.979499 9.06492 1.48064 9.06492 1.75399 8.79157L4.12301 6.42255L7.94989 10.295C8.22323 10.5683 8.72437 10.5683 8.99772 10.295L14.0091 5.2836L15.3759 6.60478C16.0592 7.33371 17.2437 6.83257 17.2437 5.8303V1.22893C17.2437 0.864465 16.8793 0.5 16.5148 0.5H11.9134C10.9112 0.5 10.41 1.68451 11.139 2.36788L12.4601 3.73462L8.49658 7.74374L4.62415 3.8713C4.3508 3.59795 3.84966 3.59795 3.57631 3.8713L0.205011 7.2426C-0.0683371 7.51595 -0.0683371 8.01708 0.205011 8.29043L0.70615 8.79157Z" fill="#68BC00" />
                  </svg>
                </span>
                </span>
                  : 
                  <span className="providers-title text-red">{`${props.databoardStatistics.departmentGrowth == 0 ? '' : ''}${props.databoardStatistics.departmentGrowth}%`} <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.70615 2.20843C0.979499 1.93508 1.48064 1.93508 1.75399 2.20843L4.12301 4.57745L7.94989 0.705011C8.22323 0.431663 8.72437 0.431663 8.99772 0.705011L14.0091 5.7164L15.3759 4.39522C16.0592 3.66629 17.2437 4.16743 17.2437 5.1697V9.77107C17.2437 10.1355 16.8793 10.5 16.5148 10.5H11.9134C10.9112 10.5 10.41 9.31549 11.139 8.63212L12.4601 7.26538L8.49658 3.25626L4.62415 7.1287C4.3508 7.40205 3.84966 7.40205 3.57631 7.1287L0.205011 3.7574C-0.0683371 3.48405 -0.0683371 2.98292 0.205011 2.70957L0.70615 2.20843Z" fill="#D22828" />
                  </svg>
                </span>
                </span>
                }
           </div>
          }
        </> : null
      }

      {
        props.dashboardItemShow && props.dashboardItemShow.includes('openShiftsToday') ?
          <>
            {
              props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
              <div className="no-of-providers col-providers">
                <span className="no-of-title">Today open shifts</span>
                <span className="providers-price">{props.databoardStatistics && props.databoardStatistics.openShiftsToday}</span>
              </div>
            }
          </>
        : null  
      }

      {
        props.dashboardItemShow && props.dashboardItemShow.includes('organizationCount') ?
        <>
          {
            props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
            <div className="no-of-providers col-providers">
              <span className="no-of-title">Organization</span>
              <span className="providers-price text-green">{props.databoardStatistics && props.databoardStatistics.organizationCount}</span>
              {
                  props.databoardStatistics && props.databoardStatistics.organizationGrowth >= 0 ? 
                  <span className="providers-title text-green">{`${props.databoardStatistics.organizationGrowth == 0 ? '' : '+'} ${props.databoardStatistics.organizationGrowth == 0 ? '0.0' : props.databoardStatistics.organizationGrowth}%`}  <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.70615 8.79157C0.979499 9.06492 1.48064 9.06492 1.75399 8.79157L4.12301 6.42255L7.94989 10.295C8.22323 10.5683 8.72437 10.5683 8.99772 10.295L14.0091 5.2836L15.3759 6.60478C16.0592 7.33371 17.2437 6.83257 17.2437 5.8303V1.22893C17.2437 0.864465 16.8793 0.5 16.5148 0.5H11.9134C10.9112 0.5 10.41 1.68451 11.139 2.36788L12.4601 3.73462L8.49658 7.74374L4.62415 3.8713C4.3508 3.59795 3.84966 3.59795 3.57631 3.8713L0.205011 7.2426C-0.0683371 7.51595 -0.0683371 8.01708 0.205011 8.29043L0.70615 8.79157Z" fill="#68BC00" />
                  </svg>
                </span>
                </span>
                  : 
                  <span className="providers-title text-red">{`${props.databoardStatistics.organizationGrowth == 0 ? '' : ''}${props.databoardStatistics.organizationGrowth}%`} <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.70615 2.20843C0.979499 1.93508 1.48064 1.93508 1.75399 2.20843L4.12301 4.57745L7.94989 0.705011C8.22323 0.431663 8.72437 0.431663 8.99772 0.705011L14.0091 5.7164L15.3759 4.39522C16.0592 3.66629 17.2437 4.16743 17.2437 5.1697V9.77107C17.2437 10.1355 16.8793 10.5 16.5148 10.5H11.9134C10.9112 10.5 10.41 9.31549 11.139 8.63212L12.4601 7.26538L8.49658 3.25626L4.62415 7.1287C4.3508 7.40205 3.84966 7.40205 3.57631 7.1287L0.205011 3.7574C-0.0683371 3.48405 -0.0683371 2.98292 0.205011 2.70957L0.70615 2.20843Z" fill="#D22828" />
                  </svg>
                </span>
                </span>
                }
            </div>
          }
        </> : null
      }

      {
        props.dashboardItemShow && props.dashboardItemShow.includes('assignShiftsToday') ?
        <>
          {
            props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
            <div className="no-of-providers col-providers">
              <span className="no-of-title">Today assigned shifts</span>
              <span className="providers-price">{props.databoardStatistics && props.databoardStatistics.assignShiftsToday}</span>
            </div>
          }
        </> : null
      }

      {
        props.dashboardItemShow && props.dashboardItemShow.includes('facilityCount') ?
        <>
          {
            props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
            <div className="no-of-providers col-providers">
              <span className="no-of-title">Facilities</span>
              <span className="providers-price text-green">{props.databoardStatistics && props.databoardStatistics.facilityCount}</span>
              {
                  props.databoardStatistics && props.databoardStatistics.facilityGrowth >= 0 ? 
                  <span className="providers-title text-green">{`${props.databoardStatistics.facilityGrowth == 0 ? '' : '+'}${props.databoardStatistics.facilityGrowth == 0 ? '0.0' : props.databoardStatistics.facilityGrowth}%`}  <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.70615 8.79157C0.979499 9.06492 1.48064 9.06492 1.75399 8.79157L4.12301 6.42255L7.94989 10.295C8.22323 10.5683 8.72437 10.5683 8.99772 10.295L14.0091 5.2836L15.3759 6.60478C16.0592 7.33371 17.2437 6.83257 17.2437 5.8303V1.22893C17.2437 0.864465 16.8793 0.5 16.5148 0.5H11.9134C10.9112 0.5 10.41 1.68451 11.139 2.36788L12.4601 3.73462L8.49658 7.74374L4.62415 3.8713C4.3508 3.59795 3.84966 3.59795 3.57631 3.8713L0.205011 7.2426C-0.0683371 7.51595 -0.0683371 8.01708 0.205011 8.29043L0.70615 8.79157Z" fill="#68BC00" />
                  </svg>
                </span>
                </span>
                  : 
                  <span className="providers-title text-red">{`${props.databoardStatistics.facilityGrowth == 0 ? '' : ''}${props.databoardStatistics.facilityGrowth}%`} <span className="box-svg">
                  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.70615 2.20843C0.979499 1.93508 1.48064 1.93508 1.75399 2.20843L4.12301 4.57745L7.94989 0.705011C8.22323 0.431663 8.72437 0.431663 8.99772 0.705011L14.0091 5.7164L15.3759 4.39522C16.0592 3.66629 17.2437 4.16743 17.2437 5.1697V9.77107C17.2437 10.1355 16.8793 10.5 16.5148 10.5H11.9134C10.9112 10.5 10.41 9.31549 11.139 8.63212L12.4601 7.26538L8.49658 3.25626L4.62415 7.1287C4.3508 7.40205 3.84966 7.40205 3.57631 7.1287L0.205011 3.7574C-0.0683371 3.48405 -0.0683371 2.98292 0.205011 2.70957L0.70615 2.20843Z" fill="#D22828" />
                  </svg>
                </span>
                </span>
                }
            </div>
          }
        </> : null
      }

      {
        props.dashboardItemShow && props.dashboardItemShow.includes('pendingSecureShiftCount') ?
        <>
          {
            props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
            <div className="no-of-providers col-providers">
              <span className="no-of-title">Pending shift approval</span>
              <span className="providers-price text-green">{props.databoardStatistics && props.databoardStatistics.pendingSecureShiftCount}</span>
            </div>
          }
        </> : null
      }
       {
        props.dashboardItemShow && props.dashboardItemShow.includes('pendingSwapRequestCount') ?
        <>
          {
            props.databoardStatistics == undefined ? <div className="no-of-providers col-providers"> <CircularProgress style={{ display: 'flex' }} /> </div> :
            <div className="no-of-providers col-providers">
              <span className="no-of-title">Pending Swap Request</span>
              <span className="providers-price text-green">{props.databoardStatistics && props.databoardStatistics.pendingSwapRequestCount}</span>
            </div>
          }
        </> : null
      }
    </Fragment>
  );
}

export default ProgressCard;