import React from "react";
import { Typography } from "@material-ui/core";
import moment from 'moment';

const Footer = props => {
  return (
    <footer className="fixed-bottom">
      <Typography variant="div" className={"footer-text"}>
        {`©${moment().year()} TesselMed. All rights reserved.`}
      </Typography>
    </footer>
  );
};

export default Footer;
