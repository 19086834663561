import { combineReducers } from "redux";
import dashboard from "./dashboard";
import department from "./department";
import facility from "./facility";
import home from "./home";
import organization from "./organization";
import registration from "./registration";
import signin from "./signin";
import team from "./team";
import users from "./users";
import scheduler from "./scheduler";
import report from "./report";
import layout from "./layout";
import loader from "./loader"


export default combineReducers({
  layout,
  scheduler,
  dashboard,
  department,
  facility,
  home,
  organization,
  registration,
  signin,
  team,
  users,
  report,
  loader
});
