import React from "react";

export default ({width = "26px", height = "26px"}) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
            <path d="M21.19,17 L21.19,23.26 C20.915961,23.9317314 20.2654484,24.3732914 19.54,24.38 L1.68,24.38 C0.920864656,24.3713117 0.248794275,23.887261 0,23.17 L0,17 C0,15.0156736 1.60568126,13.405512 3.59,13.4 L7.45,13.4 L10.67,18.14 L13.88,13.4 L17.6,13.4 C19.5843187,13.405512 21.19,15.0156736 21.19,17 L21.19,17 Z M4.91996454,5.66 C4.91190436,7.95914825 6.2906473,10.0364126 8.41246414,10.9218738 C10.534281,11.807335 12.9806234,11.3263274 14.6092415,9.7034438 C16.2378597,8.08056022 16.7274933,5.63592972 15.849522,3.51100276 C14.9715507,1.3860758 12.8991625,-3.53861715e-05 10.6,-3.53861715e-05 C9.09541241,-0.00531659716 7.65062589,0.588655226 6.58484043,1.65068792 C5.51905497,2.71272062 4.91996454,4.15540301 4.91996454,5.66 Z M53.43,32.71 L53.43,39 C53.1514468,39.671153 52.4966617,40.1089912 51.77,40.11 L33.91,40.11 C33.1560363,40.1043427 32.4854959,39.6293766 32.23,38.92 L32.23,32.71 C32.235512,30.7256813 33.8456736,29.12 35.83,29.12 L39.69,29.12 L42.9,33.86 L46.11,29.12 L49.83,29.12 C51.8143264,29.12 53.424488,30.7256813 53.43,32.71 L53.43,32.71 Z M37.1499202,21.37 C37.1378518,23.6700366 38.5140104,25.7503602 40.6354903,26.6389765 C42.7569702,27.5275928 45.2049437,27.0490691 46.8356386,25.4269871 C48.4663334,23.804905 48.957814,21.3595 48.0804447,19.2333441 C47.2030755,17.1071881 45.1300687,15.7199909 42.83,15.7199909 C39.7024377,15.7144646 37.1610271,18.2424522 37.1499202,21.37 L37.1499202,21.37 Z" fill="#1C3A54"></path>
            <path d="M7.08,31.16 L8.59,41.7 L11,39 L11.39,39.3 C14.28,41.54 17.25,42.39 20.2,41.84 C23.135203,41.1420766 25.7535236,39.4845107 27.64,37.13 C24.1059229,38.514011 20.092948,37.7780407 17.28,35.23 L15.72,33.8 L18.11,31.16 L7.08,31.16 Z" fill="#0EAFE6"></path>
            <path d="M40.1,11.13 L38.63,0.85 L36.26,3.47 L35.88,3.18 C33.06,1 30.17,0.17 27.29,0.71 C24.4135573,1.37806741 21.8456981,2.99486761 20,5.3 C23.4459034,3.96002271 27.3527632,4.67563565 30.1,7.15 L31.64,8.55 L29.3,11.12 L40.1,11.13 Z" fill="#0EAFE6"></path>
        </g>
    </g>
  </svg>
);
