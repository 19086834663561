import React, { Component } from "react";
import { EventEmitter } from "../components/events";
import instance from "../utils/services/services";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable, { defaultThemes } from "react-data-table-component";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import LinearIndeterminate from "../common/ListLoader";

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  rows: {
    style: {
      minHeight: "38px"
    }, 
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize: 16
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};

export class DepartmentDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deptData: [],
      selectedColumn: [],
      isLoading: false
    };
  }

  fetchDepartments = async () => {
    this.setState({isLoading:true});
    const response = await instance.get(`Department/GetAll`);
    const { data } = response.data;
    let deptArray = [];
    if (data) {
      response.data.data.forEach(element => {
        deptArray.push(element);
      });
    }
    this.setState({ deptData: deptArray }, () => {
      this.setState({isLoading:false});
    });
  };

  componentWillMount = async () => {
    EventEmitter.on("deptCreated", event => this.fetchDepartments());
    await this.getColumns();
  };

  componentDidMount() {
    this.fetchDepartments();
  }

  getDepartmentById = row => {
    instance
      .get(`Department/GetById`, { params: { id: row.departmentId } })
      .then(res => {
        EventEmitter.emit("deptEdit", res.data.data);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getColumns = () => {
    const columns = [
      {
        name: "NAME",
        selector: "departmentName",
        sortable: true
      },
      {
        name: "FACILITY",
        selector: "extension.facilityName",
        sortable: true
      },
      {
        name: "	SPECIALITY",
        selector: "specialty",
        sortable: true,
        // hide: "md"
      },
      {
        name: "ACTION",
        cell: row => (
          <Button
            startIcon={<EditIcon />}
            style={{
              backgroundColor: "#33a6db",
              color: "white",
              fontWeight: 500
            }}
            title={"Edit"}
            variant="contained"
            size="small"
            onClick={() => this.getDepartmentById(row)}
          >
            Edit
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      }
    ];

    this.setState({ selectedColumn: columns });
  };

  render() {
    return (
      <DataTable
        title={
          <Grid container spacing={1}>
            <Grid item xs={2} md={2} sm={2}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Department
                </h1>
              </div>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
              {!this.state.isDepUser && (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 25
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      justifyContent: "center",
                      color: "white",
                      backgroundColor: "#33a6db",
                      fontSize: 8,
                      fontWeight: 600
                    }}
                    onClick={this.props.createNewDepartment}
                  >
                    <AddIcon />
                    Add
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        }
        subHeader={true}
        columns={this.state.selectedColumn}
        data={this.state.deptData}
        defaultSortField="departmentName"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={this.state.isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
          <LinearIndeterminate isLoading={this.state.isLoading} loaderTitle={"Loading..."}/>
        </div>}
        noDataComponent={
          <div
            style={{
              paddingTop: 150,
              paddingBottom: 150,
              fontSize: 14,
              fontWeight: 500
            }}
          >
            Departments not found!
          </div>
        }
      />
    );
  }
}

export default DepartmentDataTable;
