import React from "react";

export default ({width = "26px", height = "26px"}) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
            <path d="M25.6000102,4.9 C25.5944798,7.59936379 23.4027139,9.7836737 20.703347,9.7800001 C18.00398,9.77631722 15.8181751,7.5860423 15.8200102,4.88667348 C15.8218477,2.18730467 18.0106306,0 20.71,0 C22.0086431,0 23.2539516,0.51656472 24.1712925,1.43578156 C25.0886334,2.35499841 25.6026557,3.60135961 25.6000102,4.9 L25.6000102,4.9 Z M27.6,11.9 L23.78,11.9 L20.78,16.36 L17.78,11.9 L13.88,11.9 C12.6799263,11.905505 11.71,12.8799137 11.71,14.08 L11.71,25.58 C11.7145179,27.2498981 13.0409646,28.6161382 14.71,28.67 L14.71,41.88 C14.7802097,43.3873861 15.9421719,44.616833 17.4431996,44.7719392 C18.9442273,44.9270454 20.3330386,43.9611784 20.71,42.5 C21.0869614,43.9611784 22.4757727,44.9270454 23.9768004,44.7719392 C25.4778281,44.616833 26.6397903,43.3873861 26.71,41.88 L26.71,28.64 C28.376776,28.5810377 29.700317,27.2177905 29.71,25.55 L29.71,14.05 C29.71,12.8576383 28.7522488,11.8864086 27.56,11.87 L27.6,11.9 Z" fill="#1C3A54"></path>
            <path d="M33.91,11.91 C36.1688446,11.91 38,10.0788446 38,7.82 C38,5.56115537 36.1688446,3.73 33.91,3.73 C31.6511554,3.73 29.82,5.56115537 29.82,7.82 C29.82,10.0788446 31.6511554,11.91 33.91,11.91 Z" fill="#0EAFE6"></path>
            <path d="M7.53,11.91 C9.78884463,11.91 11.62,10.0788446 11.62,7.82 C11.62,5.56115537 9.78884463,3.73 7.53,3.73 C5.27115537,3.73 3.44,5.56115537 3.44,7.82 C3.44,10.0788446 5.27115537,11.91 7.53,11.91 Z" fill="#0EAFE6"></path>
            <path d="M12.58,30.26 C10.7468952,29.4095645 9.57568598,27.570766 9.58,25.55 L9.58,14.05 C9.58,13.91 9.58,13.78 9.58,13.65 L1.81,13.65 C0.812637103,13.6554651 0.00546512502,14.4626371 0,15.46 L0,25.07 C0.00966550578,26.4600228 1.11097084,27.5965699 2.5,27.65 L2.5,38.71 C2.49610926,40.0219988 3.48840254,41.122828 4.79376732,41.2546569 C6.09913211,41.3864858 7.29148982,40.5062848 7.55,39.22 C7.84506539,40.4322301 8.97684516,41.2506881 10.2204957,41.151196 C11.4641462,41.051704 12.4514108,40.0637224 12.55,38.82 L12.58,30.26 Z" fill="#0EAFE6"></path>
            <path d="M39.63,13.65 L31.8,13.65 C31.8,13.78 31.8,13.91 31.8,14.05 L31.8,25.55 C31.7940559,27.5628061 30.6248461,29.3906707 28.7999886,30.24 L28.7999886,38.71 C28.7961093,40.0219988 29.7884025,41.122828 31.0937673,41.2546569 C32.3991321,41.3864858 33.5914898,40.5062848 33.85,39.22 C34.1588902,40.4416146 35.3166846,41.252099 36.5702421,41.1242362 C37.8237996,40.9963733 38.7941143,39.9688218 38.85,38.71 L38.85,27.65 C40.2272364,27.5810806 41.311116,26.4489312 41.32,25.07 L41.32,15.46 C41.3171987,14.5073457 40.5802254,13.7180429 39.63,13.65 Z" fill="#0EAFE6"></path>
        </g>
    </g>
  </svg>
);
