import instance from "../utils/services/services";
import { EventEmitter } from "../components/events";

const checkDepUser = async userCode => {
  instance
    .get(`Department/GetByUserCode?`, {
      params: { userCode: userCode }
    })
    .then(response => {
      if (!response.error && response.data.data) {
        localStorage.setItem("is_dep_user", true);
      }
    })
    .catch(err => {
      // console.log(err);
    });
};

const checkFacUser = async userCode => {
  instance
    .get(`Facility/GetFacilityByUserCode?`, {
      params: { userCode: userCode }
    })
    .then(async response => {
      if (!response.error) {
        if (response.data.data) {
          localStorage.setItem("is_fac_user", true);
        } else {
          await checkDepUser(userCode);
        }
      }
    })
    .catch(err => {
      // console.log(err);
    });
};

const checkOrgUser = async userCode => {
  if (userCode) {
    instance
      .get(`Organization/GetOrganization?`, {
        params: { userCode: userCode }
      })
      .then(async response => {
        if (!response.error) {
          if (response.data.data) {
            localStorage.setItem("is_org_user", true);
          } else {
            await checkFacUser(userCode);
          }
        }
      })
      .catch(err => {
        // console.log(err);
      });
  }
};

export const setUserType = async userType => {
  switch (userType) {
    case 'admin':
      localStorage.setItem("loginUserType", userType);
      break;
    case 'organization':
      localStorage.setItem("loginUserType", userType);
      EventEmitter.emit("onUserSet", true);
      break;
    case 'facility':
      localStorage.setItem("loginUserType", userType);
      break;
    case 'department':
      localStorage.setItem("loginUserType", userType);
      break;
    case 'generic':
      localStorage.setItem("loginUserType", userType);
      break;
    default:
       break;
  }
};

export const getUserType = () => {
  let userType =  localStorage.getItem("loginUserType");
  return userType;
};

export const getAccessLevel = menu => {
  const menuType = ["users", "organization", "facility", "department", "team", "generic"];
  switch (menuType[menu]) {
    case "users":
      if (
        getUserType() === 'department'
      ) {
        return false;
      } else {
        return true;
      }
    case "organization":
      if (
        getUserType() === 'admin'
      ) {
        return true;
      } else if (
        (getUserType() === 'organization') ||
        (getUserType() === 'facility') ||
        (getUserType() === 'department')
      ) {
        return false;
      } else {
        return false;
      }
    case "facility":
      if (
        getUserType() === 'admin'
      ) {
        return true;
      } else if (
        getUserType() === 'organization'
      ) {
        return true;
      } else if (
        (getUserType() === 'facility') ||
        (getUserType() === 'department')
      ) {
        return false;
      } else {
        return false;
      }
    case "department":
      if (getUserType() === 'admin') {
        return true;
      } else if (
        (getUserType() === 'organization') ||
        (getUserType() === 'facility')
      ) {
        return true;
      } else if (
        getUserType() === 'department'
      ) {
        return false;
      } else {
        return false;
      }
    default:
      return false;
  }
};

export const getLoginUserType = () => {
  if (
    localStorage.getItem("isTesselMedAdmin") &&
    localStorage.getItem("isTesselMedAdmin") == "true"
  ) {
    return "isTesselMedAdmin";
  } else if (
    localStorage.getItem("is_org_user") &&
    localStorage.getItem("is_org_user") === "true"
  ) {
    return "is_org_user";
  } else if (
    localStorage.getItem("is_fac_user") &&
    localStorage.getItem("is_fac_user") === "true"
  ) {
    return "is_fac_user";
  } else if (
    localStorage.getItem("is_dep_user") &&
    localStorage.getItem("is_dep_user") === "true"
  ) {
    return "is_dep_user";
  }
};
