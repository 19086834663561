import React, { Component } from 'react';
import {Fragment} from 'react';

export default class Home extends Component {
    render() {
        return (
            <Fragment>
             <h1>DASHBOARD</h1>
            </Fragment>
        )
    }
}
