import axios from "axios";
import { getToken, setToken, baseURL } from "../../settings";

const instance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache"
  }
});

instance.interceptors.request.use(
  config => {
    let token = getToken();
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function(config) {
  const token = getToken(); //'Bearer '+ null
  config.headers.Authorization = token;
  return config;
});

//Used for server error catch we will implement later 
// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response.status === 401) {
//       setToken();
//       window.location.href = "/";
//     }
//     return Promise.reject(error);
//   }
// );

export default instance;
