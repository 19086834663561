import React, { useEffect, Fragment } from "react";
import instance from "../utils/services/services";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import EarningCard from "../containers/Dashboard/EarningCard";
import UsersCard from "../containers/Dashboard/UsersCard";
import ProgressCard from "../containers/Dashboard/ProgressCard";
import DashboardListCard from "../containers/Dashboard/DashboardListCard";
import { Bar } from 'react-chartjs-2';

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 40,
    minWidth: 300,
    flexDirection:"center"
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 4
  }),
  clearIndicator: base => ({
    ...base,
    padding: 4
  }),
  multiValue: base => ({
    ...base
  }),
  valueContainer: base => ({
    ...base,
    padding: "0px 6px",
    fontSize:16
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0
  })
};

// const LIST_OF_DASHBOARD_ITEM = ["organizationCount", "facilityCount", "departmentCount", "userCount", "providerCount", "pendingSecureShiftCount", "pendingSwapRequestCount", "openShiftsToday", "assignShiftsToday"];

const LIST_OF_DASHBOARD_SUPER_ADMIN = ["organizationCount", "facilityCount", "departmentCount", "userCount", "providerCount", "pendingSecureShiftCount"]

const LIST_OF_DASHBOARD_ORG_ADMIN = ["facilityCount", "departmentCount", "userCount", "providerCount", "pendingSecureShiftCount", "openShiftsToday", "assignShiftsToday", "nextOpenShift", "tesselMatchTrial"]

const LIST_OF_DASHBOARD_FACILILITY_ADMIN = ["departmentCount", "userCount", "providerCount", "pendingSecureShiftCount", "pendingSwapRequestCount", "openShiftsToday", "assignShiftsToday", "nextOpenShift"]

const Dashboard = (props) => {
  const [datesArray, setDatesArray] = React.useState([]);
  const [viewBy, setViewBy] = React.useState({ label: 'Monthly', value: 2 });
  const [viewByOption, setViewByOption] = React.useState([{ label: 'Yearly', value: 0 }, { label: 'Quaterly', value: 1 }, { label: 'Monthly', value: 2 }]);
  const [databoardStatistics, setDataboardStatistics] = React.useState(null);
  const [upcommingLeave, setUpcommingLeave] = React.useState([]);
  const [upcomingOpenShifts, setUpcomingOpenShifts] = React.useState([]);
  const [shifts, setShifts] = React.useState(null);
  const [topProviders, setTopProviders] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState(moment().utcOffset(0).format('MM-DD-YYYY'));
  const [dashboardItemShow, setDashboardItemShow] = React.useState(null);
  const [leastPickedupShifts, setLeastPickedupShifts] = React.useState([]);
  const [sourceSpend, setSourceSpend] = React.useState(null);
  const [openShiftArray, setOpenShiftArray] = React.useState([]);
  const [assinedShiftArray, setAssinedShiftArray] = React.useState([]);

  const getUserType = () => {
    let userType = localStorage.getItem("loginUserType");
    return userType;
  };

  const getDataArray = (datesArray) => {
    let dataArray = [];
    if(viewBy.value === 2) {
      datesArray.forEach((data, id)=> {
        dataArray.push(moment().set({date:data}).format('DD-MMM').toString())
      })
      return dataArray;
    } else if(viewBy.value === 1) {
      datesArray.forEach((data, id)=> {
        dataArray.push(moment().quarter(data).startOf('quarter').format('MMM-YYYY').toString() + '-' + moment().quarter(data).endOf('quarter').format('MMM-YYYY').toString())
      })
      return dataArray;
    } else if(viewBy.value === 0) {
      datesArray.forEach((data, id)=> {
        dataArray.push((moment().set({month:Number(data)}).format('MMM').toString()))
      })
      return dataArray;
    }
  }

  const data = {
    labels: getDataArray(datesArray),
    datasets: [
      {
        label: 'Open Shift Created',
        data: openShiftArray,
        backgroundColor: '#1F78B4',
      },
      {
        label: 'Assigned Shift Created',
        data: assinedShiftArray,
        backgroundColor: '#A6CEE3',
      }
    ]
  };

  const options = {
    plugins: {
      title: {
        display: false,
        text: 'Shifts'
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        time: {
          unit: viewBy.value === 1 ? "quarter" : "day",
          displayFormats: {
            quarter: 'HH:mm'
          }
        }
      },
      y: {
        stacked: true
      }
    },
  };


  const isAccess = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";
    let dashboard_show_cards = null
    if (isTesselMedAdmin) {
      dashboard_show_cards = LIST_OF_DASHBOARD_SUPER_ADMIN;
    } else if (isOrgUser) {
      dashboard_show_cards = LIST_OF_DASHBOARD_ORG_ADMIN;
    } else if (isFacility) {
      dashboard_show_cards = LIST_OF_DASHBOARD_FACILILITY_ADMIN;
    }
    setDashboardItemShow(dashboard_show_cards);
  }

  const getUpcomingOpenShifts = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/UpcomingOpenShifts?date=${currentDate}&daterange=${viewBy.value}&isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}`)
      .then(response => {
        if (response.data.status) {
          setUpcomingOpenShifts(response.data.data)
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getGetStatsDataboardData = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/GetStats?isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}&date=${currentDate}&daterange=${viewBy.value}`)
      .then(response => {
        if (response.data.status) {
          setDataboardStatistics(response.data.data)
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getUpcommingLeave = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/UpcomingLeaves?isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}&date=${currentDate}&daterange=${viewBy.value}`)
      .then(response => {
        if (response.data.status) {
          setUpcommingLeave(response.data.data)
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  /* Graph API */
  const getGetShiftsDataboard = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/GetShifts?isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}&date=${currentDate}&daterange=${viewBy.value}`)
      .then(response => {
        if (response.data.status) {
          if (viewBy.value === 2) {
            if (response.data.data && response.data.data.length > 0) {
              let openShiftData = [];
              let assignedShiftData = [];

              for (let i = 0; i <= (datesArray.length); i++) {
                response.data.data.forEach((shift, idx) => {
                  if (shift.assignShiftCount > 0 && (Number(moment(shift.assignShiftDate.split("T")[0]).format('D')) == Number(datesArray[i]))) {
                    openShiftData[i] = shift.openShiftCount;
                    assignedShiftData[i] = shift.assignShiftCount;
                  } else if (shift.openShiftCount > 0 && Number(moment(shift.openShiftDate.split("T")[0]).format('D')) == Number(datesArray[i])) {
                    openShiftData[i] = shift.openShiftCount;
                    assignedShiftData[i] = shift.assignShiftCount;
                  }
                })
              }
             
              setOpenShiftArray(openShiftData)
              setAssinedShiftArray(assignedShiftData)
            }
          } else if (viewBy.value === 1) {
            if (response.data.data && response.data.data.length) {
              let openShiftData = [];
              let assignedShiftData = [];
              response.data.data.forEach((shift, idx) => {
                openShiftData[idx] = shift.openShiftCount;
                assignedShiftData[idx] = shift.assignShiftCount;
              })
              setOpenShiftArray(openShiftData)
              setAssinedShiftArray(assignedShiftData)
            }
          } else if (viewBy.value === 0) {
            if (response.data.data && response.data.data.length) {
              let openShiftData = [];
              let assignedShiftData = [];
              const resData = response.data.data;
              for(let i=0; i <= (datesArray.length-1); i++) {
                let tempI = i
                resData.forEach((shift) => {
                  if(shift.assignShiftCount > 0 && shift.assignShiftMonth == (tempI+1)) {
                    openShiftData[i] = shift.openShiftCount ? shift.openShiftCount : '0';
                    assignedShiftData[i] = shift.assignShiftCount ? shift.assignShiftCount : '0';
                  } else if(shift.openShiftCount > 0 && shift.openShiftMonth== (tempI+1)) {
                    openShiftData[i] = shift.openShiftCount ? shift.openShiftCount : '0';
                    assignedShiftData[i] = shift.assignShiftCount ? shift.assignShiftCount : '0';
                  }
                })
              }
              setOpenShiftArray(openShiftData)
              setAssinedShiftArray(assignedShiftData)
            }
          }
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getTopProvidersList = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/TopProviders?isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}&date=${currentDate}&daterange=${viewBy.value}`)
      .then(response => {
        if (response.data.status) {
          setTopProviders(response.data.data)
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getLeastPickedupShiftList = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/LeastPickedupShifts?date=${currentDate}&daterange=${viewBy.value}&isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}`)
      .then(response => {
        if (response.data.status) {
          setLeastPickedupShifts(response.data.data)
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getSourceSpendGrow = () => {
    let isFacility = getUserType() === "facility";
    let isOrgUser = getUserType() === "organization";
    let isTesselMedAdmin = getUserType() === "admin";

    instance
      .get(`Dashboard/SourceSpend?date=${currentDate}&daterange=${viewBy.value}&isTesselMedAdmin=${isTesselMedAdmin}&isFacilityUser=${isFacility}&isOrgUser=${isOrgUser}`)
      .then(response => {
        if (response.data.status) {
          setSourceSpend(response.data.data)
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getAllDashboard = () => {
    getGetStatsDataboardData()
    getGetShiftsDataboard()
    getUpcommingLeave()
    getUpcomingOpenShifts()
    getTopProvidersList()
    getLeastPickedupShiftList();
    getSourceSpendGrow()
  }

  const onChangeByType = (selected) => {
    setViewBy(selected)
    let dayArray = [];
    let datex = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(datex.getFullYear(), datex.getMonth() + 1, 0);
    lastDay = moment(lastDay).format('D')
    for (let i = 1; i <= (selected.value == 2 ? lastDay : selected.value == 1 ? 4 : 12) ; i++) {
      if(selected.value == 0) {
        dayArray.push(((i-1).toString()))
      } else {
        dayArray.push((i.toString()))
      }
    }
    setDatesArray(dayArray);
    // getAllDashboard()
  }

  useEffect(() => {
    setSourceSpend(sourceSpend)
  }, [sourceSpend])


  useEffect(() => {
    // setDatesArray(datesArray)
    getAllDashboard()
  }, [datesArray])

  useEffect(() => {
    isAccess();
    const bgImage = require('../backg.png');
    document.body.style.backgroundImage = `url(${bgImage})`;
    let dayArray = [];
    var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay = moment(lastDay).format('D')
    for (let i = 1; i <= Number(lastDay); i++) {
      dayArray.push((i.toString()))
    }
    setDatesArray(dayArray);
  }, [])

  return (
    <Fragment>
      <Grid className="dashboard-container" container spacing={3}>
        <Grid item xs={8}>
          <div className="dashboard-left-bar">

            <div className="d-flex mt-2">
              <h2 className="dashboard-title" style={{marginRight:20}}>Dashboard</h2>
              {/* <span className="dashboard-date">Jan 01, 2021 - june 30, 2021 </span> */}

              <Select
                styles={customStyles}
                className="dashboard-title"
                value={viewBy}
                onChange={(selected) => {
                  onChangeByType(selected)
                }}
                options={viewByOption}
                placeholder="View By"
              />
            </div>

            <div className="chart-img">
              <Bar data={data} options={options} />
            </div>
            <div className="table-wrapper-main d-flex flex-wrap row">
              <DashboardListCard upcommingLeave={upcommingLeave} listType={'upcommingLeave'} />
              <DashboardListCard upcomingOpenShifts={upcomingOpenShifts} listType={'upcomingOpenShifts'} />
              <DashboardListCard leastPickedupShifts={leastPickedupShifts} listType={'leastPickedupShifts'} />
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <EarningCard sourceSpend={sourceSpend}></EarningCard>
          <div className="providers-boxs">
            <UsersCard topProviders={topProviders}></UsersCard>
            <ProgressCard
              databoardStatistics={databoardStatistics}
              dashboardItemShow={dashboardItemShow}
            ></ProgressCard>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Dashboard;