import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton'
import AppBar from 'material-ui/AppBar'
import { MuiThemeProvider } from 'material-ui/styles';
import { ReactMUIDatatable } from "react-material-ui-datatable";
import axios from 'axios';
import ReactDataGrid from 'react-data-grid';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
// First way to import
import { GridLoader } from 'react-spinners';

export  class AccoutInfoGrid extends Component {
    state = {
        data: null,
        notValidNpi:false,
        loading: true
      };
      
       columns = [
        {
          key: "number",
          name: "NPI"
        },
        {
            key: "first_name",
            name: "First Name"
          },
        {
          key: "last_name",
          name: "Last Name"
        },
        {
          key: "gender",
          name: "Gender"
        },
        {
         key: "desc",
         name: "Speciality"
          }
      ];


      rows = [];

    componentDidMount() {
        this.fetchNpiData();
      }


   
      extend(obj, src) {
        for (var key in src) {
            if (src.hasOwnProperty(key)) obj[key] = src[key];
        }
        return obj;
    }

     fetchNpiData=()=>{
      var dataPrequel={};
      var data={};
     if(this.props.values.NPI){
      axios.get(`https://tesselmed.azurewebsites.net/Users/Search?`,{params:{NPI:this.props.values.NPI}}).then(res => {
        this.setState({loading:false});
        if(!res.data.data.Errors){
            // this.setState({notValidNpi:false});
             const dataGeneral = res.data.data.results[0];
            const dataBasic = res.data.data.results[0].basic;
            const dataTax = res.data.data.results[0].taxonomies[0];
       
            dataPrequel= this.extend(dataBasic, dataTax);
            data= this.extend(dataPrequel, dataGeneral);
            this.rows.push(data);
            this.props.getGridData(data);
        }else{
          this.setState({notValidNpi:true});
        }
       });
      
      }
    }
     
    continue = e=>{
        e.preventDefault();
        this.props.nextStep();
    }
    back = e=>{
        e.preventDefault();
        this.props.prevStep();
    }
    render() {
     
        const {values,handleChange}=this.props;
        const divStyle = {
          paddingLeft: '30%',
          paddingTop: '10%',
         // border: '5px solid pink'
        };
        const divStyleError = {
          paddingLeft: '35%',
          paddingTop: '15%',
         // border: '5px solid pink'
        };
        const divStyleSpinner = {
          //paddingLeft: '3%',
          paddingTop: '10%',
         // border: '5px solid pink'
        };
        const override = css`
                  display: block;
                  margin: 0 auto;
                  border-color: red;
                  
              `;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                   
                    <div>
                    {this.state.loading?
                    
                 <div className='sweet-loading'  style={divStyleSpinner}>
                    <GridLoader
                      css={override}
                      sizeUnit={"px"}
                      size={30}
                      color={'#FFA500'}
                      loading={this.state.loading}
                    />
                  </div> 
                     :<div>
                    {!this.state.notValidNpi?
                    <div style={divStyle}>
                    <h6>Our NPI database links this NPI with the following information</h6>
                    <br/>
                    <h6>Is this your information ?</h6>
                    <br/>
                    <ReactDataGrid
                    columns={this.columns}
                    rowGetter={i => this.rows[i]}
                    rowsCount={1}
                    minHeight={100}
                    minWidth={600} />
                    <br/>
                    {/* <RaisedButton
                    label="Not my information"
                    secondary={true}
                    style={styles.button}
                    onClick={this.back}/> */}

                    <Button variant="outlined" color="secondary" onClick={this.back}>
                       Not my information
                    </Button>&nbsp;&nbsp;
                   <Button variant="outlined" color="primary" onClick={this.continue}>
                        Yes,proceed with this information
                    </Button>
                    
                    {/* <RaisedButton
                    label="Yes,proceed with this information"
                    primary={true}
                    style={styles.button}
                    onClick={this.continue}/> */}
                    </div>
                    :<div style={divStyleError}>
                    <h1>SORRY!</h1>
                    <br/>
                    <h6>The NPI number is invalid.</h6>
                    <br/>
                    
                    <br/>
                    <Button variant="outlined" color="secondary" onClick={this.back}>
                    Retry
                    </Button>
                    {/* <RaisedButton
                    label="Retry"
                    primary={true}
                    style={styles.button}
                    onClick={this.back}/>
                 */}
                    </div>
                 
                    }
                </div>
               
                    }
                  </div>  
              </React.Fragment>
            </MuiThemeProvider>
             
        )
    }
}
const styles = {
    button:{
        margin:20
    }
}
export default AccoutInfoGrid;