import React, { Component } from 'react'
import {EnterNpi} from '../components/EnterNpi'
import {AccoutInfoGrid} from '../components/AccoutInfoGrid'
import {AccountDataVerification} from '../components/AccountDataVerification'
import {SmsCodeVerification} from '../components/SmsCodeVerification'
import {VerificationSuccess} from '../components/VerificationSuccess';
import Stepper from 'react-stepper-horizontal';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CssBaseline from '@material-ui/core/CssBaseline';
import {faLock} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../Tesselmed Logo.png';
import moment from 'moment';

export  class QhpRegistration extends Component {
    state={
        step:1,
        userId:'',
        firstName:'',
        lastName:'',
        address:'',
        speciality:[],
        NPI:'',
        mobileNumber:'',
        email:'',
        password:'',
        dob:'',
        verificationCode:'',
        steps: [{
            title: 'Enter NPI',
            // href: 'http://example1.com',
            // onClick: (e) => {
            //   e.preventDefault()
            // }
          }, {
            title: 'Check your info',
            // href: 'http://example2.com',
            // onClick: (e) => {
            //   e.preventDefault()
            // }
          }, {
            title: 'Enter Data for verification',
            // href: 'http://example3.com',
            // onClick: (e) => {
            //   e.preventDefault()
            // }
          }, {
            title: 'Verify OTP',
            // href: 'http://example4.com',
            // onClick: (e) => {
            //   e.preventDefault()
            // }
          }],
          currentStep: 0,
    }

    //PROCEED TO NEXT STEP
    
    nextStep =()=>{
      const {step,steps, currentStep} = this.state;
      this.setState({
          step:step + 1
      });
      this.setState({
        currentStep: currentStep + 1,
    });

    }
    prevStep =()=>{
        const {step,steps, currentStep} = this.state;
        this.setState({
            step:step - 1,
            
        });
        this.setState({
            currentStep: currentStep - 1,
            
        });
  
      }
      handleChange= input => e => {
          this.setState({[input]: e.target.value});
      }

      setUserId=(userid)=>{
        this.setState({
            userId:userid
            
        });
      }

      getGridData=(data)=>{
        this.setState({
            firstName:data.first_name,
            lastName:data.last_name,
            address:data.addresses[1].address_1,
            speciality:data.desc,
            
        });
      }
    render() {
        const {step}=this.state;
        const {NPI,mobileNumber,email,password,dob,verificationCode,steps, currentStep,userId,firstName,lastName,address,speciality}=this.state;
        const values={NPI,mobileNumber,email,password,dob,verificationCode,steps, currentStep,userId,firstName,lastName,address,speciality};
        const formContainer={
            backgroundColor:'#FFFFFF',
            height:'600px',
            width:'50%',
            // display: 'flex',
            // flexDirection: 'column'
        };
        const outContainer={
          //  backgroundColor:'#F8F9F9',
          paddingTop:'3%',
          // display: 'flex',className='container'
          // flexDirection: 'column' className='container'style={formContainer} style={outContainer}
            
        };
        const buttonStyle={
          marginLeft:'80%'
        };
         const footerText={
          marginLeft:'45%'
        };
        const footerStyle={
          marginBottom:'0%'
        };
        const stepperStyle = {
          backgroundColor: '#F9E70C',
          fontSize: '13px',
          color: '#F95B0C'
          
        }
        const iconStyle={
          paddingBottom:'7%',
          paddingRight:'7%',
          fontSize: '13px',
        }
       
        switch(step) {
            case 1:
                return(
                  <div>
                   <React.Fragment>
                        <CssBaseline />
                     <div>
                      <AppBar position="static">
                          <Toolbar>
                           {/* <Typography variant="h4">
                              TesselMed
                            </Typography> */}
                         
                          <img src={logo} width="6%" height="3%" alt="Logo" />
                      
                                            
                            <Button color="inherit" style={buttonStyle}> <FontAwesomeIcon style={iconStyle} icon={faLock}/> Login</Button>
                          </Toolbar>
                       </AppBar>
                       </div>
                    <div >
                    <Stepper className="stepper" steps={ steps } activeStep={ currentStep } disabledSteps={ [2] } />
                    <EnterNpi
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    values={values}/>
                    </div> 
                   <div className="fixed-bottom">
                    <AppBar position="static">
                          <Toolbar>
                            <Typography variant="h6" style={footerText}>
                            {`©${moment().year()} TesselMed. All rights reserved.`}
                            </Typography>
                         </Toolbar>
                        </AppBar>
                  </div>
                  </React.Fragment>
                 </div>
                )
            case 2:
                 return(
                  <div>
                  <React.Fragment>
                   <CssBaseline />
                     <div>
                      <AppBar position="static">
                          <Toolbar>
           
                          <img src={logo} width="6%" height="3%" alt="Logo" />
                            <Button color="inherit" style={buttonStyle}>Login</Button>
                          </Toolbar>
                       </AppBar>
                       </div>
                    <div>
                    <Stepper  steps={ steps } activeStep={ currentStep } disabledSteps={ [2] } />
                    <AccoutInfoGrid
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                    getGridData={this.getGridData}/>
                    </div>
                      <div className="fixed-bottom">
                      <AppBar position="static">
                            <Toolbar>
                              <Typography variant="h6" style={footerText}>
                              {`©${moment().year()} TesselMed. All rights reserved.`}
                              </Typography>
                           </Toolbar>
                          </AppBar>
                    </div>
                    </React.Fragment>
                    </div>
                  
               )
            case 3:
                return(
                 <div>
                 <React.Fragment>
                    <CssBaseline />
                   <div>
                      <AppBar position="static">
                          <Toolbar>
                            
                          <img src={logo} width="6%" height="3%" alt="Logo" />
                            <Button color="inherit" style={buttonStyle}>Login</Button>
                          </Toolbar>
                       </AppBar>
                       </div>
                    <div>
                    <Stepper steps={ steps } activeStep={ currentStep }  style={stepperStyle}/>
                    <AccountDataVerification
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                    setUserId={this.setUserId}/>
                    </div>
                    <div className="fixed-bottom">
                      <AppBar position="static">
                            <Toolbar>
                              <Typography variant="h6" style={footerText}>
                              {`©${moment().year()} TesselMed. All rights reserved.`}
                              </Typography>
                           </Toolbar>
                          </AppBar>
                    </div>
                    </React.Fragment>
                    </div>
                   
                )
            case 4:
                 return(
                  <div>
                  <React.Fragment>
                        <CssBaseline />
                    <div>
                      <AppBar position="static">
                          <Toolbar>
                            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
                              <MenuIcon />
                            </IconButton> */}
                            <img src={logo} width="6%" height="3%" alt="Logo" />
                            <Button color="inherit" style={buttonStyle}>Login</Button>
                          </Toolbar>
                       </AppBar>
                       </div>
                    <div>
                    <Stepper steps={ steps } activeStep={ currentStep } />
                    <SmsCodeVerification
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}/>
                    </div>
                    <div className="fixed-bottom">
                    <AppBar position="static">
                          <Toolbar>
                            <Typography variant="h6" style={footerText}>
                            {`©${moment().year()} TesselMed. All rights reserved.`}
                            </Typography>
                         </Toolbar>
                        </AppBar>
                  </div>
                  </React.Fragment>
                  </div>

                 )
            case 5:
                 return(
                   <div>
                   <React.Fragment>
                        <CssBaseline />
                     <div>
                      <AppBar position="static">
                          <Toolbar>
                            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
                              <MenuIcon />
                            </IconButton> */}
                            <img src={logo} width="6%" height="3%" alt="Logo" />
                            <Button color="inherit" style={buttonStyle}>Login</Button>
                          </Toolbar>
                       </AppBar>
                       </div>
                    <div style={outContainer}>
                    <div className='container' style={formContainer}>
                     
                      <VerificationSuccess/>
                    </div>
                    </div>
                    <div className="fixed-bottom">
                    <AppBar position="static">
                          <Toolbar>
                            <Typography variant="h6" style={footerText}>
                            {`©${moment().year()} TesselMed. All rights reserved.`}
                            </Typography>
                         </Toolbar>
                        </AppBar>
                  </div>
                  </React.Fragment>
                    </div>
                 )
        }
        return (
            <div>
                
            </div>
        )
    }
}
export default QhpRegistration;