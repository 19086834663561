import React, { useState, useEffect } from "react";
import { EventEmitter } from "../components/events";
import instance from "../utils/services/services";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { RadioButton } from "primereact/radiobutton";
import DataTable, { defaultThemes } from "react-data-table-component";
import Typography from "@material-ui/core/Typography";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import { getAccessLevel, getUserType } from "../settings/userSetting";
import LinearIndeterminate from "../common/ListLoader";
import SearchIcon from '@material-ui/icons/Search';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Alert from "sweetalert2";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const customStyles = {
  header: {
    style: {
      minHeight: "56px"
    }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default
    }
  },
  headCells: {
    style: {
      fontWeight: "600",
      fontSize: 14
    }
  },
  rows: {
    style: {
      minHeight: "38px"
    },
    stripedStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    highlightOnHoverStyle:{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      fontWeight:'bold',
      fontSize:16
    }
  },
  pagination: {
    style: {
      align: "center",
      textAlign: "center",
      justifyContent: "center"
    }
  }
};


const UserTable = props => {

  const [userData, setUserData] = useState([]);
  const [token, setToken] = useState("");
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [onlyProvider, setOnlyProvider] = useState(false);
  const [onlyUser, setOnlyUser] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onProviderChange = () => {
    setOnlyProvider(!onlyProvider);
    setOnlyUser(false);
    setShowAll(false);
    setIsLoading(false);
  };

  const onUserChange = () => {
    setOnlyProvider(false);
    setOnlyUser(!onlyUser);
    setShowAll(false);
    setQuery('');  
  };

  const onAllChange = () => {
    setOnlyProvider(false);
    setOnlyUser(false);
    setShowAll(!showAll);
    setQuery('');
  };

  const getUserById = row => {
    if (row.userCode !== undefined) {
      instance
        .get(`Users/GetUserByCode`, { params: { id: row.userCode } })
        .then(res => {
          EventEmitter.emit("userEdit", res.data);
        })
        .catch(error => {
          // console.log(error);
        });
    } else {
      toast.error("Please try again.");
    }
  };

  const fetchUser = async () => {
    setIsLoading(true)
    await instance.get(`Users/GetAll`).then(response => {
      if (response.status) {
        setIsLoading(false);
        setUserData(prevUserData => (response.data.data !== null ? response.data.data : []));
        // setUserData((response.data.data !== null ? response.data.data : []));
        // selectUserTypeFilter()
      } else {
        setIsLoading(false);
      }
    });
  };

  const selectUserTypeFilter = () => {
    if (onlyProvider) {
      setFilteredData(userData.filter(c => c.isProvider == true))
    } else if (onlyUser) {
      setFilteredData(userData.filter(c => c.isProvider == false))
    } else {
      setFilteredData(userData);
    }
  };

  useEffect(() => {
    setFilteredData(filteredData)
  },[filteredData])
  
  useEffect(()=>{
    if(query == "") {
      filterUserTable();
    }
  }, [query]);

  useEffect(()=>{
    selectUserTypeFilter(!onlyProvider)
  }, [onlyProvider]);

  useEffect(()=>{
    selectUserTypeFilter(!onlyUser)
  }, [onlyUser]);

  useEffect(()=>{
    selectUserTypeFilter(!showAll)
  }, [showAll]);

  useEffect(()=>{
    selectUserTypeFilter(userData)
  }, [userData]);
  

  useEffect(() => {
    setToken(localStorage.getItem("api_token"));
    if (localStorage.getItem("api_token") != "") {
      fetchUser();
    }
    EventEmitter.on("userCreated", event => fetchUser());
  },[]);

  const userTypeTemplate = (rowData, column) => {
    var userType = rowData.isProvider ? "QHP" : "User";
    return userType;
  }

  const handleChangeQuery = event => {
    setQuery(event.target.value);
  };

  const updateSearch = () => {
    setQuery('')
    // filterUserTable()
  }

  const filterUserTable = () => {
    if (query.length > 0) {
      let filteredDataArray = [];
      filteredDataArray = userData.filter(
        c => {
          return c["firstName"] && c["firstName"] != "" && c["firstName"]
          .toLowerCase()
          .includes(query.toLowerCase()) ||
          c["lastName"] && c["lastName"] != "" && c["lastName"].toLowerCase().includes(query.toLowerCase())
        }
      );

      if (onlyProvider) {
        setFilteredData(prevFilterData => {
          return filteredDataArray.filter(c => c.isProvider == true)
        });
      } else if (onlyUser) {
        setFilteredData(prevFilterData => {
          return filteredDataArray.filter(c => c.isProvider == false)
        });
      } else {
        setFilteredData(filteredDataArray);
      }
    } else {
      selectUserTypeFilter()
    }
  };

  // const confirmDeleteUser = (row) => {

  //       Alert.fire({
  //         title:
  //           "Are you sure you want delete this user?",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes",
  //         cancelButtonText: "Close"
  //       }).then(result => {
  //         if (result.value !== undefined && result.value) {
  //           instance
  //             .post(`ProviderSwap/UpdateProviderSwap`, {
  //               userId: row
  //             })
  //             .then(response => {
  //               if (!response.error && response.data.data) {
  //                 toast.success("Swap request updated successfully.");
  //                 this.getAllSwapRequest();
  //               } else {
  //                 toast.error(response.error);
  //               }
  //             })
  //             .catch(err => {
  //               // console.log(err);
  //             });
  //         }
  //       });
  //     }
    


  const columns = [
  {
    name: "FIRST NAME",
    selector: "firstName",
    sortable: true,
    cell: row => row.isProvider ? <HtmlTooltip
    title={
      <React.Fragment>
        <Typography variant="h4" gutterBottom color="inherit">{`Phone: ${row.phone}`}</Typography>
      </React.Fragment>
    }
  >
    <p style={{cursor: 'pointer'}}>{row.firstName}</p>
  </HtmlTooltip>  : <p>{row.firstName}</p>
  },
  {
    name: "LAST NAME",
    selector: "lastName",
    sortable: true,
    // hide: "md"
  },
  {
    name: "NPI",
    selector: "npi",
    sortable: true
  },
  {
    name: "QHP",
    selector: "isProvider",
    cell: row => (
      <Typography
        variant="overline"
        style={{
          height: 20,
          display: "inline-flex",
          padding: "4px 8px",
          flexGrow: 0,
          fontSize: 10,
          fontWeight: 600,
          minWidth: 20,
          alignItems: "center",
          flexShrink: 0,
          lineHeight: 10,
          whiteSpace: "nowrap",
          borderRadius: 5,
          backgroundColor: row.isProvider ? "#659658" : "#939393",
          color: "white",
          justifyContent: "center"
        }}
      >
        {row.isProvider ? "QHP" : "Admin"}
      </Typography>
    ),
    sortable: true
  },
  {
    name: "ACTION",
    width: '250px',
    cell: row => (
      <div style={{ justifyContent: "space-between" }}>
        <Button
          startIcon={<EditIcon />}
          style={{
            backgroundColor: "#33a6db",
            color: "white",
            fontWeight: 500
          }}
          title={"Edit"}
          variant="contained"
          size="small"
          disabled={getUserType() === 'department' && (!row.isProvider)}
          onClick={() => (getUserType() !== 'department' || row.isProvider) ? getUserById(row) : console.log('access denied')}
        >
          Edit
        </Button>
       
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true
  }
];

  return (
    <DataTable
        striped={false}
        title={
          <Grid container spacing={1}>
             <Grid item xs={1} md={1} sm={1}>
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h1
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  Users
                </h1>
              </div>
            </Grid>
            <Grid xs={2} md={2} sm={2}>
            <div style={{ justifyContent: "center", alignItems: "center", marginLeft: 30 }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "#33a6db",
                    fontSize: 8,
                    fontWeight: 600,
                    paddingLeft: 1,
                    paddingRight: 1
                  }}
                  onClick={props.createNewUser}
                >
                <AddIcon />
                Add
                </Button>
                </div>
            </Grid>
            <Grid xs={8} md={8} sm={8}>
              {
                localStorage.getItem("isTesselMedAdmin") == 'true' ? 
                <div style={{ justifyContent: "center", alignItems: "center", marginRight: 10 }}>
                  <Button
                      variant="contained"
                      size="small"
                      style={{
                        justifyContent: "center",
                        color: "white",
                        backgroundColor: "#33a6db",
                        fontSize: 8,
                        fontWeight: 600,
                        paddingLeft: 1,
                        paddingRight: 1
                      }}
                      onClick={()=>props.onClickExport()}
                    >
                    Export
                  </Button>
                </div>
                : null
              }
            </Grid>
          </Grid>
        }
        subHeader={true}
        subHeaderAlign={"left"}
        subHeaderComponent={
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} sm={6} style={{ paddingTop: 15 }}>   
              <RadioButton
                name="showall"
                onChange={onAllChange}
                checked={showAll}
              />
              <label
                style={{ paddingLeft: "0.5%" }}
                htmlFor="cb1"
                className="p-radiobutton-label"
              >
                Show All
              </label>
              <RadioButton
                style={{ paddingLeft: "5%" }}
                name="provider"
                onChange={onProviderChange}
                checked={onlyProvider}
              />
              <label
                style={{ paddingLeft: "3%" }}
                htmlFor="cb1"
                className="p-radiobutton-label"
              >
                Show Only QHP
              </label>
              <RadioButton
                style={{ paddingLeft: "5%" }}
                name="user"
                onChange={onUserChange}
                checked={onlyUser}
              />
              <label
                style={{ paddingLeft: "3%" }}
                htmlFor="cb2"
                className="p-radiobutton-label"
              >
                Show Only Admin
              </label>
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <TextField
                id="outlined-dense-team"
                value={query}
                placeholder="Search for Admin/QHP"
                onChange={handleChangeQuery}
                onInput={filterUserTable}
                onSelect={filterUserTable}
                margin="dense"
                variant="outlined"
                title={"Search for Admin/QHP"}
                inputProps={{ maxLength: 15 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                        {query ? <IconButton onClick={() => updateSearch()}><CloseOutlined /></IconButton> : <IconButton onClick={() => filterUserTable()}><SearchIcon /></IconButton>}
                    </InputAdornment>
                   )
                  }}
                style={{ paddingBottom: 10 }}
                required
              />
            </Grid>
          </Grid>
        }
        columns={columns}
        data={filteredData}
        defaultSortField="firstName"
        sortIcon={<SortIcon />}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        paginationPerPage={10}
        customStyles={customStyles}
        persistTableHead={true}
        progressPending={isLoading}
        highlightOnHover
        progressComponent={<div style={{ paddingTop: 150, textAlign:"center", alignItems: 'center', paddingBottom: 150, fontSize: 14, justifyContent:"center", fontWeight: 500 }}>
          <LinearIndeterminate isLoading={isLoading} loaderTitle={"Loading..."}/>
        </div>}
        noDataComponent={
          <div
            style={{
              paddingTop: 150,
              paddingBottom: 150,
              fontSize: 14,
              fontWeight: 500
            }}
          >
            Users not found!
          </div>
        }
      />
  );
};

export default UserTable;
