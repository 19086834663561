import React, { Component } from "react";
import { Fragment } from "react";
import UserTable from "../containers/UserTable";
import AddUsers from "../containers/AddUsers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { EventEmitter } from "../components/events";
import { signIn } from "../actions/signin";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import instance from "../utils/services/services";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import moment from "moment";

export class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isUpdate: false,
      isDepUser: false,
      isFacUser: false,
      isOrgUser: false,
      userCode: 0,
      isTesselMedAdmin: "",
      newUser: false
    };
  }

  optionsRole = [
    { value: "0", label: "Default" },
    { value: "1", label: "ADMIN" },
    { value: "2", label: "Doctor" },
    { value: "3", label: "BILLER" }
  ];
  optionsProvider = [
    { value: "0", label: "DUR24" },
    { value: "1", label: "DURFEE" },
    { value: "2", label: "BILL" }
  ];
  optionsAdminType = [
    { value: "0", label: "USER ADMINITRATOR" },
    { value: "1", label: "FULL ADMINISTRATOR" }
  ];
  optionsAdminType = [
    { value: "0", label: "USER ADMINITRATOR" },
    { value: "1", label: "FULL ADMINISTRATOR" }
  ];
  optionsUserType = [
    { value: "0", label: "PM" },
    { value: "1", label: "PM,EHR" }
  ];

  componentWillMount() {
    const bgImage= require('../backg.png');
    document.body.style.backgroundImage = `url(${bgImage})`;
    this.setState({ userCode: localStorage.getItem("userCode") });
  }

  componentDidMount() {
    EventEmitter.on("userEdit", () => {
      this.setState({ isPaneOpen: true, isUpdate: true, newUser: false });
    });

    EventEmitter.on("closeModal", () => {
      this.setState({ isPaneOpen: false });
    });
    EventEmitter.on("userCreated", () => {
      this.setState({ isPaneOpen: false });
    });
  }

  createNewUser = () => {
    this.setState({ isPaneOpen: true, isUpdate: false, newUser: true });
  };

  onClickExport = async () => {
    this.setState({ isLoading: true });
    await instance.get(`Users/GetAllProviderDetails`).then((response) => {
      if (response.data.status) {
        let providers = (response.data.data ? response.data.data : [])
        let userExportArray = [];
        providers && providers.forEach((provider)=> {
            let row = {
              "FIRST NAME": provider.firstName,
              "LAST NAME": provider.lastName,
              "NAME": provider.name,
              "NPI": provider.npi,
              "PHONE": provider.phone,
              "EMAIL ADDRESS": provider.emailId,
              "DEPARTMENT":provider.department,
              "FACILITY":provider.facility,
              "ADDRESS1": provider.addressLine1,
              "ADDRESS2": provider.addressLine2
            };
            userExportArray.push(row);
          });
          if(userExportArray && userExportArray.length) {
            this.exportReport(userExportArray)
          } else {
            toast.error(
              `There is no any records found.`
            );
          }
        }
    });
  }

  exportReport = csvData => {
    let fileName =
      "provider_users_list" +
      "_" +
      moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A")
        .format("MM_DD_YYYY");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    toast.success(
      `File exported successfully. Found the ${csvData.length} records`
    );
  };

  render() {
    return (
      <Fragment>
        <UserTable createNewUser={this.createNewUser} onClickExport={this.onClickExport}></UserTable>
        <Modal
          aria-labelledby="modal-title"
          open={this.state.isPaneOpen}
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "center",
            right: 0,
            left: "none",
            height: "100%",
            width: "720px",
            maxWidth: "100%",
            position: "fixed",
            zIndex: 1010
          }}
          onClose={() => {
            this.setState({ isPaneOpen: false });
          }}
        >
          <Slide
            direction="left"
            timeout={350}
            in={this.state.isPaneOpen}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                overflowY: "initial !important"
              }}
            >
              <h2
                style={{
                  background:
                    "linear-gradient(90deg, #208bca 38%, #40b6e6 100%)",
                  paddingBottom: 13,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 13,
                  color: "white"
                }}
                id="user-modal-title"
              >
                {this.state.isUpdate ? "Update User" : "Add User"}
              </h2>
              <div
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                  padding: 20,
                  overflowY: "auto",
                  paddingBottom: 90
                }}
              >
                <AddUsers newUser={this.state.newUser ? this.state.newUser : false}/>
              </div>
            </div>
          </Slide>
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch);
};

const mapStateToProps = state => ({
  signInPayload: state.signin.signInPayload
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
