import React, { Component } from 'react'
// import TextField from 'material-ui/TextField';
import TextField from '@material-ui/core/TextField';
import RaisedButton from 'material-ui/RaisedButton'
import AppBar from 'material-ui/AppBar'
import { MuiThemeProvider } from 'material-ui/styles';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

export class EnterNpi extends Component {
   
      
    continue = e=>{
        e.preventDefault();
        this.props.nextStep();
    }
    render() {
        const divStyle = {
            paddingLeft: '20%',
            paddingTop: '15%',
            margin: 'auto'
           // border: '5px solid pink'
          };
          const textFieldStyle = {
           width:'70%'
           // border: '5px solid pink'
          };
        const {values,handleChange}=this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                {/* <AppBar title="Provider Registration"></AppBar> */}
                    <div  className="container row"  style={divStyle}>
                    <TextField   style={textFieldStyle}
                    variant="outlined"
                    margin="normal"
                    label="NPI"
                    onChange={handleChange('NPI')}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Search"
                              onClick={this.continue}
                            >
                              <Search /> 
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                   
                    {/* <RaisedButton
                    label="Continue"
                    primary={true}
                    style={styles.button}
                    disabled={!values.NPI}
                    onClick={this.continue}/> */}
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
             
        )
    }
}
const styles = {
    button:{
        margin:20
    }
}
export default EnterNpi;