import React, { Component, Fragment } from "react";
import SwapRequestDataTable from "../containers/SwapRequest/SwapRequestDataTable";
import LeaveRequestDataTable from "../containers/LeaveRequest/LeaveRequestDataTable";
import DepartmentApprovalDataTable from "../containers/DepartmentApproval/DepartmentApprovalDataTable";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../components/TabPanel";

export class SwapRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRequestStatus: {
        label: "Waiting for approval",
        value: "Open"
      },
      currentLeaveStatus: {
        label: "All",
        value: "All"
      },
      currentLeaveType: {
        label: "All",
        value: "All"
      },
      currentDepartmentApprovalStatus:{
        label: "All",
        value: "All"
      },
      tabValue: 0
    };
  }

  componentWillMount() {
    const bgImage= require('../backg.png');
    document.body.style.backgroundImage = `url(${bgImage})`;
  }

  handleRequestStatusChange = value => {
    this.setState({ currentRequestStatus: value });
  };

  handleDepartmentApprovalStatusChange = value => {
    this.setState({ currentDepartmentApprovalStatus: value });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleLeaveStatusChange = value => {
    this.setState({ currentLeaveStatus: value });
  };

  handleLeaveTypesChange = value => {
    this.setState({ currentLeaveType: value });
  };

  render() {
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 9999 }) };
    const { currentRequestStatus, currentLeaveStatus, currentLeaveType, currentDepartmentApprovalStatus } = this.state;
    const a11yProps = index => {
      return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
      };
    };
    return (
      <Fragment>
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          style={{backgroundColor:"white", marginBottom:0, bottom:0, paddingBottom:0, marginLeft:26}}
          wrapped
          {...a11yProps("one")}
        >
          <Tab label="Swap Request" style={{backgroundColor:"white", fontSize:14}} />
          <Tab label="Leave Request" style={{backgroundColor:"white", fontSize:14}}/>
          <Tab label="Shift Request" style={{backgroundColor:"white", fontSize:14}}/>
        </Tabs>
        <TabPanel value={this.state.tabValue} index={0}>
          <SwapRequestDataTable
            selectedRequestStatus={currentRequestStatus}
            handleRequestStatusChange={this.handleRequestStatusChange}
            selectStyles={selectStyles}
            currentRequestStatus={
              currentRequestStatus.value !== "Status"
                ? currentRequestStatus.value
                : null
            }
          ></SwapRequestDataTable>
        </TabPanel>
        <TabPanel value={this.state.tabValue} index={1}>
          <LeaveRequestDataTable
            selectedRequestStatus={currentLeaveStatus}
            handleRequestStatusChange={this.handleLeaveStatusChange}
            selectStyles={selectStyles}
            currentLeaveStatus={
              currentLeaveStatus.value !== "All"
                ? currentLeaveStatus.value
                : null
            }
            handleLeaveTypesChange={this.handleLeaveTypesChange}
            selectedLeaveType={currentLeaveType}
            currentLeaveType={
              currentLeaveType.value !== "All"
                ? currentLeaveType.value
                : null
            }
          ></LeaveRequestDataTable>
        </TabPanel>
        <TabPanel value={this.state.tabValue} index={2}>
          <DepartmentApprovalDataTable
            selectedDepartmentApprovalStatus={currentDepartmentApprovalStatus}
            handleDepartmentApprovalStatusChange={this.handleDepartmentApprovalStatusChange}
            selectStyles={selectStyles}
            currentDepartmentApprovalStatus={
              currentDepartmentApprovalStatus.value !== "All"
                ? currentDepartmentApprovalStatus.value
                : null
            }
          ></DepartmentApprovalDataTable>
        </TabPanel>
      </Fragment>
    );
  }
}
export default SwapRequest;
