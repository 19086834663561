import React from 'react';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import { render } from "react-dom";
import './styles/scss/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

render(
    <ClearCacheProvider auto={6000000}>
      <App />
    </ClearCacheProvider>,
    document.getElementById('root')
);

// render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
