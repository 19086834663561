import React from "react";

export default ({ width = "26px", height = "26px" }) => (
  <svg width={width} height={height} viewBox="0 0 48 52" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Swap" transform="translate(0.000000, 37.000000)" fill="#0EAFE6" fillRule="nonzero">
            <polygon id="Shape-path" points="36.0028409 0 38 0 38 4 0 4 0 0"></polygon>
        </g>
        <g id="dept" transform="translate(6.000000, 0.000000)" fill="#1C3A54" fillRule="nonzero">
            <path d="M0,35 L0,0 L27,0 L27,35 L0,35 Z M15.12,10 L21.6,10 L21.6,4 L15.12,4 L15.12,10 Z M15.12,21 L21.6,21 L21.6,15 L15.12,15 L15.12,21 Z M15.12,32 L21.6,32 L21.6,26 L15.12,26 L15.12,32 Z M4.32,10 L10.8,10 L10.8,4 L4.32,4 L4.32,10 Z M4.32,21 L10.8,21 L10.8,15 L4.32,15 L4.32,21 Z M4.32,26 L4.32,32 L10.8,32 L10.8,26 L4.32,26 Z" id="Shape"></path>
        </g>
    </g>
  </svg>
);
