import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

import MomentUtils from '@date-io/moment';
import React from "react";
import { createStyles } from "@material-ui/styles";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const styles = createStyles(theme => ({
 
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#33a5db"
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        MuiTypography:{
          body1:{
            fontSize:30
          }
        }
      },
      dayLabel: {
        fontSize:'12px',
        color:"#33a5db"
      }
    },
    MuiPickersDay: {
      day: {
        fontSize:30
      },
      daySelected: {
        backgroundColor: "#33a5db"
      },
      dayDisabled: {},
      current: {}
    },
    MuiPickersModal: {
      dialogAction: {}
    }
  }
});

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format('YYY,');
  }
}

const StaticDatePicker = ({onChange, defaultValue, selectedDays, disablePrevMonth, disableNextMonth, minDate, maxDate, disableWeekDays, ...rest}) => {
  return (
    <ThemeProvider theme={materialTheme}>
    <MuiPickersUtilsProvider utils={LocalizedUtils}>
      <DatePicker
        autoOk
        variant="static"
        orientation="landscape"
        openTo="date"
        // leftArrowIcon={true}
        // rightArrowIcon={true}
        minDate={minDate ? minDate : moment()}
        maxDate={maxDate ? maxDate : moment()}
        disableToolbar
        // disablePrevMonth={disablePrevMonth ? disablePrevMonth : false}
        // disableNextMonth={disableNextMonth ? disableNextMonth : false}
        value={defaultValue}
        shouldDisableDate={(date) => {
          return (disableWeekDays.indexOf(date.day()) !== -1 ? true : false)
        }}
        onChange={onChange}
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
          const isSelected = isInCurrentMonth && selectedDays.filter((element) => { 
            return ((new Date(element).getFullYear()) === (new Date(day).getFullYear())) && (new Date(element).getMonth() === (new Date(day).getMonth()) && (new Date(element).getDate()) === (new Date(day).getDate()));
          });
          return <div className={isSelected && isSelected.length !== 0 ? 'MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-daySelected' : null }>
                  {dayComponent}
             </div>;
        }}
      />
     </MuiPickersUtilsProvider>
     </ThemeProvider>
  );
};

export default withStyles(styles)(StaticDatePicker);