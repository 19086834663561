import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getToken, isTesselMedAdmin, isSchedulerView } from "../settings";
import { Typography, Container, List, ListItem, ListItemText, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#137BC0",
    marginBottom: theme.spacing(3),
    paddingTop: 20,
    fontSize: "2.5rem", // Updated title font size
    fontWeight: "bold",
  },
  subtitle: {
    color: "#137BC0",
    marginBottom: theme.spacing(2),
    fontSize: "1.75rem", // Updated subtitle font size
    fontWeight: "500",
  },
  bodyText: {
    fontSize: "1.125rem", // Updated body font size for better readability
    lineHeight: "1.75rem", // Increase line-height for better spacing
    marginBottom: theme.spacing(2),
  },
  listItemText: {
    margin: 0,
    marginBottom: theme.spacing(1),
    fontSize: "1.125rem", // Updated list item font size
    lineHeight: "1.75rem",
  },
  contactContent: {
    fontSize: "1.125rem", // Updated contact section font size
    paddingBottom: 100,
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.backgroundImage = `url(${null})`;
  }, []);

  if (
    !isSchedulerView() &&
    !isTesselMedAdmin() &&
    localStorage.getItem("generic") === "false" &&
    localStorage.getItem("department") === "true" &&
    getToken()
  ) {
    return <Redirect to="/layout/users" />;
  } else if (!isSchedulerView() && isTesselMedAdmin() && getToken()) {
    return <Redirect to="/layout/dashboard" />;
  } else if (
    !isSchedulerView() &&
    !isTesselMedAdmin() &&
    localStorage.getItem("generic") === "true" &&
    getToken()
  ) {
    return <Redirect to="/layout/generic-dashboard" />;
  } else if (!isSchedulerView() && !isTesselMedAdmin() && localStorage.getItem("generic") === "false" && getToken()) {
    return <Redirect to="/layout/dashboard" />;
  }

  if (isSchedulerView() || (localStorage.getItem("generic") === "true" && getToken())) {
    return localStorage.getItem("generic") === "true" ? (
      <Redirect to="/layout/generic-dashboard" />
    ) : (
      <Redirect to="/layout/scheduler" />
    );
  }

  return (
    <Container>
      <Typography variant="h3" className={classes.title}>
        TesselMed Mobile App Privacy Policy
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Introduction
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        TesselMed provides a cloud-based platform and mobile app designed to streamline the sourcing, scheduling, and staffing of healthcare providers. Our goal is to help hospital administrators and department leaders focus on high-level processes, ensure effective clinical operations, and provide exceptional patient care.
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Information We Collect
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        We collect the following information through the TesselMed mobile app:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Healthcare Organization Registration Data: When you complete the Healthcare Organization registration form, we collect necessary details to set up your account."
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="User Account Information: We collect information from providers who download the app, including their profiles and preferences."
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Usage Data: We may collect data related to app usage, such as interactions, preferences, and device information."
            className={classes.listItemText}
          />
        </ListItem>
      </List>

      <Typography variant="h4" className={classes.subtitle}>
        How We Use Collected Information
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        We use the collected information for the following purposes:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Account Setup and Management: To create and manage user accounts."
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Scheduling and Staffing: To facilitate scheduling and staffing of healthcare providers."
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Improving App Performance: To enhance the app's functionality and user experience."
            className={classes.listItemText}
          />
        </ListItem>
      </List>

      <Typography variant="h4" className={classes.subtitle}>
        Data Sharing
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        We do not sell or share personal information with third parties. However, we may share aggregated and anonymized data for analytical purposes or to comply with legal requirements.
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Data Retention and Deletion
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        We retain your data only for as long as necessary to provide the services you requested and to comply with legal obligations. You may request deletion of your personal data at any time by contacting us (see contact details below).
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Data Security
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        We implement industry-standard security measures to protect your data, including encryption, access controls, and regular security assessments. However, no system is 100% secure, and we cannot guarantee absolute security.
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Your Rights
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        You have the right to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Access: Request access to your personal data."
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Correction: Request corrections to any inaccuracies in your data."
            className={classes.listItemText}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Deletion: Request deletion of your data, subject to legal obligations."
            className={classes.listItemText}
          />
        </ListItem>
      </List>

      <Typography variant="h4" className={classes.subtitle}>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph className={classes.bodyText}>
        If you have any questions or concerns regarding our privacy policy, please contact us at{" "}
        <Link href="mailto:contact@tesselmed.com">contact@tesselmed.com</Link>.
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Changes to This Policy
      </Typography>
      <Typography variant="body1" className={classes.contactContent}>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" will be updated. Please review the policy periodically for any updates.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
